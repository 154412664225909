import { Component, Input, OnInit } from '@angular/core';

import { StatusTrackingServer } from './status-tracking-server.model';

@Component( {
	selector: 'ct-status-tracking-server',
	templateUrl: './status-tracking-server.component.html',
	styleUrls: ['./status-tracking-server.component.css']
} )
export class StatusTrackingServerComponent implements OnInit
{
	@Input() statusTrackingServer: StatusTrackingServer;

	constructor() { }

	ngOnInit ()
	{
	}
}
