import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import htmlToImage from './html-to-image';

import { AdImage, AdImageType, AdImageSizes, AdImageSources } from '../images.model';
import { Model } from '../../../device-manager/devices/devices.model';
import { AngularEditorConfig } from './angular-editor/angular-editor-config';

@Component( {
	selector: 'ct-new-image',
	templateUrl: './new.component.html',
	styleUrls: ['./new.component.css']
} )
export class NewComponent implements OnInit {
	imageData: string;
	preCropImageData: string;
	imageType: AdImageType;
	imageCount: number;
	isSelected: boolean;
	isLoaded = false;
	isCropped = false;
	isResized = false;
	isLoadedToCropper = false;
	imageFillColor = '#ffffff';
	imageWidth: number = AdImageSizes.find( imageSize => imageSize.UnitModelID === this.data.unitModel.Id && imageSize.Type === this.data.imageType.Type ).Width;
	imageHeight: number = AdImageSizes.find( imageSize => imageSize.UnitModelID === this.data.unitModel.Id && imageSize.Type === this.data.imageType.Type ).Heigth;
	get isSelect(): boolean { return this.adImageSource === AdImageSources.Select; }
	get isCrop(): boolean { return this.adImageSource === AdImageSources.Crop; }
	get isHtml(): boolean { return this.adImageSource === AdImageSources.Html; }

	editorConfig: AngularEditorConfig = {
		customClasses: [],
		defaultFontName: 'Arial',
		defaultFontSize: '5',
		defaultParagraphSeparator: '',
		editable: true,
		enableToolbar: true,
		imagefillColor: this.imageFillColor,
		imageHeight: this.imageHeight,
		imageWidth: this.imageWidth,
		placeholder: 'Enter text here...',
		spellcheck: true,
		translate: 'no',
		uploadUrl: null
	};

	private file: File;
	private files: FileList;
	private adImage: AdImage;
	private adImages: AdImage[] = [];
	private adImageSource: AdImageSources;

	constructor(
		public dialogRef: MatDialogRef<NewComponent>,
		@Inject( MAT_DIALOG_DATA ) public data: { facilityId: number, unitModel: Model, imageType: AdImageType }
	) { }

	ngOnInit() {
	}

	/**
	 * Get an array of selected pre-formatted image files.
	 */
	onSelectChange( event: Event ) {
		this.files = ( event.target as HTMLInputElement ).files;
		this.isSelected = true;
		this.imageCount = this.files.length;
		this.adImageSource = AdImageSources.Select;
	}

	/**
	 * Get an array of one selected image file to be cropped.
	 */
	onCropChange( event: Event ) {
		this.files = ( event.target as HTMLInputElement ).files;
		this.isSelected = true;
		this.imageCount = this.files.length;
		this.adImageSource = AdImageSources.Crop;
		this.readImageFile();
	}

	/**
	 * Image file will come from HTML.
	 */
	imageInputHtml() {
		this.adImageSource = AdImageSources.Html;
		this.isSelected = true;
	}

	/**
	 * Process new image data.
	 * If `isCroped` the image is ready, close dialog returning an array.
	 * If `isHtml` create new image, close dialog returning an array.
	 * If 'isSelect` read next image file.
	 */
	newImage() {
		if ( this.isCropped ) {
			this.adImages.push( this.adImage );
			this.dialogRef.close( this.adImages );
		}

		if ( this.isHtml ) {
			const editor = document.getElementById( 'editor' );			// @ViewChild works only locally to the component.

			htmlToImage.toPng( editor )
				.then( ( imageData: string ) => {
					const now = new Date();
					const fileName = ( now.getHours() * 3600 + now.getSeconds() ) + '_' + this.data.unitModel.Name + '_' + this.data.imageType.Code + '.png';
					this.adImage = new AdImage( 0, this.imageName( fileName ), this.data.imageType.Type, now, fileName, imageData.slice( 22 ), null,
						this.data.facilityId, this.data.unitModel.Id );
					this.adImages.push( this.adImage );
					this.dialogRef.close( this.adImages );
				} )
				.catch( () => console.error( 'ERROR in domToImage' ) );
		}

		if ( this.isSelect ) {
			this.readImageFile();
		}
	}

	/**
	 * Initates file read operation. Sets up `onload` handler.
	 */
	readImageFile( index: number = 0 ) {
		const fileReader = new FileReader();

		this.isLoaded = false;
		fileReader.onload = event => this.fileReaderOnLoad( event, index );
		fileReader.readAsDataURL( this.files[index] );
	}

	/**
	 * Get the loaded file.
	 * Resize to required aspect ratio.
	 * If `isSelect` file source create new AdImage, add to AdImage array.
	 * If it is the last formatted file close the dialog returning image array.
	 * Otherwise process next file.
	 */
	async fileReaderOnLoad( event: Event, index: number ) {
		this.imageData = ( event as any ).target.result;

		this.file = this.files[index];
		this.isLoaded = true;

		if ( this.isCrop ) {
			this.preCropImageData = this.imageData;
		}

		await this.imageResizeFill( this.imageData, this.imageWidth / this.imageHeight, this.imageFillColor );

		if ( this.isSelect ) {
			this.adImage = new AdImage( 0, this.imageName( this.file.name ), this.data.imageType.Type,
				new Date( this.file.lastModified ), this.file.name, this.imageData.slice( 22 ), null, this.data.facilityId, this.data.unitModel.Id );

			this.adImages.push( this.adImage );

			if ( this.files.length > index + 1 ) {
				this.readImageFile( index + 1 );
			} else {
				this.dialogRef.close( this.adImages );
			}
		}
	}

	/**
	 * Image was cropped. Create new image.
	 */
	imageCropped( imageData: any ) {
		this.isCropped = true;
		this.adImage = new AdImage( 0, this.imageName( this.file.name ), this.data.imageType.Type,
			new Date( this.file.lastModified ), this.file.name, imageData.base64.slice( 22 ), null, this.data.facilityId, this.data.unitModel.Id );
	}

	/**
	 * Image to be formatted has been loaded.
	 */
	imageCropLoaded() {
		this.isLoadedToCropper = true;
	}

	imageCropLoadFailed() {
		console.log( 'Crop Load failed' );
	}

	async onImageFillColorChange( event: Event ) {
		this.editorConfig.imagefillColor = this.imageFillColor = ( event.currentTarget as HTMLInputElement ).value;

		if ( this.isCrop ) {
			await this.imageResizeFill( this.preCropImageData, this.imageWidth / this.imageHeight, this.imageFillColor );
		}
	}

	/**
	 * Fills an image to a specified aspect ratio.
	 */
	private async imageResizeFill( imageData: string, aspectRatio: number, fillColor: string ): Promise<void> {

		const image = await this.loadImage( imageData );

		const imageRatio = image.width / image.height;
		const canvas = document.createElement( 'canvas' );
		const ctx = canvas.getContext( '2d' );

		if ( aspectRatio > 1 ) {
			canvas.height = image.height;
			canvas.width = image.width * aspectRatio / imageRatio;
		} else {
			canvas.height = image.height * imageRatio / aspectRatio;
			canvas.width = image.width;
		}

		const hRatio = canvas.width / image.width;
		const vRatio = canvas.height / image.height;
		const ratio = Math.min( hRatio, vRatio );
		const centerShiftX = ( canvas.width - image.width * ratio ) / 2;
		const centerShiftY = ( canvas.height - image.height * ratio ) / 2;

		ctx.fillStyle = fillColor;
		ctx.fillRect( 0, 0, canvas.width, canvas.height );
		ctx.drawImage( image, 0, 0, image.width, image.height, centerShiftX, centerShiftY, image.width * ratio, image.height * ratio );

		this.isResized = true;

		this.imageData = canvas.toDataURL();
	}

	/**
	 * Creates an Image element. Set the source and after loaded returns promise with the Image.
	 */
	private loadImage( imageData: string ): Promise<HTMLImageElement> {
		return new Promise( ( resolve ) => {
			const image = new Image();

			image.onload = () => resolve( image );
			image.src = imageData;
		} );
	}

	/**
	 * Returns image name by removing file extension and prefixing fileName with "MMDD_".
	 */
	private imageName( fileName: string ): string {

		const now = new Date();

		return ( '0' + ( now.getMonth() + 1 ) ).slice( -2 ) + ( '0' + now.getDate() ).slice( -2 ) + '_' + fileName.split( '.' )[0];
	}
}
