<mat-toolbar>
    <ct-trackers-actions [trackers]="trackers$ | async" [selected]="selected"></ct-trackers-actions>

    <span class="fill-remaining-space"></span>

    <ct-trackers-actions-right [refresh]="refresh$ | async" (refreshChange)="onRefreshChange($event)">
    </ct-trackers-actions-right>
</mat-toolbar>

<ct-trackers-list [trackers]="trackers$ | async" (select)="onSelect($event)" (toggleVerbose)="onToggleVerbose($event)">
</ct-trackers-list>