import { Injectable, Optional } from '@angular/core';
import { BingMapControlLoader } from './bing-map-control-loader';

export class BingMapControlLoaderLazyConfig {
	scriptURL = 'www.bing.com/api/maps/mapcontrol';
	protocol = 'https';
	branch = 'experimental';
}

const DefaultConfiguration = new BingMapControlLoaderLazyConfig();

@Injectable()
export class BingMapControlLoaderLazy extends BingMapControlLoader {
	private scriptLoadingPromise: Promise<void>;

	constructor( @Optional() private config: BingMapControlLoaderLazyConfig ) {
		super();

		if ( this.config === null || this.config === undefined ) {
			this.config = DefaultConfiguration;
		}
	}

	load(): Promise<void> {
		if ( this.scriptLoadingPromise ) {
			return this.scriptLoadingPromise;
		}

		const script = document.createElement( 'script' );
		const callbackName: string = 'carttracbingmaps' + new Date().getMilliseconds();

		script.type = 'text/javascript';
		script.async = true;
		script.defer = true;
		script.src = this.getScriptURL( callbackName );

		this.scriptLoadingPromise = new Promise<void>( ( resolve: () => any, reject: ( error: Event ) => any ) => {
			( window as any )[callbackName] = () => { resolve(); };
			script.onerror = ( error: Event ) => { reject( error ); };
		} );

		document.body.appendChild( script );

		return this.scriptLoadingPromise;
	}

	private getScriptURL( callbackName: string ): string {
		const protocol: string = ( this.config && this.config.protocol ) || DefaultConfiguration.protocol;
		const scriptURL: string = this.config.scriptURL || DefaultConfiguration.scriptURL;
		const branch: string = this.config.branch || DefaultConfiguration.branch;
		const queryParams: { [key: string]: string } = { callback: callbackName };

		if ( branch ) {
			queryParams['branch'] = branch;		// tslint:disable-line
		}

		const params: string = Object.keys( queryParams )
			.map( ( k: string, i: number ) => {
				let param = ( i === 0 ) ? '?' : '&';
				return param += `${k}=${queryParams[k]}`;
			} )
			.join( '' );

		return `${protocol}://${scriptURL}${params}`;
	}
}
