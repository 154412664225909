<button mat-icon-button matTooltip="New Message" (click)="new()">
	<mat-icon class="material-icons">note_add</mat-icon>
</button>
<button mat-icon-button matTooltip="Delete Message" (click)="delete()" [disabled]="!selected">
	<mat-icon class="material-icons">delete_forever</mat-icon>
</button>
<button mat-icon-button matTooltip="Edit Message" (click)="edit()" [disabled]="!selected">
	<mat-icon class="material-icons">edit</mat-icon>
</button>
<button mat-icon-button matTooltip="Send Message" (click)="send()" [disabled]="!selected">
	<mat-icon class="material-icons">send</mat-icon>
</button>