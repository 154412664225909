import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { catchError, map, switchMap, tap, mergeMap } from 'rxjs/operators';

import * as FacilitiesActions from './facilities.actions';
import * as AdminActions from '../admin/admin.actions';
import * as RoutingActions from '../routing/routing.actions';

import { FacilitiesService } from './facilities.service';
import { Facility, FacilitySettingsDTO } from './facilities.model';
import { DialogService } from '../shared/dialogs/dialog.service';
import { WebApiError } from '../shared/web-api/web-api-error';

import * as fromCartTrac from '../cart-trac.reducers';

@Injectable()
export class FacilitiesEffects {

	@Effect()
	list$ = this.actions$.pipe(
		ofType( FacilitiesActions.LIST ),
		map( ( action: FacilitiesActions.List ) => action.loginId ),
		switchMap( loginId => this.facilitiesService.list( loginId ).pipe(
			map( ( facilities: Facility[] ) => {
				if ( facilities.length === 0 ) {
					this.dialogService.alert( 'FacilitiesEffects', 'No Facilities Found' )
						.subscribe( () => this.store$.dispatch( new RoutingActions.Go( { path: ['/login'] } ) ) );
				}

				return new FacilitiesActions.ListSuccess( facilities );
			} ),
			catchError( ( error: WebApiError ) => of( new FacilitiesActions.ListFail( error ) ) )
		) )
	);

	@Effect()
	selected$ = this.actions$.pipe(
		ofType( FacilitiesActions.SELECTED ),
		map( ( action: FacilitiesActions.Selected ) => action.facility ),
		map( facility => new AdminActions.Build( facility.Id )
		)
	);

	@Effect()
	settingsSave$ = this.actions$.pipe(
		ofType( FacilitiesActions.SETTINGS_SAVE ),
		map( ( action: FacilitiesActions.SettingsSave ) => action.settings ),
		switchMap( settings => this.facilitiesService.settingsSave( settings ).pipe(
			map( ( settingsSaved: FacilitySettingsDTO ) => new FacilitiesActions.SettingsSaveSuccess( settingsSaved ) ),
			catchError( ( error: WebApiError ) => of( new FacilitiesActions.SettingsSaveFail( error ) ) )
		) )
	);

	@Effect( { dispatch: false } )
	webApiFail$ = this.actions$.pipe(
		ofType( FacilitiesActions.LIST_FAIL, FacilitiesActions.SETTINGS_SAVE_FAIL ),
		tap( ( action: FacilitiesActions.ListFail | FacilitiesActions.SettingsSaveFail ) =>
			this.dialogService.webApiError( `FacilitiesEffects - ${action.type}`, action.webApiError ).subscribe(
				() => this.store$.dispatch( new RoutingActions.Go( { path: ['/login'] } ) )
			)
		)
	);

	constructor(
		private actions$: Actions,
		private store$: Store<fromCartTrac.State>,
		private dialogService: DialogService,
		private facilitiesService: FacilitiesService
	) { }
}
