import { Injectable } from '@angular/core';
import { HubConnection } from '@aspnet/signalr';
import * as signalR from '@aspnet/signalr';
import { Observable, Subject } from 'rxjs';
import { WebApiService } from '../../shared/web-api/web-api.service';
import { GPSUpdate } from './gps-updates.model';

@Injectable()
export class GPSUpdatesService {

	private gpsUpdate: Subject<GPSUpdate> = new Subject();
	private hubConnection: HubConnection;
	private facilityId: number;

	constructor( private webApiService: WebApiService ) {
	}

	init( facilityId: number ): Observable<GPSUpdate> {

		this.facilityId = facilityId;

		this.hubConnection = new signalR.HubConnectionBuilder()
			.withUrl( this.webApiService.serverUrl + '/hubs/facility' )
			.configureLogging( signalR.LogLevel.Information )
			.build();

		this.hubConnection.start()
			.then( () => this.hubConnection.invoke( 'JoinGroup', this.facilityId.toString() ) )
			.catch( error => console.error( error.toString() ) );

		this.hubConnection.on( 'GPSUpdate', ( gpsUpdate: GPSUpdate ) => this.gpsUpdate.next( gpsUpdate ) );

		return this.gpsUpdate.asObservable();
	}

	close() {
		this.hubConnection.invoke( 'LeaveGroup', this.facilityId.toString() );
	}
}
