import { Component, EventEmitter, OnInit, OnDestroy, Input, Output, ViewChildren, QueryList } from '@angular/core';
import { CdkDragDrop, CdkDropList } from '@angular/cdk/drag-drop';
import { MatCard } from '@angular/material/card';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';

import * as fromCartTrac from '../../cart-trac.reducers';
import * as AdsActions from '../ads/ads.actions';
import * as AdminActions from '../../admin/admin.actions';
import * as ListsActions from './lists.actions';

import { AdList } from './lists.model';
import { Ad } from '../ads/ads.model';
import { AdImageType, AdImageTypes } from '../images/images.model';
import { Course, Hole } from '../../courses/courses.model';


@Component( {
	selector: 'ct-ad-manager-lists',
	templateUrl: './lists.component.html',
	styleUrls: ['./lists.component.css']
} )
export class ListsComponent implements OnInit, OnDestroy {

	@Input() adLists: AdList[];
	@Input() courseNumber: string;
	@Input() published: boolean;
	@Input() selected: AdList;
	@Output() listImages = new EventEmitter<AdImageType>();
	@Output() clearImages = new EventEmitter<AdImageType>();

	ads$: Observable<Ad[]>;
	courses$: Observable<Course[]>;
	holes$: Observable<Hole[]>;
	selectedAd$: Observable<Ad>;

	@ViewChildren( 'adsDropList' ) adsListsRef: QueryList<CdkDropList>;

	constructor( private store$: Store<fromCartTrac.State> ) {
		this.ads$ = this.store$.select( fromCartTrac.getAdManagerAds );
		this.courses$ = this.store$.select( fromCartTrac.getCourses );
		this.holes$ = this.store$.select( fromCartTrac.getHoles );
		this.selectedAd$ = this.store$.select( fromCartTrac.getSelectedAd );
	}

	ngOnInit() {
	}

	ngOnDestroy() {
	}

	dropAdImage( event: CdkDragDrop<MatExpansionPanel[], MatCard[]> ) {
		this.store$.dispatch( new AdsActions.New(
			new Ad( 0, +event.item.element.nativeElement.id, this.selected.AdListID, '', 1, 15, null, null, null, null, null, '', this.selected.Type ) ) );
	}

	opened( adList: AdList ) {
		this.listImages.emit( this.adImageType( adList.Type ) );
		this.store$.dispatch( new AdsActions.List( adList.AdListID ) );
		this.store$.dispatch( new ListsActions.Select( adList ) );
		this.store$.dispatch( new AdminActions.SelectAdImageType( this.adImageType( adList.Type ) ) );
		this.store$.dispatch( new ListsActions.SetDropList( this.adsListsRef.find( ref => +ref.id === adList.AdListID ) ) );
	}

	closed( adList: AdList ) {
		this.clearImages.emit( this.adImageType( adList.Type ) );
	}

	adImageType( type: number ): AdImageType {
		return AdImageTypes.find( adImageType => adImageType.Type === type );
	}
}
