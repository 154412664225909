import * as AlarmsActions from './alarms.actions';
import { Alarm, AlarmPhoneNumber } from './alarms.model';
import { Store, createFeatureSelector, createSelector } from '@ngrx/store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

export const phoneNumbersAdapter = createEntityAdapter<AlarmPhoneNumber>( {
	selectId: ( phoneNumber: AlarmPhoneNumber ) => phoneNumber.Id,
	sortComparer: false
} );

export interface PhoneNumbersState extends EntityState<AlarmPhoneNumber> { }

const initialPhoneNumbersState: PhoneNumbersState = phoneNumbersAdapter.getInitialState( {} );

export interface State {
	alarm: Alarm;
	progress: boolean;
	phoneNumbersState: PhoneNumbersState;
}

const initialState: State = {
	alarm: null,
	progress: false,
	phoneNumbersState: initialPhoneNumbersState
};

export function reducer( state = initialState, action: AlarmsActions.AlarmsAction ): State {
	switch ( action.type ) {
		case AlarmsActions.GET:
		case AlarmsActions.UPDATE:
		case AlarmsActions.PHONE_NUMBERS_DELETE:
		case AlarmsActions.PHONE_NUMBERS_LIST:
		case AlarmsActions.PHONE_NUMBERS_NEW:
		case AlarmsActions.PHONE_NUMBERS_UPDATE:
			return { ...state, progress: true };

		case AlarmsActions.GET_SUCCESS:
		case AlarmsActions.UPDATE_SUCCESS:
			return { ...state, alarm: action.alarm, progress: false };

		case AlarmsActions.PHONE_NUMBERS_DELETE_SUCCESS:
			return { ...state, phoneNumbersState: phoneNumbersAdapter.removeOne( action.alarmsPhoneNumbersId, state.phoneNumbersState ), progress: false };

		case AlarmsActions.PHONE_NUMBERS_LIST_SUCCESS:
			return { ...state, phoneNumbersState: phoneNumbersAdapter.addAll( action.phoneNumbers, state.phoneNumbersState ), progress: false };

		case AlarmsActions.PHONE_NUMBERS_NEW_SUCCESS:
			return { ...state, phoneNumbersState: phoneNumbersAdapter.addOne( action.phoneNumber, state.phoneNumbersState ), progress: false };

		case AlarmsActions.PHONE_NUMBERS_UPDATE_SUCCESS:
			return { ...state, phoneNumbersState: phoneNumbersAdapter.updateOne( { id: action.phoneNumber.Id, changes: action.phoneNumber }, state.phoneNumbersState ), progress: false };

		case AlarmsActions.GET_FAIL:
		case AlarmsActions.UPDATE_FAIL:
		case AlarmsActions.PHONE_NUMBERS_DELETE_FAIL:
		case AlarmsActions.PHONE_NUMBERS_LIST_FAIL:
		case AlarmsActions.PHONE_NUMBERS_NEW_FAIL:
		case AlarmsActions.PHONE_NUMBERS_UPDATE_FAIL:
			return initialState;

		default:
			return state;
	}
}

export const {
	selectIds: getPhoneNumbersIds,
	selectEntities: getPhoneNumbersEntities,
	selectAll: getPhoneNumbersAll,
	selectTotal: getPhoneNumbersTotal,
} = phoneNumbersAdapter.getSelectors();

export const getProgress = ( state: State ) => state.progress;
export const getAlarm = ( state: State ) => state.alarm;
// PhoneNumbersState is nested in State need to map the entities to an array
export const getPhoneNumbers = ( state: State ) => ( state.phoneNumbersState.ids as Array<number | string> ).map( ( id ) => state.phoneNumbersState.entities[id] );
