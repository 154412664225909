import { Action } from '@ngrx/store';

import { WebApiError } from '../shared/web-api/web-api-error';
import { Map } from '../facilities/facilities.model';
import { AdImageType } from '../ad-manager/images/images.model';
import { Model } from '../device-manager/devices/devices.model';

export const BUILD = '[Admin] Build';
export const BUILD_FAIL = '[Admin] Build Fail';
export const BUILD_SUCCESS = '[Admin] Build Success';
export const MAP_GET = '[Admin] Map Get';
export const MAP_GET_FAIL = '[Admin] Map Get Fail';
export const MAP_GET_SUCCESS = '[Admin] Map Get Success';
export const SELECT_AD_IMAGE_TYPE = '[Admin] Versions Select Ad mage Type';
export const SELECT_UNIT_MODEL = '[Admin] Versions Select Unit Model';

export class Build implements Action {
	readonly type = BUILD;

	constructor( public payload: number ) { }
}

export class BuildFail implements Action {
	readonly type = BUILD_FAIL;

	constructor( public payload: WebApiError ) { }
}

export class BuildSuccess implements Action {
	readonly type = BUILD_SUCCESS;
}

export class MapGet implements Action {
	readonly type = MAP_GET;

	constructor( public payload: number ) { }
}

export class MapGetFail implements Action {
	readonly type = MAP_GET_FAIL;

	constructor( public payload: WebApiError ) { }
}

export class MapGetSuccess implements Action {
	readonly type = MAP_GET_SUCCESS;

	constructor( public payload: Map ) { }
}

export class SelectAdImageType implements Action {
	readonly type = SELECT_AD_IMAGE_TYPE;

	constructor( public adImageType: AdImageType ) { }
}

export class SelectUnitModel implements Action {
	readonly type = SELECT_UNIT_MODEL;

	constructor( public unitModel: Model ) { }
}

export type AdminAction
	= Build
	| BuildFail
	| BuildSuccess
	| MapGet
	| MapGetFail
	| MapGetSuccess
	| SelectAdImageType
	| SelectUnitModel;
