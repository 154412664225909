import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Facility } from '../../../facilities/facilities.model';
import { RecurringMessage } from '../recurring-messages.model';
import { select } from '@ngrx/store';

import { DialogService } from '../../../shared/dialogs/dialog.service';
import { RecurringMessageEditComponent } from '../recurring-message-edit/recurring-message-edit.component';

@Component( {
	selector: 'ct-recurring-messages-actions',
	templateUrl: './recurring-messages-actions.component.html',
	styleUrls: ['./recurring-messages-actions.component.css']
} )
export class RecurringMessagesActionsComponent implements OnInit {
	@Input() facility: Facility;
	@Input() selected: RecurringMessage;

	@Output( 'delete' ) recurringMessageDelete = new EventEmitter<number>();
	@Output( 'update' ) recurringMessageEdit = new EventEmitter<RecurringMessage>();
	@Output( 'new' ) recurringMessageNew = new EventEmitter<RecurringMessage>();
	@Output( 'refresh' ) recurringMessagesRefresh = new EventEmitter<void>();
	@Output( 'send' ) recurringMessageSend = new EventEmitter<number>();

	private dialogRef: MatDialogRef<RecurringMessageEditComponent>;

	constructor(
		private dialog: MatDialog,
		private dialogService: DialogService ) { }

	ngOnInit() {
	}

	delete() {
		this.dialogService.confirm( 'Delete Message', 'Are You Sure?' )
			.subscribe( positive => positive ? this.recurringMessageDelete.emit( this.selected.Id ) : null );
	}

	edit() {
		this.dialogRef = this.dialog.open( RecurringMessageEditComponent, { data: { recurringMessage: this.selected, facilityId: this.facility.Id } } );

		this.dialogRef.afterClosed()
			.subscribe( ( recurringMessage: RecurringMessage ) => recurringMessage ? this.recurringMessageEdit.emit( recurringMessage ) : null );
	}

	new() {
		this.dialogRef = this.dialog.open( RecurringMessageEditComponent, { data: { recurringMessage: null, facilityId: this.facility.Id } } );

		this.dialogRef.afterClosed()
			.subscribe( ( recurringMessage: RecurringMessage ) => recurringMessage ? this.recurringMessageNew.emit( recurringMessage ) : null );
	}

	refresh() {
		this.recurringMessagesRefresh.emit();
	}

	send() {
		this.dialogService.confirm( 'Send Message', this.selected.Text + '. To devices: ' + this.selected.DeviceNumbers )
			.subscribe( positive => positive ? this.recurringMessageSend.emit( this.selected.Id ) : null );
	}
}
