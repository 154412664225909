import * as StatusActions from './status.actions';

import { StatusTrackingServer } from './status-tracking-server/status-tracking-server.model';
import { StatusOther } from './status-other/status-other.model';

export interface State {
	progress: boolean;
	statusTrackingServer: StatusTrackingServer;
	statusOther: StatusOther;
}

const initialState: State = {
	progress: false,
	statusTrackingServer: null,
	statusOther: null
};

export function reducer( state = initialState, action: StatusActions.StatusAction ): State {
	switch ( action.type ) {
		case StatusActions.STATUS_TRACKING_SERVER_LOAD:
			return { ...state, progress: true };

		case StatusActions.STATUS_TRACKING_SERVER_LOAD_SUCCESS:
			return { ...state, progress: false, statusTrackingServer: action.payload };

		case StatusActions.STATUS_OTHER_LOAD:
			return { ...state, progress: true };

		case StatusActions.STATUS_OTHER_LOAD_SUCCESS:
			return { ...state, progress: false, statusOther: action.payload };

		case StatusActions.STATUS_OTHER_LOAD_FAIL:
			return initialState;

		default:
			return state;
	}
}

export const getProgress = ( state: State ) => state.progress;
export const getStatusTrackingServer = ( state: State ) => state.statusTrackingServer;
export const getStatusOther = ( state: State ) => state.statusOther;
