import { Action } from '@ngrx/store';

import { TracePoint } from './trace-point.model';
import { TraceParameters } from '../trace-parameters/trace-parameters.model';
import { WebApiError } from '../shared/web-api/web-api-error';

export const LOAD = '[Trace] Load';
export const LOAD_SUCCESS = '[Trace] Load Success';
export const LOAD_FAIL = '[Trace] Load Fail';
export const RESET = '[Trace] Reset';

export class Load implements Action {
	readonly type = LOAD;

	constructor( public payload: TraceParameters ) { }
}

export class LoadSuccess implements Action {
	readonly type = LOAD_SUCCESS;

	constructor( public payload: TracePoint[] ) { }
}

export class LoadFail implements Action {
	readonly type = LOAD_FAIL;

	constructor( public payload: WebApiError ) { }
}

export class Reset implements Action {
	readonly type = RESET;
}

export type TraceAction
	= Load
	| LoadSuccess
	| LoadFail
	| Reset;
