import { NgModule } from '@angular/core';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { TrackersEffects } from './trackers.effects';
import { reducer } from './trackers.reducer';

import { CartTracAngularModule } from '../../cart-trac-angular.module';
import { CartTracMaterialModule } from '../../cart-trac-material.module';

import { DialogModule } from '../../shared/dialogs/dialog.module';
import { PipesModule } from '../../shared/utils/pipes/pipes.module';
import { UtilsModule } from '../../shared/utils/utils.module';

import { TrackersRoutingModule } from './trackers-routing.module';

import { TrackersService } from './trackers.service';

import { TrackersComponent } from './trackers.component';
import { TrackersListComponent } from './list/list.component';
import { TrackersActionsComponent } from './actions/actions.component';
import { TrackersActionsRightComponent } from './actions-right/actions-right.component';
import { TrackersCommandComponent } from './command/command.component';
import { TrackersPowerOnOffComponent } from './power-on-off/power-on-off.component';

@NgModule( {
	declarations: [
		TrackersComponent,
		TrackersListComponent,
		TrackersActionsComponent,
		TrackersActionsRightComponent,
		TrackersCommandComponent,
		TrackersPowerOnOffComponent
	],
	exports: [
		TrackersComponent
	],
	imports: [
		CartTracAngularModule,
		CartTracMaterialModule,

		TrackersRoutingModule,

		DialogModule,
		PipesModule,
		UtilsModule,

		StoreModule.forFeature( 'trackers', reducer ),
		EffectsModule.forFeature( [TrackersEffects] )
	],
	providers: [TrackersService]
} )
export class TrackersModule { }
