import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import * as VersionsActions from './versions.actions';
import * as RoutingActions from '../../routing/routing.actions';
import * as fromCartTrac from '../../cart-trac.reducers';

import { VersionsService } from './versions.service';
import { DialogService } from '../../shared/dialogs/dialog.service';
import { AdVersion, AdVersionRenameParameters } from './versions.model';
import { WebApiError } from '../../shared/web-api/web-api-error';

@Injectable()
export class VersionsEffects {

	@Effect()
	activate$ = this.actions$.pipe(
		ofType( VersionsActions.ACTIVATE ),
		map( ( action: VersionsActions.Activate ) => action.id ),
		switchMap( id => this.versionsService.activate( id ).pipe(
			map( () => new VersionsActions.ActivateSuccess() ),
			catchError( ( error: WebApiError ) => of( new VersionsActions.ActivateFail( error ) ) )
		) ) );

	@Effect()
	copy$ = this.actions$.pipe(
		ofType( VersionsActions.COPY ),
		map( ( action: VersionsActions.Copy ) => action.parameters ),
		switchMap( copyParameters => this.versionsService.copy( copyParameters ).pipe(
			map( ( adVersion: AdVersion ) => new VersionsActions.CopySuccess( adVersion ) ),
			catchError( ( error: WebApiError ) => of( new VersionsActions.CopyFail( error ) ) )
		) ) );

	@Effect()
	delete$ = this.actions$.pipe(
		ofType( VersionsActions.DELETE ),
		map( ( action: VersionsActions.Delete ) => action.id ),
		switchMap( id => this.versionsService.delete( id ).pipe(
			map( () => new VersionsActions.DeleteSuccess() ),
			catchError( ( error: WebApiError ) => of( new VersionsActions.DeleteFail( error ) ) )
		) ) );

	@Effect()
	list$ = this.actions$.pipe(
		ofType( VersionsActions.LIST ),
		map( ( action: VersionsActions.List ) => action.parameters ),
		switchMap( listParameters => this.versionsService.list( listParameters ).pipe(
			concatMap( ( versions: AdVersion[] ) => [
				new VersionsActions.ListSuccess( versions ),
				new VersionsActions.Sort( 1 )
			] ),
			catchError( ( error: WebApiError ) => of( new VersionsActions.ListFail( error ) ) )
		) )
	);

	@Effect()
	new$ = this.actions$.pipe(
		ofType( VersionsActions.NEW ),
		map( ( action: VersionsActions.New ) => action.adVersion ),
		switchMap( adVersion => this.versionsService.new( adVersion ).pipe(
			map( ( newAdVersion: AdVersion ) => {
				if ( newAdVersion.Name === null )
					return new VersionsActions.NameExists( newAdVersion );
				else
					return new VersionsActions.NewSuccess( newAdVersion );
			} ),
			catchError( ( error: WebApiError ) => of( new VersionsActions.NewFail( error ) ) )
		) )
	);

	@Effect()
	publish$ = this.actions$.pipe(
		ofType( VersionsActions.PUBLISH ),
		map( ( action: VersionsActions.Publish ) => action.parameters ),
		switchMap( publishParameters => this.versionsService.publish( publishParameters ).pipe(
			map( () => new VersionsActions.PublishSuccess() ),
			catchError( ( error: WebApiError ) => of( new VersionsActions.PublishFail( error ) ) )
		) ) );

	@Effect()
	rename$ = this.actions$.pipe(
		ofType( VersionsActions.RENAME ),
		map( ( action: VersionsActions.Rename ) => action.parameters ),
		switchMap( renameParameters => this.versionsService.rename( renameParameters ).pipe(
			map( ( parameters: AdVersionRenameParameters ) => {
				if ( parameters.Name === null )
					return new VersionsActions.NameExists( parameters );
				else
					return new VersionsActions.RenameSuccess( parameters );
			} ),
			catchError( ( error: WebApiError ) => of( new VersionsActions.RenameFail( error ) ) )
		) )
	);

	@Effect( { dispatch: false } )
	fail$ = this.actions$.pipe(
		ofType( VersionsActions.ACTIVATE_FAIL, VersionsActions.COPY_FAIL, VersionsActions.DELETE_FAIL,
			VersionsActions.LIST_FAIL, VersionsActions.NEW_FAIL, VersionsActions.PUBLISH_FAIL, VersionsActions.RENAME_FAIL ),
		tap( ( action: VersionsActions.ActivateFail | VersionsActions.CopyFail | VersionsActions.DeleteFail |
			VersionsActions.ListFail | VersionsActions.NewFail | VersionsActions.PublishFail | VersionsActions.RenameFail ) =>
			this.dialogService.webApiError( `VersionsEffects - ${action.type}`, action.error ).subscribe(
				() => this.store$.dispatch( new RoutingActions.Go( { path: ['/login'] } ) )
			)
		)
	);

	constructor(
		private actions$: Actions,
		private store$: Store<fromCartTrac.State>,
		private versionsService: VersionsService,
		private dialogService: DialogService
	) { }
}
