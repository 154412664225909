import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FormErrorStateMatcher } from '../../../shared/utils/form-error-state-matcher';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdSet } from '../sets.model';
import { Course } from '../../../courses/courses.model';

@Component( {
	selector: 'ct-new-ad-set',
	templateUrl: './new.component.html',
	styleUrls: ['./new.component.css']
} )
export class NewComponent implements OnInit, OnDestroy {

	adVersionId: number;
	courses: Course[];

	errorStateMatcher = new FormErrorStateMatcher();
	newForm: FormGroup;

	get name(): string { return this.newForm.get( 'name' ).value; }
	get startTime(): string { return this.newForm.get( 'startTime' ).value; }
	get endTime(): string { return this.newForm.get( 'endTime' ).value; }
	get courseNumber(): string { return this.newForm.get( 'courseNumber' ).value; }
	get exclusive(): boolean { return this.newForm.get( 'exclusive' ).value; }

	constructor(
		@Inject( MAT_DIALOG_DATA ) public data: { adVersionId: number, courses: Course[] },
		public dialogRef: MatDialogRef<NewComponent>,
		private formBuilder: FormBuilder ) {
	}

	ngOnInit() {
		this.adVersionId = this.data.adVersionId;
		this.courses = this.data.courses;

		this.newForm = this.formBuilder.group( {
			name: ['', Validators.required],
			startTime: ['12:00', Validators.required],
			endTime: ['11:59', Validators.required],
			courseNumber: ['0', Validators.required],
			exclusive: [false, Validators.required]
		} );
	}

	ngOnDestroy() {
	}

	new() {
		this.dialogRef.close( new AdSet(
			0, this.name, this.data.adVersionId, 2 /* Facility Owner */, this.startTime, this.endTime, this.exclusive, this.courseNumber ) );
	}
}
