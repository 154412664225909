import { NgModule } from '@angular/core';
import { CartTracAngularModule } from '../cart-trac-angular.module';
import { CartTracMaterialModule } from '../cart-trac-material.module';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { FacilitiesEffects } from './facilities.effects';
import { reducer } from './facilities.reducer';

import { FacilitiesComponent } from './facilities.component';

import { FacilitiesService } from './facilities.service';

@NgModule( {
	declarations: [
		FacilitiesComponent
	],
	exports: [
		FacilitiesComponent
	],
	imports: [
		CartTracAngularModule,
		CartTracMaterialModule,

		StoreModule.forFeature( 'facilities', reducer ),
		EffectsModule.forFeature( [FacilitiesEffects] )
	],
	providers: [FacilitiesService]
} )
export class FacilitiesModule { }
