import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import * as ZonesVersionsActions from './zones-versions.actions';
import * as RoutingActions from '../../routing/routing.actions';
import * as fromCartTrac from '../../cart-trac.reducers';

import { ZonesVersionsService } from './zones-versions.service';
import { CoursesService } from '../../courses/courses.service';
import { DialogService } from '../../shared/dialogs/dialog.service';
import { WebApiError } from '../../shared/web-api/web-api-error';
import { ZoneVersion } from './zones-versions.model';

@Injectable()
export class ZonesVersionsEffects {

	@Effect()
	versionsLoad$ = this.actions$.pipe(
		ofType( ZonesVersionsActions.LOAD ),
		map( ( action: ZonesVersionsActions.Load ) => action.payload ),
		switchMap( facilityId =>
			this.zonesService.versions( facilityId ).pipe(
				map( ( versions: ZoneVersion[] ) => new ZonesVersionsActions.LoadSuccess( versions ) ),
				catchError( ( error: WebApiError ) => of( new ZonesVersionsActions.LoadFail( error ) ) )
			)
		)
	);

	@Effect()
	holesLocationsNew$ = this.actions$.pipe(
		ofType( ZonesVersionsActions.HOLES_LOCATIONS_NEW ),
		map( ( action: ZonesVersionsActions.HolesLocationsNew ) => action.payload ),
		switchMap( geoZonesVersionId => this.coursesService.holesLocationsNew( geoZonesVersionId ).pipe(
			tap( ( count: number ) => this.dialogService.alert( 'Create Hole Location Definitions', `Processed ${count} Definition(s)` ) ),
			map( count => new ZonesVersionsActions.HolesLocationsNewSuccess( count ) ),
			catchError( ( error: WebApiError ) => of( new ZonesVersionsActions.HolesLocationsNewFail( error ) ) )
		) )
	);

	@Effect( { dispatch: false } )
	fail$ = this.actions$.pipe(
		ofType( ZonesVersionsActions.HOLES_LOCATIONS_NEW_FAIL || ZonesVersionsActions.LOAD_FAIL ),
		tap( ( action: ZonesVersionsActions.HolesLocationsNewFail | ZonesVersionsActions.LoadFail ) =>
			this.dialogService.webApiError( `ZonesVersionsEffects - ${action.type}`, action.payload ).subscribe(
				() => this.store$.dispatch( new RoutingActions.Go( { path: ['/login'] } ) )
			)
		)
	);

	constructor(
		private actions$: Actions,
		private store$: Store<fromCartTrac.State>,
		private dialogService: DialogService,
		private zonesService: ZonesVersionsService,
		private coursesService: CoursesService
	) { }
}
