export class Facility {
	constructor(
		public Id: number,
		public Name: string,
		public FileRoot: string,
		public Logo: string,
		public Settings: FacilitySettings
	) { }
}

export class Map {
	constructor(
		public Id: number,
		public LatitudeShift: number,
		public LongitudeShift: number,
		public Version: string,
		public MapProviderName: string,
		public MapProviderIndexFormat: string,
		public MapProviderAPIKey: string,
		public MapProviderServerAddress: string,
		public NWLatitude: number,
		public NWLongitude: number,
		public SELatitude: number,
		public SELongitude: number,
		public CenterLatitude: number,
		public CenterLongitude: number,
		public MinZoom: number,
		public MaxZoom: number,
		public StartZoom: number,
		public BackgroundColor: string,
		public RotationAngle: number,
		public PanNWLatitude: number,
		public PanNWLongitude: number,
		public PanSELatitude: number,
		public PanSELongitude: number
	) { }
}

export class FacilitySettings {
	constructor(
		public ResetDeviceColorEOD: boolean,
		public ResetDeviceNameEOD: boolean,
		public ResetDeviceModeEOD: boolean,
		public ResetDeviceModeEOR: boolean
	) { }
}

export class FacilitySettingsDTO {
	constructor(
		public FacilityId: number,
		public JSON: string
	) { }
}
