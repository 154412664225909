<mat-toolbar>
	<ct-devices-actions [expanded]="expanded$ | async" [devices]="devices$ | async" [selected]="selected$ | async"
		[systemUser]="systemUser$ | async" [facility]="facility">
	</ct-devices-actions>

	<span class="fill-remaining-space"></span>

	<ct-devices-actions-bulk [facility]="facility" [devices]="devices$ | async" [models]="models$ | async"
		[modes]="modes$ | async" [systemUser]="systemUser$ | async">
	</ct-devices-actions-bulk>

</mat-toolbar>

<ct-devices-list [expanded]="expanded$ | async" [devices]="devices$ | async" (select)=onSelectDevice($event)>
</ct-devices-list>