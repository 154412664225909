import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Alarm } from '../alarms.model';

@Component( {
	selector: 'ct-alarm',
	templateUrl: './alarm.component.html',
	styleUrls: ['./alarm.component.css']
} )
export class AlarmComponent implements OnInit {

	@Input() alarm: Alarm;
	@Output() update = new EventEmitter<Alarm>();

	alarmForm: FormGroup;

	get textControl() { return this.alarmForm.get( 'text' ); }
	get phoneNumberControl() { return this.alarmForm.get( 'fromPhoneNumber' ); }

	private readonly phoneNumberRegEx = '^\\+\\d{11,14}$';
	private readonly maxTextLength = 50;

	constructor( private formBuilder: FormBuilder ) { }

	ngOnInit() {
		this.alarmForm = this.formBuilder.group( {
			fromPhoneNumber: ['', { validators: [Validators.required, Validators.pattern( this.phoneNumberRegEx )], updateOn: 'blur' }],
			text: ['', { validators: [Validators.required, Validators.maxLength( this.maxTextLength )], updateOn: 'blur' }]
		} );

		this.alarmForm.valueChanges.subscribe( changes => {
			if ( this.alarmForm.dirty && this.alarmForm.valid ) {
				const updatedAlarm = new Alarm( this.alarm.FacilityId, changes.text, changes.fromPhoneNumber );
				this.update.emit( updatedAlarm );
				this.alarmForm.markAsPristine();
			}
		} );
	}

	ngOnChanges( changes: SimpleChanges ) {
		if ( this.alarmForm ) {
			this.alarmForm.setValue( { text: changes.alarm.currentValue.Text, fromPhoneNumber: changes.alarm.currentValue.FromPhoneNumber }, { emitEvent: false } );
		}
	}

	errorText(): string {
		return this.textControl.hasError( 'required' ) ? 'required' :
			this.textControl.hasError( 'maxlength' ) ? 'Maximum length of 50 reached' : '';
	}

	errorPhoneNumber(): string {
		return this.phoneNumberControl.hasError( 'required' ) ? 'required' :
			this.phoneNumberControl.hasError( 'pattern' ) ? 'Invalid format, use: +11234567890' : '';
	}
}
