<mat-toolbar mat-dialog-title color="secondary">Upload Devices</mat-toolbar>
<div>
	<mat-dialog-content align="center">
		<div class="selection">
			<p><em>Facility:</em>&nbsp;<strong>{{data.facility.Name}}</strong></p>
			<p><em *ngIf="file">File Name:</em>&nbsp;<strong>{{file?.name}}</strong></p>
		</div>

		<div>
			<input type="file" #fileInputSelect [hidden]="true" (change)="onFileSelectChange($event)" accept=".xlsx" />
			<button mat-raised-button type="button" class="file-input-button" (click)="fileInputSelect.click()">
				<mat-icon class="material-icons image-input-icon">devices</mat-icon>
				&nbsp;Select Devices File
			</button>
		</div>

		<div class="template">
			<a href="/assets/devices.xlsx" download>Download Template File</a>
		</div>

		<p><em>&nbsp;{{line1}}&nbsp;</em></p>
		<p><em>&nbsp;{{line2}}&nbsp;</em></p>
		<p><strong>&nbsp;{{line3}}&nbsp;</strong></p>
	</mat-dialog-content>
	<mat-dialog-actions align="center">
		<button mat-raised-button (click)="dialogRef.close()">Close</button>
		<button mat-raised-button mat-primary [disabled]="!file||done" (click)="upload()">Upload</button>
	</mat-dialog-actions>
</div>