import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AdVersion } from '../versions.model';
import { DialogService } from '../../../shared/dialogs/dialog.service';

@Component( {
	selector: 'ct-ad-manager-versions-actions',
	templateUrl: './actions.component.html',
	styleUrls: ['./actions.component.css']
} )
export class ActionsComponent implements OnInit {

	@Input() adVersion: AdVersion;

	@Output( 'activate' ) activateVersion = new EventEmitter();
	@Output( 'copy' ) copyVersion = new EventEmitter();
	@Output( 'delete' ) deleteVersion = new EventEmitter();
	@Output( 'publish' ) publishVersion = new EventEmitter();

	constructor(
		private dialogService: DialogService
	) { }

	ngOnInit() {
	}

	activate( event: Event ) {
		event.stopPropagation();
		this.dialogService.confirm( 'Activate Ad Version', `Ad Version Name: ${this.adVersion.Name}. Are You Sure?` )
			.subscribe( positive => positive ? this.activateVersion.emit() : null );
	}

	copy( event: Event ) {
		event.stopPropagation();
		this.dialogService.confirm( 'Copy Ad Version to WORKSPACE', `Ad Version Name: ${this.adVersion.Name}. Are You Sure?` )
			.subscribe( positive => positive ? this.copyVersion.emit() : null );
	}

	delete( event: Event ) {
		event.stopPropagation();
		this.dialogService.confirm( 'Delete Ad Version', `Ad Version Name: ${this.adVersion.Name}. Are You Sure?` )
			.subscribe( positive => positive ? this.deleteVersion.emit() : null );
	}

	publish( event: Event ) {
		event.stopPropagation();
		this.dialogService.confirm( 'Publish Ad Version to PUBLISHED', `Ad Version Name: ${this.adVersion.Name}. Are You Sure?` )
			.subscribe( positive => positive ? this.publishVersion.emit() : null );
	}
}
