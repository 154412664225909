import { ChangeDetectionStrategy, Component, OnInit, OnDestroy } from '@angular/core';

import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import * as fromCartTrac from './cart-trac.reducers';
import * as LayoutActions from './layout/layout.actions';

import { Facility } from './facilities/facilities.model';

@Component( {
	selector: 'cart-trac',							// tslint:disable-line
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './cart-trac.component.html',
	styleUrls: ['./cart-trac.component.css']
} )
export class CartTracComponent implements OnInit, OnDestroy {
	featureName$: Observable<string>;
	facility$: Observable<Facility>;
	loggedIn$: Observable<boolean>;
	openSidenav$: Observable<boolean>;
	showProgress$: Observable<boolean>;
	systemUser$: Observable<boolean>;

	facility: Facility;

	private facilitySubscription: Subscription;
	private openSideNavSubscription: Subscription;

	constructor( private store$: Store<fromCartTrac.State> ) {
		this.featureName$ = this.store$.select( fromCartTrac.getFeatureName );
		this.facility$ = this.store$.select( fromCartTrac.getFacility );
		this.loggedIn$ = this.store$.select( fromCartTrac.getLoggedIn );
		this.openSidenav$ = this.store$.select( fromCartTrac.getOpenSidenav );
		this.showProgress$ = this.store$.select( fromCartTrac.getShowProgress );
		this.systemUser$ = this.store$.select( fromCartTrac.getSystemUser );
	}

	ngOnInit() {
		this.facilitySubscription = this.facility$.subscribe( facility => this.facility = facility );
	}

	ngOnDestroy() {
		this.facilitySubscription.unsubscribe();
		this.openSideNavSubscription.unsubscribe();
	}

	closeSidenav() {
		this.store$.dispatch( new LayoutActions.CloseSidenav() );
	}

	toggleSidenav() {
		let open: boolean;

		this.openSideNavSubscription = this.openSidenav$.subscribe( value => open = value );

		if ( open ) {
			this.store$.dispatch( new LayoutActions.CloseSidenav() );
		} else {
			this.store$.dispatch( new LayoutActions.OpenSidenav() );
		}
	}

}
