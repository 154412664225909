import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { Store } from '@ngrx/store';
import * as fromCartTrac from '../../../cart-trac.reducers';
import { Facility } from '../../../facilities/facilities.model';
import { Observable, Subscription } from 'rxjs';
import * as SystemAction from '../../../system/system.actions';

@Component( {
	selector: 'ct-facility-picker',
	templateUrl: './facility-picker.component.html',
	styleUrls: ['./facility-picker.component.css']
} )
export class FacilityPickerComponent implements OnInit {
	@Input() label: boolean;
	@Input() selected: Facility;
	@Output() select = new EventEmitter<Facility>();

	facilities$: Observable<Facility[]>;
	picked$: Observable<Facility>;

	facilities: Facility[];

	private facilitiesSubscription: Subscription;
	private pickedSubscription: Subscription;

	constructor( private store$: Store<fromCartTrac.State> ) {
		this.facilities$ = this.store$.select( fromCartTrac.getFacilities );
		this.picked$ = this.store$.select( fromCartTrac.getPickedFacility );
	}

	ngOnInit() {
		this.facilitiesSubscription = this.facilities$
			.subscribe( facilities => {
				this.facilities = facilities;

				if ( !this.selected && this.facilities.length > 0 ) {
					this.select.emit( this.facilities[0] );
					this.store$.dispatch( new SystemAction.PickFacility( this.facilities[0] ) );
				}
				else {
					this.select.emit( this.selected );
					this.store$.dispatch( new SystemAction.PickFacility( this.selected ) );
				}

				this.pickedSubscription = this.picked$
					.subscribe( facility => {
						this.selected = facility;
					} );
			} );
	}

	selectionChange( event: MatSelectChange ) {
		this.select.emit( event.value );
		this.store$.dispatch( new SystemAction.PickFacility( event.value ) );
	}

	ngOnDestroy() {
		this.facilitiesSubscription.unsubscribe();
		this.pickedSubscription.unsubscribe();
	}
}
