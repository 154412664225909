import { NgModule } from '@angular/core';

import { CartTracAngularModule } from '../cart-trac-angular.module';
import { CartTracMaterialModule } from '../cart-trac-material.module';

import { SettingsRoutingModule } from './settings-routing.module';
import { SettingsComponent } from './settings.component';

import { SettingsService } from './settings.service';
import { SettingsTrackingComponent } from './settings-tracking/settings-tracking.component';
import { SettingsOtherComponent } from './settings-other/settings-other.component';
import { SettingsFacilityComponent } from './settings-facility/settings-facility.component';

@NgModule( {
	declarations: [
		SettingsComponent,
		SettingsTrackingComponent,
		SettingsOtherComponent,
		SettingsFacilityComponent
	],
	imports: [
		CartTracAngularModule,
		CartTracMaterialModule,

		SettingsRoutingModule
	],
	providers: [SettingsService]
} )
export class SettingsModule { }
