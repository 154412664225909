import { Action } from '@ngrx/store';

import { WebApiError } from '../shared/web-api/web-api-error';
import { Course, Hole } from './courses.model';

export const LIST = '[Courses] List';
export const LIST_FAIL = '[Courses] List Fail';
export const LIST_SUCCESS = '[Courses] List Success';
export const HOLES_LIST = '[Courses] Holes List';
export const HOLES_LIST_FAIL = '[Courses] Holes List Fail';
export const HOLES_LIST_SUCCESS = '[Courses] Holes List Success';

export class List implements Action {
	readonly type = LIST;

	constructor( public facilityId: number ) { }
}

export class ListFail implements Action {
	readonly type = LIST_FAIL;

	constructor( public error: WebApiError ) { }
}

export class ListSuccess implements Action {
	readonly type = LIST_SUCCESS;

	constructor( public courses: Course[] ) { }
}

export class HolesList implements Action {
	readonly type = HOLES_LIST;

	constructor( public courseId: number ) { }
}

export class HolesListFail implements Action {
	readonly type = HOLES_LIST_FAIL;

	constructor( public error: WebApiError ) { }
}

export class HolesListSuccess implements Action {
	readonly type = HOLES_LIST_SUCCESS;

	constructor( public holes: Hole[] ) { }
}

export type CoursesAction
	= HolesList
	| HolesListFail
	| HolesListSuccess
	| List
	| ListFail
	| ListSuccess;
