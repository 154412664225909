import { environment } from '../../environments/environment';
import { NgModule } from '@angular/core';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { TraceEffects } from './trace.effects';
import { reducer } from './trace.reducer';

import { CartTracAngularModule } from '../cart-trac-angular.module';
import { CartTracMaterialModule } from '../cart-trac-material.module';

import { BingMapModule } from '../shared/bing-map/bing-map.module';

import { TraceRoutingModule } from './trace-routing.module';
import { TraceComponent } from './trace.component';
import { TraceService } from './trace.service';

@NgModule( {
	declarations: [
		TraceComponent
	],
	imports: [
		CartTracAngularModule,
		CartTracMaterialModule,

		BingMapModule.forRoot( environment.bingMapConfig() ),

		TraceRoutingModule,

		StoreModule.forFeature( 'trace', reducer ),
		EffectsModule.forFeature( [TraceEffects] )
	],
	providers: [TraceService]
} )
export class TraceModule { }
