<mat-toolbar mat-dialog-title color="secondary">New {{data?.unitModel?.Name}}&nbsp;{{data?.imageType?.Name}} Image(s)
</mat-toolbar>

<mat-dialog-content>
	<div *ngIf="!isSelected" class="select-buttons">
		<div>
			<input type="file" #imageInputSelect [hidden]="true" (change)="onSelectChange($event)" multiple
				accept=".png, .jpg, .jpeg" />
			<button mat-raised-button type="button" class="image-input-button" (click)="imageInputSelect.click()">
				<mat-icon class="material-icons image-input-icon">collections</mat-icon>
				&nbsp;Select Image File(s)
			</button>
		</div>
		<div>
			<input type="file" #imageInputCrop [hidden]="true" (change)="onCropChange($event)"
				accept=".png, .jpg, .jpeg" />
			<button mat-raised-button type="button" class="image-input-button" (click)="imageInputCrop.click()">
				<mat-icon class="material-icons image-input-icon">crop</mat-icon>
				&nbsp;Select and Crop Image File
			</button>
		</div>
		<div>
			<button mat-raised-button type="button" class="image-input-button" (click)="imageInputHtml()">
				<mat-icon class="material-icons image-input-icon">title</mat-icon>
				&nbsp;Create Image from Text
			</button>
		</div>
	</div>
	<div *ngIf="isSelected && isSelect">
		Selected {{imageCount}} Image(s).
	</div>
	<div *ngIf="isLoaded && isCrop">
		<image-cropper [imageBase64]="imageData" [maintainAspectRatio]="true" [aspectRatio]="imageWidth/imageHeight"
			[resizeToWidth]="imageWidth" format="png" (imageCropped)="imageCropped($event)"
			(imageLoaded)="imageCropLoaded()" (loadImageFailed)="imageCropLoadFailed()" style="max-height: 33vh"
			[style.display]="isResized && isLoadedToCropper ? null : 'none'">
		</image-cropper>
	</div>
	<div *ngIf="isSelected && isHtml">
		<ct-angular-editor [config]="editorConfig"></ct-angular-editor>
	</div>
</mat-dialog-content>
<mat-dialog-actions>
	<button mat-raised-button (click)="dialogRef.close()">Close</button>
	<button mat-raised-button mat-primary (click)="newImage()" [disabled]="!isSelected">Create</button>
	<span class="fill-remaining-space"></span>
	<input type="color" #imageFillColorPicker [value]="imageFillColor" [hidden]="true"
		(change)="onImageFillColorChange($event)" />
	<button mat-mini-fab matTooltip="Chose Resize/Fill Color" color="accent" (click)="imageFillColorPicker.click()">
		<mat-icon [style.color]="imageFillColor">format_color_fill</mat-icon>
	</button>
</mat-dialog-actions>