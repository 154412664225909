import { environment } from '../../environments/environment';
import { NgModule } from '@angular/core';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { TrackingEffects } from './tracking.effects';
import { reducer } from './tracking.reducer';

import { CartTracAngularModule } from '../cart-trac-angular.module';
import { CartTracMaterialModule } from '../cart-trac-material.module';

import { BingMapModule } from '../shared/bing-map/bing-map.module';

import { TrackingRoutingModule } from './tracking-routing.module';
import { TrackingComponent } from './tracking.component';
import { TrackingService } from './tracking.service';

@NgModule( {
	declarations: [
		TrackingComponent
	],
	imports: [
		CartTracAngularModule,
		CartTracMaterialModule,

		TrackingRoutingModule,

		BingMapModule.forRoot( environment.bingMapConfig() ),

		StoreModule.forFeature( 'tracking', reducer ),
		EffectsModule.forFeature( [TrackingEffects] )
	],
	providers: [TrackingService]
} )
export class TrackingModule { }
