import * as TrackingActions from './tracking.actions';

import { TrackingDevice } from './tracking-device.model';

export interface State {
	progress: boolean;
	trackingDevices: TrackingDevice[];
}

const initialState: State = {
	progress: false,
	trackingDevices: []
};

export function reducer( state = initialState, action: TrackingActions.TrackingAction ): State {
	switch ( action.type ) {
		case TrackingActions.LOAD:
			return { ...state, progress: true };

		case TrackingActions.LOAD_SUCCESS:
			return { ...state, progress: false, trackingDevices: action.payload };

		case TrackingActions.LOAD_FAIL:
			return initialState;

		default:
			return state;
	}
}

export const getProgress = ( state: State ) => state.progress;
export const getTrackingDevices = ( state: State ) => state.trackingDevices;
