import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromCartTrac from '../../../cart-trac.reducers';
import * as DevicesActions from '../devices.actions';
import { Device } from '../devices.model';
import { EditComponent } from './edit/edit.component';
import { MoveComponent } from './move/move.component';
import { NewComponent } from './new/new.component';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { DialogService } from '../../../shared/dialogs/dialog.service';
import { Facility } from '../../../facilities/facilities.model';

@Component( {
	selector: 'ct-devices-actions',
	templateUrl: './actions.component.html',
	styleUrls: ['./actions.component.css']
} )
export class ActionsComponent implements OnInit {
	@Input() devices: Device[];
	@Input() selected: Device;
	@Input() facility: Facility;
	@Input() expanded: boolean;
	@Input() systemUser: boolean;

	private editDialogRef: MatDialogRef<EditComponent>;
	private moveDialogRef: MatDialogRef<MoveComponent>;
	private newDialogRef: MatDialogRef<NewComponent>;

	constructor(
		private store$: Store<fromCartTrac.State>,
		private dialog: MatDialog,
		private dialogService: DialogService ) { }

	ngOnInit() {
	}

	delete() {
		this.dialogService.confirm( `Delete Device #${this.selected.Number} | ${this.selected.DeviceId}`, 'Are You Sure?' )
			.subscribe( positive => positive ? this.store$.dispatch( new DevicesActions.Delete( this.selected.Id ) ) : null );
	}

	edit() {
		this.editDialogRef = this.dialog.open( EditComponent, { data: { device: this.selected } } );

		this.editDialogRef.afterClosed()
			.subscribe( ( device: Device ) => {
				if ( device ) {
					this.store$.dispatch( new DevicesActions.Update( device ) );
				}
			} );

	}

	toggleExpand() {
		this.store$.dispatch( new DevicesActions.ToggleExpanded() );
	}

	move() {
		this.moveDialogRef = this.dialog.open( MoveComponent, { data: { device: this.selected, facility: this.facility } } );

		this.moveDialogRef.afterClosed()
			.subscribe( ( facility: Facility ) => {
				if ( facility ) {
					this.store$.dispatch( new DevicesActions.Move( { Id: this.selected.Id, FacilityId: facility.Id } ) );
				}
			} );
	}

	new() {
		this.newDialogRef = this.dialog.open( NewComponent, { data: { facilityId: this.facility.Id } } );

		this.newDialogRef.afterClosed()
			.subscribe( ( device: Device ) => {
				if ( device ) {
					this.store$.dispatch( new DevicesActions.New( device ) );
				}
			} );
	}

	sort( sortOrder: number ) {
		this.store$.dispatch( new DevicesActions.Sort( sortOrder ) );
	}
}
