import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { FacilitySettings, Facility } from '../../facilities/facilities.model';

@Component( {
	selector: 'ct-settings-facility',
	templateUrl: './settings-facility.component.html',
	styleUrls: ['./settings-facility.component.css']
} )
export class SettingsFacilityComponent implements OnInit {
	@Input() facility: Facility;
	@Output() saveSettings = new EventEmitter<FacilitySettings>();

	constructor() { }

	ngOnInit() {
	}

	save() {
		this.saveSettings.emit( this.facility.Settings );
	}
}
