import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RecurringMessage, RecurringMessageSetActiveParameters } from './recurring-messages.model';
import { WebApiService } from '../../shared/web-api/web-api.service';
import { WebApiError } from '../../shared/web-api/web-api-error';

@Injectable()
export class RecurringMessagesService {

	private serviceBaseUrl: string;

	constructor( private webApiService: WebApiService ) {
		this.serviceBaseUrl = this.webApiService.serverUrl + '/api/facilities/recurringmessages';
	}

	delete( id: number ): Observable<void | WebApiError> {
		return this.webApiService.delete( this.serviceBaseUrl + '/' + id );
	}

	list( facilityId: number ): Observable<RecurringMessage[] | WebApiError> {
		return this.webApiService.get<RecurringMessage[]>( this.serviceBaseUrl + '/' + facilityId );
	}

	new( recurringMessage: RecurringMessage ): Observable<RecurringMessage | WebApiError> {
		return this.webApiService.post( this.serviceBaseUrl, recurringMessage );
	}

	send( id: number ): Observable<number | WebApiError> {
		return this.webApiService.post( this.serviceBaseUrl + '/send/' + id, null );
	}

	setActive( recurringMessagesSetActiveParameters: RecurringMessageSetActiveParameters ): Observable<void | WebApiError> {
		return this.webApiService.put( this.serviceBaseUrl + '/setactive', recurringMessagesSetActiveParameters );
	}

	update( recurringMessage: RecurringMessage ): Observable<RecurringMessage | WebApiError> {
		return this.webApiService.put( this.serviceBaseUrl, recurringMessage );
	}
}
