import { Component, Input } from '@angular/core';

@Component( {
	selector: 'ct-sidenav',
	templateUrl: './sidenav.component.html',
	styleUrls: ['./sidenav.component.css']
} )
export class SidenavComponent
{
	@Input() open = false;
}
