import { NgModule } from '@angular/core';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { RangerEffects } from './ranger.effects';
import { reducer } from './ranger.reducer';

import { CartTracAngularModule } from '../cart-trac-angular.module';
import { CartTracMaterialModule } from '../cart-trac-material.module';

import { PipesModule } from '../shared/utils/pipes/pipes.module';

import { RangerRoutingModule } from './ranger-routing.module';
import { RangerComponent } from './ranger.component';
import { RangerGridComponent } from './ranger-grid/ranger-grid.component';

@NgModule( {
	declarations: [
		RangerComponent,
		RangerGridComponent
	],
	imports: [
		CartTracAngularModule,
		CartTracMaterialModule,

		RangerRoutingModule,

		PipesModule,

		StoreModule.forFeature( 'ranger', reducer ),
		EffectsModule.forFeature( [RangerEffects] )
	]
} )
export class RangerModule { }
