import { Component, OnInit } from '@angular/core';

import { TwilioService } from './twilio.service';
import { TwilioResponseAccounts, TwilioResponseSims } from './twilio-response.model';

@Component( {
	selector: 'ct-twilio',
	templateUrl: './twilio.component.html',
	styleUrls: ['./twilio.component.css']
} )
export class TwilioComponent implements OnInit
{
	accounts: any;
	sims: any;

	constructor(
		private twilioService: TwilioService )
	{ }

	ngOnInit()
	{
		// TODO: store this from DB, add to WebApi as /twilio
		const accountSid = 'ACabb4375d31fa876bb35f48224d4f2a7a';
		const authToken = 'eb9bf372607c39148f8cff7b0731b110';
		const accountsUrl = 'https://api.twilio.com/2010-04-01/Accounts.json';
		const simsUrl = 'https://wireless.twilio.com/v1/Sims';

		this.twilioService.init( accountSid, authToken, accountsUrl, simsUrl );

		this.twilioService.accounts()
			.subscribe(( response: TwilioResponseAccounts ) =>
			{
				this.accounts = response.accounts;
			} );

		this.twilioService.sims()
			.subscribe(( response: TwilioResponseSims ) =>
			{
				this.sims = response.sims;
			} );
	}
}
