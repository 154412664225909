import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import * as DevicesActions from './devices.actions';
import { Device } from './devices.model';

export interface State extends EntityState<Device> {
	expanded: boolean;
	progress: boolean;
	selected: Device;
	sortOrder: number;
}

export const adapter = createEntityAdapter<Device>( {
	selectId: ( device: Device ) => device.Id,
	sortComparer: false
} );

const initialState: State = adapter.getInitialState( {
	expanded: false,
	progress: false,
	selected: null,
	sortOrder: 1
} );

export function reducer( state = initialState, action: DevicesActions.DevicesAction ): State {
	switch ( action.type ) {
		case DevicesActions.DELETE:
		case DevicesActions.MOVE:
		case DevicesActions.NEW:
		case DevicesActions.UPDATE:
			return { ...state, progress: true };

		case DevicesActions.TOGGLE_EXPANDED:
			return { ...state, expanded: !state.expanded };

		case DevicesActions.LIST:
			return { ...adapter.removeAll( state ), progress: true };

		case DevicesActions.DELETE_SUCCESS:
		case DevicesActions.MOVE_SUCCESS:
			return { ...adapter.removeOne( state.selected.Id, state ), progress: false, selected: null };

		case DevicesActions.LIST_SUCCESS:
			action.devices.forEach( device => device.CSSClass = 'device' );
			return { ...adapter.addAll( action.devices, state ), progress: false, selected: null };

		case DevicesActions.NEW_SUCCESS:

			if ( state.selected ) {
				state.selected.CSSClass = 'device';
			}

			return { ...adapter.addOne( action.device, state ), progress: false, selected: null };

		case DevicesActions.UPDATE_SUCCESS:
			action.device.CSSClass = 'device';

			return { ...adapter.updateOne( { id: action.device.Id, changes: action.device }, state ), progress: false, selected: null };

		case DevicesActions.SELECT:
			action.device.CSSClass = 'device-selected';

			if ( state.selected ) {
				const updatedState = adapter.updateOne( { id: state.selected.Id, changes: { CSSClass: 'device' } }, state );

				if ( state.selected.Id === action.device.Id ) {
					return { ...updatedState, selected: null };
				} else {
					return { ...updatedState, selected: action.device };
				}
			} else {
				return { ...state, selected: action.device };
			}

		case DevicesActions.SORT:
			return { ...state, selected: null, sortOrder: action.sortOrder };

		case DevicesActions.DELETE_FAIL:
		case DevicesActions.NEW_FAIL:
		case DevicesActions.UPDATE_FAIL:
			return initialState;

		default:
			return state;
	}
}

export const {
	selectIds: getIds,
	selectEntities: getEntities,
	selectAll: getAll,
	selectTotal: getCount,
} = adapter.getSelectors();

export const getExpanded = ( state: State ) => state.expanded;
export const getProgress = ( state: State ) => state.progress;
export const getSelected = ( state: State ) => state.selected;
export const getSortOrder = ( state: State ) => state.sortOrder;

const getSortedIds = createSelector(
	getAll,
	getSortOrder,
	( devices, sortOrder ) => {

		let sortFunction: ( a: Device, b: Device ) => number;

		switch ( sortOrder ) {
			case 1:
				sortFunction = ( a, b ) => ( a.Number > b.Number ? 1 : -1 );
				break;
			case 2:
				sortFunction = ( a, b ) => ( a.DeviceId > b.DeviceId ? 1 : -1 );
				break;
			case 3:
				sortFunction = ( a, b ) => new Date( a.LastGPSTime ).getTime() - new Date( b.LastGPSTime ).getTime();
				break;
			case 4:
				sortFunction = ( a, b ) => ( a.ModelName > b.ModelName ? 1 : -1 );
				break;
			case 5:
				sortFunction = ( a, b ) => ( a.ModeName > b.ModeName ? 1 : -1 );
				break;
		}

		return devices
			.slice()									// don't change devices
			.sort( sortFunction )						// sort
			.map( device => device.Id );				// return ids
	} );

export const getAllSorted = createSelector(
	getEntities,
	getSortedIds,
	( entities, sortedIds ) => {
		return sortedIds.map( id => entities[id] );
	} );
