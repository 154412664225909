<mat-tab-group id="level-two">
    <mat-tab label="Tracking Server">
            <ct-status-tracking-server [statusTrackingServer]="statusTrackingServer$ | async"></ct-status-tracking-server>
    </mat-tab>
    <mat-tab label="Sessions">
    </mat-tab>
    <mat-tab label="Other">
        <ct-status-other [statusOther]="statusOther$ | async"></ct-status-other>
    </mat-tab>
</mat-tab-group>
