import { Action } from '@ngrx/store';

import { WebApiError } from '../../shared/web-api/web-api-error';
import { AdSet } from './sets.model';

export const CLEAR = '[Sets] Clear';
export const DELETE = '[Sets] Delete';
export const DELETE_FAIL = '[Sets] Delete Fail';
export const DELETE_SUCCESS = '[Sets] Delete Success';
export const LIST = '[Sets] List';
export const LIST_FAIL = '[Sets] List Fail';
export const LIST_SUCCESS = '[Sets] List Success';
export const NAME_EXISTS = '[Sets] Name Exists';
export const NAME_EXISTS_RESET = '[Sets] Name Exists Reset';
export const NEW = '[Sets] New';
export const NEW_FAIL = '[Sets] New Fail';
export const NEW_SUCCESS = '[Sets] New Success';
export const SELECT = '[Sets] Select';
export const UPDATE = '[Sets] Update';
export const UPDATE_FAIL = '[Sets] Update Fail';
export const UPDATE_SUCCESS = '[Sets] Reanme Success';

export class Clear implements Action {
	readonly type = CLEAR;
}

export class Delete implements Action {
	readonly type = DELETE;

	constructor( public id: number ) { }
}

export class DeleteFail implements Action {
	readonly type = DELETE_FAIL;

	constructor( public error: WebApiError ) { }
}

export class DeleteSuccess implements Action {
	readonly type = DELETE_SUCCESS;
}

export class List implements Action {
	readonly type = LIST;

	constructor( public adVersionId: number ) { }
}

export class ListFail implements Action {
	readonly type = LIST_FAIL;

	constructor( public error: WebApiError ) { }
}

export class ListSuccess implements Action {
	readonly type = LIST_SUCCESS;

	constructor( public adSets: AdSet[] ) { }
}

export class NameExists implements Action {
	readonly type = NAME_EXISTS;

	constructor( public adSet: AdSet ) { }
}

export class NameExistsReset implements Action {
	readonly type = NAME_EXISTS_RESET;
}

export class New implements Action {
	readonly type = NEW;

	constructor( public adSet: AdSet ) { }
}

export class NewFail implements Action {
	readonly type = NEW_FAIL;

	constructor( public error: WebApiError ) { }
}

export class NewSuccess implements Action {
	readonly type = NEW_SUCCESS;

	constructor( public adSet: AdSet ) { }
}

export class Update implements Action {
	readonly type = UPDATE;

	constructor( public adSet: AdSet ) { }
}

export class UpdateFail implements Action {
	readonly type = UPDATE_FAIL;

	constructor( public error: WebApiError ) { }
}

export class UpdateSuccess implements Action {
	readonly type = UPDATE_SUCCESS;

	constructor( public adSet: AdSet ) { }
}

export class Select implements Action {
	readonly type = SELECT;

	constructor( public adSet: AdSet ) { }
}

export type AdsSetsAction
	= Clear
	| Delete
	| DeleteFail
	| DeleteSuccess
	| List
	| ListFail
	| ListSuccess
	| NameExists
	| NameExistsReset
	| New
	| NewFail
	| NewSuccess
	| Update
	| UpdateFail
	| UpdateSuccess
	| Select;
