import { Action } from '@ngrx/store';

import { WebApiError } from '../../shared/web-api/web-api-error';
import { Tracker } from '../trackers/trackers.model';

export const LIST = '[Trackers] List';
export const LIST_FAIL = '[Trackers] List Fail';
export const LIST_SUCCESS = '[Trackers] List Success';
export const REFRESH_ON_OFF = '[Trackers] Refresh On Off';
export const SELECT = '[Trackers] Select';
export const SORT = '[Trackers] Sort';
export const VERBOSE = '[Trackers] Verbose';

export class List implements Action {
	readonly type = LIST;

	constructor( public facilityId: number ) { }
}

export class ListFail implements Action {
	readonly type = LIST_FAIL;

	constructor( public error: WebApiError ) { }
}

export class ListSuccess implements Action {
	readonly type = LIST_SUCCESS;

	constructor( public trackers: Tracker[] ) { }
}

export class RefreshOnOff implements Action {
	readonly type = REFRESH_ON_OFF;

	constructor( public refreshOnOff: boolean ) { }
}

export class Select implements Action {
	readonly type = SELECT;

	constructor( public tracker: Tracker ) { }
}

export class Sort implements Action {
	readonly type = SORT;

	constructor( public sortOrder: number ) { }
}

export class Verbose implements Action {
	readonly type = VERBOSE;

	constructor( public trackerId: number ) { }
}

export type TrackersAction
	= List
	| ListFail
	| ListSuccess
	| RefreshOnOff
	| Select
	| Sort
	| Verbose;
