import * as TraceActions from './trace.actions';

import { TracePoint } from './trace-point.model';

export interface State {
	progress: boolean;
	tracePoints: TracePoint[];
}

const initialState: State = {
	progress: false,
	tracePoints: []
};

export function reducer( state = initialState, action: TraceActions.TraceAction ): State {
	switch ( action.type ) {

		case TraceActions.LOAD:
			return { ...state, progress: true };

		case TraceActions.LOAD_SUCCESS:
			return { ...state, progress: false, tracePoints: action.payload };

		case TraceActions.RESET:
		case TraceActions.LOAD_FAIL:
			return initialState;

		default:
			return state;
	}
}

export const getProgress = ( state: State ) => state.progress;
export const getTracePoints = ( state: State ) => state.tracePoints;
