<mat-tab-group>
	<mat-tab label="FACILITY">
		<ct-settings-facility [facility]="facility" (saveSettings)="saveFacilitySettings($event)">
		</ct-settings-facility>
	</mat-tab>
	<mat-tab label="TRACKING">
		<ct-settings-tracking [visibleDeviceModes]="visibleDeviceModes$ | async" [webUser]="webUser"
			(saveSettings)="saveMobileSettings($event)"></ct-settings-tracking>
	</mat-tab>
	<mat-tab label="OTHER">
		<ct-settings-other [webUser]="webUser" (saveSettings)="saveMobileSettings($event)"></ct-settings-other>
	</mat-tab>
</mat-tab-group>