import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { JsonViewerComponent } from './json-viewer/json-viewer.component';
import { BrowserDetectionService } from './browser-detection.service';
import { FileService } from './file.service';

export const UTILS = [
	JsonViewerComponent
];

@NgModule( {
	imports: [CommonModule],
	exports: UTILS,
	declarations: UTILS,
	providers: [
		BrowserDetectionService,
		FileService
	]
} )
export class UtilsModule { }
