import { Component, OnInit } from '@angular/core';

@Component( {
	selector: 'ct-facilities',
	templateUrl: './facilities.component.html',
	styleUrls: ['./facilities.component.css']
} )
export class FacilitiesComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}

}
