import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import * as AdsActions from './ads.actions';
import * as RoutingActions from '../../routing/routing.actions';
import * as fromCartTrac from '../../cart-trac.reducers';

import { AdsService } from './ads.service';
import { DialogService } from '../../shared/dialogs/dialog.service';
import { Ad } from './ads.model';
import { WebApiError } from '../../shared/web-api/web-api-error';

@Injectable()
export class AdsEffects {

	@Effect()
	delete$ = this.actions$.pipe(
		ofType( AdsActions.DELETE ),
		map( ( action: AdsActions.Delete ) => action.id ),
		switchMap( id => this.setsService.delete( id ).pipe(
			map( () => new AdsActions.DeleteSuccess() ),
			catchError( ( error: WebApiError ) => of( new AdsActions.DeleteFail( error ) ) )
		) ) );

	@Effect()
	list$ = this.actions$.pipe(
		ofType( AdsActions.LIST ),
		map( ( action: AdsActions.List ) => action.adListId ),
		switchMap( adListId => this.setsService.list( adListId ).pipe(
			map( ( ads: Ad[] ) => new AdsActions.ListSuccess( ads ) ),
			catchError( ( error: WebApiError ) => of( new AdsActions.ListFail( error ) ) )
		) )
	);

	@Effect()
	new$ = this.actions$.pipe(
		ofType( AdsActions.NEW ),
		map( ( action: AdsActions.New ) => action.ad ),
		mergeMap( ad => this.setsService.new( ad ).pipe(			// switchMap unsubscribes when second Observable arrives, that cancels the http request
			map( ( newAd: Ad ) => new AdsActions.NewSuccess( newAd ) ),
			catchError( ( error: WebApiError ) => of( new AdsActions.NewFail( error ) ) )
		) )
	);

	@Effect()
	update$ = this.actions$.pipe(
		ofType( AdsActions.UPDATE ),
		map( ( action: AdsActions.Update ) => action.ad ),
		switchMap( ad => this.setsService.update( ad ).pipe(
			map( ( updatedAd: Ad ) => new AdsActions.UpdateSuccess( updatedAd ) ),
			catchError( ( error: WebApiError ) => of( new AdsActions.UpdateFail( error ) ) )
		) )
	);

	@Effect( { dispatch: false } )
	fail$ = this.actions$.pipe(
		ofType( AdsActions.DELETE_FAIL, AdsActions.LIST_FAIL, AdsActions.NEW_FAIL, AdsActions.UPDATE_FAIL ),
		tap( ( action: AdsActions.DeleteFail | AdsActions.ListFail | AdsActions.NewFail | AdsActions.UpdateFail ) =>
			this.dialogService.webApiError( `AdsEffects - ${action.type}`, action.error ).subscribe(
				() => this.store$.dispatch( new RoutingActions.Go( { path: ['/login'] } ) )
			)
		)
	);

	constructor(
		private actions$: Actions,
		private store$: Store<fromCartTrac.State>,
		private setsService: AdsService,
		private dialogService: DialogService
	) { }
}
