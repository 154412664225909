<mat-toolbar color="secondary">Import Iamges</mat-toolbar>

<form (ngSubmit)="import()" #importImagesForm="ngForm">
  <br /> Source Folder on Server:
  <div>
    <mat-form-field>
      <input matInput type="text" class="folder-name" required mdMaxLength="50" [(ngModel)]="folderName" name="folderName" autofocus
      />
    </mat-form-field>
  </div>

  <div>
    <button mat-raised-button type="submit" [disabled]="!importImagesForm.form.valid">Import</button>
    <button mat-raised-button type="button" (click)="close()">Close</button>
  </div>
</form>