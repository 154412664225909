<mat-card class="center">
	<mat-card-title>SMS Alarm</mat-card-title>
	<mat-card-content>
		<form [formGroup]="alarmForm">
			<mat-form-field>
				<input matInput type="text" placeholder="Text" formControlName="text">
				<mat-error *ngIf="textControl.invalid">{{ errorText() }}</mat-error>
			</mat-form-field>
			<mat-form-field>
				<input matInput type="tel" placeholder='"From" Phone Number' formControlName="fromPhoneNumber">
				<mat-error *ngIf="phoneNumberControl.invalid">{{ errorPhoneNumber() }}</mat-error>
			</mat-form-field>
		</form>
	</mat-card-content>
</mat-card>