import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HoleSelectorComponent } from './hole-selector.component';
import { CartTracMaterialModule } from '../../cart-trac-material.module';
import { CartTracAngularModule } from '../../cart-trac-angular.module';

@NgModule( {
	declarations: [HoleSelectorComponent],
	imports: [
		CommonModule,
		CartTracAngularModule,
		CartTracMaterialModule,
	],
	exports: [
		HoleSelectorComponent
	]
} )
export class HoleSelectorModule { }
