export class ZoneVersion {
	constructor(
		public Active: boolean,
		public DeviceModeId: number,
		public FacilityId: number,
		public Name: string,
		public Number: string,
		public Published: boolean,
		public ZoneVersionId: number,

		public CSSClass: string = 'zone-version'			// DisplayZoneVersion
	) { }
}

export enum ZonesTabs { Versions, Other }
