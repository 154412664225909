<mat-card>
	<mat-card>
		<mat-card-title>Map</mat-card-title>
		<mat-card-content>
			<mat-slide-toggle *ngIf="webUser" [(ngModel)]="webUser.Settings.Tracking.ShowFlags">Show Flags
			</mat-slide-toggle>
			<mat-slide-toggle *ngIf="webUser" [(ngModel)]="webUser.Settings.Tracking.ShowTees">Show Tees
			</mat-slide-toggle>
			<mat-slide-toggle *ngIf="webUser" [(ngModel)]="webUser.Settings.Tracking.UseHoleName">Use Hole Name
			</mat-slide-toggle>
			<mat-slide-toggle *ngIf="webUser" [(ngModel)]="webUser.Settings.Tracking.ShowLogo">Show Map Logo (while fullscreen)
			</mat-slide-toggle>
		</mat-card-content>
	</mat-card>
	<mat-card>
		<mat-card-title>Show Carts by Mode</mat-card-title>
		<mat-card-content>
			<div *ngFor="let visibleDeviceMode of visibleDeviceModes">
				<mat-slide-toggle [(ngModel)]="visibleDeviceMode.checked">{{visibleDeviceMode.name}}</mat-slide-toggle>
			</div>
		</mat-card-content>
	</mat-card>
	<mat-card>
		<mat-card-title>Cart Colors</mat-card-title>
		<mat-card-content>
			<mat-slide-toggle *ngIf="webUser" [(ngModel)]="webUser.Settings.Tracking.UsePOPColors">Use POP Colors
			</mat-slide-toggle>
		</mat-card-content>
	</mat-card>
	<mat-card-actions>
		<button mat-raised-button (click)="save()">SAVE</button>
	</mat-card-actions>
</mat-card>