import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap, mergeMap } from 'rxjs/operators';

import * as DevicesActions from './devices.actions';
import * as RoutingActions from '../../routing/routing.actions';
import * as fromCartTrac from '../../cart-trac.reducers';

import { DevicesService } from './devices.service';
import { Device } from './devices.model';
import { DialogService } from '../../shared/dialogs/dialog.service';
import { WebApiError } from '../../shared/web-api/web-api-error';
import { FileService } from '../../shared/utils/file.service';

@Injectable()
export class DevicesEffects {

	@Effect()
	delete$ = this.actions$.pipe(
		ofType( DevicesActions.DELETE ),
		map( ( action: DevicesActions.Delete ) => action.id ),
		switchMap( id => this.devicesService.delete( id ).pipe(
			map( () => new DevicesActions.DeleteSuccess() ),
			catchError( ( error: WebApiError ) => of( new DevicesActions.DeleteFail( error ) ) )
		) )
	);

	@Effect()
	list$ = this.actions$.pipe(
		ofType( DevicesActions.LIST ),
		map( ( action: DevicesActions.List ) => action.facilityId ),
		switchMap( facilityId => this.devicesService.list( facilityId ).pipe(
			map( ( devices: Device[] ) => new DevicesActions.ListSuccess( devices ) ),
			catchError( ( error: WebApiError ) => of( new DevicesActions.ListFail( error ) ) )
		) )
	);

	@Effect()
	move$ = this.actions$.pipe(
		ofType( DevicesActions.MOVE ),
		map( ( action: DevicesActions.Move ) => action.parameters ),
		switchMap( parameters => this.devicesService.move( parameters ).pipe(
			map( () => new DevicesActions.MoveSuccess() ),
			catchError( ( error: WebApiError ) => of( new DevicesActions.MoveFail( error ) ) )
		) )
	);

	@Effect()
	new$ = this.actions$.pipe(
		ofType( DevicesActions.NEW ),
		map( ( action: DevicesActions.New ) => action.device ),
		mergeMap( device => this.devicesService.new( device ).pipe(
			// switchMap unsubscribes when second Observable arrives, that cancels the http request
			map( ( newDevice: Device ) => new DevicesActions.NewSuccess( newDevice ) ),
			catchError( ( error: WebApiError ) => of( new DevicesActions.NewFail( error ) ) )
		) )
	);

	@Effect()
	update$ = this.actions$.pipe(
		ofType( DevicesActions.UPDATE ),
		map( ( action: DevicesActions.Update ) => action.device ),
		mergeMap( device => this.devicesService.update( device ).pipe(
			// switchMap unsubscribes when second Observable arrives, that cancels the http request
			map( ( updateDevice: Device ) => new DevicesActions.UpdateSuccess( updateDevice ) ),
			catchError( ( error: WebApiError ) => of( new DevicesActions.UpdateFail( error ) ) )
		) )
	);

	@Effect( { dispatch: false } )
	fail$ = this.actions$.pipe(
		ofType( DevicesActions.LIST_FAIL, DevicesActions.DELETE_FAIL, DevicesActions.MOVE_FAIL,
			DevicesActions.NEW_FAIL, DevicesActions.UPDATE_FAIL ),
		tap( ( action: DevicesActions.ListFail | DevicesActions.DeleteFail | DevicesActions.MoveFail |
			DevicesActions.NewFail | DevicesActions.UpdateFail ) =>
			this.dialogService.webApiError( `DevicesEffects - ${action.type}`, action.error ).subscribe(
				() => this.store$.dispatch( new RoutingActions.Go( { path: ['/login'] } ) )
			)
		)
	);

	constructor(
		private actions$: Actions,
		private store$: Store<fromCartTrac.State>,
		private dialogService: DialogService,
		private devicesService: DevicesService,
		private fileService: FileService
	) { }
}
