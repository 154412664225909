import { Pipe, PipeTransform } from '@angular/core';

@Pipe( { name: 'ellipsis' } )
export class EllipsisPipe implements PipeTransform {
	transform( text: string, length: number, useElementBoundary: boolean, deliminater: string ): string {
		if ( length === undefined || useElementBoundary === undefined ) {
			return text;
		}

		if ( text.length <= length ) {
			return text;
		}

		const subString = text.substr( 0, length - 1 );

		return ( useElementBoundary ? subString.substr( 0, subString.lastIndexOf( deliminater ) ) : subString ) + '...';
	}
}
