import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromCartTrac from '../cart-trac.reducers';
import * as LayoutActions from '../layout/layout.actions';
import * as RoutingActions from '../routing/routing.actions';

import { WebUser } from '../login/login.model';

@Component( {
	selector: 'ct-system',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './system.component.html',
	styleUrls: ['./system.component.css']
} )
export class SystemComponent implements OnDestroy, OnInit {

	private webUser$: Observable<WebUser>;
	private webUserSubscription: Subscription;

	constructor( private store$: Store<fromCartTrac.State> ) { }

	ngOnInit() {
		this.webUser$ = this.store$.select( fromCartTrac.getWebUser );

		this.webUserSubscription = this.webUser$.pipe( take( 1 ) )
			.subscribe( webUser => {
				if ( webUser )
					this.store$.dispatch( new LayoutActions.FeatureName( 'SYSTEM' ) );
				else
					this.store$.dispatch( new RoutingActions.Go( { path: ['/login'] } ) );
			} );
	}

	ngOnDestroy() {
		this.webUserSubscription.unsubscribe();
	}
}
