<form>
	<mat-form-field>
		<mat-label *ngIf="label">Mode</mat-label>
		<mat-select disableOptionCentering="true" [(value)]="selected" name="selected"
			(selectionChange)="selectionChange($event)">
			<mat-option *ngFor="let mode of modes" [value]="mode">
				{{mode.Name}}
			</mat-option>
		</mat-select>
	</mat-form-field>
</form>