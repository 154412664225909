import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Facility } from '../../../facilities.model';

@Component( {
	selector: 'ct-facility-selector-list-item',
	templateUrl: './item.component.html',
	styleUrls: ['./item.component.css']
} )
export class FacilitySelectorListItemComponent {
	@Input() facility: Facility;
	@Output() selected = new EventEmitter<Facility>();

	get logo(): string {
		return this.facility.Logo;
	}

	get name(): string {
		return this.facility.Name;
	}

	onClick() {
		this.selected.emit( this.facility );
	}
}
