import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AdImage, AdImageUploadParameters, AdImageListParameters, AdImageRenameParameters } from './images.model';
import { WebApiService } from '../../shared/web-api/web-api.service';
import { WebApiError } from '../../shared/web-api/web-api-error';

@Injectable()
export class ImagesService {

	private serviceBaseUrl: string;

	constructor( private webApiService: WebApiService ) {
		this.serviceBaseUrl = this.webApiService.serverUrl + '/api/ads/images';
	}

	copy( id: number ): Observable<AdImage | WebApiError> {
		return this.webApiService.post( this.serviceBaseUrl + '/copy/' + id, null );
	}

	delete( id: number ): Observable<void | WebApiError> {
		return this.webApiService.delete( this.serviceBaseUrl + '/' + id );
	}

	download( facilityId: number ): Observable<number | WebApiError> {
		return this.webApiService.post( this.serviceBaseUrl + '/export/' + facilityId, null );
	}

	upload( imagesImportParameters: AdImageUploadParameters ): Observable<number | WebApiError> {
		return this.webApiService.post( this.serviceBaseUrl + '/import', imagesImportParameters );
	}

	list( listParameters: AdImageListParameters ): Observable<AdImage[] | WebApiError> {
		return this.webApiService.get<AdImage[]>( this.serviceBaseUrl + '/' + listParameters.FacilityID + '/' + listParameters.UnitModelID + '/' + listParameters.ImageTypeType );
	}

	new( adImage: AdImage ): Observable<AdImage | WebApiError> {
		return this.webApiService.post( this.serviceBaseUrl, adImage );
	}

	purge( facilityId: number ): Observable<number | WebApiError> {
		return this.webApiService.delete( this.serviceBaseUrl + '/purge/' + facilityId );
	}

	rename( parameters: AdImageRenameParameters ): Observable<AdImageRenameParameters | WebApiError> {
		return this.webApiService.put( this.serviceBaseUrl, parameters );
	}
}

