import { Component, OnInit, Input } from '@angular/core';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DownloadComponent } from './download/download.component';
import { UploadComponent } from './upload/upload.component';
import { Facility } from '../../../facilities/facilities.model';
import { Device, Model, Mode } from '../devices.model';

@Component( {
	selector: 'ct-devices-actions-bulk',
	templateUrl: './actions-bulk.component.html',
	styleUrls: ['./actions-bulk.component.css']
} )
export class ActionsBulkComponent implements OnInit {
	@Input() devices: Device[];
	@Input() facility: Facility;
	@Input() models: Model[];
	@Input() modes: Mode[];
	@Input() systemUser: boolean;

	constructor(
		private dialog: MatDialog
	) { }

	ngOnInit() {
	}

	download() {
		this.dialog.open( DownloadComponent, { data: { facility: this.facility, devices: this.devices } } );
	}

	upload() {
		this.dialog.open( UploadComponent, { data: { facility: this.facility, models: this.models, modes: this.modes } } );
	}
}
