import { NgModule } from '@angular/core';

import { CartTracAngularModule } from '../cart-trac-angular.module';
import { CartTracMaterialModule } from '../cart-trac-material.module';
import { UserManagerRoutingModule } from './user-manager-routing.module';
import { UserManagerComponent } from './user-manager.component';

@NgModule( {
	imports: [
		CartTracAngularModule,
		CartTracMaterialModule,

		UserManagerRoutingModule
	],
	declarations: [UserManagerComponent]
} )
export class UserManagerModule { }
