import { NgModule } from '@angular/core';

import { CartTracAngularModule } from '../cart-trac-angular.module';
import { CartTracMaterialModule } from '../cart-trac-material.module';
import { TrackersModule } from './trackers/trackers.module';
import { DevicesModule } from './devices/devices.module';

import { DialogModule } from '../shared/dialogs/dialog.module';
import { PipesModule } from '../shared/utils/pipes/pipes.module';
import { UtilsModule } from '../shared/utils/utils.module';

import { DevicesRoutingModule } from './device-manager-routing.module';

import { DeviceManagerComponent } from './device-manager.component';

@NgModule( {
	declarations: [
		DeviceManagerComponent
	],
	exports: [
		DeviceManagerComponent
	],
	imports: [
		CartTracAngularModule,
		CartTracMaterialModule,
		DevicesModule,
		TrackersModule,

		DevicesRoutingModule,

		DialogModule,
		PipesModule,
		UtilsModule
	]
} )
export class DeviceManagerModule { }
