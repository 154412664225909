import { Component, Input, OnInit } from '@angular/core';

import { Course, Hole } from '../courses.model';
@Component( {
	selector: 'ct-courses-tabs',
	templateUrl: './courses-tabs.component.html',
	styleUrls: ['./courses-tabs.component.css']
} )
export class CoursesTabsComponent implements OnInit {

	@Input() courses: Course[];
	@Input() holes: Hole[];

	constructor() { }

	ngOnInit() {
	}

	holesByCourse( courseId: number ): Hole[] {
		return ( this.holes.filter( hole => hole.CourseId === courseId ) );
	}
}
