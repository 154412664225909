import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

import { Facility } from '../../facilities.model';

@Component( {
	selector: 'ct-facility-selector-list',
	templateUrl: './list.component.html',
	styleUrls: ['./list.component.css']
} )
export class FacilitySelectorListComponent implements OnChanges {
	@Input() facilities: Facility[];
	@Output() selected = new EventEmitter<Facility>();

	constructor() {
	}

	ngOnChanges() {

		if ( this.facilities.length === 1 )
			this.selected.emit( this.facilities[0] );
	}

	onSelected( facility: Facility ) {
		this.selected.emit( facility );
	}
}
