import { EntityState, createEntityAdapter } from '@ngrx/entity';
import * as SetsActions from './sets.actions';

import { AdSet } from './sets.model';

export interface State extends EntityState<AdSet> {
	progress: boolean;
	selected: AdSet;
	nameExists: boolean;
}

export const adapter = createEntityAdapter<AdSet>( {
	selectId: ( adSet: AdSet ) => adSet.AdSetID,
	sortComparer: false
} );

const initialState: State = adapter.getInitialState( {
	progress: false,
	selected: null,
	nameExists: false
} );

export function reducer( state = initialState, action: SetsActions.AdsSetsAction ): State {

	switch ( action.type ) {
		case SetsActions.DELETE:
		case SetsActions.NEW:
		case SetsActions.UPDATE:
			return { ...state, progress: true };

		case SetsActions.CLEAR:
		case SetsActions.LIST:
			return { ...adapter.removeAll( state ), selected: null };

		case SetsActions.DELETE_SUCCESS:
			return { ...adapter.removeOne( state.selected.AdSetID, state ), progress: false, selected: null };

		case SetsActions.LIST_SUCCESS:
			return { ...adapter.addAll( action.adSets, state ), progress: false, selected: null };

		case SetsActions.NAME_EXISTS:
			return { ...state, nameExists: true, progress: false };

		case SetsActions.NAME_EXISTS_RESET:
			return { ...state, nameExists: false };

		case SetsActions.NEW_SUCCESS:
			return { ...adapter.addOne( action.adSet, state ), progress: false, selected: action.adSet };

		case SetsActions.UPDATE_SUCCESS:
			return { ...adapter.updateOne( { id: action.adSet.AdSetID, changes: action.adSet }, { ...state, progress: false, selected: null } ) };

		case SetsActions.SELECT:
			return { ...state, selected: action.adSet };

		case SetsActions.DELETE_FAIL:
		case SetsActions.LIST_FAIL:
		case SetsActions.NEW_FAIL:
		case SetsActions.UPDATE_FAIL:
			return initialState;

		default:
			return state;
	}
}

export const {
	selectIds: getIds,
	selectEntities: getEntities,
	selectAll: getAll,
	selectTotal: getCount,
} = adapter.getSelectors();

export const getProgress = ( state: State ) => state.progress;
export const getSelectedAdSet = ( state: State ) => state.selected;
export const getNameExists = ( state: State ) => state.nameExists;
