import { NgModule } from '@angular/core';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StatusEffects } from './status.effects';
import { reducer } from './status.reducer';

import { CartTracAngularModule } from '../../cart-trac-angular.module';
import { CartTracMaterialModule } from '../../cart-trac-material.module';

import { SystemRoutingModule } from '../system-routing.module';

import { UtilsModule } from '../../shared/utils/utils.module';

import { StatusComponent } from './status.component';
import { StatusTrackingServerComponent } from './status-tracking-server/status-tracking-server.component';
import { StatusOtherComponent } from './status-other/status-other.component';

@NgModule( {
	declarations: [
		StatusComponent,
		StatusTrackingServerComponent,
		StatusOtherComponent
	],
	imports: [
		CartTracAngularModule,
		CartTracMaterialModule,
		SystemRoutingModule,

		UtilsModule,

		StoreModule.forFeature( 'systemStatus', reducer ),
		EffectsModule.forFeature( [StatusEffects] )
	],
	exports: [
		StatusComponent,
		StatusTrackingServerComponent
	]
} )
export class StatusModule { }
