import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, take, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import * as fromCartTrac from '../../cart-trac.reducers';
import * as SetsActions from './sets.actions';
import * as ListsActions from '../lists/lists.actions';

import { AdSet } from './sets.model';
import { AdList } from '../lists/lists.model';
import { AdImageType } from '../images/images.model';
import { Course } from '../../courses/courses.model';
import { FormErrorStateMatcher } from '../../shared/utils/form-error-state-matcher';
import { DialogService } from '../../shared/dialogs/dialog.service';
import { NewComponent } from './new/new.component';

@Component( {
	selector: 'ct-ad-manager-sets',
	templateUrl: './sets.component.html',
	styleUrls: ['./sets.component.css']
} )
export class SetsComponent implements OnInit {

	@Input() adSets: AdSet[];
	@Input() adImageType: AdImageType;
	@Input() courses: Course[];
	@Input() published: boolean;
	@Input() selected: AdSet;
	@Input() adVersionId: number;

	@Output() listImages = new EventEmitter<AdImageType>();
	@Output() clearImages = new EventEmitter<AdImageType>();

	adLists$: Observable<AdList[]>;
	selectedAdList$: Observable<AdList>;
	nameExists$: Observable<boolean>;

	editMode = false;
	editForm: FormGroup;
	nameErrorMatcher = new FormErrorStateMatcher();
	nameExists: boolean;

	private dialogRef: MatDialogRef<NewComponent>;
	private nameExistsSubscription: Subscription;

	constructor(
		private store$: Store<fromCartTrac.State>,
		private formBuilder: FormBuilder,
		private dialog: MatDialog,
		private dialogService: DialogService
	) {
		this.adLists$ = this.store$.select( fromCartTrac.getAdManagerLists );
		this.nameExists$ = this.store$.select( fromCartTrac.getAdManagerSetNameExists );
		this.selectedAdList$ = this.store$.select( fromCartTrac.getSelectedAdList );
	}

	ngOnInit() {
		this.editForm = this.formBuilder.group( {
			name: [null, Validators.required],
			startTime: [null, Validators.required],
			endTime: [null, Validators.required],
			courseNumber: [null, Validators.required],
			exclusive: [null, Validators.required]
		} );
	}

	closed( adSet: AdSet ) {
		if ( this.selected && this.selected.AdSetID === adSet.AdSetID ) {
			this.editMode = false;
			this.reset();
		}

		this.onClearImages();
	}

	courseName( courseNumber: string ): string {
		if ( courseNumber === '0' ) {
			return 'Any';
		} else {
			return this.courses.find( course => course.Number === courseNumber ).Name;
		}
	}

	delete( event: Event, adSet: AdSet ) {
		event.stopPropagation();
		this.store$.dispatch( new SetsActions.Select( adSet ) );
		this.store$.dispatch( new SetsActions.Delete( adSet.AdSetID ) );
	}

	edit( adSet: AdSet ) {
		this.editMode = true;
		this.store$.dispatch( new SetsActions.Select( adSet ) );
	}

	opened( adSet: AdSet ) {
		this.store$.dispatch( new SetsActions.Select( adSet ) );

		if ( this.editMode && this.selected && this.selected.AdSetID !== adSet.AdSetID ) {
			this.editMode = false;
		}

		if ( this.editMode ) {
			this.store$.dispatch( new ListsActions.Clear() );

			this.editForm.get( 'name' ).setValue( adSet.Name );
			this.editForm.get( 'startTime' ).setValue( adSet.StartTime );
			this.editForm.get( 'endTime' ).setValue( adSet.EndTime );
			this.editForm.get( 'courseNumber' ).setValue( adSet.CourseNumber );
			this.editForm.get( 'exclusive' ).setValue( adSet.Exclusive );

			if ( adSet.Name === 'CartTrac Set' || adSet.Name === 'Facility Set' ) {
				this.editForm.get( 'name' ).disable();
			}
		} else {
			this.store$.dispatch( new ListsActions.List( adSet.AdSetID ) );
		}
	}

	onClearImages( adImageType: AdImageType = this.adImageType ) {
		this.clearImages.emit( adImageType );
	}

	onListImages( adImageType: AdImageType ) {
		this.editMode = false;
		this.adImageType = adImageType;
		this.listImages.emit( adImageType );
	}

	new() {
		if ( this.nameExistsSubscription ) {
			this.nameExistsSubscription.unsubscribe();
		}

		this.dialogRef = this.dialog.open( NewComponent, { data: { adVersionId: this.adVersionId, courses: this.courses } } );

		this.dialogRef.afterClosed()
			.subscribe( ( adSet: AdSet ) => {
				if ( adSet.Name !== '' ) {
					this.nameExistsSubscription = this.nameExists$.pipe( distinctUntilChanged() )
						.subscribe( ( nameExists ) => {
							if ( nameExists ) {
								this.dialogService.alert( 'Create Ad Set', `Set name: "${adSet.Name}" already exists.` )
									.subscribe( () => {
										this.nameExistsSubscription.unsubscribe();
										this.store$.dispatch( new SetsActions.NameExistsReset() );
									} );
							}
						} );

					this.store$.dispatch( new SetsActions.New( adSet ) );
				}
			} );
	}

	reset( event: Event = null ) {
		if ( event ) {
			event.stopPropagation();
		}

		this.editForm.reset( {
			name: this.selected.Name,
			startTime: this.selected.StartTime,
			endTime: this.selected.EndTime,
			courseNumber: this.selected.CourseNumber,
			exclusive: this.selected.Exclusive
		} );
	}

	update( evnet: Event ) {
		event.stopPropagation();

		if ( this.nameExistsSubscription ) {
			this.nameExistsSubscription.unsubscribe();
		}

		this.nameExistsSubscription = this.nameExists$.pipe( distinctUntilChanged() )
			.subscribe( ( nameExists ) => {
				if ( nameExists ) {
					this.dialogService.alert( 'Ad Set Upadte', `Set name: "${this.editForm.get( 'name' ).value}" already exists.` )
						.subscribe( () => {
							this.reset();
							this.nameExistsSubscription.unsubscribe();
							this.store$.dispatch( new SetsActions.NameExistsReset() );
						} );
				}
			} );

		this.store$.dispatch( new SetsActions.Update( new AdSet(
			this.selected.AdSetID,
			this.editForm.get( 'name' ).value,
			this.selected.AdVersionID,
			this.selected.OwnerType,
			this.editForm.get( 'startTime' ).value,
			this.editForm.get( 'endTime' ).value,
			this.editForm.get( 'exclusive' ).value,
			this.editForm.get( 'courseNumber' ).value ) ) );
	}
}
