import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

import { WebLogin } from '../../login/login.model';
import { WebApiError } from './web-api-error';

import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { throwError } from 'rxjs';

@Injectable()
export class WebApiService {

	serverUrl: string;

	private username: string;
	private password: string;

	constructor( private httpClient: HttpClient ) {
		this.serverUrl = environment.webApiServer;
	}

	/**
	 * WebApi GET wrapper
	 * @param url: Api request string.
	 * @param authorization: If specified overrides default (CartTrac) authorization header.
	 */
	get<R>( url: string, authorization?: string ): Observable<R | WebApiError> {
		const headers = new HttpHeaders()
			.set( 'Authorization', authorization || this.authorization() );

		return this.httpClient.get<R>( url, { headers } )
			.pipe( catchError( this.error ) );
	}

	/**
	 * WebApi POST wrapper
	 * @param url: Api request string.
	 * @param payload: Payload.
	 * @param authorization: If specified overrides default (CartTrac) authorization header.
	 */
	post<R>( url: string, payload: any, authorization?: string ): Observable<R | WebApiError> {
		const headers = new HttpHeaders()
			.set( 'Authorization', authorization || this.authorization() )
			.set( 'Content-Type', 'application/json' );

		return this.httpClient.post<R>( url, payload, { headers } )
			.pipe( catchError( this.error ) );
	}

	/**
	 * WebApi PUT wrapper.
	 * @param url: Api request string.
	 * @param payload: Payload.
	 * @param authorization: If specified overrides default (CartTrac) authorization header.
	 */
	put<R>( url: string, payload: any, authorization?: string ): Observable<R | WebApiError> {
		const headers = new HttpHeaders()
			.set( 'Content-Type', 'application/json' )
			.set( 'Authorization', authorization || this.authorization() );

		return this.httpClient.put<R>( url, payload, { headers } )
			.pipe( catchError( this.error ) );
	}

	/**
	 * WebApi DELETE wrapper
	 * @param url: Api request string.
	 * @param authorization: If specified overrides default (CartTrac) authorization header.
	 */
	delete<R>( url: string, authorization?: string ): Observable<R | WebApiError> {
		const headers = new HttpHeaders()
			.set( 'Authorization', authorization || this.authorization() );

		return this.httpClient.delete<R>( url, { headers } )
			.pipe( catchError( this.error ) );
	}

	/**
	 * WebApi error handler.
	 * @param error: Server response.
	 */
	error( errorResponse: HttpErrorResponse ): Observable<WebApiError> {
		let webApiError: WebApiError;

		if ( !errorResponse.ok && errorResponse.status === 0 ) {
			webApiError = {
				Status: 0, StatusText: errorResponse.message || errorResponse.toString(), WebApiResponse: {
					Action: null,
					Path: null,
					Message: 'Is WebApi running?',
					Return: null
				}
			};
		} else {
			if ( errorResponse.error instanceof ErrorEvent ) {
				webApiError = { Status: 900, StatusText: errorResponse.message || errorResponse.toString(), WebApiResponse: null };
			} else {
				webApiError = { Status: errorResponse.status, StatusText: errorResponse.statusText, WebApiResponse: errorResponse.error };
			}
		}

		// return ErrorObservable.create( webApiError );
		return throwError( webApiError );
	}

	/**
	 * Initialization of default (CartTrac) WebApi.
	 * @param webLogin: web login
	 */
	init( webLogin: WebLogin ) {
		this.username = webLogin.Login;
		this.password = webLogin.Password;
	}

	/**
 	* Default (CartTrac) Authorization
 	*/
	authorization(): string {
		return this.basicAuthorization( this.username, this.password );
	}

	basicAuthorization( username: string, password: string ): string {
		const encodedData = window.btoa( username + ':' + password );
		return 'Basic ' + encodedData;
	}
}

