import { createSelector } from '@ngrx/store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import * as VersionsActions from './versions.actions';

import { AdVersion } from './versions.model';


export interface State extends EntityState<AdVersion> {
	progress: boolean;
	selected: AdVersion;
	sortOrder: number;
	nameExists: boolean;
}

export const adapter = createEntityAdapter<AdVersion>( {
	selectId: ( adVersion: AdVersion ) => adVersion.AdVersionID,
	sortComparer: false
} );

const initialState: State = adapter.getInitialState( {
	progress: false,
	selected: null,
	sortOrder: 1,
	nameExists: false
} );

export function reducer( state = initialState, action: VersionsActions.AdsVersionsAction ): State {

	switch ( action.type ) {
		case VersionsActions.NEW:
			return { ...state, progress: true };

		case VersionsActions.COPY:
		case VersionsActions.PUBLISH:
			return { ...state, progress: true, selected: state.entities[action.parameters.AdVersionID] };

		case VersionsActions.RENAME:
			return { ...state, nameExists: false, progress: true, selected: state.entities[action.parameters.AdVersionID] };

		case VersionsActions.ACTIVATE:
		case VersionsActions.DELETE:
			return { ...state, progress: true, selected: state.entities[action.id] };

		case VersionsActions.LIST:
			return { ...adapter.removeAll( state ) };

		case VersionsActions.ACTIVATE_SUCCESS:
			for ( const id in state.entities )
				if ( state.entities[id].Active === true )
					state.entities[id].Active = false;

			return { ...adapter.updateOne( { id: state.selected.AdVersionID, changes: { Active: true } }, { ...state, progress: false } ) };

		case VersionsActions.COPY_SUCCESS:
			if ( !state.selected.Published )
				return { ...adapter.addOne( action.adVersion, state ), progress: false, selected: action.adVersion };
			else
				return { ...state, progress: false };

		case VersionsActions.DELETE_SUCCESS:
			return { ...adapter.removeOne( state.selected.AdVersionID, state ), progress: false, selected: null };

		case VersionsActions.LIST_SUCCESS:
			return { ...adapter.addAll( action.adVersions, state ), nameExists: false, progress: false };

		case VersionsActions.NAME_EXISTS:
			return { ...state, nameExists: true, progress: false };

		case VersionsActions.NAME_EXISTS_RESET:
			return { ...state, nameExists: false };

		case VersionsActions.NEW_SUCCESS:
			return { ...adapter.addOne( action.adVersion, state ), progress: false, selected: action.adVersion };

		case VersionsActions.PUBLISH_SUCCESS:
			return { ...adapter.removeOne( state.selected.AdVersionID, state ), progress: false, selected: null };

		case VersionsActions.RENAME_SUCCESS:
			return { ...adapter.updateOne( { id: action.parameters.AdVersionID, changes: action.parameters }, { ...state, renameExists: false, progress: false } ) };

		case VersionsActions.SELECT:
			return { ...state, selected: action.adVersion };

		case VersionsActions.SORT:
			return { ...state, sortOrder: action.sortOrder };

		case VersionsActions.COPY_FAIL:
		case VersionsActions.DELETE_FAIL:
		case VersionsActions.LIST_FAIL:
		case VersionsActions.NEW_FAIL:
		case VersionsActions.RENAME_FAIL:
			return initialState;

		default:
			return state;
	}
}

export const {
	selectIds: getIds,
	selectEntities: getEntities,
	selectAll: getAll,
	selectTotal: getCount,
} = adapter.getSelectors();

export const getProgress = ( state: State ) => state.progress;
export const getSelectedAdVersion = ( state: State ) => state.selected;
export const getSortOrder = ( state: State ) => state.sortOrder;
export const getNameExists = ( state: State ) => state.nameExists;

const getSortedIds = createSelector(
	getAll,
	getSortOrder,
	( adVersions, sortOrder ) => {

		let sortFunction: ( a: AdVersion, b: AdVersion ) => number;

		switch ( sortOrder ) {
			case 1:
				sortFunction = ( a, b ) => ( a.Name > b.Name ? 1 : -1 );
				break;
			case 2:
				sortFunction = ( a, b ) => ( a.Number > b.Number ? 1 : -1 );
				break;
		}

		return adVersions
			.slice()									// don't change adImages
			.sort( sortFunction )						// sort
			.map( adVersion => adVersion.AdVersionID );	// return ids
	} );

export const getAllSorted = createSelector(
	getEntities,
	getSortedIds,
	( entities, sortedIds ) => {
		return sortedIds.map( id => entities[id] );
	} );
