import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromCartTrac from '../cart-trac.reducers';
import * as LayoutActions from '../layout/layout.actions';
import * as RoutingActions from '../routing/routing.actions';
import * as FacilitiesActions from '../facilities/facilities.actions';
import * as LoginActions from '../login/login.actions';

import { MobileSettings, WebUser, MobileSettingsDTO } from '../login/login.model';
import { VisibleDeviceMode } from './settings.model';
import { Facility, FacilitySettings, FacilitySettingsDTO } from '../facilities/facilities.model';

@Component(
	{
		selector: 'ct-settings',
		changeDetection: ChangeDetectionStrategy.OnPush,
		templateUrl: './settings.component.html',
		styleUrls: ['./settings.component.css']
	} )
export class SettingsComponent implements OnDestroy, OnInit {
	facility$: Observable<Facility>;
	visibleDeviceModes$: Observable<VisibleDeviceMode[]>;
	webUser$: Observable<WebUser>;

	facility: Facility;
	webUser: WebUser;

	private webUserSubscription: Subscription;
	private facilitySubscription: Subscription;

	constructor( private store$: Store<fromCartTrac.State> ) {
		this.facility$ = this.store$.select( fromCartTrac.getFacility );
		this.visibleDeviceModes$ = this.store$.select( fromCartTrac.getVisibleDeviceModes );
		this.webUser$ = this.store$.select( fromCartTrac.getWebUser );
	}

	ngOnInit() {
		this.facilitySubscription = this.facility$.pipe( take( 1 ) )
			.subscribe( facility => {
				if ( facility ) {
					this.facility = facility;
					this.store$.dispatch( new LayoutActions.FeatureName( 'SETTINGS' ) );
				} else {
					this.store$.dispatch( new RoutingActions.Go( { path: ['/login/'] } ) );
				}
			} );

		this.webUserSubscription = this.webUser$.pipe( take( 1 ) )
			.subscribe( webUser => {
				if ( webUser ) {
					this.webUser = webUser;
				} else {
					this.store$.dispatch( new RoutingActions.Go( { path: ['/login/'] } ) );
				}
			} );
	}

	saveFacilitySettings( settings: FacilitySettings ) {
		this.store$.dispatch( new FacilitiesActions.SettingsSave( new FacilitySettingsDTO( this.facility.Id, JSON.stringify( settings ) ) ) );
	}

	saveMobileSettings( settings: MobileSettings ) {
		this.store$.dispatch( new LoginActions.SettingsSave( new MobileSettingsDTO( this.webUser.Id, JSON.stringify( settings ) ) ) );
	}

	ngOnDestroy() {
		this.webUserSubscription.unsubscribe();
		this.facilitySubscription.unsubscribe();
	}
}
