import * as RangerActions from './ranger.actions';

import { RangerData } from './ranger-data.model';

export interface State {
	progress: boolean;
	rangerData: RangerData[];
}

const initialState: State = {
	progress: false,
	rangerData: []
};

export function reducer( state = initialState, action: RangerActions.RangerAction ): State {
	switch ( action.type ) {
		case RangerActions.LOAD:
			return { ...state, progress: true };

		case RangerActions.LOAD_SUCCESS:
			return { ...state, progress: false, rangerData: action.payload };

		case RangerActions.LOAD_FAIL:
			return initialState;

		default:
			return state;
	}
}

export const getProgress = ( state: State ) => state.progress;
export const getRangerData = ( state: State ) => state.rangerData;
