import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CartTracAngularModule } from '../../cart-trac-angular.module';
import { CartTracMaterialModule } from '../../cart-trac-material.module';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { SetsEffects } from './sets.effects';
import { reducer } from './sets.reducer';

import { ListsModule } from '../lists/lists.module';

import { SetsComponent } from './sets.component';
import { SetsService } from './sets.service';
import { NewComponent } from './new/new.component';

@NgModule( {
	declarations: [
		SetsComponent,
		NewComponent
	],
	imports: [
		CommonModule,
		CartTracAngularModule,
		CartTracMaterialModule,
		ListsModule,

		StoreModule.forFeature( 'adManagerSets', reducer ),
		EffectsModule.forFeature( [SetsEffects] )
	],
	exports: [SetsComponent],
	providers: [SetsService]
} )
export class SetsModule { }
