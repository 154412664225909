import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import * as moment from 'moment';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component( {
	selector: 'ct-trace-dates',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './trace-dates.component.html',
	styleUrls: ['./trace-dates.component.css'],
} )
export class TraceDatesComponent implements OnInit {

	@Output() startDate = new EventEmitter<Date>();
	@Output() endDate = new EventEmitter<Date>();

	traceDatesForm: FormGroup;

	constructor() { }

	ngOnInit() {
		const formStartDate = moment().startOf( 'day' ).toDate();
		const formEndDate = moment( formStartDate ).add( 1, 'days' ).toDate();

		this.traceDatesForm = new FormGroup( {
			startDateControl: new FormControl( formStartDate ),
			endDateControl: new FormControl( formEndDate )
		} );

		this.setFormDates( formStartDate, formEndDate );

		this.startDate.emit( formStartDate );
		this.endDate.emit( formEndDate );
	}

	startDateChanged( dateEvent: MatDatepickerInputEvent<Date> ) {
		const formStartDate = moment( dateEvent.value ).toDate();
		const formEndDate = moment( formStartDate ).add( 1, 'days' ).toDate();

		this.setFormDates( formStartDate, formEndDate );

		this.startDate.emit( formStartDate );
		this.endDate.emit( formEndDate );
	}

	endDateChanged( dateEvent: MatDatepickerInputEvent<Date> ) {
		const formEndDate = moment( dateEvent.value ).toDate();
		const formStartDate = moment( formEndDate ).subtract( 1, 'days' ).toDate();

		this.setFormDates( formStartDate, formEndDate );

		this.startDate.emit( formStartDate );
		this.endDate.emit( formEndDate );
	}

	setFormDates( startDate: Date, endDate: Date ) {
		this.traceDatesForm.get( 'startDateControl' ).setValue( startDate );
		this.traceDatesForm.get( 'endDateControl' ).setValue( endDate );
	}
}
