export class Alarm {
	constructor(
		public FacilityId: number,
		public Text: string,
		public FromPhoneNumber: string
	) { }
}

export class AlarmPhoneNumber {
	constructor(
		public Id: number,
		public FacilityId: number,
		public ToPhoneNumber: string
	) { }
}
