import { NgModule } from '@angular/core';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { DevicesEffects } from './devices.effects';
import { reducer } from './devices.reducer';

import { CartTracAngularModule } from '../../cart-trac-angular.module';
import { CartTracMaterialModule } from '../../cart-trac-material.module';

import { DialogModule } from '../../shared/dialogs/dialog.module';
import { PipesModule } from '../../shared/utils/pipes/pipes.module';
import { UtilsModule } from '../../shared/utils/utils.module';
import { ModelPickerModule } from '../../shared/pickers/model-picker/model-picker.module';
import { ModePickerModule } from '../../shared/pickers/mode-picker/mode-picker.module';

import { DevicesService } from './devices.service';
import { DevicesComponent } from './devices.component';

import { ActionsComponent } from './actions/actions.component';
import { ActionsBulkComponent } from './actions-bulk/actions-bulk.component';
import { ListComponent } from './list/list.component';
import { EditComponent } from './actions/edit/edit.component';
import { MoveComponent } from './actions/move/move.component';
import { NewComponent } from './actions/new/new.component';
import { FacilityPickerModule } from '../../shared/pickers/facility-picker/facility-picker.module';
import { UploadComponent } from './actions-bulk/upload/upload.component';
import { DownloadComponent } from './actions-bulk/download/download.component';

@NgModule( {
	declarations: [
		DevicesComponent,
		ActionsComponent,
		ActionsBulkComponent,
		ListComponent,
		MoveComponent,
		NewComponent,
		EditComponent,
		UploadComponent,
		DownloadComponent
	],
	exports: [
		DevicesComponent
	],
	imports: [
		CartTracAngularModule,
		CartTracMaterialModule,

		DialogModule,
		PipesModule,
		UtilsModule,
		FacilityPickerModule,
		ModelPickerModule,
		ModePickerModule,

		StoreModule.forFeature( 'devices', reducer ),
		EffectsModule.forFeature( [DevicesEffects] )
	],
	providers: [DevicesService]
} )
export class DevicesModule { }
