import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import * as SetsActions from './sets.actions';
import * as RoutingActions from '../../routing/routing.actions';
import * as fromCartTrac from '../../cart-trac.reducers';

import { SetsService } from './sets.service';
import { DialogService } from '../../shared/dialogs/dialog.service';
import { AdSet } from './sets.model';
import { WebApiError } from '../../shared/web-api/web-api-error';

@Injectable()
export class SetsEffects {

	@Effect()
	delete$ = this.actions$.pipe(
		ofType( SetsActions.DELETE ),
		map( ( action: SetsActions.Delete ) => action.id ),
		switchMap( id => this.setsService.delete( id ).pipe(
			map( () => new SetsActions.DeleteSuccess() ),
			catchError( ( error: WebApiError ) => of( new SetsActions.DeleteFail( error ) ) )
		) ) );

	@Effect()
	list$ = this.actions$.pipe(
		ofType( SetsActions.LIST ),
		map( ( action: SetsActions.List ) => action.adVersionId ),
		switchMap( adVersionId => this.setsService.list( adVersionId ).pipe(
			map( ( sets: AdSet[] ) => new SetsActions.ListSuccess( sets ) ),
			catchError( ( error: WebApiError ) => of( new SetsActions.ListFail( error ) ) )
		) )
	);

	@Effect()
	new$ = this.actions$.pipe(
		ofType( SetsActions.NEW ),
		map( ( action: SetsActions.New ) => action.adSet ),
		switchMap( adSet => this.setsService.new( adSet ).pipe(
			map( ( newAdSet: AdSet ) => {
				if ( newAdSet.Name === null )
					return new SetsActions.NameExists( newAdSet );
				else
					return new SetsActions.NewSuccess( newAdSet );
			} ),
			catchError( ( error: WebApiError ) => of( new SetsActions.NewFail( error ) ) )
		) )
	);

	@Effect()
	update$ = this.actions$.pipe(
		ofType( SetsActions.UPDATE ),
		map( ( action: SetsActions.Update ) => action.adSet ),
		switchMap( adSet => this.setsService.update( adSet ).pipe(
			map( ( updatedAdSet: AdSet ) => {
				if ( updatedAdSet.Name === null )
					return new SetsActions.NameExists( updatedAdSet );
				else
					return new SetsActions.UpdateSuccess( updatedAdSet );
			} ),
			catchError( ( error: WebApiError ) => of( new SetsActions.UpdateFail( error ) ) )
		) )
	);

	@Effect( { dispatch: false } )
	fail$ = this.actions$.pipe(
		ofType( SetsActions.DELETE_FAIL, SetsActions.LIST_FAIL, SetsActions.NEW_FAIL, SetsActions.UPDATE_FAIL ),
		tap( ( action: SetsActions.DeleteFail | SetsActions.ListFail | SetsActions.NewFail | SetsActions.UpdateFail ) =>
			this.dialogService.webApiError( `SetsEffects - ${action.type}`, action.error ).subscribe(
				() => this.store$.dispatch( new RoutingActions.Go( { path: ['/login'] } ) )
			)
		)
	);

	constructor(
		private actions$: Actions,
		private store$: Store<fromCartTrac.State>,
		private setsService: SetsService,
		private dialogService: DialogService
	) { }
}
