import { Action } from '@ngrx/store';

import { WebApiError } from '../shared/web-api/web-api-error';
import { TrackingDevice, TrackingParameters } from './tracking-device.model';

export const LOAD = '[Tracking] Load';
export const LOAD_SUCCESS = '[Tracking] Load Success';
export const LOAD_FAIL = '[Tracking] Load Fail';

export class Load implements Action {
	readonly type = LOAD;

	constructor( public payload: TrackingParameters ) { }
}

export class LoadSuccess implements Action {
	readonly type = LOAD_SUCCESS;

	constructor( public payload: TrackingDevice[] ) { }
}

export class LoadFail implements Action {
	readonly type = LOAD_FAIL;

	constructor( public payload: WebApiError ) { }
}


export type TrackingAction
	= Load
	| LoadSuccess
	| LoadFail;
