import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';

import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import * as AlarmActions from './alarms.actions';
import * as RoutingActions from '../../routing/routing.actions';
import * as fromCartTrac from '../../cart-trac.reducers';

import { AlarmsService } from './alarms.service';
import { WebApiError } from '../../shared/web-api/web-api-error';
import { DialogService } from '../../shared/dialogs/dialog.service';
import { Alarm, AlarmPhoneNumber } from './alarms.model';

@Injectable()
export class AlarmsEffects {

	@Effect()
	get$ = this.actions$.pipe(
		ofType( AlarmActions.GET ),
		map( ( action: AlarmActions.Get ) => action.facilityId ),
		switchMap( facilityId => this.alarmsService.get( facilityId ).pipe(
			map( ( alarm: Alarm ) => new AlarmActions.GetSuccess( alarm ) ),
			catchError( ( error: WebApiError ) => of( new AlarmActions.GetFail( error ) ) )
		) )
	);

	@Effect()
	update$ = this.actions$.pipe(
		ofType( AlarmActions.UPDATE ),
		map( ( action: AlarmActions.Update ) => action.alarm ),
		switchMap( alarm => this.alarmsService.update( alarm ).pipe(
			map( ( updatedAlarm: Alarm ) => new AlarmActions.UpdateSuccess( updatedAlarm ) ),
			catchError( ( error: WebApiError ) => of( new AlarmActions.UpdateFail( error ) ) )
		) )
	);

	@Effect()
	deletePhoneNumbers$ = this.actions$.pipe(
		ofType( AlarmActions.PHONE_NUMBERS_DELETE ),
		map( ( action: AlarmActions.PhoneNumbersDelete ) => action.alarmsPhoneNumbersId ),
		switchMap( id => this.alarmsService.phoneNumbersDelete( id ).pipe(
			map( () => new AlarmActions.PhoneNumbersDeleteSuccess( id ) ),
			catchError( ( error: WebApiError ) => of( new AlarmActions.PhoneNumbersDeleteFail( error ) ) )
		) )
	);

	@Effect()
	listPhoneNumbers$ = this.actions$.pipe(
		ofType( AlarmActions.PHONE_NUMBERS_LIST ),
		map( ( action: AlarmActions.PhoneNumbersList ) => action.facilityId ),
		switchMap( facilityId => this.alarmsService.phoneNumbersList( facilityId ).pipe(
			map( ( phoneNumbers: AlarmPhoneNumber[] ) => new AlarmActions.PhoneNumbersListSuccess( phoneNumbers ) ),
			catchError( ( error: WebApiError ) => of( new AlarmActions.PhoneNumbersListFail( error ) ) )
		) )
	);

	@Effect()
	newPhoneNumbers$ = this.actions$.pipe(
		ofType( AlarmActions.PHONE_NUMBERS_NEW ),
		map( ( action: AlarmActions.PhoneNumbersNew ) => action.phoneNumber ),
		mergeMap( phoneNumber => this.alarmsService.phoneNumbersNew( phoneNumber ).pipe(			// switchMap unsubscribes when second Observable arrives, that cancels the http request
			map( ( newPhonenumber: AlarmPhoneNumber ) => new AlarmActions.PhoneNumbersNewSuccess( newPhonenumber ) ),
			catchError( ( error: WebApiError ) => of( new AlarmActions.PhoneNumbersNewFail( error ) ) )
		) )
	);

	@Effect()
	updatePhoneNumbers$ = this.actions$.pipe(
		ofType( AlarmActions.PHONE_NUMBERS_UPDATE ),
		map( ( action: AlarmActions.PhoneNumbersUpdate ) => action.phoneNumber ),
		switchMap( update => this.alarmsService.phoneNumbersUpdate( update ).pipe(
			map( ( updatedPhoneNumber: AlarmPhoneNumber ) => new AlarmActions.PhoneNumbersUpdateSuccess( updatedPhoneNumber ) ),
			catchError( ( error: WebApiError ) => of( new AlarmActions.PhoneNumbersUpdateFail( error ) ) )
		) )
	);

	@Effect( { dispatch: false } )
	fail$ = this.actions$.pipe(
		ofType( AlarmActions.GET_FAIL, AlarmActions.UPDATE_FAIL, AlarmActions.PHONE_NUMBERS_DELETE_FAIL,
			AlarmActions.PHONE_NUMBERS_LIST_FAIL, AlarmActions.PHONE_NUMBERS_NEW_FAIL, AlarmActions.PHONE_NUMBERS_UPDATE_FAIL ),
		tap( ( action: AlarmActions.GetFail | AlarmActions.UpdateFail | AlarmActions.PhoneNumbersDeleteFail |
			AlarmActions.PhoneNumbersListFail | AlarmActions.PhoneNumbersNewFail | AlarmActions.PhoneNumbersUpdateFail ) =>
			this.dialogService.webApiError( `alarmsEffects - ${action.type}`, action.error ).subscribe(
				() => this.store$.dispatch( new RoutingActions.Go( { path: ['/login'] } ) )
			)
		)
	);

	constructor(
		private actions$: Actions,
		private store$: Store<fromCartTrac.State>,
		private alarmsService: AlarmsService,
		private dialogService: DialogService
	) { }
}
