<mat-accordion class="headers-align">
	<mat-expansion-panel #adPanel *ngFor="let ad of ads" (opened)="opened(ad)" (closed)="closed(ad)">
		<mat-expansion-panel-header [style.min-height]="imageHeight(ad) + 15 +'px'">
			<mat-panel-title>
				<img [style.height]="imageHeight(ad) + 'px'" class="ad-image" src="data:image/PNG;base64,{{ad.Image}}" />
				<span *ngIf="!adPanel.expanded" [ngSwitch]="ad.Type">
					<mat-icon class="material-icons ad-type">{{iconName(ad.Type)}}</mat-icon>
				</span>
			</mat-panel-title>
			<mat-panel-description>
				<span *ngIf="!adPanel.expanded" [ngSwitch]="ad.Type">
					<span *ngSwitchCase="2">
						<div>
							{{ad.Timer}} (sec)
						</div>
						<div>
							{{courseHole(ad.HoleID)}}
						</div>
					</span>

					<span *ngSwitchCase="3">
						<div>
							{{ad.Timer}} (sec), {{ad.Radius}} (yd)
						</div>
						<div>
							{{ad.Latitude}}, {{ad.Longitude}}
						</div>
					</span>

					<span *ngSwitchDefault>
						{{ad.Timer}} (sec)
					</span>
				</span>

				<span class="fill-remaining-space"></span>

				<button mat-icon-button matTooltip="Save Ad" *ngIf="!published && adPanel.expanded && editFrom.dirty && editFrom.valid"
				 (click)="update($event)">
					<mat-icon class="material-icons">save</mat-icon>
				</button>
				<button mat-icon-button matTooltip="Reset Ad" *ngIf="!published && adPanel.expanded && editFrom.dirty" (click)="reset($event)">
					<mat-icon class="material-icons">clear</mat-icon>
				</button>
				<button mat-icon-button matTooltip="Edit Ad" *ngIf="!published && !adPanel.expanded">
					<mat-icon class="material-icons">edit</mat-icon>
				</button>
				<button mat-icon-button matTooltip="Delete Ad" *ngIf="!published && !adPanel.expanded" (click)="delete($event,ad)">
					<mat-icon class="material-icons">delete_forever</mat-icon>
				</button>
			</mat-panel-description>
		</mat-expansion-panel-header>

		<!-- using ng-tamplate (*) to laizly render the content, otherwise it is very slow -->
		<form *matExpansionPanelContent [formGroup]="editFrom">
			<mat-form-field class="ad-type">
				<mat-select placeholer="Ad Type" formControlName="adType" [value]="ad.Type">
					<mat-select-trigger>
						<mat-icon class="material-icons">{{iconName()}}</mat-icon>
					</mat-select-trigger>
					<mat-option *ngFor="let adType of adTypes" [value]="adType.Type">
						<mat-icon class="material-icons">{{adType.IconName}}</mat-icon>
						{{adType.Name}}
					</mat-option>
				</mat-select>
				<mat-error>You must enter value</mat-error>
			</mat-form-field>

			<mat-form-field class="ad-timer">
				<input matInput required type="number" placeholder="Timer" formControlName="timer" [errorStateMatcher]="formStateErrorMatcher">
				<mat-error>Required</mat-error>
			</mat-form-field>

			<span *ngIf="formAdType === 2">
				<ct-hole-selector [selected]="ad.HoleID" [courses]="courses" [holes]="holes" (selectionChange)="holeChange($event)"></ct-hole-selector>
				<mat-error *ngIf="!validCourse">Course does not match course in the Ad Set</mat-error>
			</span>

			<span *ngIf="formAdType === 3">
				<mat-form-field class="ad-latitude">
					<input matInput required placeholder="Latitude" formControlName="latitude" [errorStateMatcher]="formStateErrorMatcher">
					<mat-error>Required</mat-error>
				</mat-form-field>
				<mat-form-field class="ad-longitude">
					<input matInput required placeholder="Longitude" formControlName="longitude" [errorStateMatcher]="formStateErrorMatcher">
					<mat-error>Required</mat-error>
				</mat-form-field>
				<mat-form-field class="ad-radius">
					<input matInput required type="number" placeholder="Radius" formControlName="radius" [errorStateMatcher]="formStateErrorMatcher">
					<mat-error>Required</mat-error>
				</mat-form-field>
			</span>
		</form>
	</mat-expansion-panel>
</mat-accordion>