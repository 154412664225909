import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DialogService } from '../../../shared/dialogs/dialog.service';
import { WebApiError } from '../../../shared/web-api/web-api-error';
import { TrackersService } from '../trackers.service';
import { TerminalCommand } from '../trackers-terminal-command.model';
import { TerminalResponse } from '../trackers-terminal-response.model';
import { Tracker } from '../trackers-terminal-connection.model';

import * as parseRange from 'src/app/shared/utils/js/parse-range.js';
import { MatRadioChange } from '@angular/material/radio';

@Component({
	selector: 'ct-trackers-power-on-off',
	templateUrl: './power-on-off.component.html',
	styleUrls: [ './power-on-off.component.css' ]
})
export class TrackersPowerOnOffComponent implements OnInit {
	selectionType: string;
	powerOnOff: string;
	customSelection: string;
	responses: string[] = [];

	constructor(
		@Inject(MAT_DIALOG_DATA)
		public data: { selected: Tracker, trackers: Tracker[] },
		public dialogRef: MatDialogRef<TrackersPowerOnOffComponent>,
		private trackersService: TrackersService,
		private dialogService: DialogService
	) { }

	ngOnInit() {
		this.selectionType = 'Selected';
		this.powerOnOff = 'PowerOn';
		this.customSelection = this.data.selected.Terminal.Number;
	}

	clear() {
		this.responses = [];
	}

	selectionTypeChange(event: MatRadioChange) {
		if (event.value === 'Custom')
			this.customSelection = '';
		else
			this.customSelection = this.data.selected.Terminal.Number;
	}

	send() {

		this.responses = [];

		const command = this.powerOnOff === 'PowerOff' ? this.data.selected.Terminal.PowerOffCommand : this.data.selected.Terminal.PowerOnCommand;

		const zeroPad = (n: number, places: number) => String(n).padStart(places, '0');

		const cartIntegers: number[] = parseRange(this.customSelection);

		const cartNumbers = cartIntegers.map(i => zeroPad(i, 3));

		this.sendLoop(cartNumbers, command);
	}

	sendLoop(cartNumbers: string[], command: string) {

		const cartNumber = cartNumbers.shift();

		if (!cartNumber)
			return;

		const connection = this.data.trackers.find(tracker => tracker.Terminal.Number === cartNumber);

		if (!connection) {
			this.responses.push(`Cart# ${cartNumber} - No Connection!`);
			this.sendLoop(cartNumbers, command);
			return;
		}

		const terminalCommand: TerminalCommand = {
			ConnectionIndex: connection.ConnectionIndex,
			Command: command,
			TimeStamp: new Date()
		};

		const line = `Sending [${command}] to Cart# ${this.data.selected.Terminal.Number}`;

		this.responses.push(line);
		this.responses.push('Waiting for response...');

		this.trackersService.command(terminalCommand)
			.subscribe((response: TerminalResponse) => {
				this.responses.pop();
				this.responses.push(response.Response);
				this.sendLoop(cartNumbers, command);
			},
				(error: WebApiError) => this.dialogService.webApiError('PowerOnOffComponent - TrackersService.command()', error)
			);
	}
}
