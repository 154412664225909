import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromCartTrac from '../../cart-trac.reducers';
import * as TrackersActions from './trackers.actions';
import * as RoutingActions from '../../routing/routing.actions';

import { DialogService } from '../../shared/dialogs/dialog.service';
import { WebApiError } from '../../shared/web-api/web-api-error';
import { TrackersService } from './trackers.service';
import { Tracker } from './trackers.model';
import { Facility } from '../../facilities/facilities.model';
import { TerminalResponse } from './trackers-terminal-response.model';
import { TrackersCommandComponent } from './command/command.component';
import { TrackersPowerOnOffComponent } from './power-on-off/power-on-off.component';

@Component( {
	selector: 'ct-device-manager-trackers',
	templateUrl: './trackers.component.html',
	styleUrls: ['./trackers.component.css']
} )
export class TrackersComponent implements OnDestroy, OnInit {
	@Input() facility: Facility;
	@Input() selected: Tracker;
	@Output() refresh = new EventEmitter<boolean>();

	refresh$: Observable<boolean>;
	selected$: Observable<Tracker>;
	trackers$: Observable<Tracker[]>;

	private timerId: number;

	constructor(
		private store$: Store<fromCartTrac.State>,
		private dialog: MatDialog,
		private dialogService: DialogService,
		private trackersService: TrackersService ) {

		this.trackers$ = this.store$.select( fromCartTrac.getTrackers );
		this.refresh$ = this.store$.select( fromCartTrac.getTrackersRefresh );
	}

	ngOnInit() {
		if ( !this.facility ) {
			this.store$.dispatch( new RoutingActions.Go( { path: ['/login/'] } ) );
		}
	}

	onSelect( tracker: Tracker ) {
		this.store$.dispatch( new TrackersActions.Select( tracker ) );
	}

	onToggleVerbose( connectionIndex: number ) {
		this.store$.dispatch( new TrackersActions.Verbose( connectionIndex ) );
	}

	onRefreshChange( refresh: boolean ) {
		this.store$.dispatch( new TrackersActions.RefreshOnOff( refresh ) );
		this.refresh.emit( refresh );
	}

	ngOnDestroy() {
		clearInterval( this.timerId );
	}
}
