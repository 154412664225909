import { environment } from '../environments/environment';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
// import { StoreRouterConnectingModule } from '@ngrx/router-store';
// import { RouterStateSerializer } from '@ngrx/router-store';
// import { CustomRouterStateSerializer } from './shared/utils/ngrx';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { reducers } from './cart-trac.reducers';

import { CartTracAngularModule } from './cart-trac-angular.module';
import { CartTracMaterialModule } from './cart-trac-material.module';

import { AdminModule } from './admin/admin.module';
import { AdManagerModule } from './ad-manager/ad-manager.module';
import { CoursesModule } from './courses/courses.module';
import { DeviceManagerModule } from './device-manager/device-manager.module';
import { FacilitiesModule } from './facilities/facilities.module';
import { FacilitySelectorModule } from './facilities/facility-selector/facility-selector.module';
import { LayoutModule } from './layout/layout.module';
import { LoginModule } from './login/login.module';
import { RangerModule } from './ranger/ranger.module';
import { RoutingModule } from './routing/routing.module';
import { SettingsModule } from './settings/settings.module';
import { SystemModule } from './system/system.module';
import { TraceModule } from './trace/trace.module';
import { TraceParametersModule } from './trace-parameters/trace-parameters.module';
import { TrackingModule } from './tracking/tracking.module';
import { UserManagerModule } from './user-manager/user-manager.module';
import { ZonesModule } from './zones/zones.module';

import { CartTracComponent } from './cart-trac.component';

@NgModule( {
	declarations: [CartTracComponent],
	imports: [
		// import only once framework modules
		BrowserAnimationsModule,
		BrowserModule,

		// Framework modules
		CartTracAngularModule,
		CartTracMaterialModule,

		// CartTrac modules
		AdminModule,
		AdManagerModule,
		CoursesModule,
		DeviceManagerModule,
		FacilitiesModule,
		FacilitySelectorModule,

		LayoutModule,
		LoginModule,
		RangerModule,
		RoutingModule,
		SettingsModule,
		SystemModule,
		TraceModule,
		TraceParametersModule,
		TrackingModule,
		UserManagerModule,
		ZonesModule,

		// @ngrx modules
		StoreModule.forRoot( reducers, {
			runtimeChecks: {
				strictStateImmutability: false,
				strictActionImmutability: false,
				strictStateSerializability: false,
				strictActionSerializability: false,
				// strictActionWithinNgZone: true,
				// strictActionTypeUniqueness: true
			},
		} ),
		EffectsModule.forRoot( [] )
		// !environment.production ? StoreDevtoolsModule.instrument( { maxAge: 50 } ) : []
		// StoreRouterConnectingModule  ==> there is a performance problem when connection the router to the store
	],
	providers: [
		// { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer }
	],
	bootstrap: [CartTracComponent]
} )
export class CartTracModule { }


