export class Ad {
	constructor(
		public AdID: number,
		public AdImageID: number,
		public AdListID: number,
		public PanelFileName: string,
		public Type: number,
		public Timer: number | null,
		public HoleID: number | null,
		public Latitude: number | null,
		public Longitude: number | null,
		public Radius: number | null,
		public Position: number | null,
		public Image: string,
		public ImageType: number,
	) { }
}

export class AdType {
	constructor(
		public Type: number,
		public Name: string,
		public IconName: string
	) { }
}

export enum AdTypeType { Rotation = 1, CourseHole = 2, GPSLocation = 3, Clock = 4 }

export const AdTypes: AdType[] = [
	{ Type: AdTypeType.Rotation, Name: 'Rotation', IconName: 'cached' },
	{ Type: AdTypeType.CourseHole, Name: 'Course/Hole', IconName: 'golf_course' },
	{ Type: AdTypeType.GPSLocation, Name: 'GPS Location', IconName: 'location_on' },
	{ Type: AdTypeType.Clock, Name: 'Clock', IconName: 'schedule' }
];
