<mat-toolbar color="secondary">Send Power On / Power Off Command</mat-toolbar>
<mat-dialog-content>
	<div class=power-on-off>
		<mat-radio-group required [(ngModel)]="powerOnOff">
			<mat-radio-button value="PowerOn">Send Power On</mat-radio-button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
			<mat-radio-button value="PowerOff">Send Power Off</mat-radio-button>
		</mat-radio-group>
	</div>

	<mat-divider></mat-divider>

	<div class="selection-type">
		<mat-radio-group required [(ngModel)]="selectionType" (change)=selectionTypeChange($event)>
			<mat-radio-button value="Selected">Send to Selected Device</mat-radio-button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
			<mat-radio-button value="Custom">Send to Custom Selection</mat-radio-button>
		</mat-radio-group>
	</div>

	<form (ngSubmit)="send()" #selectionForm="ngForm">
		<mat-form-field>
			<input matInput autocomplete="off" placeholder="Cart Selection" type="text" required mdMaxLength="50"
				[(ngModel)]="customSelection" name="command" [readonly]="selectionType==='Selected'" />
		</mat-form-field>
		&nbsp;&nbsp;&nbsp;
		<button mat-raised-button type="submit" [disabled]="!selectionForm.form.valid">Send</button>
	</form>

	<span [ngSwitch]="selectionType==='Custom'">
		<div *ngSwitchCase="true" class="custom-selection-prompt">(Custom Selection - e.g., 1 | 2,3 | 4-7)</div>
		<div *ngSwitchCase="false" class="custom-selection-prompt">&nbsp;</div>
	</span>

	<mat-list>
		<mat-list-item *ngFor="let response of responses">
			{{response}}
		</mat-list-item>
	</mat-list>
</mat-dialog-content>

<mat-dialog-actions>
	<button mat-raised-button (click)="clear()" [disabled]="responses.length===0">Clear</button>
	<button mat-raised-button (click)="dialogRef.close()">Close</button>
</mat-dialog-actions>