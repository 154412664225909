import { Component, Input, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromCartTrac from '../../cart-trac.reducers';
import * as AdminActions from '../../admin/admin.actions';
import * as ImagesActions from './images.actions';
import * as RoutingActions from '../../routing/routing.actions';

import { AdImage, AdImageUploadParameters as AdImageUploadParameters, AdImageListParameters, AdImageType, AdImageTypes, AdImageRenameParameters } from './images.model';
import { Facility } from '../../facilities/facilities.model';
import { Model } from '../../device-manager/devices/devices.model';

@Component( {
	selector: 'ct-ad-manager-images',
	templateUrl: './images.component.html',
	styleUrls: ['./images.component.css']
} )
export class ImagesComponent implements OnInit {
	@Input() facility: Facility;
	@Input() selected: AdImage;
	@Input() selectedUnitModel: Model;
	@Input() selectedAdImageType: AdImageType;

	adImages$: Observable<AdImage[]>;

	constructor( private store$: Store<fromCartTrac.State> ) {
		this.adImages$ = this.store$.select( fromCartTrac.getAdManagerImages );
	}

	ngOnInit() {
		if ( !this.facility ) {
			this.store$.dispatch( new RoutingActions.Go( { path: ['/login/'] } ) );
		}
	}

	copy() {
		this.store$.dispatch( new ImagesActions.Copy( this.selected.AdImageID ) );
	}

	delete() {
		this.store$.dispatch( new ImagesActions.Delete( this.selected.AdImageID ) );
	}

	download() {
		this.store$.dispatch( new ImagesActions.Download( this.facility.Id ) );
	}

	upload( uploadParameters: AdImageUploadParameters ) {
		this.store$.dispatch( new ImagesActions.Upload( uploadParameters ) );
	}

	purge() {
		this.store$.dispatch( new ImagesActions.Purge( this.facility.Id ) );
	}

	new( adImages: AdImage[] ) {
		if ( adImages ) {
			adImages.forEach( image => image.FacilityID = this.facility.Id );

			this.store$.dispatch( new ImagesActions.NewBatch( adImages ) );		// Use batch action so the progress will be visible
		}
	}

	refresh( unitModel: Model, imageType: AdImageType ) {
		const imageTypeType = imageType ? imageType.Type : AdImageTypes[0].Type;

		this.store$.dispatch( new ImagesActions.List( new AdImageListParameters( this.facility.Id, 1, imageTypeType ) ) );
	}

	rename( renameParameters: AdImageRenameParameters ) {
		this.store$.dispatch( new ImagesActions.Rename( renameParameters ) );
	}

	select( image: AdImage ) {
		this.store$.dispatch( new ImagesActions.Select( image ) );
	}

	selectImageType( adImageType: AdImageType ) {
		this.store$.dispatch( new AdminActions.SelectAdImageType( adImageType ) );
		this.refresh( this.selectedUnitModel, adImageType );
	}

	sort( sortOrder: number ) {
		this.store$.dispatch( new ImagesActions.Sort( sortOrder ) );
	}
}
