import { Component, Input, OnChanges } from '@angular/core';

import { BingMapService } from '../bing-map.service';
import { TracePoint } from '../../../trace/trace-point.model';

@Component( {
	selector: 'ct-bing-map-trace-points',
	template: '<ng-content></ng-content>'
} )
export class BingMapTracePointsComponent implements OnChanges
{
	@Input() tracePoints: TracePoint[];

	constructor( private bingMapService: BingMapService ) { }

	ngOnChanges()
	{
		this.bingMapService.displayTracePoints( this.tracePoints );
	}
}
