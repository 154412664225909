import { Action } from '@ngrx/store';

export const OPEN_SIDENAV = '[Layout] Open Sidenav';
export const CLOSE_SIDENAV = '[Layout] Close Sidenav';
export const FEATURE_NAME = '[Layout] Feature Name';

export class OpenSidenav implements Action {
	readonly type = OPEN_SIDENAV;
}

export class CloseSidenav implements Action {
	readonly type = CLOSE_SIDENAV;
}

export class FeatureName implements Action {
	readonly type = FEATURE_NAME;

	constructor( public payload: string ) { }
}

export type LayoutAction
	= OpenSidenav
	| CloseSidenav
	| FeatureName;
