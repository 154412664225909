import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component( {
	selector: 'ct-trackers-actions-right',
	templateUrl: './actions-right.component.html',
	styleUrls: ['./actions-right.component.css']
} )
export class TrackersActionsRightComponent implements OnInit {

	@Input() refresh: boolean;
	@Output() refreshChange = new EventEmitter<boolean>();

	constructor() { }

	ngOnInit() {
	}

	changeRefresh( change: MatSlideToggleChange ) {
		this.refreshChange.emit( change.checked );
	}
}
