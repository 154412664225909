export class AdVersion {
	constructor(
		public AdVersionID: number,
		public UnitModelID: number,
		public Number: string,
		public Name: string,
		public Active: boolean,
		public FacilityID: number,
		public Published: boolean
	) { }
}

export class AdVersionCopyParameters {
	constructor(
		public AdVersionID: number,
		public Name: string
	) { }
}

export class AdVersionListParameters {
	constructor(
		public FacilityID: number,
		public UnitModelID: number,
		public Published: boolean
	) { }
}

export class AdVersionPublishParameters {
	constructor(
		public AdVersionID: number,
		public Activate: boolean,
		public Overwrite: boolean,
		public UnitModelID: number
	) { }
}

export class AdVersionRenameParameters {
	constructor(
		public AdVersionID: number,
		public Name: string
	) { }
}
