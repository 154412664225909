import { Injectable } from '@angular/core';
import { Alarm, AlarmPhoneNumber } from './alarms.model';
import { WebApiService } from '../../shared/web-api/web-api.service';
import { WebApiError } from '../../shared/web-api/web-api-error';
import { Observable } from 'rxjs';

@Injectable()
export class AlarmsService {

	private serviceBaseUrl: string;

	constructor( private webApiService: WebApiService ) {
		this.serviceBaseUrl = this.webApiService.serverUrl + '/api/facilities/alarms';
	}

	get( facilityId: number ): Observable<Alarm | WebApiError> {
		return this.webApiService.get<Alarm>( this.serviceBaseUrl + '/' + facilityId );
	}

	update( alarm: Alarm ): Observable<Alarm | WebApiError> {
		return this.webApiService.put( this.serviceBaseUrl, alarm );
	}

	phoneNumbersDelete( id: number ): Observable<void | WebApiError> {
		return this.webApiService.delete( this.serviceBaseUrl + '/phonenumbers/' + id );
	}

	phoneNumbersList( facilityId: number ): Observable<AlarmPhoneNumber[] | WebApiError> {
		return this.webApiService.get<AlarmPhoneNumber[]>( this.serviceBaseUrl + '/phonenumbers/' + facilityId );
	}

	phoneNumbersNew( phoneNumber: AlarmPhoneNumber ): Observable<AlarmPhoneNumber | WebApiError> {
		return this.webApiService.post<AlarmPhoneNumber>( this.serviceBaseUrl + '/phonenumbers', phoneNumber );
	}

	phoneNumbersUpdate( phoneNumber: AlarmPhoneNumber ): Observable<AlarmPhoneNumber | WebApiError> {
		return this.webApiService.put<AlarmPhoneNumber>( this.serviceBaseUrl + '/phonenumbers', phoneNumber );
	}
}
