import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { DialogService } from '../../../shared/dialogs/dialog.service';
import { Facility } from '../../../facilities/facilities.model';
import { AdImage, AdImageType } from '../images.model';
import { NewComponent } from '../new/new.component';
import { Model } from '../../../device-manager/devices/devices.model';

@Component( {
	selector: 'ct-ads-images-actions',
	templateUrl: './actions.component.html',
	styleUrls: ['./actions.component.css']
} )
export class ActionsComponent {
	@Input() images: AdImage[];
	@Input() facility: Facility;
	@Input() selected: AdImage;
	@Input() selectedImageType: AdImageType;
	@Input() selectedUnitModel: Model;

	@Output( 'copy' ) copyImage = new EventEmitter();
	@Output( 'delete' ) deleteImage = new EventEmitter();
	@Output( 'new' ) newImages = new EventEmitter<AdImage[]>();
	@Output( 'sort' ) sortImages = new EventEmitter<number>();

	private dialogRef: MatDialogRef<NewComponent>;

	constructor(
		private dialog: MatDialog,
		private dialogService: DialogService ) { }

	copy() {
		this.dialogService.confirm( 'Copy Image', 'Are You Sure?' )
			.subscribe( positive => positive ? this.copyImage.emit() : null );
	}

	delete() {
		this.dialogService.confirm( 'Delete Image', 'Are You Sure?' )
			.subscribe( positive => positive ? this.deleteImage.emit() : null );
	}

	new() {
		this.dialogRef = this.dialog.open( NewComponent, { data: { facilityId: this.facility.Id, unitModel: this.selectedUnitModel, imageType: this.selectedImageType } } );

		this.dialogRef.afterClosed()
			.subscribe( ( images: AdImage[] ) => this.newImages.emit( images ) );
	}

	sort( sortOrder: number ) {
		this.sortImages.emit( sortOrder );
	}
}
