<div>
	<table>
		<thead>
			<tr>
				<th>Number</th>
				<th>Device ID</th>
				<th>Model</th>
				<th>Mode</th>
				<th>Last Update</th>
				<th *ngIf="expanded">Firmware Version</th>
				<th *ngIf="expanded">Config Version</th>
				<th *ngIf="expanded">Ads Version</th>
				<th *ngIf="expanded">Zones Version</th>
				<th *ngIf="expanded">Session Start</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let device of devices; let i = index" [ngClass]="device.CSSClass" (click)="onSelect(device)">
				<td>{{device.Number}}</td>
				<td>{{device.DeviceId}}</td>
				<td>{{device.ModelName}}</td>
				<td>{{device.ModeName}}</td>
				<td>{{device.LastGPSTime | date : 'yyyy-MM-dd HH:mm'}}</td>
				<td *ngIf="expanded">{{device.FirmwareVersion}}</td>
				<td *ngIf="expanded">{{device.ConfigVersion}}</td>
				<td *ngIf="expanded">{{device.AdsVersion}}</td>
				<td *ngIf="expanded">{{device.ZonesVersion}}</td>
				<td *ngIf="expanded">{{device.SessionStart | date : 'yyyy-MM-dd HH:mm'}}</td>
			</tr>
		</tbody>
	</table>
</div>