import { Component, OnInit } from '@angular/core';

@Component( {
	selector: 'ct-courses-holes-actions',
	templateUrl: './courses-holes-actions.component.html',
	styleUrls: ['./courses-holes-actions.component.css']
} )
export class CoursesHolesActionsComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}

	save() {

	}
}
