import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component( {
	selector: 'ct-confirm-dialog',
	templateUrl: './dialog-confirm.component.html',
	styleUrls: ['./dialog-confirm.component.css']
} )
export class ConfirmDialogComponent
{
	public title: string;
	public message: string;

	constructor( public dialogRef: MatDialogRef<ConfirmDialogComponent> )
	{
	}
}
