import { Component, Input, OnInit } from '@angular/core';

import { Hole } from '../courses.model';

@Component( {
	selector: 'ct-courses-holes-list',
	templateUrl: './courses-holes-list.component.html',
	styleUrls: ['./courses-holes-list.component.css']
} )
export class CoursesHolesListComponent implements OnInit {

	@Input() holes: Hole[];

	constructor() { }

	ngOnInit() {
	}

	select( hole: Hole ) {

	}
}
