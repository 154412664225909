import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';

import * as fromCartTrac from '../../cart-trac.reducers';
import * as AdsActions from './ads.actions';

import { Ad, AdType, AdTypes, AdTypeType } from './ads.model';
import { AdImage, AdImageTypes } from '../images/images.model';
import { Course, Hole } from '../../courses/courses.model';
import { FormErrorStateMatcher } from '../../shared/utils/form-error-state-matcher';

@Component( {
	selector: 'ct-ad-manager-ads',
	templateUrl: './ads.component.html',
	styleUrls: ['./ads.component.css']
} )
export class AdsComponent implements OnInit {

	@Input() ads: Ad[];
	@Input() courseNumber: string;
	@Input() courses: Course[];
	@Input() holes: Hole[];
	@Input() published: boolean;
	@Input() selected: Ad;

	adImages: AdImage[];
	editFrom: FormGroup;
	adTypes: AdType[] = AdTypes;
	formStateErrorMatcher = new FormErrorStateMatcher();
	validCourse = true;

	get formAdType(): number { return this.editFrom.get( 'adType' ).value; }

	private holeId: number;

	constructor(
		private store$: Store<fromCartTrac.State>,
		private formBuilder: FormBuilder
	) { }

	ngOnInit() {
		this.editFrom = this.formBuilder.group( {
			adType: [null],
			timer: [null, Validators.required],
			latitude: [null, Validators.required],
			longitude: [null, Validators.required],
			radius: [null, Validators.required]
		} );
	}

	adFormValues( ad: Ad = this.selected ) {
		return {
			adType: ad.Type,
			timer: ad.Timer,
			latitude: ad.Latitude ? ad.Latitude : 44.44,
			longitude: ad.Longitude ? ad.Longitude : -96.96,
			radius: ad.Radius ? ad.Radius : 12
		};
	}

	closed( ad: Ad ) {
		this.reset();
	}

	courseHole( holeId: number ): string {
		const hole = this.holes.find( h => h.Id === holeId );
		const course = this.courses.find( c => c.Id === hole.CourseId );

		return course.Name + ' #' + hole.Number;
	}

	delete( event: Event, ad: Ad ) {
		event.stopPropagation();
		this.store$.dispatch( new AdsActions.Select( ad ) );
		this.store$.dispatch( new AdsActions.Delete( ad.AdID ) );
	}

	holeChange( holeId: number ) {
		this.holeId = holeId;
		this.editFrom.markAsDirty();
		this.validateCourse( holeId );
	}

	iconName( type: number = this.formAdType ): string {
		return AdTypes.find( adType => adType.Type === type ).IconName;
	}

	imageHeight( ad: Ad ): number {
		return AdImageTypes.find( adImageType => adImageType.Type === ad.ImageType ).VersionsThumbnailHeight;
	}

	opened( ad: Ad ) {
		this.store$.dispatch( new AdsActions.Select( ad ) );
		this.editFrom.setValue( this.adFormValues( ad ) );

		if ( ad.Type === AdTypeType.CourseHole ) {
			this.validateCourse( ad.HoleID );
		}
	}

	reset( event: Event = null ) {
		if ( event ) {
			event.stopPropagation();
		}

		this.editFrom.reset( this.adFormValues() );
	}

	update( event: Event ) {

		this.selected.Type = this.formAdType;
		this.selected.Timer = this.editFrom.get( 'timer' ).value;

		switch ( this.formAdType ) {
			case AdTypeType.Rotation:
				this.selected.HoleID = null;
				this.selected.Latitude = null;
				this.selected.Longitude = null;
				this.selected.Radius = null;
				break;
			case AdTypeType.CourseHole:
				this.selected.HoleID = this.holeId;
				this.selected.Latitude = null;
				this.selected.Longitude = null;
				this.selected.Radius = null;
				break;
			case AdTypeType.GPSLocation:
				this.selected.HoleID = null;
				this.selected.Latitude = this.editFrom.get( 'latitude' ).value;
				this.selected.Longitude = this.editFrom.get( 'longitude' ).value;
				this.selected.Radius = this.editFrom.get( 'radius' ).value;
				break;
			case AdTypeType.Clock:
				this.selected.HoleID = null;
				this.selected.Latitude = null;
				this.selected.Longitude = null;
				this.selected.Radius = null;
				break;
		}

		this.store$.dispatch( new AdsActions.Update( this.selected ) );
	}

	validateCourse( holeId: number ) {
		this.validCourse = this.courseNumber === '0' ||
			this.courseNumber === this.courses.find( course => course.Id === this.holes.find( hole => hole.Id === holeId ).CourseId ).Number;
	}
}
