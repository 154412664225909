import { NgModule } from '@angular/core';
import { CartTracAngularModule } from '../../cart-trac-angular.module';
import { CartTracMaterialModule } from '../../cart-trac-material.module';
import { DialogModule } from '../../shared/dialogs/dialog.module';
import { AdImageTypePickerModule } from '../../shared/pickers/ad-image-type-picker/ad-image-type-picker.module';
import { ModelPickerModule } from '../../shared/pickers/model-picker/model-picker.module';
import { SetsModule } from '../sets/sets.module';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { VersionsEffects } from './versions.effects';
import { reducer } from './versions.reducer';

import { ActionsComponent } from './actions/actions.component';
import { VersionsComponent } from './versions.component';

import { VersionsService } from './versions.service';
import { WebApiService } from '../../shared/web-api/web-api.service';
import { NewComponent } from './new/new.component';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule( {
	declarations: [
		ActionsComponent,
		VersionsComponent,
		NewComponent
	],
	imports: [
		CartTracAngularModule,
		CartTracMaterialModule,
		DialogModule,
		DragDropModule,
		AdImageTypePickerModule,
		ModelPickerModule,
		SetsModule,

		StoreModule.forFeature( 'adManagerVersions', reducer ),
		EffectsModule.forFeature( [VersionsEffects] )
	],
	exports: [VersionsComponent],
	providers: [
		VersionsService,
		WebApiService
	]
} )
export class VersionsModule { }
