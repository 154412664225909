import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import * as fromCartTrac from '../cart-trac.reducers';
import * as TrackingActions from './tracking.actions';
import * as RoutingActions from '../routing/routing.actions';

import { TrackingService } from './tracking.service';
import { TrackingDevice } from './tracking-device.model';
import { WebApiError } from '../shared/web-api/web-api-error';
import { DialogService } from '../shared/dialogs/dialog.service';

@Injectable()
export class TrackingEffects {
	@Effect()
	load$ = this.actions$.pipe(
		ofType( TrackingActions.LOAD ),
		map( ( action: TrackingActions.Load ) => action.payload ),
		switchMap( trackingParameters => this.trackingService.list( trackingParameters.Id, trackingParameters.LoginId ).pipe(
			map( ( trackingDevices: TrackingDevice[] ) => new TrackingActions.LoadSuccess( trackingDevices ) ),
			catchError( ( error: WebApiError ) => of( new TrackingActions.LoadFail( error ) ) )
		) )
	);

	@Effect( { dispatch: false } )
	fail$ = this.actions$.pipe(
		ofType( TrackingActions.LOAD_FAIL ),
		tap( ( action: TrackingActions.LoadFail ) =>
			this.dialogService.webApiError( `TrackingEffects - ${action.type}`, action.payload ).subscribe(
				() => this.store$.dispatch( new RoutingActions.Go( { path: ['/login'] } ) )
			)
		)
	);

	constructor(
		private actions$: Actions,
		private store$: Store<fromCartTrac.State>,
		private trackingService: TrackingService,
		private dialogService: DialogService
	) { }
}
