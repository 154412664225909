import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { WebApiService } from '../shared/web-api/web-api.service';
import { WebApiError } from '../shared/web-api/web-api-error';
import { Facility, FacilitySettingsDTO } from './facilities.model';
import { Model } from '../device-manager/devices/devices.model';

@Injectable()
export class FacilitiesService {
	private serviceBaseUrl: string;

	constructor( private webApiService: WebApiService ) {
		this.serviceBaseUrl = this.webApiService.serverUrl + '/api/facilities';
	}

	list( loginId: number ): Observable<Facility[] | WebApiError> {
		return this.webApiService.get<Facility[]>( this.serviceBaseUrl + '/' + loginId );
	}

	models( facilityId: number ): Observable<Model[] | WebApiError> {
		return this.webApiService.get<Model[]>( this.serviceBaseUrl + '/models/' + facilityId );
	}

	settingsSave( settings: FacilitySettingsDTO ): Observable<FacilitySettingsDTO | WebApiError> {
		return this.webApiService.put( this.serviceBaseUrl + '/settings', settings );
	}
}
