<mat-card class="center">
	<mat-card-title>"To" Phone Numbers&nbsp;&nbsp;&nbsp;
		<button mat-fab class=fab-add [disabled]="addDisabled" (click)=onAdd()>
			<mat-icon>add</mat-icon>
		</button>
	</mat-card-title>
	<mat-card-content>
		<mat-list>
			<mat-list-item *ngFor="let phoneNumber of phoneNumbers; index as i">
				<mat-form-field>
					<input matInput type="text" [(ngModel)]="phoneNumber.ToPhoneNumber" [ngModelOptions]="{updateOn: 'blur'}" pattern="^\+\d{11,14}$"
					 (ngModelChange)=onChange(i,$event)>
				</mat-form-field>
				&nbsp;&nbsp;&nbsp;
				<button mat-mini-fab (click)="onDelete(phoneNumber.Id)">
					<mat-icon>delete_forever</mat-icon>
				</button>
				&nbsp;&nbsp;&nbsp;
				<span class="phone-number-error" *ngIf="invalidFlags[i]">Invalid, use format: +12223334444</span>
			</mat-list-item>
		</mat-list>
	</mat-card-content>
</mat-card>