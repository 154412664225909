import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AdVersion, AdVersionRenameParameters, AdVersionListParameters, AdVersionCopyParameters, AdVersionPublishParameters } from './versions.model';
import { WebApiService } from '../../shared/web-api/web-api.service';
import { WebApiError } from '../../shared/web-api/web-api-error';

@Injectable()
export class VersionsService {

	private serviceBaseUrl: string;

	constructor( private webApiService: WebApiService ) {
		this.serviceBaseUrl = this.webApiService.serverUrl + '/api/ads/versions';
	}

	activate( id: number ): Observable<void | WebApiError> {
		return this.webApiService.put( this.serviceBaseUrl + '/' + id, null );
	}

	copy( copyParameters: AdVersionCopyParameters ): Observable<AdVersion | WebApiError> {
		return this.webApiService.post( this.serviceBaseUrl + '/copy/', copyParameters );
	}

	delete( id: number ): Observable<void | WebApiError> {
		return this.webApiService.delete( this.serviceBaseUrl + '/' + id );
	}

	list( listParameters: AdVersionListParameters ): Observable<AdVersion[] | WebApiError> {
		return this.webApiService.get<AdVersion[]>( this.serviceBaseUrl + '/' + listParameters.FacilityID + '/' + listParameters.UnitModelID + '/' + listParameters.Published );
	}

	new( adVersion: AdVersion ): Observable<AdVersion | WebApiError> {
		return this.webApiService.post( this.serviceBaseUrl, adVersion );
	}

	publish( publishParameters: AdVersionPublishParameters ): Observable<AdVersionPublishParameters | WebApiError> {
		return this.webApiService.put( this.serviceBaseUrl + '/publish/', publishParameters );
	}

	rename( renameParameters: AdVersionRenameParameters ): Observable<AdVersionRenameParameters | WebApiError> {
		return this.webApiService.put( this.serviceBaseUrl + '/rename/', renameParameters );
	}
}

