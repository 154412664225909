import { Component, OnDestroy, OnInit } from '@angular/core';

import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';


import { Store } from '@ngrx/store';
import * as fromCartTrac from '../cart-trac.reducers';
import * as TrackersActions from './trackers/trackers.actions';
import * as LayoutActions from '../layout/layout.actions';
import * as RoutingActions from '../routing/routing.actions';

import { Tracker } from './trackers/trackers.model';
import { Facility } from '../facilities/facilities.model';

export enum DevicesTabs { General, Trackers, Other }

@Component( {
	selector: 'ct-device-manager',
	templateUrl: './device-manager.component.html',
	styleUrls: ['./device-manager.component.css']
} )
export class DeviceManagerComponent implements OnDestroy, OnInit {

	facility$: Observable<Facility>;
	selectedTracker$: Observable<Tracker>;

	private facilityId: number;
	private facilitySubscription: Subscription;
	private previousTabIndex: number;
	private trackersTimerId: number;

	constructor( private store$: Store<fromCartTrac.State> ) {
		this.facility$ = this.store$.select( fromCartTrac.getFacility );
		this.selectedTracker$ = this.store$.select( fromCartTrac.getSelectedTracker );
	}

	ngOnInit() {
		this.facilitySubscription = this.facility$.pipe( take( 1 ) )
			.subscribe( facility => {
				if ( facility ) {
					this.facilityId = facility.Id;
					this.store$.dispatch( new LayoutActions.FeatureName( 'DEVICES' ) );
				} else {
					this.store$.dispatch( new RoutingActions.Go( { path: ['/login/'] } ) );
				}
			} );

		this.previousTabIndex = DevicesTabs.General;
	}

	tabChanged( tabIndex: number ) {

		switch ( this.previousTabIndex ) {
			case DevicesTabs.General:
			case DevicesTabs.Other:
				break;
			case DevicesTabs.Trackers:
				this.trackersRefresh( false );
				break;
		}

		switch ( tabIndex ) {
			case DevicesTabs.General:
				this.previousTabIndex = DevicesTabs.General;
				break;
			case DevicesTabs.Other:
				this.previousTabIndex = DevicesTabs.Other;
				break;
			case DevicesTabs.Trackers:
				this.store$.dispatch( new TrackersActions.RefreshOnOff( true ) );
				this.trackersRefresh( true );
				this.previousTabIndex = DevicesTabs.Trackers;
				break;
		}
	}

	trackersRefresh( refresh: boolean ) {

		if ( refresh ) {
			this.store$.dispatch( new TrackersActions.List( this.facilityId ) );
			this.trackersTimerId = window.setInterval( () => this.store$.dispatch( new TrackersActions.List( this.facilityId ) ), 15000 );
		} else {
			clearInterval( this.trackersTimerId );
		}
	}

	ngOnDestroy() {
		this.facilitySubscription.unsubscribe();
		this.trackersRefresh( false );
	}
}
