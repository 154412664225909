<div (focus)="onEditorFocus()" class="angular-editor" id="angularEditor" [style.width]="config.width"
	[style.minWidth]="config.minWidth">
	<angular-editor-toolbar #editorToolbar (execute)="executeCommand($event)"></angular-editor-toolbar>

	<div class="angular-editor-wrapper" #editorWrapper [style.height]="(config.imageHeight + 1) + 'px'"
		[style.width]="(config.imageWidth + 1) + 'px'">
		<div #editor id="editor" class="angular-editor-textarea" [attr.contenteditable]="config.editable"
			[attr.translate]="config.translate" [attr.spellcheck]="config.spellcheck"
			[style.height]="config.imageHeight +'px'" [style.width]="config.imageWidth + 'px'"
			[style.background-color]="config.imagefillColor" (input)="onContentChange($event.target)"
			(focus)="onTextAreaFocus()" (blur)="onTextAreaBlur()" (click)="exec()" (keyup)="exec()">
		</div>
		<span class="angular-editor-placeholder">{{ placeholder || config['placeholder'] }}</span>
	</div>
</div>