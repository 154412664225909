import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { TrackingComponent } from './tracking.component';

/*
	The lazy module should have his own routing module.
	The routes array defined in the "lazy-routing.module" should have a single element;
	the path property of that element should be an empty string;
	the component property should be defined (necessary when the lazy module provides
	any service in order to injection works well) and the template of the referenced
	component should have an element with the <router-outlet> directive.
	This route usually has a children property.
*/

export const routes: Routes = [
	{
		path: '', component: TrackingComponent /*,
		children: [
			{ path: '', component: SystemComponent },
			{ path: 'status', component: StatusComponent },
			{ path: 'twilio', component: TwilioComponent },
		] */
	}
];

@NgModule( {
	imports: [RouterModule.forChild( routes )],
	exports: [RouterModule]
} )
export class TrackingRoutingModule { }
