import { Component, OnInit, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromCartTrac from '../../../../cart-trac.reducers';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Facility } from '../../../../facilities/facilities.model';
import { Device, Model, Mode } from '../../devices.model';
import * as DevicesActions from '../../devices.actions';
import * as XLSX from 'xlsx';
import { DialogService } from '../../../../shared/dialogs/dialog.service';

@Component( {
	selector: 'ct-devices-actions-bulk-upload',
	templateUrl: './upload.component.html',
	styleUrls: ['./upload.component.css']
} )
export class UploadComponent implements OnInit {

	file: File;
	done = false;
	line1: string;
	line2: string;
	line3: string;

	constructor(
		private store$: Store<fromCartTrac.State>,
		private dialogService: DialogService,
		public dialogRef: MatDialogRef<UploadComponent>,
		@Inject( MAT_DIALOG_DATA ) public data: { facility: Facility, models: Model[], modes: Mode[] }
	) { }

	ngOnInit() {
	}

	upload() {
		this.line1 = 'Reading file...';
		const reader = new FileReader();

		reader.onload = ( event: any ) => {
			const buffer: string = event.target.result;
			const workbook: XLSX.WorkBook = XLSX.read( buffer, { type: 'binary' } );

			const worksheetName: string = workbook.SheetNames[0];
			const worksheet: XLSX.WorkSheet = workbook.Sheets[worksheetName];

			const xlsxDevices: string[][] = XLSX.utils.sheet_to_json( worksheet, { header: 1 } );

			const message = this.validateXLSX( xlsxDevices );

			if ( message ) {
				this.dialogRef.close();
				this.dialogService.alert( 'Upload Devices', message );
				return;
			}

			this.line2 = `Creating ${xlsxDevices.length - 1} devices...`;
			xlsxDevices[0][3] = 'ModelId';

			for ( let i = 1; i < xlsxDevices.length; i++ ) {
				xlsxDevices[i][3] = ( this.data.models.find( model => xlsxDevices[i][3] === model.Name ) ).Id.toString();
			}

			const devices = this.convertToJSON( xlsxDevices );

			devices.forEach( device => {
				device.FacilityId = this.data.facility.Id;
				this.store$.dispatch( new DevicesActions.New( device ) );
			} );

			this.done = true;
			this.line3 = 'DONE.';
		};

		reader.readAsBinaryString( this.file );
	}

	onFileSelectChange( event: any ) {
		this.file = ( event.target as HTMLInputElement ).files[0];
	}

	convertToJSON( xlsxArray: string[][] ): Device[] {
		const objectArray = [];

		for ( let i = 1; i < xlsxArray.length; i++ ) {
			objectArray[i - 1] = {};

			for ( let k = 0; k < xlsxArray[0].length && k < xlsxArray[i].length; k++ ) {
				const key = xlsxArray[0][k];

				objectArray[i - 1][key] = xlsxArray[i][k];
			}
		}

		return objectArray;
	}

	validateXLSX( xlsxDevices: string[][] ): string {

		let message: string;

		if ( xlsxDevices[0][0] !== 'Number' || xlsxDevices[0][1] !== 'DeviceId' || xlsxDevices[0][2] !== 'ModeId' || xlsxDevices[0][3] !== 'Model' || xlsxDevices[0][4] !== 'Name' ) {
			message = 'Invalid File Header';
		}

		let validModels = true;
		const xlsxModels = xlsxDevices.map( xlsxDevice => xlsxDevice[3] ).slice( 1 );

		xlsxModels.forEach( xlsxModel => {
			if ( validModels && !this.data.models.find( model => model.Name === xlsxModel ) ) {
				message = `Invalid Device Model: [${xlsxModel}]`;
				validModels = false;
			}
		} );

		let validModeIds = true;
		const xlsxModeIds = xlsxDevices.map( xlsxDevice => xlsxDevice[2] ).slice( 1 );

		xlsxModeIds.forEach( xlsxModeId => {
			if ( validModeIds && !this.data.modes.find( mode => mode.Id === xlsxModeId ) ) {
				message = `Invalid Device Mode: [${xlsxModeId}]`;
				validModeIds = false;
			}
		} );

		return message;
	}
}
