import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FormErrorStateMatcher } from '../../../shared/utils/form-error-state-matcher';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Model } from '../../../device-manager/devices/devices.model';
import { AdVersion } from '../versions.model';
import { Store } from '@ngrx/store';
import * as fromCartTrac from '../../../cart-trac.reducers';

@Component( {
	selector: 'ct-new-ad-version',
	templateUrl: './new.component.html',
	styleUrls: ['./new.component.css']
} )
export class NewComponent implements OnInit, OnDestroy {

	errorStateMatcher = new FormErrorStateMatcher();
	newForm: FormGroup;

	constructor(
		@Inject( MAT_DIALOG_DATA ) public data: { facilityId: number, unitModel: Model }, private store$: Store<fromCartTrac.State>,
		public dialogRef: MatDialogRef<NewComponent>,
		private formBuilder: FormBuilder ) {
	}

	ngOnInit() {
		this.newForm = this.formBuilder.group( {
			name: ['', Validators.required]
		} );
	}

	ngOnDestroy() {
	}

	new() {
		this.dialogRef.close(
			new AdVersion( 0, this.data.unitModel.Id, null, this.newForm.get( 'name' ).value, false, this.data.facilityId, false ) );
	}
}
