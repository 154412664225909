import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { DialogService } from 'src/app/shared/dialogs/dialog.service';
import { WebApiError } from 'src/app/shared/web-api/web-api-error';
import * as fromCartTrac from '../../../cart-trac.reducers';
import { TrackersCommandComponent } from '../command/command.component';
import { TrackersPowerOnOffComponent } from '../power-on-off/power-on-off.component';
import { TerminalResponse } from '../trackers-terminal-response.model';
import * as TrackersActions from '../trackers.actions';
import { Tracker } from '../trackers.model';
import { TrackersService } from '../trackers.service';

@Component({
	selector: 'ct-trackers-actions',
	templateUrl: './actions.component.html',
	styleUrls: [ './actions.component.css' ]
})
export class TrackersActionsComponent {
	@Input() selected: Tracker;
	@Input() trackers: Tracker[];

	constructor(
		private store$: Store<fromCartTrac.State>,
		private dialog: MatDialog,
		private dialogService: DialogService,
		private trackersService: TrackersService) { }

	powerOnOffDisabled() {
		return this.trackers.length < 1 || !this.selected || !this.selected.Terminal.PowerOffCommand || !this.selected.Terminal.PowerOnCommand;
	}

	onCommand() {
		let dialogRef = this.dialog.open(TrackersCommandComponent, {
			data: {
				deviceNumber: this.selected.Terminal.Number,
				connectionIndex: this.selected.ConnectionIndex,
				terminalId: this.selected.TerminalRequestLoginPacket.TerminalId
			}
		});

		dialogRef.afterClosed().subscribe(result => {
			dialogRef = null;
		});
	}

	onPowerOnOff() {
		let dialogRef = this.dialog.open(TrackersPowerOnOffComponent, {
			data: {
				selected: this.selected,
				trackers: this.trackers
			}
		});

		dialogRef.afterClosed().subscribe(result => {
			dialogRef = null;
		});
	}

	onReset() {
		this.trackersService.reset(this.selected.ConnectionIndex)
			.subscribe((response: TerminalResponse) => {
				this.dialogService.alert('TrackersService Response', response.Response);
			},
				(error: WebApiError) => {
					this.dialogService.webApiError('TrackersService.resetConnection()', error);
				});
	}

	onVerbose() {
		this.store$.dispatch(new TrackersActions.Verbose(this.selected.ConnectionIndex));
	}

	sort(sortOrder: number) {
		this.store$.dispatch(new TrackersActions.Sort(sortOrder));
	}
}
