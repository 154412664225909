import { Action } from '@ngrx/store';

import { WebApiError } from '../../shared/web-api/web-api-error';
import { StatusTrackingServer } from './status-tracking-server/status-tracking-server.model';
import { StatusOther } from './status-other/status-other.model';

export const STATUS_TRACKING_SERVER_LOAD = '[System] Status Tracking Load';
export const STATUS_TRACKING_SERVER_LOAD_FAIL = '[System] Status Tracking Load Fail';
export const STATUS_TRACKING_SERVER_LOAD_SUCCESS = '[System] Status Tracking Load Success';
export const STATUS_OTHER_LOAD = '[System] Status Other Load';
export const STATUS_OTHER_LOAD_FAIL = '[System] Status Other Load Fail';
export const STATUS_OTHER_LOAD_SUCCESS = '[System] Status Other Load Success';

export class StatusTrackingServerLoad implements Action {
	readonly type = STATUS_TRACKING_SERVER_LOAD;
}

export class StatusTrackingServerLoadFail implements Action {
	readonly type = STATUS_TRACKING_SERVER_LOAD_FAIL;

	constructor( public payload: WebApiError ) { }
}

export class StatusTrackingServerLoadSuccess implements Action {
	readonly type = STATUS_TRACKING_SERVER_LOAD_SUCCESS;

	constructor( public payload: StatusTrackingServer ) { }
}

export class StatusOtherLoad implements Action {
	readonly type = STATUS_OTHER_LOAD;
}

export class StatusOtherLoadFail implements Action {
	readonly type = STATUS_OTHER_LOAD_FAIL;

	constructor( public payload: WebApiError ) { }
}

export class StatusOtherLoadSuccess implements Action {
	readonly type = STATUS_OTHER_LOAD_SUCCESS;

	constructor( public payload: StatusOther ) { }
}

export type StatusAction
	= StatusTrackingServerLoad
	| StatusTrackingServerLoadFail
	| StatusTrackingServerLoadSuccess
	| StatusOtherLoad
	| StatusOtherLoadFail
	| StatusOtherLoadSuccess;
