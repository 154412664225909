<mat-tab-group id="level-one" [selectedIndex]="currentTabIndex" (selectedTabChange)=onTabChange($event)>
	<mat-tab [disabled]="true">
		<ng-template mat-tab-label>
			<ct-model-picker [screenOnly]="true" [selected]="selectedUnitModel$ | async"
				(select)="selectUnitModel($event)">
			</ct-model-picker>
		</ng-template>
	</mat-tab>
	<mat-tab label="IMAGES">
		<ct-ad-manager-images [facility]="facility$ | async" [selected]="selectedAdImage$ | async"
			[selectedUnitModel]="selectedUnitModel$ | async" [selectedAdImageType]="selectedAdImageType$ | async">
		</ct-ad-manager-images>
	</mat-tab>
	<mat-tab>
		<ng-template mat-tab-label>
			WORKSPACE
			<button id="new-version-button" mat-icon-button matTooltip="New Version"
				*ngIf="currentTabIndex === adManagerTabs.Workspace" (click)="versionsComponent.new()">
				<mat-icon class="material-icons">note_add</mat-icon>
			</button>
		</ng-template>

		<ct-ad-manager-versions #versionsComponent [published]="false" [facility]="facility$ | async"
			[selected]="selectedAdVersion$ | async" [selectedUnitModel]="selectedUnitModel$ | async"
			[selectedAdImageType]="selectedAdImageType$ | async"></ct-ad-manager-versions>
	</mat-tab>
	<mat-tab label="PUBLISHED">
		<ct-ad-manager-versions [published]="true" [facility]="facility$ | async"
			[selected]="selectedAdVersion$ | async" [selectedUnitModel]="selectedUnitModel$ | async"
			[selectedAdImageType]="selectedAdImageType$ | async"></ct-ad-manager-versions>
	</mat-tab>
</mat-tab-group>