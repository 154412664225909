import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromCartTrac from '../../cart-trac.reducers';
import * as AlarmsActions from './alarms.actions';
import * as RoutingActions from '../../routing/routing.actions';
import { AlarmPhoneNumber, Alarm } from './alarms.model';
import { Facility } from '../../facilities/facilities.model';

@Component( {
	selector: 'ct-alarms',
	templateUrl: './alarms.component.html',
	styleUrls: ['./alarms.component.css']
} )
export class AlarmsComponent implements OnInit {

	@Input() facility: Facility;

	alarm$: Observable<Alarm>;
	phoneNumbers$: Observable<AlarmPhoneNumber[]>;

	constructor( private store$: Store<fromCartTrac.State> ) {
		this.alarm$ = this.store$.select( fromCartTrac.getAlarm );
		this.phoneNumbers$ = this.store$.select( fromCartTrac.getAlarmsPhoneNumbers );
	}

	ngOnInit() {
		if ( this.facility ) {

			this.store$.dispatch( new AlarmsActions.Get( this.facility.Id ) );
			this.store$.dispatch( new AlarmsActions.PhoneNumbersList( this.facility.Id ) );
		}
		else
			this.store$.dispatch( new RoutingActions.Go( { path: ['/login'] } ) );
	}

	onUpdate( alarm: Alarm ) {
		this.store$.dispatch( new AlarmsActions.Update( alarm ) );
	}

	onPhoneNumbersDelete( id: number ) {
		this.store$.dispatch( new AlarmsActions.PhoneNumbersDelete( id ) );
	}

	onPhoneNumbersNew( toPhoneNumber: string ) {
		this.store$.dispatch( new AlarmsActions.PhoneNumbersNew( new AlarmPhoneNumber( 0, this.facility.Id, toPhoneNumber ) ) );
	}

	onPhoneNumbersUpdate( phoneNumber: AlarmPhoneNumber ) {
		this.store$.dispatch( new AlarmsActions.PhoneNumbersUpdate( phoneNumber ) );
	}
}
