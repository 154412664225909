import { NgModule, ModuleWithProviders } from '@angular/core';

import { CartTracMaterialModule } from '../../cart-trac-material.module';
import { CartTracAngularModule } from '../../cart-trac-angular.module';

import { StoreModule } from '@ngrx/store';
import { reducer } from './bing-map.reducer';

import { BingMapComponent } from './bing-map.component';
import { BingMapService } from './bing-map.service';

import { BingMapControlLoader } from './bing-map-control-loader/bing-map-control-loader';
import { BingMapControlLoaderLazy, BingMapControlLoaderLazyConfig } from './bing-map-control-loader/bing-map-control-loader-lazy';
import { BingMapControlLoaderScript } from './bing-map-control-loader/bing-map-control-loader-script';
import { BingMapDevicesComponent } from './bing-map-devices/bing-map-devices.component';
import { BingMapTracePointsComponent } from './bing-map-trace-points/bing-map-trace-points.component';
import { BingMapFlagsTeesComponent } from './bing-map-flags-tees/bing-map-flags-tees.component';
import { BingMapMenuComponent } from './bing-map-menu/bing-map-menu.component';

@NgModule( {
	declarations: [
		BingMapComponent,
		BingMapFlagsTeesComponent,
		BingMapDevicesComponent,
		BingMapMenuComponent,
		BingMapTracePointsComponent
	],
	imports: [
		CartTracMaterialModule,
		CartTracAngularModule,

		StoreModule.forFeature( 'bingMap', reducer ),
	],
	exports: [
		BingMapComponent,
		BingMapFlagsTeesComponent,
		BingMapDevicesComponent,
		BingMapMenuComponent,
		BingMapTracePointsComponent
	],
	providers: [
		BingMapControlLoaderLazy,
		BingMapControlLoaderScript,
		BingMapService
	]
} )
export class BingMapModule {
	static forRoot( config: BingMapControlLoaderLazyConfig ): ModuleWithProviders<BingMapModule> {
		return {
			ngModule: BingMapModule,
			providers: [
				{
					provide: BingMapControlLoader,
					useClass: BingMapControlLoaderLazy
					// useClass: BingMapControlLoaderScript
				},
				{
					provide: BingMapControlLoaderLazyConfig,
					useValue: config
				}
			],
		};
	}
}
