import { CdkDropList } from '@angular/cdk/drag-drop';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Action } from '@ngrx/store';

import { WebApiError } from '../../shared/web-api/web-api-error';
import { AdList } from './lists.model';

export const CLEAR = '[Lists] Clear';
export const DELETE = '[Lists] Delete';
export const DELETE_FAIL = '[Lists] Delete Fail';
export const DELETE_SUCCESS = '[Lists] Delete Success';
export const LIST = '[Lists] List';
export const LIST_FAIL = '[Lists] List Fail';
export const LIST_SUCCESS = '[Lists] List Success';
export const NEW = '[Lists] New';
export const NEW_FAIL = '[Lists] New Fail';
export const NEW_SUCCESS = '[Lists] New Success';
export const SELECT = '[Lists] Select';
export const SET_DROP_LIST = '[Ads] Set Drop List';
export const UPDATE = '[Lists] Update';
export const UPDATE_FAIL = '[Lists] Update Fail';
export const UPDATE_SUCCESS = '[Lists] Reanme Success';

export class Clear implements Action {
	readonly type = CLEAR;
}

export class Delete implements Action {
	readonly type = DELETE;

	constructor( public id: number ) { }
}

export class DeleteFail implements Action {
	readonly type = DELETE_FAIL;

	constructor( public error: WebApiError ) { }
}

export class DeleteSuccess implements Action {
	readonly type = DELETE_SUCCESS;
}

export class List implements Action {
	readonly type = LIST;

	constructor( public adSetId: number ) { }
}

export class ListFail implements Action {
	readonly type = LIST_FAIL;

	constructor( public error: WebApiError ) { }
}

export class ListSuccess implements Action {
	readonly type = LIST_SUCCESS;

	constructor( public adLists: AdList[] ) { }
}

export class New implements Action {
	readonly type = NEW;

	constructor( public adList: AdList ) { }
}

export class NewFail implements Action {
	readonly type = NEW_FAIL;

	constructor( public error: WebApiError ) { }
}

export class NewSuccess implements Action {
	readonly type = NEW_SUCCESS;

	constructor( public adList: AdList ) { }
}

export class Select implements Action {
	readonly type = SELECT;

	constructor( public adList: AdList ) { }
}

export class SetDropList implements Action {
	readonly type = SET_DROP_LIST;

	constructor( public dropListRef: CdkDropList<MatExpansionPanel[]> ) { }
}

export class Update implements Action {
	readonly type = UPDATE;

	constructor( public adList: AdList ) { }
}

export class UpdateFail implements Action {
	readonly type = UPDATE_FAIL;

	constructor( public error: WebApiError ) { }
}

export class UpdateSuccess implements Action {
	readonly type = UPDATE_SUCCESS;

	constructor( public adList: AdList ) { }
}

export type AdsListsAction
	= Clear
	| Delete
	| DeleteFail
	| DeleteSuccess
	| List
	| ListFail
	| ListSuccess
	| New
	| NewFail
	| NewSuccess
	| Select
	| SetDropList
	| Update
	| UpdateFail
	| UpdateSuccess;
