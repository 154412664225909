import * as RecurringMessagesActions from './recurring-messages.actions';
import { RecurringMessage } from './recurring-messages.model';
import { Store, createFeatureSelector, createSelector } from '@ngrx/store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

export const adapter = createEntityAdapter<RecurringMessage>( {
	selectId: ( recurringMessage: RecurringMessage ) => recurringMessage.Id,
	sortComparer: false
} );

export interface State extends EntityState<RecurringMessage> {
	progress: boolean;
	selected: RecurringMessage;
	sendCount: number;
}

const initialState: State = adapter.getInitialState( {
	progress: false,
	selected: null,
	sendCount: 0
} );

export function reducer( state = initialState, action: RecurringMessagesActions.RecurringMessagesAction ): State {
	switch ( action.type ) {
		case RecurringMessagesActions.DELETE:
		case RecurringMessagesActions.LIST:
		case RecurringMessagesActions.NEW:
		case RecurringMessagesActions.SEND:
		case RecurringMessagesActions.SET_ACTIVE:
		case RecurringMessagesActions.UPDATE:
			return { ...state, progress: true };

		case RecurringMessagesActions.DELETE_SUCCESS:
			return { ...adapter.removeOne( state.selected.Id, state ), progress: false, selected: null };

		case RecurringMessagesActions.LIST_SUCCESS:
			action.recurringMessages.forEach( recurringMessage => recurringMessage.CSSClass = 'recurring-message' );

			return { ...adapter.addAll( action.recurringMessages, state ), progress: false, selected: null };

		case RecurringMessagesActions.NEW_SUCCESS:
			return { ...adapter.addOne( action.recurringMessage, state ), progress: false, selected: null };

		case RecurringMessagesActions.SELECT:
			action.recurringMessage.CSSClass = 'recurring-message-selected';

			if ( state.selected ) {
				const updatedState = adapter.updateOne( { id: state.selected.Id, changes: { CSSClass: 'recurring-message' } }, state );

				return { ...updatedState, selected: action.recurringMessage };
			}
			else
				return { ...state, selected: action.recurringMessage };

		case RecurringMessagesActions.SEND_SUCCESS:
			return { ...state, progress: false, sendCount: action.count };

		case RecurringMessagesActions.SET_ACTIVE_SUCCESS:
			return {
				...adapter.updateOne( { id: action.parameters.Id, changes: { Active: action.parameters.Active, CSSClass: 'recurring-message' } },
					{ ...state, progress: false, selected: null } )
			};

		case RecurringMessagesActions.UPDATE_SUCCESS:
			return {
				...adapter.updateOne( { id: action.recurringMessage.Id, changes: { ...action.recurringMessage, CSSClass: 'recurring-message' } },
					{ ...state, progress: false, selected: null } )
			};

		case RecurringMessagesActions.DELETE_FAIL:
		case RecurringMessagesActions.LIST_FAIL:
		case RecurringMessagesActions.NEW_FAIL:
		case RecurringMessagesActions.SEND_FAIL:
		case RecurringMessagesActions.SET_ACTIVE_FAIL:
		case RecurringMessagesActions.UPDATE_FAIL:
			return initialState;

		default:
			return state;
	}
}

export const {
	selectIds: getIds,
	selectEntities: getEntities,
	selectAll: getAll,
	selectTotal: getTotal,
} = adapter.getSelectors();

export const getProgress = ( state: State ) => state.progress;
export const getSelected = ( state: State ) => state.selected;

