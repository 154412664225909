import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DialogService } from '../../../shared/dialogs/dialog.service';
import { RecurringMessage } from '../recurring-messages.model';
import { ValidateDaysOfWeek } from './days-of-week.validator';

@Component( {
	selector: 'ct-recurring-message-edit',
	templateUrl: './recurring-message-edit.component.html',
	styleUrls: ['./recurring-message-edit.component.css']
} )
export class RecurringMessageEditComponent implements OnInit {

	editForm: FormGroup;

	get edit(): boolean { return this.data.recurringMessage ? true : false; }				// Edit versus New mode

	get active(): AbstractControl { return this.editForm.get( 'active' ); }
	get text(): AbstractControl { return this.editForm.get( 'text' ); }
	get deviceNumbers(): AbstractControl { return this.editForm.get( 'deviceNumbers' ); }
	get time(): AbstractControl { return this.editForm.get( 'time' ); }
	get dayOfMonth(): AbstractControl { return this.editForm.get( 'dayOfMonth' ); }
	get daysOfWeek(): AbstractControl { return this.editForm.get( 'daysOfWeek' ); }
	get daysOfWeekAll(): AbstractControl { return this.editForm.get( 'daysOfWeek.daysOfWeekAll' ); }
	get daysOfWeekSun(): AbstractControl { return this.editForm.get( 'daysOfWeek.daysOfWeekSun' ); }
	get daysOfWeekMon(): AbstractControl { return this.editForm.get( 'daysOfWeek.daysOfWeekMon' ); }
	get daysOfWeekTue(): AbstractControl { return this.editForm.get( 'daysOfWeek.daysOfWeekTue' ); }
	get daysOfWeekWed(): AbstractControl { return this.editForm.get( 'daysOfWeek.daysOfWeekWed' ); }
	get daysOfWeekThu(): AbstractControl { return this.editForm.get( 'daysOfWeek.daysOfWeekThu' ); }
	get daysOfWeekFri(): AbstractControl { return this.editForm.get( 'daysOfWeek.daysOfWeekFri' ); }
	get daysOfWeekSat(): AbstractControl { return this.editForm.get( 'daysOfWeek.daysOfWeekSat' ); }

	constructor(
		@Inject( MAT_DIALOG_DATA ) public data: { recurringMessage: RecurringMessage, facilityId: number },
		public dialogRef: MatDialogRef<RecurringMessageEditComponent>,
		private dialogService: DialogService,
		private formBuilder: FormBuilder
	) { }

	ngOnInit() {
		this.editForm = this.formBuilder.group( {
			active: [this.edit ? this.data.recurringMessage.Active : false],
			text: [this.edit ? this.data.recurringMessage.Text : '', [Validators.required, Validators.maxLength( 255 )]],
			deviceNumbers: [this.edit ? this.data.recurringMessage.DeviceNumbers : 'All', [
				Validators.required, Validators.maxLength( 2048 ), Validators.pattern( '^((\\d{1,3},)*\\d{1,3})|All|all$' )]],
			time: [this.edit ? this.data.recurringMessage.Time : '17:00', [Validators.required]],
			dayOfMonth: [this.edit ? this.data.recurringMessage.DayOfMonth.toString() : '0'],
			daysOfWeek: this.formBuilder.group( {
				daysOfWeekAll: [this.edit ? this.data.recurringMessage.DaysOfWeek === 0x7F : true],
				daysOfWeekSun: [this.edit ? ( this.data.recurringMessage.DaysOfWeek & 0x01 ) > 0 : true],
				daysOfWeekMon: [this.edit ? ( this.data.recurringMessage.DaysOfWeek & 0x02 ) > 0 : true],
				daysOfWeekTue: [this.edit ? ( this.data.recurringMessage.DaysOfWeek & 0x04 ) > 0 : true],
				daysOfWeekWed: [this.edit ? ( this.data.recurringMessage.DaysOfWeek & 0x08 ) > 0 : true],
				daysOfWeekThu: [this.edit ? ( this.data.recurringMessage.DaysOfWeek & 0x10 ) > 0 : true],
				daysOfWeekFri: [this.edit ? ( this.data.recurringMessage.DaysOfWeek & 0x20 ) > 0 : true],
				daysOfWeekSat: [this.edit ? ( this.data.recurringMessage.DaysOfWeek & 0x40 ) > 0 : true]
			}, { validator: ValidateDaysOfWeek } )
		} );

		if ( this.dayOfMonth.value !== '0' )
			this.daysOfWeek.disable();

		this.dayOfMonth.valueChanges.subscribe( () => this.onChangeDayOfMonth() );
		this.daysOfWeekAll.valueChanges.subscribe( () => this.onChangeDaysOfWeekAll() );
	}

	onSubmit() {
		this.dialogRef.close( new RecurringMessage(
			this.edit ? this.data.recurringMessage.Id : 0,
			this.edit ? this.data.recurringMessage.FacilityId : this.data.facilityId,
			this.active.value,
			this.text.value,
			this.time.value,
			( this.daysOfWeekSun.value ? 0x01 : 0 ) + ( this.daysOfWeekMon.value ? 0x02 : 0 ) + ( this.daysOfWeekTue.value ? 0x04 : 0 ) + ( this.daysOfWeekWed.value ? 0x08 : 0 ) +
			( this.daysOfWeekThu.value ? 0x10 : 0 ) + ( this.daysOfWeekFri.value ? 0x20 : 0 ) + ( this.daysOfWeekSat.value ? 0x40 : 0 ),
			this.dayOfMonth.value,
			this.deviceNumbers.value
		) );
	}

	onCancel() {
		this.dialogRef.close();
	}

	onChangeDayOfMonth() {
		if ( this.dayOfMonth.value === '0' ) {		// Everyday
			this.daysOfWeek.enable();
			this.daysOfWeekAll.setValue( true );
		}
		else {
			this.daysOfWeek.disable();
			this.daysOfWeekAll.setValue( false );
		}
	}

	onChangeDaysOfWeekAll() {
		if ( this.daysOfWeekAll.value === true ) {
			this.daysOfWeekMon.setValue( true );
			this.daysOfWeekTue.setValue( true );
			this.daysOfWeekWed.setValue( true );
			this.daysOfWeekThu.setValue( true );
			this.daysOfWeekFri.setValue( true );
			this.daysOfWeekSat.setValue( true );
			this.daysOfWeekSun.setValue( true );
		} else {
			this.daysOfWeekMon.setValue( false );
			this.daysOfWeekTue.setValue( false );
			this.daysOfWeekWed.setValue( false );
			this.daysOfWeekThu.setValue( false );
			this.daysOfWeekFri.setValue( false );
			this.daysOfWeekSat.setValue( false );
			this.daysOfWeekSun.setValue( false );
		}
	}

	errorDeviceNumbers(): string {
		return this.deviceNumbers.hasError( 'required' ) ? 'Enter cart numbers or \"All\"' :
			this.deviceNumbers.hasError( 'maxlength' ) ? 'Maximum length reached' :
				this.deviceNumbers.hasError( 'pattern' ) ? 'Enter \"All\" or comma deliminated numbers, max 3 digits' : '';
	}

	errorText(): string {
		return this.text.hasError( 'required' ) ? 'Enter message text' :
			this.text.hasError( 'maxlength' ) ? 'Message too long' : '';
	}

	errorTime(): string {
		return this.time.hasError( 'required' ) ? 'Enter time' : '';
	}

	errorDaysOfWeek(): string {
		return this.daysOfWeek.hasError( 'mandatory' ) && this.daysOfWeek.enabled ? 'Set minimum one of the weekdays' : '';
	}
}
