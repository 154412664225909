import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { of, forkJoin } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import * as SystemActions from './system.actions';
import * as RoutingActions from '../routing/routing.actions';
import * as fromCartTrac from '../cart-trac.reducers';

import { WebApiError } from '../shared/web-api/web-api-error';
import { SystemService } from './system.service';
import { Mode, Model } from '../device-manager/devices/devices.model';
import { DialogService } from '../shared/dialogs/dialog.service';

@Injectable()
export class SystemEffects {
	@Effect()
	build$ = this.actions$.pipe(
		ofType( SystemActions.BUILD ),
		switchMap( () =>
			forkJoin( this.systemService.models(), this.systemService.modes() ).pipe(
				mergeMap( results => {
					return [
						new SystemActions.ModelsLoadSuccess( results[0] as Model[] ),
						new SystemActions.ModesLoadSuccess( results[1] as Mode[] ),
						new SystemActions.BuildSuccess()
					];
				} ),
				catchError( ( error: WebApiError ) => of( new SystemActions.BuildFail( error ) ) )
			)
		)
	);

	@Effect()
	modelsLoad$ = this.actions$.pipe(
		ofType( SystemActions.MODELS_LOAD ),
		switchMap( () => this.systemService.models().pipe(
			map( ( models: Model[] ) => new SystemActions.ModelsLoadSuccess( models ) ),
			catchError( ( error: WebApiError ) => of( new SystemActions.ModelsLoadFail( error ) ) )
		) )
	);

	@Effect()
	modesLoad$ = this.actions$.pipe(
		ofType( SystemActions.MODES_LOAD ),
		switchMap( () => this.systemService.modes().pipe(
			map( ( modes: Mode[] ) => new SystemActions.ModesLoadSuccess( modes ) ),
			catchError( ( error: WebApiError ) => of( new SystemActions.ModesLoadFail( error ) ) )
		) )
	);

	@Effect( { dispatch: false } )
	webApiFail$ = this.actions$.pipe(
		ofType( SystemActions.MODELS_LOAD_FAIL, SystemActions.MODELS_LOAD_FAIL ),
		tap( ( action: SystemActions.ModelsLoadFail | SystemActions.ModesLoadFail ) =>
			this.dialogService.webApiError( `SystemEffects - ${action.type}`, action.error ).subscribe(
				() => this.store$.dispatch( new RoutingActions.Go( { path: ['/login'] } ) )
			)
		)
	);

	constructor(
		private actions$: Actions,
		private store$: Store<fromCartTrac.State>,
		private systemService: SystemService,
		private dialogService: DialogService
	) { }
}
