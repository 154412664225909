import * as TrackersActions from './trackers.actions';

import { Tracker } from './trackers.model';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';

export interface State extends EntityState<Tracker> {
	progress: boolean;
	refresh: boolean;
	selected: Tracker;
	sortOrder: number;
}

export const adapter = createEntityAdapter<Tracker>( {
	selectId: ( tracker: Tracker ) => tracker.ConnectionIndex,
	sortComparer: false
} );

const initialState: State = adapter.getInitialState( {
	progress: false,
	refresh: true,
	selected: null,
	sortOrder: 1
} );

export function reducer( state = initialState, action: TrackersActions.TrackersAction ): State {
	switch ( action.type ) {
		case TrackersActions.LIST:
			return { ...state, progress: true };

		case TrackersActions.LIST_SUCCESS:
			action.trackers.forEach( tracker => {
				tracker.CSSClass = 'tracker';
				tracker.Terminal.PowerOffCommand = 'RELAY,1#';		// TODO: Add this to WebAPI and TrackingServer config
				tracker.Terminal.PowerOnCommand = 'RELAY,0#';		// TODO: Add this to WebAPI and TrackingServer config
			} );

			return { ...adapter.addAll( action.trackers, state ), progress: false, selected: null };

		case TrackersActions.SELECT:
			action.tracker.CSSClass = 'tracker-selected';

			if ( state.selected ) {
				const updatedState = adapter.updateOne( { id: state.selected.ConnectionIndex, changes: { CSSClass: 'tracker' } }, state );

				if ( state.selected.ConnectionIndex === action.tracker.ConnectionIndex )
					return { ...updatedState, selected: null };
				else
					return { ...updatedState, selected: action.tracker };
			}
			else
				return { ...state, selected: action.tracker };

		case TrackersActions.REFRESH_ON_OFF:
			return { ...state, refresh: action.refreshOnOff };

		case TrackersActions.SORT:
			return { ...state, selected: null, sortOrder: action.sortOrder };

		case TrackersActions.VERBOSE:
			const verbose = state.entities[action.trackerId].Verbose;
			return adapter.updateOne( { id: action.trackerId, changes: { Verbose: !verbose } }, state );

		case TrackersActions.LIST_FAIL:
			return initialState;

		default:
			return state;
	}
}

export const {
	selectIds: getIds,
	selectEntities: getEntities,
	selectAll: getAll,
	selectTotal: getCount,
} = adapter.getSelectors();

export const getProgress = ( state: State ) => state.progress;
export const getRefresh = ( state: State ) => state.refresh;
export const getSelected = ( state: State ) => state.selected;
export const getSortOrder = ( state: State ) => state.sortOrder;

const getSortedIds = createSelector(
	getAll,
	getSortOrder,
	( devices, sortOrder ) => {

		let sortFunction: ( a: Tracker, b: Tracker ) => number;

		switch ( sortOrder ) {
			case 1:
				sortFunction = ( a, b ) => ( a.Terminal.Number > b.Terminal.Number ? 1 : -1 );
				break;
			case 2:
				sortFunction = ( a, b ) => ( a.Terminal.UnitModelName > b.Terminal.UnitModelName ? 1 : -1 );
				break;
			case 3:
				sortFunction = ( a, b ) => ( a.Terminal.CartModeName > b.Terminal.CartModeName ? 1 : -1 );
				break;
			case 4:
				sortFunction = ( a, b ) => ( new Date( a.ReceiveTimeStamp ).getMilliseconds() - new Date( b.ReceiveTimeStamp ).getMilliseconds() ? 1 : -1 );
				break;
		}

		return devices
			.slice()												// don't change trackers
			.sort( sortFunction )									// sort
			.map( tracker => tracker.ConnectionIndex );				// return ids
	} );

export const getAllSorted = createSelector(
	getEntities,
	getSortedIds,
	( entities, sortedIds ) => {
		return sortedIds.map( id => entities[id] );
	} );
