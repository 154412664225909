<mat-tab-group id="level-one">
	<mat-tab label="INFO">
	</mat-tab>
	<mat-tab label="MESSAGES">
		<ng-template matTabContent>
			<ct-recurring-messages [facility]="facility$ | async"></ct-recurring-messages>
		</ng-template>
	</mat-tab>
	<mat-tab label="ALARMS">
		<ng-template matTabContent>
			<ct-alarms [facility]="facility$ | async"></ct-alarms>
		</ng-template>
	</mat-tab>
	<mat-tab label="MAP">
	</mat-tab>
	<mat-tab label="GPS">
		<ng-template matTabContent>
			<ct-gps-updates [facility]="facility$ | async"></ct-gps-updates>
		</ng-template>
	</mat-tab>
</mat-tab-group>