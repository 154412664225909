import { Action } from '@ngrx/store';

import { WebApiError } from '../shared/web-api/web-api-error';
import { RangerData } from './ranger-data.model';

export const LOAD = '[Ranger] Load';
export const LOAD_SUCCESS = '[Ranger] Load Success';
export const LOAD_FAIL = '[Ranger] Load Fail';

export class Load implements Action {
	readonly type = LOAD;

	constructor( public payload: number ) { }
}

export class LoadSuccess implements Action {
	readonly type = LOAD_SUCCESS;

	constructor( public payload: RangerData[] ) { }
}

export class LoadFail implements Action {
	readonly type = LOAD_FAIL;

	constructor( public payload: WebApiError ) { }
}

export type RangerAction
	= Load
	| LoadSuccess
	| LoadFail;
