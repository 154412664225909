import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import * as ImagesActions from './images.actions';
import { AdImage } from './images.model';

export interface State extends EntityState<AdImage> {
	bulkActionCount: number;
	newBatchCount: number;
	progress: boolean;
	selected: AdImage;
	sortOrder: number;
}

export const adapter = createEntityAdapter<AdImage>( {
	selectId: ( adImage: AdImage ) => adImage.AdImageID,
	sortComparer: false
} );

const initialState: State = adapter.getInitialState( {
	bulkActionCount: null,
	newBatchCount: 0,
	progress: false,
	selected: null,
	sortOrder: 1
} );

export function reducer( state = initialState, action: ImagesActions.ImagesAction ): State {
	switch ( action.type ) {
		case ImagesActions.COPY:
		case ImagesActions.DELETE:
		case ImagesActions.RENAME:
		case ImagesActions.DOWNLOAD:
		case ImagesActions.UPLOAD:
		case ImagesActions.PURGE:
			return { ...state, progress: true };

		case ImagesActions.CLEAR:
			return { ...adapter.removeAll( state ) };

		case ImagesActions.LIST:
			return { ...adapter.removeAll( state ), progress: true };

		case ImagesActions.NEW:
			return { ...state, progress: true };

		case ImagesActions.NEW_BATCH:
			return { ...state, newBatchCount: action.adImages.length };

		case ImagesActions.DELETE_SUCCESS:
			return { ...adapter.removeOne( state.selected.AdImageID, state ), progress: false, selected: null };

		case ImagesActions.LIST_SUCCESS:
			action.adImages.forEach( image => image.CSSClass = 'ad-image' );

			return { ...adapter.addAll( action.adImages, state ), progress: false, selected: null };

		case ImagesActions.COPY_SUCCESS:
			action.adImage.CSSClass = 'ad-image';

			return { ...adapter.addOne( action.adImage, state ), progress: false, selected: null };

		case ImagesActions.NEW_SUCCESS:
			action.adImage.CSSClass = 'ad-image';

			return { ...adapter.addOne( action.adImage, state ), progress: false, selected: null, newBatchCount: state.newBatchCount > 0 ? state.newBatchCount - 1 : 0 };

		case ImagesActions.DOWNLOAD_SUCCESS:
		case ImagesActions.UPLOAD_SUCCESS:
		case ImagesActions.PURGE_SUCCESS:
			return { ...state, progress: false, selected: null, bulkActionCount: action.count };

		case ImagesActions.RENAME_SUCCESS:
			return {
				...adapter.updateOne( { id: action.parameters.AdImageID, changes: { ...action.parameters, CSSClass: 'ad-image' } },
					{ ...state, progress: false, selected: null } )
			};

		case ImagesActions.SELECT:
			action.adImage.CSSClass = 'ad-image-selected';

			if ( state.selected ) {
				const updatedState = adapter.updateOne( { id: state.selected.AdImageID, changes: { CSSClass: 'ad-image' } }, state );

				if ( state.selected.AdImageID === action.adImage.AdImageID ) {
					return { ...updatedState, selected: null };
				} else {
					return { ...updatedState, selected: action.adImage };
				}
			} else {
				return { ...state, selected: action.adImage };
			}

		case ImagesActions.SORT:
			return { ...state, selected: null, sortOrder: action.sortOrder };

		case ImagesActions.COPY_FAIL:
		case ImagesActions.DELETE_FAIL:
		case ImagesActions.DOWNLOAD_FAIL:
		case ImagesActions.UPLOAD_FAIL:
		case ImagesActions.LIST_FAIL:
		case ImagesActions.NEW_FAIL:
		case ImagesActions.PURGE_FAIL:
		case ImagesActions.RENAME_FAIL:
			return initialState;

		case ImagesActions.DELETE_FAIL_IGNORE:
			return { ...state, progress: false };

		default:
			return state;
	}
}

export const {
	selectIds: getIds,
	selectEntities: getEntities,
	selectAll: getAll,
	selectTotal: getCount,
} = adapter.getSelectors();

export const getBulkActionCount = ( state: State ) => state.bulkActionCount;
export const getProgress = ( state: State ) => state.progress || state.newBatchCount > 0;
export const getSelected = ( state: State ) => state.selected;
export const getSortOrder = ( state: State ) => state.sortOrder;

const getSortedIds = createSelector(
	getAll,
	getSortOrder,
	( adImages, sortOrder ) => {

		let sortFunction: ( a: AdImage, b: AdImage ) => number;

		switch ( sortOrder ) {
			case 1:
				sortFunction = ( a, b ) => ( a.Name > b.Name ? 1 : -1 );
				break;
			case 2:
				sortFunction = ( a, b ) => ( a.FileName > b.FileName ? 1 : -1 );
				break;
			case 3:
				sortFunction = ( a, b ) => new Date( a.TimeStamp ).getTime() - new Date( b.TimeStamp ).getTime();
				break;
		}

		return adImages
			.slice()									// don't change adImages
			.sort( sortFunction )						// sort
			.map( adImage => adImage.AdImageID );		// return ids
	} );

export const getAllSorted = createSelector(
	getEntities,
	getSortedIds,
	( entities, sortedIds ) => {
		return sortedIds.map( id => entities[id] );
	} );
