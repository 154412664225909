import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { EffectsModule } from '@ngrx/effects';
import { RoutingEffects } from './routing.effects';

/*
export const routes: Routes = [
	{ path: '', component: LoginComponent },

	{ path: 'admin', loadChildren: 'app/admin/admin.module#AdminModule' },
	{ path: 'ads', loadChildren: 'app/ads/ads.module#AdsModule' },
	{ path: 'courses', loadChildren: 'app/courses/courses.module#CoursesModule' },
	{ path: 'devices', loadChildren: 'app/devices/devices.module#DevicesModule' },
	{ path: 'facilities', component: FacilitiesComponent },
	{ path: 'login', component: LoginComponent },
	{ path: 'ranger', loadChildren: 'app/ranger/ranger.module#RangerModule' },
	{ path: 'settings', loadChildren: 'app/settings/settings.module#SettingsModule' },
	{ path: 'system', loadChildren: 'app/system/system.module#SystemModule' },
	{ path: 'trace', loadChildren: 'app/trace/trace.module#TraceModule' },
	{ path: 'trace-parameters', loadChildren: 'app/trace-parameters/trace-parameters.module#TraceParametersModule' },
	{ path: 'tracking', loadChildren: 'app/tracking/tracking.module#TrackingModule' },
	{ path: 'users', loadChildren: 'app/users/users.module#UsersModule' },
	{ path: 'zones', loadChildren: 'app/zones/zones.module#ZonesModule' },
	{ path: '**', redirectTo: 'login' }
];
*/

import { AdminComponent } from '../admin/admin.component';
import { AdManagerComponent } from '../ad-manager/ad-manager.component';
import { CoursesComponent } from '../courses/courses.component';
import { DeviceManagerComponent } from '../device-manager/device-manager.component';
import { FacilitySelectorComponent } from '../facilities/facility-selector/facility-selector.component';
import { LoginComponent } from '../login/login.component';
import { RangerComponent } from '../ranger/ranger.component';
import { SettingsComponent } from '../settings/settings.component';
import { SystemComponent } from '../system/system.component';
import { TraceComponent } from '../trace/trace.component';
import { TraceParametersComponent } from '../trace-parameters/trace-parameters.component';
import { TrackingComponent } from '../tracking/tracking.component';
import { UserManagerComponent } from '../user-manager/user-manager.component';
import { ZonesComponent } from '../zones/zones.component';

export const routes: Routes = [
	{ path: '', component: LoginComponent },

	{ path: 'admin', component: AdminComponent },
	{ path: 'ads', component: AdManagerComponent },
	{ path: 'courses', component: CoursesComponent },
	{ path: 'devices', component: DeviceManagerComponent },
	{ path: 'facilities', component: FacilitySelectorComponent },
	{ path: 'login', component: LoginComponent },
	{ path: 'ranger', component: RangerComponent },
	{ path: 'settings', component: SettingsComponent },
	{ path: 'system', component: SystemComponent },
	{ path: 'trace', component: TraceComponent },
	{ path: 'trace-parameters', component: TraceParametersComponent },
	{ path: 'tracking', component: TrackingComponent },
	{ path: 'users', component: UserManagerComponent },
	{ path: 'zones', component: ZonesComponent },
	{ path: '**', redirectTo: 'login' }
];

@NgModule( {
	imports: [
		RouterModule.forRoot( routes ),
		EffectsModule.forFeature( [RoutingEffects] )
	],
	exports: [RouterModule]
} )
export class RoutingModule { }
