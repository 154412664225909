import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { WebApiService } from './shared/web-api/web-api.service';

@NgModule( {
	imports: [
		CommonModule,
		HttpClientModule,
		FormsModule,
		ReactiveFormsModule
	],
	exports: [
		CommonModule,
		HttpClientModule,
		FormsModule,
		ReactiveFormsModule
	],
	providers: [
		WebApiService
	]
} )
export class CartTracAngularModule { }
