<mat-tab-group id="level-one" (selectedIndexChange)="tabChanged($event)">
	<mat-tab label="GENERAL">
		<ct-device-manager-devices [facility]="facility$ | async"></ct-device-manager-devices>
	</mat-tab>
	<mat-tab label="TRACKERS">
		<ct-device-manager-trackers [facility]="facility$ | async" [selected]="selectedTracker$ | async"
			(refresh)=trackersRefresh($event)></ct-device-manager-trackers>
	</mat-tab>
	<mat-tab label="OTHER">
	</mat-tab>
</mat-tab-group>