import { Action } from '@ngrx/store';

import { WebApiError } from '../../shared/web-api/web-api-error';
import { AdImage, AdImageUploadParameters, AdImageListParameters, AdImageRenameParameters } from './images.model';

export const CLEAR = '[Ads] Images Clear';
export const COPY = '[Ads] Images Copy';
export const COPY_FAIL = '[Ads] Images Copy Fail';
export const COPY_SUCCESS = '[Ads] Images Copy Success';
export const DELETE = '[Ads] Images Delete';
export const DELETE_FAIL = '[Ads] Images Delete Fail';
export const DELETE_FAIL_IGNORE = '[Ads] Images Delete Fail Ignore';
export const DELETE_SUCCESS = '[Ads] Images Delete Success';
export const DOWNLOAD = '[Ads] Images Download';
export const DOWNLOAD_FAIL = '[Ads] Images Download Fail';
export const DOWNLOAD_SUCCESS = '[Ads] Images Download Success';
export const UPLOAD = '[Ads] Images Upload';
export const UPLOAD_FAIL = '[Ads] Images Upload Fail';
export const UPLOAD_SUCCESS = '[Ads] Images Upload Success';
export const LIST = '[Ads] Images List';
export const LIST_FAIL = '[Ads] Images List Fail';
export const LIST_SUCCESS = '[Ads] Images List Success';
export const NEW = '[Ads] Images New';
export const NEW_BATCH = '[Ads] Images New Batch';
export const NEW_FAIL = '[Ads] Images New Fail';
export const NEW_SUCCESS = '[Ads] Images New Success';
export const PURGE = '[Ads] Images Purge';
export const PURGE_FAIL = '[Ads] Images Purge Fail';
export const PURGE_SUCCESS = '[Ads] Images Purge Success';
export const RENAME = '[Ads] Images Rename';
export const RENAME_FAIL = '[Ads] Images Rename Fail';
export const RENAME_SUCCESS = '[Ads] Images Reanme Success';
export const SELECT = '[Ads] Images Select';
export const SORT = '[Ads] Images Sort';

export class Clear implements Action {
	readonly type = CLEAR;
}

export class Copy implements Action {
	readonly type = COPY;

	constructor( public id: number ) { }
}

export class CopyFail implements Action {
	readonly type = COPY_FAIL;

	constructor( public error: WebApiError ) { }
}

export class CopySuccess implements Action {
	readonly type = COPY_SUCCESS;

	constructor( public adImage: AdImage ) { }
}

export class Delete implements Action {
	readonly type = DELETE;

	constructor( public id: number ) { }
}

export class DeleteFail implements Action {
	readonly type = DELETE_FAIL;

	constructor( public error: WebApiError ) { }
}

export class DeleteFailIgnore implements Action {
	readonly type = DELETE_FAIL_IGNORE;

	constructor( public error: WebApiError ) { }
}

export class DeleteSuccess implements Action {
	readonly type = DELETE_SUCCESS;
}

export class Download implements Action {
	readonly type = DOWNLOAD;

	constructor( public facilityId: number ) { }
}

export class DownloadFail implements Action {
	readonly type = DOWNLOAD_FAIL;

	constructor( public error: WebApiError ) { }
}

export class DownloadSuccess implements Action {
	readonly type = DOWNLOAD_SUCCESS;

	constructor( public count: number ) { }
}

export class List implements Action {
	readonly type = LIST;

	constructor( public parameters: AdImageListParameters ) { }
}

export class ListFail implements Action {
	readonly type = LIST_FAIL;

	constructor( public error: WebApiError ) { }
}

export class ListSuccess implements Action {
	readonly type = LIST_SUCCESS;

	constructor( public adImages: AdImage[] ) { }
}

export class New implements Action {
	readonly type = NEW;

	constructor( public adImage: AdImage ) { }
}

export class NewBatch implements Action {
	readonly type = NEW_BATCH;

	constructor( public adImages: AdImage[] ) { }
}

export class NewFail implements Action {
	readonly type = NEW_FAIL;

	constructor( public error: WebApiError ) { }
}

export class NewSuccess implements Action {
	readonly type = NEW_SUCCESS;

	constructor( public adImage: AdImage ) { }
}

export class Purge implements Action {
	readonly type = PURGE;

	constructor( public count: number ) { }
}

export class PurgeFail implements Action {
	readonly type = PURGE_FAIL;

	constructor( public error: WebApiError ) { }
}

export class PurgeSuccess implements Action {
	readonly type = PURGE_SUCCESS;

	constructor( public count: number ) { }
}

export class Rename implements Action {
	readonly type = RENAME;

	constructor( public parameters: AdImageRenameParameters ) { }
}

export class RenameFail implements Action {
	readonly type = RENAME_FAIL;

	constructor( public error: WebApiError ) { }
}

export class RenameSuccess implements Action {
	readonly type = RENAME_SUCCESS;

	constructor( public parameters: AdImageRenameParameters ) { }
}

export class Select implements Action {
	readonly type = SELECT;

	constructor( public adImage: AdImage ) { }
}

export class Sort implements Action {
	readonly type = SORT;

	constructor( public sortOrder: number ) { }
}

export class Upload implements Action {
	readonly type = UPLOAD;

	constructor( public parameters: AdImageUploadParameters ) { }
}

export class UploadFail implements Action {
	readonly type = UPLOAD_FAIL;

	constructor( public error: WebApiError ) { }
}

export class UploadSuccess implements Action {
	readonly type = UPLOAD_SUCCESS;

	constructor( public count: number ) { }
}

export type ImagesAction
	= Clear
	| Copy
	| CopyFail
	| CopySuccess
	| Delete
	| DeleteFail
	| DeleteFailIgnore
	| DeleteSuccess
	| Download
	| DownloadFail
	| DownloadSuccess
	| Upload
	| UploadFail
	| UploadSuccess
	| List
	| ListFail
	| ListSuccess
	| New
	| NewBatch
	| NewFail
	| NewSuccess
	| Purge
	| PurgeFail
	| PurgeSuccess
	| Rename
	| RenameFail
	| RenameSuccess
	| Select
	| Sort;
