<mat-toolbar color="secondary">{{title}}</mat-toolbar>

<div class="dialog-error-location">
    {{location}}
</div>

<div class="dialog-error-message">
    {{message}}
</div>

<button mat-raised-button (click)="dialogRef.close()">Close</button>
