import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as moment from 'moment';

import { WebApiService } from '../shared/web-api/web-api.service';
import { WebApiError } from '../shared/web-api/web-api-error';
import { TracePoint } from './trace-point.model';
import { TraceParameters } from '../trace-parameters/trace-parameters.model';

@Injectable()
export class TraceService {
	public traceParameters: TraceParameters;

	private serviceBaseUrl: string;

	constructor( private webApiService: WebApiService ) {
		this.serviceBaseUrl = this.webApiService.serverUrl + '/api/devices';
	}

	trace( traceParameters: TraceParameters ): Observable<TracePoint[] | WebApiError> {
		if ( !traceParameters.StartDate && !traceParameters.EndDate ) {
			traceParameters.StartDate = moment().startOf( 'day' ).toDate();
			traceParameters.EndDate = moment( traceParameters.StartDate ).add( 1, 'days' ).toDate();
		}
		else
			if ( traceParameters.StartDate )
				traceParameters.EndDate = moment( traceParameters.StartDate ).add( 1, 'days' ).toDate();
			else
				traceParameters.StartDate = moment( traceParameters.EndDate ).subtract( 1, 'days' ).toDate();

		return this.webApiService.get<TracePoint[]>( this.serviceBaseUrl + '/trace/' + traceParameters.Id + '/' +
			moment( traceParameters.StartDate ).format( 'YYYY-MM-DD' ) + '/' + moment( traceParameters.EndDate ).format( 'YYYY-MM-DD' ) );
	}
}
