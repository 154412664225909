<mat-toolbar>
    <ct-ad-image-type-picker [selected]="selectedAdImageType" (select)="selectImageType($event)">
    </ct-ad-image-type-picker>

    <ct-ads-images-actions [images]="adImages$ | async" [facility]="facility" [selected]="selected"
        [selectedUnitModel]="selectedUnitModel" [selectedImageType]="selectedAdImageType"
        (refresh)="refresh(selectedUnitModel, selectedAdImageType)" (copy)="copy()" (delete)="delete()"
        (new)="new($event)" (sort)="sort($event)">
    </ct-ads-images-actions>

    <span class="fill-remaining-space"></span>

    <ct-ads-images-bulk-actions [facility]="facility" (download)="download()" (upload)="upload($event)"
        (purge)="purge()">
    </ct-ads-images-bulk-actions>
</mat-toolbar>

<div id="ad-images">
    <mat-card *ngFor="let adImage of adImages$ | async" [ngClass]="adImage.CSSClass" (click)="select(adImage)"
        (change)="rename(adImage)">
        <img class="ad-image-png" src="data:image/PNG;base64,{{adImage.Image}}" />
        <mat-card-subtitle>
            <mat-form-field>
                <input matInput type="text" required [(ngModel)]="adImage.Name" name="adImageName" />
            </mat-form-field>
        </mat-card-subtitle>
    </mat-card>
</div>