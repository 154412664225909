import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Ad } from './ads.model';
import { WebApiService } from '../../shared/web-api/web-api.service';
import { WebApiError } from '../../shared/web-api/web-api-error';

@Injectable()
export class AdsService {

	private serviceBaseUrl: string;

	constructor( private webApiService: WebApiService ) {
		this.serviceBaseUrl = this.webApiService.serverUrl + '/api/ads';
	}

	delete( id: number ): Observable<void | WebApiError> {
		return this.webApiService.delete( this.serviceBaseUrl + '/' + id );
	}

	list( adListId: number ): Observable<Ad[] | WebApiError> {
		return this.webApiService.get<Ad[]>( this.serviceBaseUrl + '/' + adListId );
	}

	new( ad: Ad ): Observable<Ad | WebApiError> {
		return this.webApiService.post( this.serviceBaseUrl, ad );
	}

	update( ad: Ad ): Observable<Ad | WebApiError> {
		return this.webApiService.put( this.serviceBaseUrl, ad );
	}
}

