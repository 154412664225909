import * as LoginActions from './login.actions';

import { MobileSettings, WebLogin, WebUser } from './login.model';

export interface State {
	progress: boolean;
	webLogin: WebLogin;
	webUser: WebUser;
}

const initialState: State = {
	progress: false,
	webLogin: { Login: '', Password: '', IP: '' },
	webUser: null
};

export function reducer(state = initialState, action: LoginActions.LoginAction): State {
	switch (action.type) {
		case LoginActions.LOGIN:
			return { ...state, progress: true, webLogin: action.webLogin };

		case LoginActions.SUCCESS:
			state.webUser = action.webUser;
			state.webUser.Settings = parseSettings(action.webUser.Settings as any);
			return { ...state, progress: false };

		case LoginActions.SETTINGS_SAVE:
			return { ...state, progress: true };

		case LoginActions.SETTINGS_SAVE_SUCCESS:
			state.webUser.Settings = parseSettings(action.settingsDTO.JSON as any);
			return { ...state, progress: false };

		case LoginActions.FAIL:
		case LoginActions.SETTINGS_SAVE_FAIL:
			return initialState;

		default:
			return state;
	}
}

function parseSettings(json: string): MobileSettings {

	let settings: MobileSettings = JSON.parse(json);

	if (!settings) {
		settings = {
			Tracking: {
				Modes: ['B', 'C', 'H', 'N', 'R', 'W'],
				ShowFlags: true,
				ShowTees: true,
				ShowLogo: true,
				UseHoleName: false,
				UsePOPColors: false
			},
			AutoLogoff: true,
			DiagnosticMode: false
		};
	}

	if ( settings.Tracking.ShowLogo === undefined ) {
		settings.Tracking.ShowLogo = true;
	}

	return settings;
}

export const getProgress = (state: State) => state.progress;
export const getWebLogin = (state: State) => state.webLogin;
export const getWebUser = (state: State) => state.webUser;
