import * as BingMapActions from './bing-map.actions';

export interface State {
	fullscreen: boolean;
}

const initialState: State = {
	fullscreen: false
};

export function reducer( state = initialState, action: BingMapActions.BingMapAction ): State {
	switch ( action.type ) {
		case BingMapActions.FULLSCREEN:
			return { ...state, fullscreen: action.payload };

		default:
			return state;
	}
}

export const getFullscreen = ( state: State ) => state.fullscreen;
