import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Tracker } from '../trackers.model';

@Component( {
	selector: 'ct-trackers-list',
	templateUrl: './list.component.html',
	styleUrls: ['./list.component.css']
} )
export class TrackersListComponent {

	@Input() trackers: Tracker[];
	// tslint:disable-next-line:no-output-native
	@Output() select = new EventEmitter<Tracker>();
	@Output() toggleVerbose = new EventEmitter<number>();

	constructor() { }

	onSelect( tracker: Tracker ) {
		this.select.emit( tracker );
	}

	onToggleVerbose( connectionIndex: number ) {
		this.toggleVerbose.emit( connectionIndex );
	}
}
