<mat-toolbar mat-dialog-title color="secondary">New Ad Set</mat-toolbar>

<form [formGroup]="newForm" (ngSubmit)="new()">
	<mat-form-field class="set-name">
		<input matInput required type="text" placeholder="Set Name" formControlName="name">
		<mat-error>Required</mat-error>
	</mat-form-field>

	<mat-form-field class="set-start-time">
		<input matInput required type="time" placeholder="Start Time" formControlName="startTime">
		<mat-error>Required</mat-error>
	</mat-form-field>

	<mat-form-field class="set-end-time">
		<input matInput required type="time" placeholder="End Time" formControlName="endTime">
		<mat-error>Required</mat-error>
	</mat-form-field>

	<mat-form-field class="set-course">
		<mat-select matInput required placeholder="Course" formControlName="courseNumber">
			<mat-option value="0">Any</mat-option>
			<mat-option *ngFor="let course of courses" [value]="course.Number">{{course.Name}}</mat-option>
		</mat-select>
		<mat-error>Required</mat-error>
	</mat-form-field>

	<mat-checkbox required formControlName="exclusive">Exclusive</mat-checkbox>

	<mat-dialog-actions>
		<button mat-raised-button (click)="dialogRef.close()">Close</button>
		<button mat-raised-button mat-primary submit [disabled]="newForm.invalid">Create</button>
	</mat-dialog-actions>
</form>