import { NgModule } from '@angular/core';

import { CartTracAngularModule } from '../cart-trac-angular.module';
import { CartTracMaterialModule } from '../cart-trac-material.module';
import { ZonesRoutingModule } from './zones-routing.module';

import { ZonesComponent } from './zones.component';
import { ZonesVersionsModule } from './zones-versions/zones-versions.module';

@NgModule( {
	declarations: [ZonesComponent],
	imports: [
		CartTracAngularModule,
		CartTracMaterialModule,
		ZonesRoutingModule,

		ZonesVersionsModule
	]
} )
export class ZonesModule { }
