<div id="mapContainer">
	<button id="fullscreen" *ngIf="!fullscreen && !isMobile && !systemUser" mat-mini-fab (click)="requestFullscreen()">
		<mat-icon>fullscreen</mat-icon>
	</button>

	<button id="menu" *ngIf="!fullscreen && systemUser" mat-mini-fab (click)="openMenu()">
		<mat-icon>more_vert</mat-icon>
	</button>

	<img *ngIf="fullscreen && webUser.Settings.Tracking.ShowLogo" src={{mapLogoUrl}} class="map-logo" />

	<div class="bing-map">
		<div id="mapContent">
			<ng-content></ng-content>
		</div>
	</div>
</div>