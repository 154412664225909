import { Component, OnInit, Input } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Device, Model, Mode } from './devices.model';
import { Facility } from '../../facilities/facilities.model';
import { Store } from '@ngrx/store';
import * as fromCartTrac from '../../cart-trac.reducers';
import * as DevicesActions from './devices.actions';

@Component( {
	selector: 'ct-device-manager-devices',
	templateUrl: './devices.component.html',
	styleUrls: ['./devices.component.css']
} )
export class DevicesComponent implements OnInit {
	@Input() facility: Facility;

	devices$: Observable<Device[]>;
	expanded$: Observable<boolean>;
	models$: Observable<Model[]>;
	modes$: Observable<Mode[]>;
	selected$: Observable<Device>;
	systemUser$: Observable<boolean>;

	constructor( private store$: Store<fromCartTrac.State> ) {
		this.devices$ = this.store$.select( fromCartTrac.getDevices );
		this.expanded$ = this.store$.select( fromCartTrac.getDevicesExpanded );
		this.models$ = this.store$.select( fromCartTrac.getModels );
		this.modes$ = this.store$.select( fromCartTrac.getModes );
		this.selected$ = this.store$.select( fromCartTrac.getDevicesSelected );
		this.systemUser$ = this.store$.select( fromCartTrac.getSystemUser );
	}

	ngOnInit() {
	}

	onSelectDevice( device: Device ) {
		this.store$.dispatch( new DevicesActions.Select( device ) );
	}
}
