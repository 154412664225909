import { Action } from '@ngrx/store';

import { WebApiError } from '../shared/web-api/web-api-error';
import { WebLogin, WebUser, MobileSettings, MobileSettingsDTO } from './login.model';

export const LOGIN = '[Login]';
export const SUCCESS = '[Login] Success';
export const FAIL = '[Login] Fail';

export const LOGIN_SUCCESS = '[Settings] Login';
export const SETTINGS_SAVE = '[Settings] Save';
export const SETTINGS_SAVE_FAIL = '[Settings] Save Fail';
export const SETTINGS_SAVE_SUCCESS = '[Settings] Save Success';

export class Login implements Action {
	readonly type = LOGIN;

	constructor( public webLogin: WebLogin ) { }
}

export class Success implements Action {
	readonly type = SUCCESS;

	constructor( public webUser: WebUser ) { }
}

export class Fail implements Action {
	readonly type = FAIL;

	constructor( public error: WebApiError ) { }
}

export class LoginSuccess implements Action {
	readonly type = LOGIN_SUCCESS;

	constructor( public settings: MobileSettings ) { }
}

export class SettingsSave implements Action {
	readonly type = SETTINGS_SAVE;

	constructor( public settings: MobileSettingsDTO ) { }
}

export class SettingsSaveFail implements Action {
	readonly type = SETTINGS_SAVE_FAIL;

	constructor( public error: WebApiError ) { }
}

export class SettingsSaveSuccess implements Action {
	readonly type = SETTINGS_SAVE_SUCCESS;

	constructor( public settingsDTO: MobileSettingsDTO ) { }
}

export type LoginAction
	= Login
	| Success
	| Fail
	| LoginSuccess
	| SettingsSave
	| SettingsSaveFail
	| SettingsSaveSuccess;
