import { Component, EventEmitter, Input, Output } from '@angular/core';

import { TraceDevice } from '../trace-device.model';

@Component( {
	selector: 'ct-trace-devices',
	templateUrl: './trace-devices.component.html',
	styleUrls: ['./trace-devices.component.css'],
} )
export class TraceDevicesComponent {
	@Input() traceDevices: TraceDevice[];
	@Output() id = new EventEmitter<number>();

	gridCols: number;
	rowHeight: string = '60px';

	constructor() { }

	ngOnInit() {
		this.gridCols = window.innerWidth / 70;
	}

	select( id: number ) {
		this.id.emit( id );
	}

	onResize( event: any ) {
		this.gridCols = event.target.innerWidth / 70;
	}
}

