import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Mode, Model } from '../../devices.model';

@Component( {
	selector: 'ct-devices-new',
	templateUrl: './new.component.html',
	styleUrls: ['./new.component.css']
} )
export class NewComponent implements OnInit {
	deviceForm: FormGroup;

	mode: Mode;
	model: Model;

	constructor(
		private fb: FormBuilder,
		public dialogRef: MatDialogRef<NewComponent>,
		@Inject( MAT_DIALOG_DATA ) public data: { facilityId: number } ) { }

	ngOnInit() {
		this.deviceForm = this.fb.group( {
			deviceId: ['', [Validators.required, Validators.pattern( '[A-Z0-9]{14}' )]],
			number: ['000', [Validators.required, Validators.pattern( '[0-9]{3}' )]],
			name: ['', [Validators.maxLength( 25 )]]
		} );
	}

	onSelectMode( mode: Mode ) {
		this.mode = mode;
	}

	onSelectModel( model: Model ) {
		this.model = model;
	}

	newDevice() {
		this.dialogRef.close( {
			FacilityId: this.data.facilityId,
			DeviceId: this.deviceForm.get( 'deviceId' ).value,
			ModeId: this.mode.Id,
			ModelId: this.model.Id,
			Number: this.deviceForm.get( 'number' ).value,
			Name: this.deviceForm.get( 'name' ).value
		} );
	}
}
