import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BingMapService } from '../bing-map.service';

@Component( {
	selector: 'ct-bing-map-menu',
	templateUrl: './bing-map-menu.component.html',
	styleUrls: ['./bing-map-menu.component.css']
} )
export class BingMapMenuComponent implements OnInit {

	latitude: number;
	longitude: number;
	center: Microsoft.Maps.Location;

	constructor(
		private bingMapService: BingMapService,
		public dialogRef: MatDialogRef<BingMapMenuComponent> ) {

		this.center = this.bingMapService.getCurrentCenter();
		this.latitude = this.center.latitude;
		this.longitude = this.center.longitude;
	}

	ngOnInit(): void {
	}

	requestFullscreen() {
		this.dialogRef.close();
		this.bingMapService.requestFullscreen();
	}

	setView() {
		this.dialogRef.close();
		this.bingMapService.setView( this.latitude, this.longitude );
	}

	resetView() {
		this.dialogRef.close();
		this.bingMapService.resetView();
	}
}
