<mat-accordion class="headers-align">
	<mat-expansion-panel #setPanel *ngFor="let adSet of adSets" (opened)=opened(adSet) (closed)="closed(adSet)">
		<mat-expansion-panel-header>
			<mat-panel-title>
				<mat-icon class="material-icons">reorder</mat-icon>
				<span class="panel-title-text">{{adSet.Name}}</span>
			</mat-panel-title>
			<mat-panel-description>
				<span *ngIf="!setPanel.expanded" class="panel-description-text">
					<div>
						{{adSet.StartTime}} - {{adSet.EndTime}}
					</div>
					<div>
						Course: {{courseName(adSet.CourseNumber)}} {{adSet.Exclusive ? ", X" : ""}}
					</div>
				</span>

				<span class="fill-remaining-space"></span>

				<button mat-icon-button matTooltip="Delete Set" *ngIf="!published && adSet.Name !== 'CartTrac Set' && adSet.Name !== 'Facility Set' && !setPanel.expanded"
				 (click)="delete($event, adSet)">
					<mat-icon class="material-icons">delete_forever</mat-icon>
				</button>
				<button mat-icon-button matTooltip="Save" *ngIf="!published && editMode && setPanel.expanded && editForm.dirty && editForm.valid"
				 (click)="update($event)">
					<mat-icon class="material-icons">save</mat-icon>
				</button>
				<button mat-icon-button matTooltip="Clear" *ngIf="!published && editMode && setPanel.expanded && editForm.dirty"
				 (click)="reset($event)">
					<mat-icon class="material-icons">clear</mat-icon>
				</button>

				<button mat-icon-button matTooltip="Edit Set" *ngIf="!published && !setPanel.expanded" (click)="edit(adSet)">
					<mat-icon class="material-icons">edit</mat-icon>
				</button>
			</mat-panel-description>
		</mat-expansion-panel-header>

		<div *matExpansionPanelContent>
			<form *ngIf="editMode" [formGroup]="editForm">
				<mat-form-field class="set-name">
					<input matInput required type="text" placeholder="Set Name" formControlName="name">
					<mat-error>Required</mat-error>
				</mat-form-field>

				<mat-form-field class="set-start-time">
					<input matInput required type="time" placeholder="Start Time" formControlName="startTime">
					<mat-error>Required</mat-error>
				</mat-form-field>

				<mat-form-field class="set-end-time">
					<input matInput required type="time" placeholder="End Time" formControlName="endTime">
					<mat-error>Required</mat-error>
				</mat-form-field>

				<mat-form-field class="set-course">
					<mat-select matInput required placeholder="Course" formControlName="courseNumber">
						<mat-option value="0">Any</mat-option>
						<mat-option *ngFor="let course of courses" [value]="course.Number">{{course.Name}}</mat-option>
					</mat-select>
					<mat-error>Required</mat-error>
				</mat-form-field>

				<mat-checkbox required formControlName="exclusive">Exclusive</mat-checkbox>
			</form>

			<ct-ad-manager-lists *ngIf="!editMode" [courseNumber]="adSet.CourseNumber" [adLists]="adLists$ | async" [selected]="selectedAdList$ | async"
			 [published]="published" (listImages)="onListImages($event)" (clearImages)="onClearImages($event)"></ct-ad-manager-lists>
		</div>

	</mat-expansion-panel>
</mat-accordion>