import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import { WebApiError } from '../web-api/web-api-error';
import { AlertDialogComponent } from './dialog-alert.component';
import { ConfirmDialogComponent } from './dialog-confirm.component';
import { ErrorDialogComponent } from './dialog-error.component';

@Injectable()
export class DialogService {
	constructor( private dialog: MatDialog ) { }

	alert( title: string, message: string ): Observable<void> {
		const config = new MatDialogConfig();
		const dialogRef = this.dialog.open( AlertDialogComponent, config );

		dialogRef.componentInstance.title = title;
		dialogRef.componentInstance.message = message;

		return dialogRef.afterClosed();
	}

	confirm( title: string, message: string ): Observable<boolean> {
		const config = new MatDialogConfig();
		const dialogRef = this.dialog.open( ConfirmDialogComponent, config );

		dialogRef.componentInstance.title = title;
		dialogRef.componentInstance.message = message;

		return dialogRef.afterClosed();
	}

	error( title: string, location: string, message: string ): Observable<void> {
		const config = new MatDialogConfig();
		const dialogRef = this.dialog.open( ErrorDialogComponent, config );

		dialogRef.componentInstance.title = title;
		dialogRef.componentInstance.location = location;
		dialogRef.componentInstance.message = message;

		return dialogRef.afterClosed();
	}

	webApiError( location: string, error: WebApiError ): Observable<void> {
		const config = new MatDialogConfig();
		const dialogRef = this.dialog.open( ErrorDialogComponent, config );

		dialogRef.componentInstance.title = 'WebApi Error';
		dialogRef.componentInstance.location = location;

		dialogRef.componentInstance.message = `Status: ${error.Status} - ${error.StatusText}.\r\nWebApiResponse: ${JSON.stringify( error.WebApiResponse )}`;

		return dialogRef.afterClosed();
	}
}

