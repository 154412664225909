import { Action } from '@ngrx/store';

import { WebApiError } from '../../shared/web-api/web-api-error';
import { AdVersion, AdVersionRenameParameters, AdVersionListParameters, AdVersionCopyParameters, AdVersionPublishParameters } from './versions.model';

export const ACTIVATE = '[Versions] Activate';
export const ACTIVATE_FAIL = '[Versions] Activate Fail';
export const ACTIVATE_SUCCESS = '[Versions] Activate Success';
export const COPY = '[Versions] Copy';
export const COPY_FAIL = '[Versions] Copy Fail';
export const COPY_SUCCESS = '[Versions] Copy Success';
export const DELETE = '[Versions] Delete';
export const DELETE_FAIL = '[Versions] Delete Fail';
export const DELETE_SUCCESS = '[Versions] Delete Success';
export const LIST = '[Versions] List';
export const LIST_FAIL = '[Ads] Vereions List Fail';
export const LIST_SUCCESS = '[Versions] List Success';
export const NAME_EXISTS = '[Versions] Name Exists';
export const NAME_EXISTS_RESET = '[Versions] Name Exists Reset';
export const NEW = '[Versions] New';
export const NEW_FAIL = '[Versions] New Fail';
export const NEW_SUCCESS = '[Versions] New Success';
export const PUBLISH = '[Versions] Publish';
export const PUBLISH_FAIL = '[Versions] Publish Fail';
export const PUBLISH_SUCCESS = '[Versions] Publish Success';
export const RENAME = '[Versions] Rename';
export const RENAME_FAIL = '[Versions] Rename Fail';
export const RENAME_SUCCESS = '[Versions] Reanme Success';
export const SELECT = '[Versions] Select';
export const SORT = '[Versions] Sort';

export class Activate implements Action {
	readonly type = ACTIVATE;

	constructor( public id: number ) { }
}

export class ActivateFail implements Action {
	readonly type = ACTIVATE_FAIL;

	constructor( public error: WebApiError ) { }
}

export class ActivateSuccess implements Action {
	readonly type = ACTIVATE_SUCCESS;
}

export class Copy implements Action {
	readonly type = COPY;

	constructor( public parameters: AdVersionCopyParameters ) { }
}

export class CopyFail implements Action {
	readonly type = COPY_FAIL;

	constructor( public error: WebApiError ) { }
}

export class CopySuccess implements Action {
	readonly type = COPY_SUCCESS;

	constructor( public adVersion: AdVersion ) { }
}

export class Delete implements Action {
	readonly type = DELETE;

	constructor( public id: number ) { }
}

export class DeleteFail implements Action {
	readonly type = DELETE_FAIL;

	constructor( public error: WebApiError ) { }
}

export class DeleteSuccess implements Action {
	readonly type = DELETE_SUCCESS;
}

export class List implements Action {
	readonly type = LIST;

	constructor( public parameters: AdVersionListParameters ) { }
}

export class ListFail implements Action {
	readonly type = LIST_FAIL;

	constructor( public error: WebApiError ) { }
}

export class ListSuccess implements Action {
	readonly type = LIST_SUCCESS;

	constructor( public adVersions: AdVersion[] ) { }
}

export class NameExists implements Action {
	readonly type = NAME_EXISTS;

	constructor( public parameters: AdVersionRenameParameters | AdVersion ) { }
}

export class NameExistsReset implements Action {
	readonly type = NAME_EXISTS_RESET;
}

export class New implements Action {
	readonly type = NEW;

	constructor( public adVersion: AdVersion ) { }
}

export class NewFail implements Action {
	readonly type = NEW_FAIL;

	constructor( public error: WebApiError ) { }
}

export class NewSuccess implements Action {
	readonly type = NEW_SUCCESS;

	constructor( public adVersion: AdVersion ) { }
}

export class Publish implements Action {
	readonly type = PUBLISH;

	constructor( public parameters: AdVersionPublishParameters ) { }
}

export class PublishFail implements Action {
	readonly type = PUBLISH_FAIL;

	constructor( public error: WebApiError ) { }
}

export class PublishSuccess implements Action {
	readonly type = PUBLISH_SUCCESS;
}

export class Rename implements Action {
	readonly type = RENAME;

	constructor( public parameters: AdVersionRenameParameters ) { }
}

export class RenameFail implements Action {
	readonly type = RENAME_FAIL;

	constructor( public error: WebApiError ) { }
}

export class RenameSuccess implements Action {
	readonly type = RENAME_SUCCESS;

	constructor( public parameters: AdVersionRenameParameters ) { }
}

export class Select implements Action {
	readonly type = SELECT;

	constructor( public adVersion: AdVersion ) { }
}

export class Sort implements Action {
	readonly type = SORT;

	constructor( public sortOrder: number ) { }
}

export type AdsVersionsAction
	= Activate
	| ActivateFail
	| ActivateSuccess
	| Copy
	| CopyFail
	| CopySuccess
	| Delete
	| DeleteFail
	| DeleteSuccess
	| List
	| ListFail
	| ListSuccess
	| NameExists
	| NameExistsReset
	| New
	| NewFail
	| NewSuccess
	| Publish
	| PublishFail
	| PublishSuccess
	| Rename
	| RenameFail
	| RenameSuccess
	| Select
	| Sort;
