import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import * as fromCartTrac from '../cart-trac.reducers';
import * as RangerActions from './ranger.actions';
import * as RoutingActions from '../routing/routing.actions';

import { CoursesService } from '../courses/courses.service';
import { RangerData } from './ranger-data.model';
import { WebApiError } from '../shared/web-api/web-api-error';
import { DialogService } from '../shared/dialogs/dialog.service';

@Injectable()
export class RangerEffects {
	@Effect()
	load$ = this.actions$.pipe(
		ofType( RangerActions.LOAD ),
		map( ( action: RangerActions.Load ) => action.payload ),
		switchMap( facilityId => this.coursesService.rangerData( facilityId ).pipe(
			map( ( rangerCourses: RangerData[] ) => new RangerActions.LoadSuccess( rangerCourses ) ),
			catchError( ( error: WebApiError ) => of( new RangerActions.LoadFail( error ) ) )
		) )
	);

	@Effect( { dispatch: false } )
	webApiFail$ = this.actions$.pipe(
		ofType( RangerActions.LOAD_FAIL ),
		tap( ( action: RangerActions.LoadFail ) =>
			this.dialogService.webApiError( `RangerEffects - ${action.type}`, action.payload ).subscribe(
				() => this.store$.dispatch( new RoutingActions.Go( { path: ['/login'] } ) )
			)
		)
	);

	constructor(
		private actions$: Actions,
		private store$: Store<fromCartTrac.State>,
		private coursesService: CoursesService,
		private dialogService: DialogService
	) { }
}
