<div>
    <table>
        <thead>
            <tr>
                <th></th>
                <th>Number</th>
                <th>Device ID</th>
                <th>Status</th>
                <th>Last GPS Time</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let tracker of trackers; let i = index" [ngClass]="tracker.CSSClass"
                (click)="onSelect(tracker)">
                <td>
                    <button mat-icon-button matTooltip="Verbose" (click)="onToggleVerbose(tracker.ConnectionIndex)">
                        <mat-icon class="material-icons">play_arrow</mat-icon>
                    </button>
                </td>
                <td>{{tracker.Terminal.Number}}</td>
                <td>{{tracker.TerminalRequestLoginPacket.TerminalId}}</td>
                <td>
                    <span *ngIf="tracker.TerminalRequestHeartbeatPacket">
                        <span [ngSwitch]="tracker.TerminalRequestHeartbeatPacket.GSMSignalStrength"
                            matTooltip="Signal Strength">
                            <mat-icon *ngSwitchCase="0" class="material-icons">signal_cellular_off</mat-icon>
                            <mat-icon *ngSwitchCase="1" class="material-icons">filter_1</mat-icon>
                            <mat-icon *ngSwitchCase="2" class="material-icons">filter_2</mat-icon>
                            <mat-icon *ngSwitchCase="3" class="material-icons">filter_3</mat-icon>
                            <mat-icon *ngSwitchCase="4" class="material-icons">signal_cellular_4_bar</mat-icon>
                        </span>
                        <span [ngSwitch]="tracker.TerminalRequestHeartbeatPacket.VoltageLevel"
                            matTooltip="Voltage Level">
                            <mat-icon *ngSwitchCase="1" class="material-icons">filter_1</mat-icon>
                            <mat-icon *ngSwitchCase="2" class="material-icons">filter_2</mat-icon>
                            <mat-icon *ngSwitchCase="3" class="material-icons">filter_3</mat-icon>
                            <mat-icon *ngSwitchCase="4" class="material-icons">filter_4</mat-icon>
                            <mat-icon *ngSwitchCase="5" class="material-icons">filter_5</mat-icon>
                            <mat-icon *ngSwitchCase="6" class="material-icons">battery_full</mat-icon>
                            <mat-icon *ngSwitchDefault class="material-icons">battery_alert</mat-icon>
                        </span>
                        <span [ngSwitch]="tracker.TerminalRequestHeartbeatPacket.Charging" matTooltip="Charging">
                            <mat-icon *ngSwitchCase="true" class="material-icons">battery_charging_full</mat-icon>
                            <mat-icon *ngSwitchCase="false" class="material-icons">battery_alert</mat-icon>
                        </span>
                        <span [ngSwitch]="tracker.TerminalRequestHeartbeatPacket.FuelAndPower" matTooltip="Fuel and Power">
                            <mat-icon *ngSwitchCase="true" class="material-icons">power</mat-icon>
                            <mat-icon *ngSwitchCase="false" class="material-icons">power_off</mat-icon>
                        </span>
                    </span>
                    <span *ngIf="!tracker.TerminalRequestHeartbeatPacket">
                        <mat-icon class="material-icons" matTooltip="Voltage Level">battery_alert</mat-icon>
                        <mat-icon class="material-icons" matTooltip="Signal Strength">signal_cellular_off</mat-icon>
                        <mat-icon class="material-icons" matTooltip="GPS Tracking">location_off</mat-icon>
                    </span>
                    <span *ngIf="tracker.TerminalRequestUpdatePacket">
                        <span [ngSwitch]="tracker.TerminalRequestUpdatePacket.GPSPositioned"
                            matTooltip="GPS Positioned">
                            <mat-icon *ngSwitchCase="true" class="material-icons">gps_fixed</mat-icon>
                            <mat-icon *ngSwitchCase="false" class="material-icons">gps_not_fixed</mat-icon>
                        </span>
                        <span [ngSwitch]="tracker.TerminalRequestUpdatePacket.SatelliteCount"
                            matTooltip="Satellite Count">
                            <mat-icon *ngSwitchCase="0" class="material-icons">visibility_off</mat-icon>
                            <mat-icon *ngSwitchCase="1" class="material-icons">filter_1</mat-icon>
                            <mat-icon *ngSwitchCase="2" class="material-icons">filter_2</mat-icon>
                            <mat-icon *ngSwitchCase="3" class="material-icons">filter_3</mat-icon>
                            <mat-icon *ngSwitchCase="4" class="material-icons">filter_4</mat-icon>
                            <mat-icon *ngSwitchCase="5" class="material-icons">filter_5</mat-icon>
                            <mat-icon *ngSwitchCase="6" class="material-icons">filter_6</mat-icon>
                            <mat-icon *ngSwitchCase="7" class="material-icons">filter_7</mat-icon>
                            <mat-icon *ngSwitchCase="8" class="material-icons">filter_8</mat-icon>
                            <mat-icon *ngSwitchCase="9" class="material-icons">filter_9</mat-icon>
                            <mat-icon *ngSwitchDefault class="material-icons">filter_9_plus</mat-icon>
                        </span>
                    </span>
                    <span *ngIf="!tracker.TerminalRequestUpdatePacket">
                        <mat-icon class="material-icons" mdToolTip="GPS Positioned">gps_not_fixed</mat-icon>
                        <mat-icon class="material-icons" mdToolTip="Satellite Count">visibility_off</mat-icon>
                    </span>

                    <ct-json-viewer *ngIf="tracker.Verbose" [json]="tracker"></ct-json-viewer>
                </td>
                <td *ngIf="tracker.TerminalRequestUpdatePacket">
                    {{tracker.TerminalRequestUpdatePacket.GPSTime | date:"yyyy-MM-dd HH:mm"}}</td>
                <td *ngIf="!tracker.TerminalRequestUpdatePacket">-</td>
            </tr>
        </tbody>
    </table>
</div>