<mat-toolbar mat-dialog-title color="secondary">Move Device #{{data.device.Number}} | {{data.device.DeviceId}}
</mat-toolbar>
<div>
	<mat-dialog-content>
		<ct-facility-picker [label]="true" [selected]="destinationFacility" (select)="onSelect($event)">
		</ct-facility-picker>
	</mat-dialog-content>
	<mat-dialog-actions align="center">
		<button mat-raised-button (click)="dialogRef.close()">Close</button>
		<button mat-raised-button mat-primary (click)="move()"
			[disabled]="destinationFacility.Id===data.facility.Id">Move</button>
	</mat-dialog-actions>
</div>