<form>
	<mat-form-field>
		<mat-label *ngIf="label">Facility</mat-label>
		<mat-select disableOptionCentering="true" [(value)]="selected" name="selected"
			(selectionChange)="selectionChange($event)">
			<mat-option *ngFor="let facility of facilities" [value]="facility">
				{{facility.Name}}
			</mat-option>
		</mat-select>
	</mat-form-field>
</form>