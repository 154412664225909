<ct-toolbar (menuClicked)="toggleSidenav()">
	<ct-branding [featureName]="featureName$ | async" [facility]="facility$ | async"></ct-branding>
</ct-toolbar>

<ct-layout>
	<ct-sidenav [open]="openSidenav$ | async">
		<a mat-button style="height: 0; position: absolute;">
			<!-- absorbs setting the focus to the first item in the list-->
		</a>
		<ct-nav-item (activate)="closeSidenav()" *ngIf="loggedIn$ | async" routerLink="/facilities"
			icon="location_city">Facilities</ct-nav-item>
		<mat-divider></mat-divider>
		<ct-nav-item (activate)="closeSidenav()" *ngIf="facility" routerLink="/tracking" icon="language">Tracking
		</ct-nav-item>
		<ct-nav-item (activate)="closeSidenav()" *ngIf="facility" routerLink="/ranger" icon="grid_on">Ranger
		</ct-nav-item>
		<ct-nav-item (activate)="closeSidenav()" *ngIf="facility" routerLink="/trace-parameters" icon="timeline">Trace
		</ct-nav-item>
		<ct-nav-item (activate)="closeSidenav()" *ngIf="facility" routerLink="/admin" icon="security">Admin
		</ct-nav-item>
		<ct-nav-item (activate)="closeSidenav()" *ngIf="facility" routerLink="/ads" icon="image">Ads</ct-nav-item>
		<ct-nav-item (activate)="closeSidenav()" *ngIf="facility" routerLink="/courses" icon="golf_course">Courses
		</ct-nav-item>
		<ct-nav-item (activate)="closeSidenav()" *ngIf="facility" routerLink="/devices" icon="devices">Devices
		</ct-nav-item>
		<ct-nav-item (activate)="closeSidenav()" *ngIf="facility" routerLink="/users" icon="group">Users</ct-nav-item>
		<ct-nav-item (activate)="closeSidenav()" *ngIf="facility" routerLink="/zones" icon="select_all">Zones
		</ct-nav-item>
		<mat-divider></mat-divider>
		<ct-nav-item (activate)="closeSidenav()" *ngIf="loggedIn$ | async" routerLink="/settings" icon="settings">
			Settings</ct-nav-item>
		<ct-nav-item (activate)="closeSidenav()" *ngIf="systemUser$ | async" routerLink="/system" icon="cloud">System
		</ct-nav-item>
		<ct-nav-item (activate)="closeSidenav()" routerLink="/login" icon="perm_identity">Login</ct-nav-item>
	</ct-sidenav>

	<div class="outlet">
		<router-outlet></router-outlet>
	</div>

	<div *ngIf="showProgress$ | async" class="progress">
		<mat-progress-spinner mode="indeterminate" color="warn"></mat-progress-spinner>
	</div>
</ct-layout>