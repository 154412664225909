import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, OnDestroy, Output, Input } from '@angular/core';
import { Router } from '@angular/router';
import fscreen from 'fscreen';

import { BingMapService } from './bing-map.service';
import { BingMapMenuComponent } from './bing-map-menu/bing-map-menu.component';
import { BrowserDetectionService } from '../utils/browser-detection.service';

import { Course } from '../../courses/courses.model';
import { Facility, Map } from '../../facilities/facilities.model';
import { MobileSettings, WebUser } from '../../login/login.model';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

const MapContentElementId = 'mapContent';
const MapContainerElementId = 'mapContainer';

@Component( {
	selector: 'ct-bing-map',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './bing-map.component.html',
	styleUrls: ['./bing-map.component.css']
} )

export class BingMapComponent implements OnInit, OnDestroy {

	@Input() courses: Course[];
	@Input() facility: Facility;
	@Input() fullscreen: boolean;
	@Input() map: Map;
	@Input() webUser: WebUser;
	@Input() systemUser: boolean;
	@Output() bingMapLoaded = new EventEmitter();
	@Output() setFullscreen = new EventEmitter<boolean>();

	dialogRef: MatDialogRef<BingMapMenuComponent>;
	mapLogoUrl: string;

	constructor(
		private router: Router,
		private bingMapService: BingMapService,
		public dialog: MatDialog,
		private browserDetectionService: BrowserDetectionService ) { }

	ngOnInit() {
		if ( !this.facility ) {
			this.router.navigate( ['/login/'] );
			return;
		}

		this.mapLogoUrl = `assets/MapLogos/${this.facility.FileRoot.trim()}_MapLogo.png`;

		this.bingMapService.createMap( '#' + MapContentElementId, this.map, this.facility.FileRoot )
			.then( bingMap => {
				this.resizeMapContent( null );

				if ( fscreen.fullscreenEnabled ) {
					fscreen.addEventListener( 'fullscreenchange', () => {
						if ( fscreen.fullscreenElement !== null ) {
							this.setFullscreen.emit( true );
						} else {
							this.setFullscreen.emit( false );
						}
					} );
				}

				window.addEventListener( 'resize', this.resizeMapContent );

				Microsoft.Maps.Events.addHandler( bingMap, 'click',
					( event: any ) => {
						this.bingMapService.concealDeviceInfobox();
						this.bingMapService.concealTracePointInfobox();
						this.bingMapService.updateAutoLogoffTimer();
					} );

				this.bingMapLoaded.emit();
			} );
	}

	resizeMapContent( event: UIEvent ) {
		const mapDiv = document.getElementById( MapContentElementId );
		const w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

		mapDiv.style.width = w.toString() + 'px';
		mapDiv.style.height = '100%';
	}

	get isMobile(): boolean {
		return this.browserDetectionService.isMobile();
	}

	openMenu() {
		const dialogRef = this.dialog.open( BingMapMenuComponent );
	}

	requestFullscreen() {
		this.bingMapService.requestFullscreen();
	}

	ngOnDestroy() {
		window.removeEventListener( 'resize', this.resizeMapContent );
		fscreen.removeEventListener( 'fullscreenchange' );
	}
}
