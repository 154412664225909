import { Component, OnInit } from '@angular/core';

@Component( {
	selector: 'ct-actions',
	templateUrl: './actions.component.html',
	styleUrls: ['./actions.component.css']
} )
export class ActionsComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}

}
