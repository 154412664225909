<mat-card>
	<mat-card-title>End of Day</mat-card-title>
	<mat-card-content>
		<mat-slide-toggle *ngIf="facility" [(ngModel)]="facility.Settings.ResetDeviceColorEOD">Reset Devices Color
		</mat-slide-toggle>
		<mat-slide-toggle *ngIf="facility" [(ngModel)]="facility.Settings.ResetDeviceNameEOD">Reset Devices Name
		</mat-slide-toggle>
		<mat-slide-toggle *ngIf="facility" [(ngModel)]="facility.Settings.ResetDeviceModeEOD">Reset Devices Mode (Ranger
			-> Normal)
		</mat-slide-toggle>
	</mat-card-content>
</mat-card>

<mat-card>
	<mat-card-title>End of Round</mat-card-title>
	<mat-card-content>
		<mat-slide-toggle *ngIf="facility" [(ngModel)]="facility.Settings.ResetDeviceModeEOR">Reset Devices Mode
			(Handicap -> Normal)
		</mat-slide-toggle>
	</mat-card-content>
	<mat-card-actions>
		<button mat-raised-button (click)="save()">SAVE</button>
	</mat-card-actions>
</mat-card>