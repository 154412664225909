import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import * as fromCartTrac from '../../cart-trac.reducers';
import * as TraceParametersActions from '../../trace-parameters/trace-parameters.actions';
import * as RoutingActions from '../../routing/routing.actions';

import { Course, Hole } from '../../courses/courses.model';
import { MobileSettings } from '../../login/login.model';
import { Map } from '../../facilities/facilities.model';
import { TrackingDevice } from '../../tracking/tracking-device.model';
import { TracePoint } from '../../trace/trace-point.model';
import { BingMapControlLoader } from './bing-map-control-loader/bing-map-control-loader';
import { BingMapEntities } from './bing-map-entities';

import fscreen from 'fscreen';

const MapContainerElementId = 'mapContainer';

@Injectable()
export class BingMapService {
	private bingMap: Microsoft.Maps.Map;
	private deviceInfobox: Microsoft.Maps.Infobox;
	private tracePointInfobox: Microsoft.Maps.Infobox;
	private flagsAndTeesLayer: Microsoft.Maps.Layer;
	private devicesLayer: Microsoft.Maps.Layer;
	private tracePointsLayer: Microsoft.Maps.Layer;
	private lastClickedTimeStamp: Date;
	private map: Map;

	constructor(
		private store$: Store<fromCartTrac.State>,
		private controlLoader: BingMapControlLoader ) { }

	createMap( elementId: string, map: Map, fileRoot: string ): Promise<Microsoft.Maps.Map> {
		return this.controlLoader.load()
			.then( () => {
				const customMap = map.MapProviderName === 'CartTrac';

				this.map = map;

				this.bingMap = new Microsoft.Maps.Map( elementId,
					{
						// IMapLoadOptions
						credentials: map.MapProviderAPIKey,

						// IMapOptions
						allowHidingLabelsOfRoad: true,
						backgroundColor: customMap ? Microsoft.Maps.Color.fromHex( '#' + map.BackgroundColor.substring( 2 ) ) : null,
						enableClickableLogo: false,
						maxZoom: map.MaxZoom,
						minZoom: map.MinZoom,
						maxBounds: customMap ? Microsoft.Maps.LocationRect.fromEdges( map.NWLatitude, map.NWLongitude,
							map.SELatitude, map.SELongitude ) : null,
						showDashboard: false,
						showLocateMeButton: false,
						showLogo: false,
						showScalebar: false,
						showTermsLink: false,

						// IViewOptions
						center: new Microsoft.Maps.Location( map.CenterLatitude, map.CenterLongitude ),
						labelOverlay: Microsoft.Maps.LabelOverlay.hidden,
						mapTypeId: customMap ? Microsoft.Maps.MapTypeId.mercator : Microsoft.Maps.MapTypeId.aerial,
						zoom: map.StartZoom
					} );

				if ( customMap ) {
					const tileSource = new Microsoft.Maps.TileSource(
						{
							uriConstructor: `${map.MapProviderServerAddress}Maps/${fileRoot.trim()}/${map.Version}/${map.MapProviderIndexFormat}`
							// uriConstructor: `http://localhost:8080/tiles/quadkey/${map.MapProviderIndexFormat}`
						} );

					const tileLayer = new Microsoft.Maps.TileLayer( { mercator: tileSource } );

					this.bingMap.layers.insert( tileLayer );
				}

				this.flagsAndTeesLayer = new Microsoft.Maps.Layer( 'FlagsAndTees' );
				this.devicesLayer = new Microsoft.Maps.Layer( 'Devices' );
				this.tracePointsLayer = new Microsoft.Maps.Layer( 'TracePoints' );

				this.bingMap.layers.insert( this.flagsAndTeesLayer );
				this.bingMap.layers.insert( this.devicesLayer );
				this.bingMap.layers.insert( this.tracePointsLayer );

				this.deviceInfobox = new Microsoft.Maps.Infobox( this.bingMap.getCenter(), { visible: false } );			// there is only one, shared
				this.deviceInfobox.setMap( this.bingMap );

				this.tracePointInfobox = new Microsoft.Maps.Infobox( this.bingMap.getCenter(), { visible: false } );		// there is only one, shared
				this.tracePointInfobox.setMap( this.bingMap );

				return this.bingMap;
			} );
	}

	displayFlagsAndTees( courses: Course[], holes: Hole[], settings: MobileSettings ) {
		this.lastClickedTimeStamp = null;

		courses.forEach( course => {
			holes.forEach( ( hole ) => {
				if ( course.Id === hole.CourseId ) {
					const holeLabel = settings.Tracking.UseHoleName ? hole.Name : hole.Number.toString();

					if ( settings.Tracking.ShowFlags ) {
						const flagPushpin = new Microsoft.Maps.Pushpin(
							new Microsoft.Maps.Location( hole.CenterLatitude, hole.CenterLongitude ),
							{
								anchor: new Microsoft.Maps.Point( 28, 30 ),
								icon: BingMapEntities.flagCanvas( course.FlagColor, course.FlagTextColor, holeLabel )
							} );

						this.flagsAndTeesLayer.add( flagPushpin );
					}

					if ( settings.Tracking.ShowTees ) {
						const teePushpin = new Microsoft.Maps.Pushpin(
							new Microsoft.Maps.Location( hole.TeeBoxLatitude, hole.TeeBoxLongitude ),
							{
								anchor: new Microsoft.Maps.Point( 5, 55 ),
								icon: BingMapEntities.teeCanvas( course.TeeColor, course.TeeTextColor, holeLabel )
							} );

						this.flagsAndTeesLayer.add( teePushpin );
					}
				}
			} );
		} );
	}

	displayDevices( trackingDevices: TrackingDevice[], modes: string[] ) {
		this.devicesLayer.clear();
		this.concealDeviceInfobox();

		trackingDevices.forEach( ( trackingDevice ) => {
			if ( modes.indexOf( trackingDevice.Mode ) >= 0 ) {
				const devicePushpin = new Microsoft.Maps.Pushpin(
					new Microsoft.Maps.Location( trackingDevice.Latitude, trackingDevice.Longitude ),
					{
						anchor: new Microsoft.Maps.Point( 16, 12 ),
						icon: BingMapEntities.deviceCanvas( trackingDevice.Number, trackingDevice.TextColor, trackingDevice.BorderColor, trackingDevice.FillColor )
					} );

				const service = this;

				Microsoft.Maps.Events.addHandler( devicePushpin, 'click',
					( event: Event ) => {
						service.displayDeviceInfobox( trackingDevice, event );
						service.updateAutoLogoffTimer();
					} );

				this.devicesLayer.add( devicePushpin );
			}
		} );
	}

	displayDeviceInfobox( trackingDevice: TrackingDevice, event: Event ) {
		this.deviceInfobox.setOptions(
			{
				htmlContent: BingMapEntities.deviceInfoboxHTML( trackingDevice ),
				location: new Microsoft.Maps.Location( trackingDevice.Latitude, trackingDevice.Longitude ),
				offset: new Microsoft.Maps.Point( 33, 30 ),
				visible: true
			} );

		( window as any ).BMCE = {
			traceDevice: ( value: number ) => {
				this.store$.dispatch( new TraceParametersActions.Set( { Id: value } ) );
				this.store$.dispatch( new RoutingActions.Go( { path: ['/trace/'] } ) );
			}
		};
	}

	concealDeviceInfobox() {
		this.deviceInfobox.setOptions( { visible: false } );
	}

	displayTracePoints( tracePoints: TracePoint[] ) {
		this.tracePointsLayer.clear();
		this.concealTracePointInfobox();

		if ( tracePoints.length === 0 ) {		// Bing Maps V8 has a problem with empty traceLines added to tracePointLayer
			return;
		}

		const vertices: Microsoft.Maps.Location[] = [];

		tracePoints.forEach( tracePoint => vertices.push( new Microsoft.Maps.Location( tracePoint.Latitude, tracePoint.Longitude ) ) );

		const traceLines = new Microsoft.Maps.Polyline( vertices, { strokeColor: new Microsoft.Maps.Color( 0xFF, 0xFF, 0x00, 0x00 ), strokeThickness: 3 } );

		this.tracePointsLayer.add( traceLines );

		tracePoints.forEach( tracePoint => {
			const tracePointPushpin = new Microsoft.Maps.Pushpin(
				new Microsoft.Maps.Location( tracePoint.Latitude, tracePoint.Longitude ),
				{
					anchor: new Microsoft.Maps.Point( 5, 5 ),
					icon: BingMapEntities.tracePointCanvas()
				} );

			Microsoft.Maps.Events.addHandler( tracePointPushpin, 'click',
				( event: Event ) => {
					this.displayTracePointInfobox( tracePoint, event );
					this.updateAutoLogoffTimer();
				} );

			this.tracePointsLayer.add( tracePointPushpin );
		} );
	}

	displayTracePointInfobox( tracePoint: TracePoint, event: Event ) {
		let intervalString = '';

		if ( this.lastClickedTimeStamp ) {
			intervalString = ` (${Math.round( ( ( ( new Date( tracePoint.TimeStamp ) ).getTime() - ( new Date( this.lastClickedTimeStamp ) ).getTime() ) / ( 1000 * 60 ) ) )})`;
		}

		this.lastClickedTimeStamp = tracePoint.TimeStamp;


		this.tracePointInfobox.setOptions(
			{
				htmlContent: BingMapEntities.tracePointInfoboxHTML( tracePoint, intervalString ),
				location: new Microsoft.Maps.Location( tracePoint.Latitude, tracePoint.Longitude ),
				offset: new Microsoft.Maps.Point( 10, 8 ),
				visible: true
			} );
	}

	concealTracePointInfobox() {
		this.tracePointInfobox.setOptions( { visible: false } );
	}

	getCurrentCenter(): Microsoft.Maps.Location {
		return this.bingMap.getCenter();
	}

	requestFullscreen() {
		if ( fscreen.fullscreenEnabled ) {
			const mapContainer = document.getElementById( MapContainerElementId );

			fscreen.requestFullscreen( mapContainer );
		}
	}

	resetView() {
		this.setView( this.map.CenterLatitude, this.map.CenterLongitude );
	}

	setView( latitude: number, longitude: number ) {
		this.bingMap.setView( {
			mapTypeId: Microsoft.Maps.MapTypeId.aerial,
			center: new Microsoft.Maps.Location( latitude, longitude ),
			zoom: 15
		} );
	}

	updateAutoLogoffTimer() {
	}
}
