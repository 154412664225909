<div class="ad-versions-container">
	<div class="ad-versions">
		<mat-accordion class="headers-align">
			<mat-expansion-panel #versionPanel *ngFor="let adVersion of adVersions$ | async" (opened)=opened(adVersion)
				(closed)="closed(adVersion)">
				<mat-expansion-panel-header>
					<mat-panel-title>
						<mat-icon class="material-icons">line_style</mat-icon>
						<span class="panel-title-text">{{adVersion.Name}}</span>
					</mat-panel-title>
					<mat-panel-description>
						<span *ngIf="!versionPanel.expanded" class="ad-version-description">
							{{adVersion.Number}}
							<span class="fill-remaining-space"></span>
							<ct-ad-manager-versions-actions [adVersion]="adVersion" (activate)="activate(adVersion)"
								(copy)="copy(adVersion)" (delete)="delete(adVersion)" (edit)="edit(adVersion)"
								(publish)="publish(adVersion)"></ct-ad-manager-versions-actions>
						</span>

						<button mat-icon-button matTooltip="New Set"
							*ngIf="!published && versionPanel.expanded && !editMode" (click)="newSet($event)">
							<mat-icon class="material-icons">note_add</mat-icon>
						</button>

						<span class="fill-remaining-space"></span>

						<button mat-icon-button matTooltip="Save"
							*ngIf="!published && editMode && versionPanel.expanded && editForm.dirty && editForm.valid"
							(click)="rename($event)">
							<mat-icon class="material-icons">save</mat-icon>
						</button>
						<button mat-icon-button matTooltip="Clear"
							*ngIf="!published && editMode && versionPanel.expanded && editForm.dirty"
							(click)="reset($event)">
							<mat-icon class="material-icons">clear</mat-icon>
						</button>
						<button mat-icon-button matTooltip="Edit Version" *ngIf="!published && !versionPanel.expanded"
							(click)="edit(adVersion)">
							<mat-icon class="material-icons">edit</mat-icon>
						</button>
					</mat-panel-description>
				</mat-expansion-panel-header>
				<div *matExpansionPanelContent>
					<form *ngIf="editMode" [formGroup]="editForm" (ngSubmit)="rename()">
						<mat-form-field>
							<input matInput type="text" placeholder="Version Name" formControlName="name"
								[errorStateMatcher]="nameErrorMatcher">
							<mat-error>You must enter a value</mat-error>
						</mat-form-field>
					</form>
					<ct-ad-manager-sets #setsComponent *ngIf="!editMode" [adSets]="adSets$ | async"
						[courses]="courses$ | async" [selected]="selectedAdSet$ | async" [published]="published"
						[adImageType]="selectedAdImageType" (listImages)="onListImages($event)"
						(clearImages)="onClearImages($event)" [adVersionId]="selected?.AdVersionID">
					</ct-ad-manager-sets>
				</div>
			</mat-expansion-panel>
		</mat-accordion>
	</div>
	<div cdkDropList #adImageDropList="cdkDropList" class="ad-images" [cdkDropListConnectedTo]="[adDropList]">
		<mat-card cdkDrag matTooltip="Create a new Ad by dragging an image to Ad list" id="{{adImage.AdImageID}}"
			*ngFor="let adImage of adImages$ | async">
			<img cdkDragHandle class="ad-image" src="data:image/PNG;base64,{{adImage.Image}}"
				[style.height]="imageHeight() +'px'" />
			<mat-card-header>
				<mat-card-subtitle>
					{{adImage.Name.substr(0,16)}}
				</mat-card-subtitle>
			</mat-card-header>
			<mat-card *cdkDragPlaceholder>
				<img class="ad-image" src="data:image/PNG;base64,{{adImage.Image}}"
					[style.height]="imageHeight() +'px'" />
				<mat-card-header>
					<mat-card-subtitle>
						{{adImage.Name.substr(0,16)}}
					</mat-card-subtitle>
				</mat-card-header>
			</mat-card>
			<img *cdkDragPreview class="ad-image" src="data:image/PNG;base64,{{adImage.Image}}"
				[style.height]="imageHeight() +'px'" />
		</mat-card>

	</div>
</div>