import { Action } from '@ngrx/store';

import { WebApiError } from '../shared/web-api/web-api-error';
import { Mode, Model } from '../device-manager/devices/devices.model';
import { Facility } from '../facilities/facilities.model';

export const BUILD = '[System] Build';
export const BUILD_SUCCESS = '[System] Build Success';
export const BUILD_FAIL = '[System] Build Fail';
export const MODELS_LOAD = '[System] Models Load';
export const MODELS_LOAD_FAIL = '[System] Models Load Fail';
export const MODELS_LOAD_SUCCESS = '[System] Models Load Success';
export const MODES_LOAD = '[System] Modes Load';
export const MODES_LOAD_FAIL = '[System] Modes Load Fail';
export const MODES_LOAD_SUCCESS = '[System] Modes Load Success';
export const PICK_FACILITY = '[System] Pick Facility';
export const PICK_MODE = '[System] Pick Mode';
export const PICK_MODEL = '[System] Pick Model';

export class Build implements Action {
	readonly type = BUILD;
}

export class BuildFail implements Action {
	readonly type = BUILD_FAIL;

	constructor( public error: WebApiError ) { }
}

export class BuildSuccess implements Action {
	readonly type = BUILD_SUCCESS;
}

export class ModelsLoad implements Action {
	readonly type = MODELS_LOAD;
}

export class ModelsLoadFail implements Action {
	readonly type = MODELS_LOAD_FAIL;

	constructor( public error: WebApiError ) { }
}

export class ModelsLoadSuccess implements Action {
	readonly type = MODELS_LOAD_SUCCESS;

	constructor( public models: Model[] ) { }
}

export class ModesLoad implements Action {
	readonly type = MODES_LOAD;
}

export class ModesLoadFail implements Action {
	readonly type = MODES_LOAD_FAIL;

	constructor( public error: WebApiError ) { }
}

export class ModesLoadSuccess implements Action {
	readonly type = MODES_LOAD_SUCCESS;

	constructor( public modes: Mode[] ) { }
}

export class PickFacility implements Action {
	readonly type = PICK_FACILITY;

	constructor( public facility: Facility ) { }
}

export class PickMode implements Action {
	readonly type = PICK_MODE;

	constructor( public mode: Mode ) { }
}

export class PickModel implements Action {
	readonly type = PICK_MODEL;

	constructor( public model: Model ) { }
}

export type SystemAction
	= Build
	| BuildFail
	| BuildSuccess
	| ModelsLoad
	| ModelsLoadFail
	| ModelsLoadSuccess
	| ModesLoad
	| ModesLoadFail
	| ModesLoadSuccess
	| PickFacility
	| PickMode
	| PickModel;
