import { NgModule } from '@angular/core';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { CoursesEffects } from './courses.effects';
import { reducer } from './courses.reducer';

import { CartTracAngularModule } from '../cart-trac-angular.module';
import { CartTracMaterialModule } from '../cart-trac-material.module';

import { DialogModule } from '../shared/dialogs/dialog.module';
import { PipesModule } from '../shared/utils/pipes/pipes.module';
import { UtilsModule } from '../shared/utils/utils.module';

import { CoursesRoutingModule } from './courses-routing.module';
import { CoursesComponent } from './courses.component';
import { CoursesService } from './courses.service';
import { CoursesTabsComponent } from './courses-tabs/courses-tabs.component';
import { CoursesHolesListComponent } from './courses-holes-list/courses-holes-list.component';
import { CoursesHolesActionsComponent } from './courses-holes-actions/courses-holes-actions.component';
import { CoursesHolesTabsComponent } from './courses-holes-tabs/courses-holes-tabs.component';

@NgModule( {
	declarations: [
		CoursesComponent,
		CoursesTabsComponent,
		CoursesHolesListComponent,
		CoursesHolesActionsComponent,
		CoursesHolesTabsComponent
	],
	exports: [
		CoursesComponent
	],
	imports: [
		CartTracAngularModule,
		CartTracMaterialModule,

		CoursesRoutingModule,

		DialogModule,
		PipesModule,
		UtilsModule,

		StoreModule.forFeature( 'courses', reducer ),
		EffectsModule.forFeature( [CoursesEffects] )
	],
	providers: [CoursesService]
} )
export class CoursesModule { }
