import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { AdImageUploadParameters } from '../../images.model';
import { Facility } from '../../../../facilities/facilities.model';

@Component( {
	selector: 'ct-ads-images-upload',
	templateUrl: './upload.component.html',
	styleUrls: ['./upload.component.css']
} )
export class UploadComponent implements OnInit {
	folderName: string;

	constructor(
		public dialogRef: MatDialogRef<UploadComponent>,
		@Inject( MAT_DIALOG_DATA ) private data: Facility ) { }

	ngOnInit() {
		this.folderName = `C:\\CartTrac\\FTPFiles\\${this.data.FileRoot.trim()}\\ImagesImport`;
	}

	import() {
		this.dialogRef.close( new AdImageUploadParameters( this.data.Id, this.folderName ) );
	}

	close() {
		this.dialogRef.close();
	}
}
