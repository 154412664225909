<mat-toolbar color="secondary">Terminal Command</mat-toolbar>

<p>
    <span>Number: {{deviceNumber}}, Device ID: {{terminalId}}</span>
</p>

<form (ngSubmit)="send()" #commandForm="ngForm">

  <mat-form-field>
    <input matInput autocomplete="off" autocapitalize="characters" placeholder="Command" type="text" required mdMaxLength="50" [(ngModel)]="commandString" name="command" />
  </mat-form-field>
    <button mat-raised-button type="submit" [disabled]="!commandForm.form.valid">Send</button>
</form>

<div class="terminal-response">
    {{terminalResponse}}
</div>

<button mat-raised-button (click)="dialogRef.close()">Close</button>
