import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DialogService } from '../../../shared/dialogs/dialog.service';
import { WebApiError } from '../../../shared/web-api/web-api-error';
import { TrackersService } from '../trackers.service';
import { TerminalCommand } from '../trackers-terminal-command.model';
import { TerminalResponse } from '../trackers-terminal-response.model';

@Component( {
	selector: 'ct-trackes-command',
	templateUrl: './command.component.html',
	styleUrls: ['./command.component.css']
} )
export class TrackersCommandComponent implements OnInit {
	deviceNumber: string;
	commandString: string;
	connectionIndex: number;
	terminalId: string;
	terminalResponse: string;

	constructor(
		@Inject( MAT_DIALOG_DATA )
		public data: { deviceNumber: string, connectionIndex: number, terminalId: string },
		public dialogRef: MatDialogRef<TrackersCommandComponent>,
		private trackersService: TrackersService,
		private dialogService: DialogService
	) { }

	ngOnInit() {
		this.deviceNumber = this.data.deviceNumber;
		this.connectionIndex = this.data.connectionIndex;
		this.terminalId = this.data.terminalId;
	}

	send() {
		const terminalCommand: TerminalCommand = {
			ConnectionIndex: this.data.connectionIndex,
			Command: this.commandString.toUpperCase(),
			TimeStamp: new Date()
		};

		this.terminalResponse = '';

		this.trackersService.command( terminalCommand )
			.subscribe( ( response: TerminalResponse ) => this.terminalResponse = response.Response,
				( error: WebApiError ) => this.dialogService.webApiError( 'TrackersService.command()', error ) );

		this.commandString = '';
	}
}
