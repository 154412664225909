<div class="angular-editor-toolbar" *ngIf="showToolbar">
	<div class="angular-editor-toolbar-set">
		<button [hidden]="true" [id]="'undo-'+id" type="button" title="Undo" class="angular-editor-button" (click)="triggerCommand('undo')">
			<mat-icon [inline]="true" class="material-icons">undo</mat-icon>
		</button>
		<button [hidden]="true" [id]="'redo-'+id" type="button" title="Redo" class="angular-editor-button" (click)="triggerCommand('redo')">
			<mat-icon [inline]="true" class="material-icons">redo</mat-icon>
		</button>
	</div>

	<div class="angular-editor-toolbar-set">
		<button [id]="'bold-'+id" type="button" title="Bold" class="angular-editor-button" (click)="triggerCommand('bold')" [disabled]="htmlMode">
			<mat-icon [inline]="true" class="material-icons">format_bold</mat-icon>
		</button>
		<button [id]="'italic-'+id" type="button" title="Italic" class="angular-editor-button" (click)="triggerCommand('italic')"
		 [disabled]="htmlMode">
			<mat-icon [inline]="true" class="material-icons">format_italic</mat-icon>
		</button>
		<button [hidden]="true" [id]="'underline-'+id" type="button" title="Underline" class="angular-editor-button" (click)="triggerCommand('underline')"
		 [disabled]="htmlMode">
			<mat-icon [inline]="true" class="material-icons">format_underline</mat-icon>
		</button>
		<button [hidden]="true" [id]="'strikeThrough-'+id" type="button" title="Strikethrough" class="angular-editor-button" (click)="triggerCommand('strikeThrough')"
		 [disabled]="htmlMode">
			<mat-icon [inline]="true" class="material-icons">format_strikethrough</mat-icon>
		</button>
		<button [hidden]="true" [id]="'subscript-'+id" type="button" title="Subscript" class="angular-editor-button" (click)="triggerCommand('subscript')"
		 [disabled]="htmlMode">
			<mat-icon [inline]="true" class="material-icons">redo</mat-icon>
		</button>
		<button [hidden]="true" [id]="'superscript-'+id" type="button" title="Superscript" class="angular-editor-button" (click)="triggerCommand('superscript')"
		 [disabled]="htmlMode">
			<mat-icon [inline]="true" class="material-icons">redo</mat-icon>
		</button>
	</div>

	<div class="angular-editor-toolbar-set">
		<button [id]="'justifyLeft-'+id" type="button" title="Justify Left" class="angular-editor-button" (click)="triggerCommand('justifyLeft')"
		 [disabled]="htmlMode">
			<mat-icon [inline]="true" class="material-icons">format_align_left</mat-icon>
		</button>
		<button [id]="'justifyCenter-'+id" type="button" title="Justify Center" class="angular-editor-button" (click)="triggerCommand('justifyCenter')"
		 [disabled]="htmlMode">
			<mat-icon [inline]="true" class="material-icons">format_align_center</mat-icon>
		</button>
		<button [id]="'justifyRight-'+id" type="button" title="Justify Right" class="angular-editor-button" (click)="triggerCommand('justifyRight')"
		 [disabled]="htmlMode">
			<mat-icon [inline]="true" class="material-icons">format_align_right</mat-icon>
		</button>
		<button [id]="'justifyFull-'+id" type="button" title="Justify Full" class="angular-editor-button" (click)="triggerCommand('justifyFull')"
		 [disabled]="htmlMode">
			<mat-icon [inline]="true" class="material-icons">format_align_justify</mat-icon>
		</button>
	</div>

	<div class="angular-editor-toolbar-set">
		<button [id]="'indent-'+id" type="button" title="Indent" class="angular-editor-button" (click)="triggerCommand('indent')"
		 [disabled]="htmlMode">
			<mat-icon [inline]="true" class="material-icons">format_indent_increase</mat-icon>
		</button>
		<button [id]="'outdent-'+id" type="button" title="Outdent" class="angular-editor-button" (click)="triggerCommand('outdent')"
		 [disabled]="htmlMode">
			<mat-icon [inline]="true" class="material-icons">format_indent_decrease</mat-icon>
		</button>
	</div>

	<div class="angular-editor-toolbar-set">
		<button [id]="'insertUnorderedList-'+id" type="button" title="Unordered List" class="angular-editor-button" (click)="triggerCommand('insertUnorderedList')"
		 [disabled]="htmlMode">
			<mat-icon [inline]="true" class="material-icons">format_list_bulleted</mat-icon>
		</button>
		<button [id]="'insertOrderedList-'+id" type="button" title="Ordered List" class="angular-editor-button" (click)="triggerCommand('insertOrderedList')"
		 [disabled]="htmlMode">
			<mat-icon [inline]="true" class="material-icons">format_list_numbered</mat-icon>
		</button>
	</div>

	<div class="angular-editor-toolbar-set">
		<label [for]="'heading-'+id" class="block-label"></label>
		<select [hidden]="true" title="Formatting" [id]="'heading-'+id" class="select-heading" [(ngModel)]="block" (change)="triggerCommand(block)"
		 [disabled]="htmlMode">
			<optgroup label="Formatting"></optgroup>
			<option class="h1" value="h1">Heading 1</option>
			<option class="h2" value="h2">Heading 2</option>
			<option class="h3" value="h3">Heading 3</option>
			<option class="h4" value="h4">Heading 4</option>
			<option class="h5" value="h5">Heading 5</option>
			<option class="h6" value="h6">Heading 6</option>
			<option class="p" value="p">Paragraph</option>
			<option class="pre" value="pre">Predefined</option>
			<option class="div" value="div">Standard</option>
			<option class="default" value="default">Default</option>
		</select>
	</div>

	<div class="angular-editor-toolbar-set">
		<label [for]="'fontSelector-'+id" class="block-label"></label>
		<select title="Font Name" [id]="'fontSelector-'+id" class="select-font" [(ngModel)]="fontName" (change)="setFontName(fontName)"
		 [disabled]="htmlMode">
			<optgroup label="Font Name"></optgroup>
			<option class="arial" value="Arial">Arial</option>
			<option class="calibri" value="Calibri">Calibri</option>
			<option class="comic-sans-ms" value="Comic Sans MS">Comic Sans MS</option>
			<option class="times-new-roman" value="Times New Roman">Times New Roman</option>
		</select>
		<label [for]="'fontSizeSelector-'+id" class="block-label">
		</label>
		<select title="Font Size" [id]="'fontSizeSelector-'+id" class="select-font-size" [(ngModel)]="fontSize" (change)="setFontSize(fontSize)"
		 [disabled]="htmlMode">
			<optgroup label="Font Sizing"></optgroup>
			<option class="size1" value="1">1</option>
			<option class="size2" value="2">2</option>
			<option class="size3" value="3">3</option>
			<option class="size4" value="4">4</option>
			<option class="size5" value="5">5</option>
			<option class="size6" value="6">6</option>
			<option class="size7" value="7">7</option>
		</select>
	</div>

	<div class="angular-editor-toolbar-set">
		<input style="display: none" type="color" (change)="insertColor(fgInput.value, 'textColor')" #fgInput>
		<button [id]="'foregroundColorPicker-'+id" type="button" class="angular-editor-button" (click)="fgInput.click()" title="Text Color"
		 [disabled]="htmlMode">
			<span class="color-label foreground">
				<mat-icon [inline]="true" class="material-icons">format_color_text</mat-icon>
			</span>
		</button>
		<input style="display: none" type="color" (change)="insertColor(bgInput.value, 'backgroundColor')" #bgInput>
		<button [hidden]="true" [id]="'backgroundColorPicker-'+id" type="button" class="angular-editor-button" (click)="bgInput.click()"
		 title="Background Color" [disabled]="htmlMode">
			<span class="color-label background">
				<mat-icon [inline]="true" class="material-icons">format_color_fill</mat-icon>
			</span>
		</button>
	</div>

	<div *ngIf="customClasses" class="angular-editor-toolbar-set">
		<label [for]="'customClassSelector-'+id" class="block-label"></label>
		<select [hidden]="true" title="Custom Style" [id]="'customClassSelector-'+id" class="select-custom-style" [(ngModel)]="customClassId"
		 (change)="setCustomClass(customClassId)" [disabled]="htmlMode">
			<optgroup label="Custom Class"></optgroup>
			<option class="" value=-1>Clear Class</option>
			<option *ngFor="let item of customClasses; let i = index" [class]="item.class" [value]="i">{{item.name}}</option>
		</select>
	</div>

	<div class="angular-editor-toolbar-set">
		<button [hidden]="true" [id]="'link-'+id" type="button" class="angular-editor-button" (click)="insertUrl()" title="Insert Link"
		 [disabled]="htmlMode">
			<mat-icon [inline]="true" class="material-icons">link</mat-icon>
		</button>
		<button [hidden]="true" [id]="'unlink-'+id" type="button" class="angular-editor-button" (click)="triggerCommand('unlink')"
		 title="Unlink" [disabled]="htmlMode">
			<mat-icon [inline]="true" class="material-icons">link_off</mat-icon>
		</button>
		<input style="display: none" type="file" (change)="onFileChanged($event)" #fileInput>
		<button [hidden]="true" [id]="'photo-'+id" type="button" class="angular-editor-button" (click)="fileInput.click()" title="Insert Image"
		 [disabled]="htmlMode">
			<mat-icon [inline]="true" class="material-icons">insert_photo</mat-icon>
		</button>
		<button [hidden]="true" [id]="'line-'+id" type="button" title="Horizontal Line" class="angular-editor-button" (click)="triggerCommand('insertHorizontalRule')"
		 [disabled]="htmlMode">
			<mat-icon [inline]="true" class="material-icons">remove</mat-icon>
		</button>
	</div>

	<div class="angular-editor-toolbar-set">
		<button [id]="'clear-'+id" type="button" title="Clear Formatting" class="angular-editor-button" (click)="triggerCommand('removeFormat')"
		 [disabled]="htmlMode">
			<mat-icon [inline]="true" class="material-icons">clear</mat-icon>
		</button>
	</div>
	<div class="angular-editor-toolbar-set">
		<button [id]="'toggleEditorMode-'+id" type="button" title="HTML Code" class="angular-editor-button" (click)="triggerCommand('toggleEditorMode')">
			<mat-icon [inline]="true" class="material-icons">code</mat-icon>
		</button>
	</div>
</div>