import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import * as TrackersActions from './trackers.actions';
import * as RoutingActions from '../../routing/routing.actions';
import * as fromCartTrac from '../../cart-trac.reducers';

import { TrackersService } from './trackers.service';
import { Tracker } from './trackers-terminal-connection.model';
import { DialogService } from '../../shared/dialogs/dialog.service';
import { WebApiError } from '../../shared/web-api/web-api-error';

@Injectable()
export class TrackersEffects {

	@Effect()
	list$ = this.actions$.pipe(
		ofType( TrackersActions.LIST ),
		map( ( action: TrackersActions.List ) => action.facilityId ),
		switchMap( facilityId => this.trackersService.list( facilityId ).pipe(
			map( ( trackers: Tracker[] ) => new TrackersActions.ListSuccess( trackers ) ),
			catchError( ( error: WebApiError ) => of( new TrackersActions.ListFail( error ) ) )
		) )
	);

	@Effect( { dispatch: false } )
	fail$ = this.actions$.pipe(
		ofType( TrackersActions.LIST_FAIL ),
		tap( ( action: TrackersActions.ListFail ) =>
			this.dialogService.webApiError( `TrackersEffects - ${action.type}`, action.error ).subscribe(
				() => this.store$.dispatch( new RoutingActions.Go( { path: ['/login'] } ) )
			)
		)
	);

	constructor(
		private actions$: Actions,
		private store$: Store<fromCartTrac.State>,
		private dialogService: DialogService,
		private trackersService: TrackersService
	) { }
}
