<mat-toolbar mat-dialog-title color="secondary">Edit Device #{{data.device.Number}} | {{data.device.DeviceId}}
</mat-toolbar>

<mat-dialog-content>

	<ct-mode-picker [label]="true" [modeId]="modeId" (select)="onModeChange($event)"></ct-mode-picker>

	<form novalidate [formGroup]="deviceForm">
		<mat-form-field hintLabel="3 digits" class="number">
			<input matInput placeholder="Number" type="text" formControlName="number" />
		</mat-form-field>

		<mat-form-field hintLabel="Max 25 characters">
			<input matInput placeholder="Name" type="text" formControlName="name" />
		</mat-form-field>
	</form>

	<div class=download-settings>
		<p><em>Download Settings</em></p>
		<mat-card-content>
			<mat-slide-toggle [(ngModel)]="firmwareEnabled" (change)="onDownloadChange($event)">Firmware
			</mat-slide-toggle>
			<mat-slide-toggle [(ngModel)]="configEnabled" (change)="onDownloadChange($event)">Config</mat-slide-toggle>
			<mat-slide-toggle [(ngModel)]="adsEnabled" (change)="onDownloadChange($event)">Ads</mat-slide-toggle>
			<mat-slide-toggle [(ngModel)]="zonesEnabled" (change)="onDownloadChange($event)">Zones</mat-slide-toggle>
		</mat-card-content>
	</div>

	<div class=color-settings>
		<p><em>Color Settings</em></p>
		<input type="color" #fillColorPicker [value]="fillColor" [hidden]="true" (change)="onFillColorChange($event)" />
		<button mat-mini-fab matTooltip="Fill Color" color="white" class="color-picker"
			(click)="fillColorPicker.click()">
			<mat-icon [style.color]="fillColor">format_color_fill</mat-icon>
		</button>

		<input type="color" #textColorPicker [value]="textColor" [hidden]="true" (change)="onTextColorChange($event)" />
		<button mat-mini-fab matTooltip="Text Color" color="white" class="color-picker"
			(click)="textColorPicker.click()">
			<mat-icon [style.color]="textColor">text_format</mat-icon>
		</button>

		<input type="color" #borderColorPicker [value]="borderColor" [hidden]="true"
			(change)="onBorderColorChange($event)" />
		<button mat-mini-fab matTooltip="Border Color" color="white" class="color-picker"
			(click)="borderColorPicker.click()">
			<mat-icon [style.color]="borderColor">border_outer</mat-icon>
		</button>
		<img src="{{deviceIMG}}" />
	</div>

</mat-dialog-content>

<mat-dialog-actions>
	<button mat-raised-button (click)="dialogRef.close()">Close</button>
	<button mat-raised-button mat-primary (click)="updateDevice()" [disabled]="disabled">Save</button>
</mat-dialog-actions>