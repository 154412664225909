import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Facility } from '../../../../facilities/facilities.model';
import { FileService } from '../../../../shared/utils/file.service';
import { Device } from '../../devices.model';

@Component( {
	selector: 'ct-devices-actions-bulk-download',
	templateUrl: './download.component.html',
	styleUrls: ['./download.component.css']
} )
export class DownloadComponent implements OnInit {

	fileName = 'devices';
	done = false;
	line1: string;
	line2: string;

	constructor(
		public dialogRef: MatDialogRef<DownloadComponent>,
		@Inject( MAT_DIALOG_DATA ) public data: { facility: Facility, devices: Device[] },
		private fileService: FileService
	) { }

	ngOnInit() {
	}

	download() {
		this.line1 = 'Downloading...';

		this.fileService.saveToExcel( this.data.devices.map( device => {
			return {
				Number: device.Number,
				DeviceId: device.DeviceId,
				ModeId: device.ModeId,
				Model: device.ModelName,
				Name: device.Name
			};
		}

		), this.fileName, this.data.facility.Name );

		this.done = true;
		this.line2 = 'DONE.';
	}
}
