import { NgModule } from '@angular/core';

import { CartTracAngularModule } from '../cart-trac-angular.module';
import { CartTracMaterialModule } from '../cart-trac-material.module';
import { AdManagerRoutingModule } from './ad-manager-routing.module';

import { AdManagerComponent } from './ad-manager.component';
import { ImagesModule } from './images/images.module';
import { ModelPickerModule } from '../shared/pickers/model-picker/model-picker.module';
import { VersionsModule } from './versions/versions.module';

@NgModule( {
	declarations: [
		AdManagerComponent
	],
	imports: [
		CartTracAngularModule,
		CartTracMaterialModule,
		AdManagerRoutingModule,
		ImagesModule,
		ModelPickerModule,
		VersionsModule
	],
	exports: [
		AdManagerComponent
	]
} )
export class AdManagerModule { }
