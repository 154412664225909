import { NgModule } from '@angular/core';
import { CartTracAngularModule } from '../../../cart-trac-angular.module';
import { CartTracMaterialModule } from '../../../cart-trac-material.module';

import { AdImageTypesComponent } from './ad-image-type-picker.component';

@NgModule( {
	declarations: [
		AdImageTypesComponent
	],
	exports: [
		AdImageTypesComponent
	],
	imports: [
		CartTracAngularModule,
		CartTracMaterialModule
	]
} )
export class AdImageTypePickerModule { }
