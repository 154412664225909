import * as AdminActions from './admin.actions';

import { Map } from '../facilities/facilities.model';
import { AdImageType, AdImageTypes } from '../ad-manager/images/images.model';
import { Model } from '../device-manager/devices/devices.model';

export interface State {
	map: Map;
	progress: boolean;
	selectedAdImageType: AdImageType;
	selectedUnitModel: Model;
}

const initialState: State = {
	map: null,
	progress: false,
	selectedAdImageType: AdImageTypes[0],
	selectedUnitModel: null
};

export function reducer( state = initialState, action: AdminActions.AdminAction ): State {
	switch ( action.type ) {
		case AdminActions.MAP_GET:
			return { ...state, progress: true };

		case AdminActions.MAP_GET_SUCCESS:
			return { ...state, progress: false, map: action.payload };

		case AdminActions.MAP_GET_FAIL:
			return initialState;

		case AdminActions.SELECT_AD_IMAGE_TYPE:
			return { ...state, selectedAdImageType: action.adImageType };

		case AdminActions.SELECT_UNIT_MODEL:
			return { ...state, selectedUnitModel: action.unitModel };
		default:
			return state;
	}
}

export const getProgress = ( state: State ) => state.progress;
export const getMap = ( state: State ) => state.map;
export const getSelectedAdImageType = ( state: State ) => state.selectedAdImageType;
export const getSelectedUnitModel = ( state: State ) => state.selectedUnitModel;
