import { Action } from '@ngrx/store';
import { EntityUpdate } from '../../shared/utils/ngrx';

import { WebApiError } from '../../shared/web-api/web-api-error';
import { RecurringMessage, RecurringMessageSetActiveParameters } from './recurring-messages.model';

export const DELETE = '[Admin]Recurring Messages Delete';
export const DELETE_FAIL = '[Admin] Recurring Messages Delete Fail';
export const DELETE_SUCCESS = '[Admin] Recurring Messages Delete Success';
export const LIST = '[Admin] Recurring Messages List';
export const LIST_FAIL = '[Admin] Recurring Messages List Fail';
export const LIST_SUCCESS = '[Admin] Recurring Messages List Success';
export const NEW = '[Admin] Recurring Messages New';
export const NEW_FAIL = '[Admin] Recurring Messages New Fail';
export const NEW_SUCCESS = '[Admin ]Recurring Messages New Success';
export const SEND = '[Admin] Recurring Messages Send';
export const SEND_FAIL = '[Admin] Recurring Messages Send Fail';
export const SEND_SUCCESS = '[Admin ]Recurring Messages Send Success';
export const SELECT = '[Admin] Recurring Messages Select';
export const SET_ACTIVE = '[Admin] Recurring Messages Set Active';
export const SET_ACTIVE_FAIL = '[Admin] Recurring Messages Set Active Fail';
export const SET_ACTIVE_SUCCESS = '[Admin ]Recurring Messages Set Active Success';
export const UPDATE = '[Admin] Recurring Messages Update';
export const UPDATE_FAIL = '[Admin] Recurring Messages Update Fail';
export const UPDATE_SUCCESS = '[Admin ]Recurring Messages Update Success';

export class Delete implements Action {
	readonly type = DELETE;

	constructor( public id: number ) { }
}

export class DeleteFail implements Action {
	readonly type = DELETE_FAIL;

	constructor( public error: WebApiError ) { }
}

export class DeleteSuccess implements Action {
	readonly type = DELETE_SUCCESS;
}

export class List implements Action {
	readonly type = LIST;

	constructor( public facilityId: number ) { }
}

export class ListFail implements Action {
	readonly type = LIST_FAIL;

	constructor( public error: WebApiError ) { }
}

export class ListSuccess implements Action {
	readonly type = LIST_SUCCESS;

	constructor( public recurringMessages: RecurringMessage[] ) { }
}

export class New implements Action {
	readonly type = NEW;

	constructor( public recurringMessage: RecurringMessage ) { }
}

export class NewFail implements Action {
	readonly type = NEW_FAIL;

	constructor( public error: WebApiError ) { }
}

export class NewSuccess implements Action {
	readonly type = NEW_SUCCESS;

	constructor( public recurringMessage: RecurringMessage ) { }
}

export class Select implements Action {
	readonly type = SELECT;

	constructor( public recurringMessage: RecurringMessage ) { }
}

export class Send implements Action {
	readonly type = SEND;

	constructor( public id: number ) { }
}

export class SendFail implements Action {
	readonly type = SEND_FAIL;

	constructor( public error: WebApiError ) { }
}

export class SendSuccess implements Action {
	readonly type = SEND_SUCCESS;

	constructor( public count: number ) { }
}

export class SetActive implements Action {
	readonly type = SET_ACTIVE;

	constructor( public parameters: RecurringMessageSetActiveParameters ) { }
}

export class SetActiveFail implements Action {
	readonly type = SET_ACTIVE_FAIL;

	constructor( public error: WebApiError ) { }
}

export class SetActiveSuccess implements Action {
	readonly type = SET_ACTIVE_SUCCESS;

	constructor( public parameters: RecurringMessageSetActiveParameters ) { }
}

export class Update implements Action {
	readonly type = UPDATE;

	constructor( public update: EntityUpdate<RecurringMessage> ) { }
}

export class UpdateFail implements Action {
	readonly type = UPDATE_FAIL;

	constructor( public error: WebApiError ) { }
}

export class UpdateSuccess implements Action {
	readonly type = UPDATE_SUCCESS;

	constructor( public recurringMessage: RecurringMessage ) { }
}

export type RecurringMessagesAction
	= Delete
	| DeleteFail
	| DeleteSuccess
	| List
	| ListFail
	| ListSuccess
	| New
	| NewFail
	| NewSuccess
	| Select
	| Send
	| SendFail
	| SendSuccess
	| SetActive
	| SetActiveFail
	| SetActiveSuccess
	| Update
	| UpdateFail
	| UpdateSuccess;
