import { Action } from '@ngrx/store';

export const FULLSCREEN = '[BingMap] Fullscreen';

export class Fullscreen implements Action {
	readonly type = FULLSCREEN;

	constructor( public payload: boolean ) { }
}

export type BingMapAction
	= Fullscreen;
