import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Course, Hole } from '../courses.model';
import { MatSelectChange } from '@angular/material/select';

@Component( {
	selector: 'ct-hole-selector',
	templateUrl: './hole-selector.component.html',
	styleUrls: ['./hole-selector.component.css']
} )
export class HoleSelectorComponent implements OnInit {

	@Input() courses: Course[];
	@Input() holes: Hole[];
	@Input() selected: number;
	@Output() selectionChange = new EventEmitter<number>();

	courseId: number;

	constructor() { }

	ngOnInit() {
		if ( !this.selected ) {
			this.courseId = this.courses[0].Id;
			this.selected = this.holes[0].Id;
			this.selectionChange.emit( this.holes[0].Id );
		} else
		{
			this.courseId = this.holes.find( hole => hole.Id === this.selected ).CourseId;
		}
	}

	holesByCourse( courseId: number ): Hole[] {
		return ( this.holes.filter( hole => hole.CourseId === courseId ) );
	}

	courseChange( event: MatSelectChange ) {
		this.selected = this.holesByCourse( event.value )[0].Id;
		this.selectionChange.emit( this.selected );
	}

	holeChange( event: MatSelectChange ) {
		this.selected = event.value;
		this.selectionChange.emit( this.selected );
	}
}
