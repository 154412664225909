import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { Model } from '../../../device-manager/devices/devices.model';
import { MatSelectChange } from '@angular/material/select';
import { Store } from '@ngrx/store';
import * as fromCartTrac from '../../../cart-trac.reducers';
import { Observable, Subscription } from 'rxjs';
import * as SystemAction from '../../../system/system.actions';

@Component({
	selector: 'ct-model-picker',
	templateUrl: './model-picker.component.html',
	styleUrls: [ './model-picker.component.css' ]
})
export class ModelPickerComponent implements OnDestroy, OnInit {
	@Input() label: boolean;
	@Input() screenOnly: boolean;
	@Input() selected: Model;
	@Input() modelId: number;
	// tslint:disable-next-line:no-output-native
	@Output() select = new EventEmitter<Model>();

	models$: Observable<Model[]>;
	picked$: Observable<Model>;

	models: Model[];

	private modelsSubscription: Subscription;
	private pickedSubscription: Subscription;

	constructor(private store$: Store<fromCartTrac.State>) {
		this.models$ = this.store$.select(fromCartTrac.getModels);
		this.picked$ = this.store$.select(fromCartTrac.getPickedModel);
	}

	ngOnInit() {
		this.modelsSubscription = this.models$
			.subscribe(models => {
				if (this.screenOnly)
					this.models = models.filter(model => model.ScreenHeight > 0 && model.ScreenWidth > 0);
				else
					this.models = models;

				if (!this.selected && this.models.length > 0) {
					let model: Model;

					if (this.modelId)
						model = this.models.find(m => m.Id === this.modelId);
					else
						model = this.models[ 0 ];


					this.select.emit(model);
					this.store$.dispatch(new SystemAction.PickModel(model));
				}
			});

		this.pickedSubscription = this.picked$
			.subscribe(model => {
				this.selected = model;
			});
	}

	selectionChange(event: MatSelectChange) {
		this.select.emit(event.value);
		this.store$.dispatch(new SystemAction.PickModel(event.value));
	}

	ngOnDestroy() {
		this.modelsSubscription.unsubscribe();
		this.pickedSubscription.unsubscribe();
	}
}
