import { TracePoint } from '../../trace/trace-point.model';
import { TrackingDevice } from '../../tracking/tracking-device.model';

export class BingMapEntities {
	static flagCanvas( flagColor: string, flagTextColor: string, holeLabel: string ): string {
		const canvas = document.createElement( 'canvas' );

		canvas.width = 28.28;
		canvas.height = 50.4;

		const context = canvas.getContext( '2d' );

		context.save();

		context.shadowColor = 'DarkSlateGray';
		context.shadowBlur = 3;
		context.shadowOffsetX = 2;
		context.shadowOffsetY = 2;

		context.beginPath();
		context.moveTo( 17.584, 0.24999973 );
		context.lineTo( 17.541986, 14.5625 );
		context.lineTo( 5.2919922, 15.5 );
		context.lineTo( 0.62532544, 15 );
		context.lineTo( 4.2919922, 10.75 );
		context.lineTo( 0.79199213, 6.25 );
		context.lineTo( 5.1255684, 5.1666665 );
		context.closePath();
		context.fillStyle = '#' + flagColor.substring( 2 );
		context.fill();

		context.beginPath();
		context.moveTo( 17.791992, 0 );
		context.lineTo( 18.791992, 0 );
		context.lineTo( 18.791992, 34 );
		context.lineTo( 17.791992, 34 );
		context.closePath();
		context.strokeStyle = '#' + flagColor.substring( 2 );
		context.lineWidth = 1;
		context.stroke();

		context.restore();
		context.fillStyle = '#' + flagTextColor.substring( 2 );
		context.font = 'bold 9px Arial';
		context.fillText( holeLabel.toString(), 11 - context.measureText( holeLabel ).width / 2, 12 );

		return canvas.toDataURL();
	}

	static teeCanvas( teeColor: string, teeTextColor: string, holeLabel: string ): string {
		const canvas = document.createElement( 'canvas' );

		canvas.width = 10;
		canvas.height = 55;

		const context = canvas.getContext( '2d' );

		context.save();

		context.shadowColor = 'DarkSlateGray';
		context.shadowBlur = 3;
		context.shadowOffsetX = 2;
		context.shadowOffsetY = 2;

		context.beginPath();
		context.moveTo( 5.00, 55.0 );
		context.lineTo( 3.16, 49.94 );
		context.lineTo( 3.16, 24.19 );
		context.lineTo( 0.17, 16.37 );
		context.lineTo( 0.17, 15.0 );
		context.lineTo( 5.00, 15.0 );
		context.lineTo( 9.82, 15.0 );
		context.lineTo( 9.82, 16.37 );
		context.lineTo( 6.83, 24.19 );
		context.lineTo( 6.83, 49.9 );
		context.lineTo( 5.00, 55.0 );
		context.closePath();
		context.fillStyle = '#' + teeColor.substring( 2 );
		context.fill();

		context.restore();
		context.fillStyle = '#' + teeTextColor.substring( 2 );
		context.font = 'bold 9px Arial';
		context.fillText( holeLabel.toString(), 4 - context.measureText( holeLabel ).width / 2, 12 );

		return canvas.toDataURL();
	}

	static deviceCanvas( cartNumber: string, textColor: string, borderColor: string, fillColor: string ): string {
		const height = 19;
		const width = 27;
		const borderRadius = 2;
		const borderWidth = 4;
		const x = 2;
		const y = 2;

		const canvas = document.createElement( 'canvas' );

		canvas.width = width + x + borderWidth / 2 + 1;
		canvas.height = height + y + borderWidth / 2 + 1;

		const context = canvas.getContext( '2d' );

		context.save();
		context.beginPath();
		context.moveTo( x, y + borderRadius );
		context.lineTo( x, y + height - borderRadius );
		context.arcTo( x, y + height - 1, x + borderRadius, y + height, borderRadius );
		context.lineTo( x + width - borderRadius, y + height );
		context.arcTo( x + width, y + height, x + width, y + height - borderRadius, borderRadius );
		context.lineTo( x + width, y + borderRadius );
		context.arcTo( x + width, y, x + width - borderRadius, y, borderRadius );
		context.lineTo( x + borderRadius, y );
		context.arcTo( x, y, x, y + borderRadius, borderRadius );
		context.strokeStyle = '#' + borderColor.substring( 2 );
		context.lineWidth = borderWidth;
		context.stroke();
		context.fillStyle = '#' + fillColor.substring( 2 );
		context.fill();
		context.restore();
		context.fillStyle = '#' + textColor.substring( 2 );
		context.font = 'bold 12px Arial';
		context.fillText( cartNumber, ( width - context.measureText( cartNumber ).width ) / 2 + x, 12 + borderWidth );

		return canvas.toDataURL();
	}

	static tracePointCanvas(): string {
		const canvas = document.createElement( 'canvas' );

		canvas.width = 10;
		canvas.height = 10;

		const context = canvas.getContext( '2d' );

		context.beginPath();
		context.arc( 5, 5, 5, 0, Math.PI * 2 );
		context.fillStyle = '#F7EE07';
		context.fill();

		return canvas.toDataURL();
	}

	static deviceInfoboxHTML( trackingDevice: TrackingDevice ): string {
		return `
				<div style="position:absolute; z-index: 202; background-color:White; border-style:solid; border-width:medium; border-radius:5px; border-color:DarkOrange; height:110px; width:195px;">
					<span style="position:absolute; top:8px; left:155px; font-size:12px;">
						<a style="position:relative; z-index:200;" href="javascript:window.BMCE.traceDevice( ${trackingDevice.Id} );" >Trace</a>
					</span>
					<span style="position:absolute; top:10px; left:10px; width:180px; font-size:12px; font-weight:bold;">Cart# ${trackingDevice.Number}</span>
					<span style="position:absolute; top:30px; left:20px; width:170px; font-size:12px;">Mode: ${trackingDevice.ModeName}</span>
					<span style="position:absolute; top:50px; left:20px; width:170px; font-size:12px;">Course: ${trackingDevice.Location}</span>
					<span style="position:absolute; top:70px; left:20px; width:170px; font-size:12px;">POP: ${trackingDevice.PaceOfPlay}</span>
					<span style="position:absolute; top:90px; left:20px; width:170px; font-size:12px;">Time: &nbsp;&nbsp;${trackingDevice.GPSTime.toString().replace( 'T', ' ' )}</span>
				</div>
				`;
	}

	static tracePointInfoboxHTML( tracePoint: TracePoint, intervalString ): string {
		return `
			<div style="position:absolute; z-index: 202; background-color:White; border-style:solid;border-width:medium; border-radius:5px; border-color:DarkOrange; height:20px; width:170px;">
			<span style="position:absolute; top:4px; left:10px; font-size:12px;">${tracePoint.TimeStamp.toString().replace( 'T', ' ' )}${intervalString}</span>
			</div>
		`;
	}
}
