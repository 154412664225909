import { Action } from '@ngrx/store';

import { WebApiError } from '../shared/web-api/web-api-error';
import { Facility, FacilitySettings, FacilitySettingsDTO } from './facilities.model';

export const LIST = '[Facilities] List';
export const LIST_FAIL = '[Facilities] List Fail';
export const LIST_SUCCESS = '[Facilities] List Success';
export const SELECTED = '[Facilities] Selected';
export const SETTINGS_SAVE = '[Facilities] Settings Save';
export const SETTINGS_SAVE_FAIL = '[Facilities] Settings Save Fail';
export const SETTINGS_SAVE_SUCCESS = '[Facilities] Settings Save Success';

export class List implements Action {
	readonly type = LIST;

	constructor( public loginId: number ) { }
}

export class ListFail implements Action {
	readonly type = LIST_FAIL;

	constructor( public webApiError: WebApiError ) { }
}

export class ListSuccess implements Action {
	readonly type = LIST_SUCCESS;

	constructor( public facilities: Facility[] ) { }
}

export class Selected implements Action {
	readonly type = SELECTED;

	constructor( public facility: Facility ) { }
}

export class SettingsSave implements Action {
	readonly type = SETTINGS_SAVE;

	// constructor( public payload: { facilityId: number, facilitySettings: FacilitySettings } ) { }
	constructor( public settings: FacilitySettingsDTO ) { }
}

export class SettingsSaveFail implements Action {
	readonly type = SETTINGS_SAVE_FAIL;

	constructor( public webApiError: WebApiError ) { }
}

export class SettingsSaveSuccess implements Action {
	readonly type = SETTINGS_SAVE_SUCCESS;

	constructor( public settingsDTO: FacilitySettingsDTO ) { }
}

export type FacilitiesAction
	= List
	| ListFail
	| ListSuccess
	| Selected
	| SettingsSave
	| SettingsSaveFail
	| SettingsSaveSuccess;
