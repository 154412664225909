import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { WebApiService } from '../shared/web-api/web-api.service';
import { Course, Hole } from './courses.model';
import { RangerData } from '../ranger/ranger-data.model';
import { WebApiError } from '../shared/web-api/web-api-error';

@Injectable()
export class CoursesService {
	public courses: Course[];

	private serviceBaseUrl: string;

	constructor( private webApiService: WebApiService ) {
		this.serviceBaseUrl = this.webApiService.serverUrl + '/api/courses';
	}

	list( facilityId: number ): Observable<Course[] | WebApiError> {
		return this.webApiService.get<Course[]>( this.serviceBaseUrl + '/' + facilityId );
	}

	holesList( facilityId: number ): Observable<Hole[] | WebApiError> {
		return this.webApiService.get<Hole[]>( this.serviceBaseUrl + '/holes/' + facilityId );
	}

	rangerData( facilityId: number ): Observable<RangerData[] | WebApiError> {
		return this.webApiService.get<RangerData[]>( this.serviceBaseUrl + '/rangerdata/' + facilityId );
	}

	holesLocationsNew( geoZonesVersionId: number ): Observable<number | WebApiError> {
		return this.webApiService.post( this.serviceBaseUrl + '/holeslocations/' + geoZonesVersionId, null );
	}
}
