<button mat-icon-button matTooltip="New Image" (click)="new()">
    <mat-icon class="material-icons">add</mat-icon>
</button>
<button mat-icon-button matTooltip="Delete Image" (click)="delete()" [disabled]="!selected">
    <mat-icon class="material-icons">remove</mat-icon>
</button>
<button mat-icon-button matTooltip="Copy Image" (click)="copy()" [disabled]="!selected">
    <mat-icon class="material-icons">content_copy</mat-icon>
</button>
<button mat-icon-button matTooltip="Sort by" [matMenuTriggerFor]="sortMenu" [disabled]="images.length < 2">
    <mat-icon class="material-icons">sort</mat-icon>
</button>
<mat-menu #sortMenu="matMenu">
    <button mat-menu-item (click)="sort(1)">Name</button>
    <button mat-menu-item (click)="sort(2)">File Name</button>
    <button mat-menu-item (click)="sort(3)">Date</button>
</mat-menu>