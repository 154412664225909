import { Component, Input, OnInit } from '@angular/core';

import { StatusOther } from './status-other.model';

@Component( {
	selector: 'ct-status-other',
	templateUrl: './status-other.component.html',
	styleUrls: ['./status-other.component.css']
} )
export class StatusOtherComponent implements OnInit {

	@Input() statusOther: StatusOther;

	constructor() { }

	ngOnInit() {
	}
}
