export class WebLogin {
	constructor(
		public Login: string,
		public Password: string,
		public IP: string
	) { }
}

export class WebLogoff {
	constructor(
		public Login: string
	) { }
}

export class WebUser {
	constructor(
		public Success: number,
		public Id: number,
		public Role: number,
		public FirstName: string,
		public LastName: string,
		public Settings: MobileSettings
	) { }
}

export class MobileSettingsDTO {
	constructor(
		public Id: number,
		public JSON: string
	) { }
}

export class MobileSettings {
	constructor(
		public Tracking: MobileSettingsTracking,
		public AutoLogoff: boolean,
		public DiagnosticMode: boolean
	) { }
}

export class MobileSettingsTracking {
	constructor(
		public Modes: string[],
		public ShowFlags: boolean,
		public ShowTees: boolean,
		public ShowLogo: boolean,
		public UseHoleName: boolean,
		public UsePOPColors: boolean
	) { }
}
