<form novalidate [formGroup]="loginForm" (ngSubmit)="onSubmit()">
	<div>
		<mat-form-field>
			<input matInput placeholder="Login" type="text" formControlName="login" autofocus />
		</mat-form-field>
	</div>
	<div>
		<mat-form-field>
			<input matInput placeholder="Password" type="password" formControlName="password" />
		</mat-form-field>
	</div>

	<button mat-raised-button type="submit" [disabled]="loginForm.invalid">LOGIN</button>
</form>