import { NgModule } from '@angular/core';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { SystemEffects } from './system.effects';
import { reducer } from './system.reducer';

import { CartTracAngularModule } from '../cart-trac-angular.module';
import { CartTracMaterialModule } from '../cart-trac-material.module';
import { SystemRoutingModule } from './system-routing.module';
import { UtilsModule } from '../shared/utils/utils.module';
import { StatusModule } from './status/status.module';

import { SystemComponent } from './system.component';
import { TwilioComponent } from './twilio/twilio.component';

import { SystemService } from './system.service';
import { TwilioService } from './twilio/twilio.service';

@NgModule( {
	declarations: [
		SystemComponent,
		TwilioComponent
	],
	imports: [
		CartTracAngularModule,
		CartTracMaterialModule,
		SystemRoutingModule,
		UtilsModule,
		StatusModule,

		StoreModule.forFeature( 'system', reducer ),
		EffectsModule.forFeature( [SystemEffects] )
	],
	providers: [TwilioService, SystemService]
} )
export class SystemModule { }
