import { Component, Input, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromCartTrac from '../../cart-trac.reducers';
import * as RecurringMessagesActions from './recurring-messages.actions';
import * as RoutingActions from '../../routing/routing.actions';

import { Facility } from '../../facilities/facilities.model';
import { RecurringMessage, RecurringMessageSetActiveParameters } from './recurring-messages.model';

@Component( {
	selector: 'ct-recurring-messages',
	templateUrl: './recurring-messages.component.html',
	styleUrls: ['./recurring-messages.component.css']
} )
export class RecurringMessagesComponent implements OnInit {
	@Input() facility: Facility;

	recurringMessages$: Observable<RecurringMessage[]>;
	selected$: Observable<RecurringMessage>;

	constructor( private store$: Store<fromCartTrac.State> ) {
		this.recurringMessages$ = this.store$.select( fromCartTrac.getRecurringMessages );
		this.selected$ = this.store$.select( fromCartTrac.getRecurringMessagesSelected );
	}

	ngOnInit() {
		if ( this.facility )
			this.store$.dispatch( new RecurringMessagesActions.List( this.facility.Id ) );
		else
			this.store$.dispatch( new RoutingActions.Go( { path: ['/login/'] } ) );
	}

	onDelete( id: number ) {
		this.store$.dispatch( new RecurringMessagesActions.Delete( id ) );
	}

	onNew( recurringMessage: RecurringMessage ) {
		this.store$.dispatch( new RecurringMessagesActions.New( recurringMessage ) );
	}

	onRefresh() {
		this.store$.dispatch( new RecurringMessagesActions.List( this.facility.Id ) );
	}
	onSelect( recurringMessage: RecurringMessage ) {
		this.store$.dispatch( new RecurringMessagesActions.Select( recurringMessage ) );
	}

	onSend( id: number ) {
		this.store$.dispatch( new RecurringMessagesActions.Send( id ) );
	}

	onSetActive( parameters: RecurringMessageSetActiveParameters ) {
		this.store$.dispatch( new RecurringMessagesActions.SetActive( parameters ) );
	}

	onUpdate( recurringMessage: RecurringMessage ) {
		this.store$.dispatch( new RecurringMessagesActions.Update( { id: recurringMessage.Id, update: recurringMessage } ) );
	}
}
