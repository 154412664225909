import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';

import { of, Observable } from 'rxjs';
import { catchError, concatMap, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import * as ImagesActions from './images.actions';
import * as RoutingActions from '../../routing/routing.actions';
import * as fromCartTrac from '../../cart-trac.reducers';

import { ImagesService } from './images.service';
import { DialogService } from '../../shared/dialogs/dialog.service';
import { AdImage, AdImageListParameters } from './images.model';
import { WebApiError } from '../../shared/web-api/web-api-error';

@Injectable()
export class ImagesEffects {

	@Effect()
	copy$ = this.actions$.pipe(
		ofType( ImagesActions.COPY ),
		map( ( action: ImagesActions.Copy ) => action.id ),
		switchMap( id => this.imagesService.copy( id ).pipe(
			map( ( adImage: AdImage ) => new ImagesActions.CopySuccess( adImage ) ),
			catchError( ( error: WebApiError ) => of( new ImagesActions.CopyFail( error ) ) )
		) ) );

	@Effect()
	delete$ = this.actions$.pipe(
		ofType( ImagesActions.DELETE ),
		map( ( action: ImagesActions.Delete ) => action.id ),
		switchMap( id => this.imagesService.delete( id ).pipe(
			map( () => new ImagesActions.DeleteSuccess() ),
			catchError( ( error: WebApiError ) => this.deleteFailHandler( error ) )
		) )
	);

	@Effect()
	download$ = this.actions$.pipe(
		ofType( ImagesActions.DOWNLOAD ),
		map( ( action: ImagesActions.Download ) => action.facilityId ),
		switchMap( facilityId => this.imagesService.download( facilityId ).pipe(
			tap( ( count: number ) => this.dialogService.alert( 'Export Images', `Exported ${count} Image(s)` ) ),
			map( count => new ImagesActions.DownloadSuccess( count ) ),
			catchError( ( error: WebApiError ) => of( new ImagesActions.DownloadFail( error ) ) )
		) )
	);

	@Effect()
	upload$ = this.actions$.pipe(
		ofType( ImagesActions.UPLOAD ),
		map( ( action: ImagesActions.Upload ) => action.parameters ),
		switchMap( importParameters => this.imagesService.upload( importParameters ).pipe(
			tap( ( count: number ) => this.dialogService.alert( 'Import Images', `Imported ${count} Image(s)` ) ),
			withLatestFrom( this.store$ ),
			concatMap( ( [count, state] ) => [
				new ImagesActions.UploadSuccess( count ),
				new ImagesActions.List( new AdImageListParameters( state.facilities.selected.Id, state.admin.selectedUnitModel.Id, state.admin.selectedAdImageType.Type ) )]
			),
			catchError( ( error: WebApiError ) => of( new ImagesActions.UploadFail( error ) ) )
		) )
	);

	@Effect()
	list$ = this.actions$.pipe(
		ofType( ImagesActions.LIST ),
		map( ( action: ImagesActions.List ) => action.parameters ),
		switchMap( listParameters => this.imagesService.list( listParameters ).pipe(
			concatMap( ( adImages: AdImage[] ) => [
				new ImagesActions.ListSuccess( adImages ),
				new ImagesActions.Sort( 1 )
			] ),
			catchError( ( error: WebApiError ) => of( new ImagesActions.ListFail( error ) ) )
		) )
	);

	@Effect()
	new$ = this.actions$.pipe(
		ofType( ImagesActions.NEW ),
		map( ( action: ImagesActions.New ) => action.adImage ),
		mergeMap( adImage => this.imagesService.new( adImage ).pipe(			// switchMap unsubscribes when second Observable arrives, that cancels the http request
			map( ( newImage: AdImage ) => new ImagesActions.NewSuccess( newImage ) ),
			catchError( ( error: WebApiError ) => of( new ImagesActions.NewFail( error ) ) )
		) )
	);

	@Effect( { dispatch: false } )
	newBatch$ = this.actions$.pipe(
		ofType( ImagesActions.NEW_BATCH ),
		map( ( action: ImagesActions.NewBatch ) => action.adImages ),
		map( ( adImages: AdImage[] ) => adImages.forEach( adImage => this.store$.dispatch( new ImagesActions.New( adImage ) ) ) )
	);

	@Effect()
	purge$ = this.actions$.pipe(
		ofType( ImagesActions.PURGE ),
		map( ( action: ImagesActions.Purge ) => action.count ),
		switchMap( facilityId => this.imagesService.purge( facilityId ).pipe(
			tap( ( count: number ) => this.dialogService.alert( 'Purge Images', `Purged ${count} Image(s)` ) ),
			withLatestFrom( this.store$ ),
			concatMap( ( [count, state] ) => [
				new ImagesActions.PurgeSuccess( count ),
				new ImagesActions.List( new AdImageListParameters( state.facilities.selected.Id, state.admin.selectedUnitModel.Id, state.admin.selectedAdImageType.Type ) )
			] ),
			catchError( ( error: WebApiError ) => of( new ImagesActions.PurgeFail( error ) ) )
		) )
	);

	@Effect()
	rename$ = this.actions$.pipe(
		ofType( ImagesActions.RENAME ),
		map( ( action: ImagesActions.Rename ) => action.parameters ),
		switchMap( parameters => this.imagesService.rename( parameters ).pipe(
			concatMap( () => [
				new ImagesActions.RenameSuccess( parameters ),
				new ImagesActions.Sort( 1 )
			] ),
			catchError( ( error: WebApiError ) => of( new ImagesActions.RenameFail( error ) ) )
		) )
	);

	@Effect( { dispatch: false } )
	fail$ = this.actions$.pipe(
		ofType( ImagesActions.COPY_FAIL, ImagesActions.DELETE_FAIL, ImagesActions.DOWNLOAD_FAIL, ImagesActions.UPLOAD_FAIL,
			ImagesActions.LIST_FAIL, ImagesActions.NEW_FAIL, ImagesActions.PURGE_FAIL, ImagesActions.RENAME_FAIL ),
		tap( ( action: ImagesActions.CopyFail | ImagesActions.DeleteFail | ImagesActions.DownloadFail | ImagesActions.UploadFail |
			ImagesActions.ListFail | ImagesActions.NewFail | ImagesActions.PurgeFail | ImagesActions.RenameFail ) =>
			this.dialogService.webApiError( `ImagesEffects - ${action.type}`, action.error ).subscribe(
				() => this.store$.dispatch( new RoutingActions.Go( { path: ['/login'] } ) )
			)
		)
	);

	constructor(
		private actions$: Actions,
		private store$: Store<fromCartTrac.State>,
		private imagesService: ImagesService,
		private dialogService: DialogService
	) { }

	// Need this function to avoid TS2345 error in catchError of delete$. This way we can return two different observables.
	deleteFailHandler( error: WebApiError ): Observable<ImagesActions.DeleteFail | ImagesActions.DeleteFailIgnore> {
		if ( error.Status === 400 && error.WebApiResponse.Return === -200 ) {
			this.dialogService.alert( 'Delete Image', error.WebApiResponse.Message );

			return of( new ImagesActions.DeleteFailIgnore( error ) );		// busines logic error -200
		}
		else
			return of( new ImagesActions.DeleteFail( error ) );				// hard DB error
	}
}
