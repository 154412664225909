import { NgModule } from '@angular/core';
import { CartTracAngularModule } from '../../cart-trac-angular.module';
import { CartTracMaterialModule } from '../../cart-trac-material.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducer } from './recurring-messages.reducer';

import { RecurringMessagesComponent } from './recurring-messages.component';
import { RecurringMessagesEffects } from './recurring-messages.effects';
import { RecurringMessagesActionsComponent } from './recurring-messages-actions/recurring-messages-actions.component';
import { RecurringMessagesListComponent } from './recurring-messages-list/recurring-messages-list.component';
import { RecurringMessageEditComponent } from './recurring-message-edit/recurring-message-edit.component';

import { RecurringMessagesService } from './recurring-messages.service';
import { PipesModule } from '../../shared/utils/pipes/pipes.module';
import { WebApiService } from '../../shared/web-api/web-api.service';

@NgModule( {
	declarations: [
		RecurringMessageEditComponent,
		RecurringMessagesComponent,
		RecurringMessagesActionsComponent,
		RecurringMessagesListComponent
	],
	imports: [
		CartTracAngularModule,
		CartTracMaterialModule,
		PipesModule,

		StoreModule.forFeature( 'adminRecurringMessages', reducer ),
		EffectsModule.forFeature( [RecurringMessagesEffects] )
	],
	exports: [
		RecurringMessagesComponent,
		RecurringMessageEditComponent,
		RecurringMessagesActionsComponent,
		RecurringMessagesListComponent
	],
	providers: [RecurringMessagesService, WebApiService]
} )
export class RecurringMessagesModule { }
