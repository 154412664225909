import { Action } from '@ngrx/store';
import { EntityUpdate } from '../../shared/utils/ngrx';

import { WebApiError } from '../../shared/web-api/web-api-error';
import { Alarm, AlarmPhoneNumber } from './alarms.model';

export const GET = '[Admin] Alarms Get';
export const GET_FAIL = '[Admin] Alarms Get Fail';
export const GET_SUCCESS = '[Admin] Alarms Get Success';
export const UPDATE = '[Admin] Alarms Update';
export const UPDATE_FAIL = '[Admin] Alarms Update Fail';
export const UPDATE_SUCCESS = '[Admin] Alarms Update Success';
export const PHONE_NUMBERS_DELETE = '[Admin] Alarms PhoneNumbers Delete';
export const PHONE_NUMBERS_DELETE_FAIL = '[Admin] Alarms PhoneNumbers Delete Fail';
export const PHONE_NUMBERS_DELETE_SUCCESS = '[Admin] Alarms PhoneNumbers Delete Success';
export const PHONE_NUMBERS_LIST = '[Admin] Alarms PhoneNumbers List';
export const PHONE_NUMBERS_LIST_FAIL = '[Admin] Alarms PhoneNumbers List Fail';
export const PHONE_NUMBERS_LIST_SUCCESS = '[Admin] Alarms PhoneNumbers List Success';
export const PHONE_NUMBERS_NEW = '[Admin] Alarms PhoneNumbers New';
export const PHONE_NUMBERS_NEW_FAIL = '[Admin] Alarms PhoneNumbers New Fail';
export const PHONE_NUMBERS_NEW_SUCCESS = '[Admin] Alarms PhoneNumbers New Success';
export const PHONE_NUMBERS_UPDATE = '[Admin] Alarms PhoneNumbers Update';
export const PHONE_NUMBERS_UPDATE_FAIL = '[Admin] Alarms PhoneNumbers Update Fail';
export const PHONE_NUMBERS_UPDATE_SUCCESS = '[Admin] Alarms PhoneNumbers Update Success';

export class Get implements Action {
	readonly type = GET;

	constructor( public facilityId: number ) { }
}

export class GetFail implements Action {
	readonly type = GET_FAIL;

	constructor( public error: WebApiError ) { }
}

export class GetSuccess implements Action {
	readonly type = GET_SUCCESS;

	constructor( public alarm: Alarm ) { }
}

export class Update implements Action {
	readonly type = UPDATE;

	constructor( public alarm: Alarm ) { }
}

export class UpdateFail implements Action {
	readonly type = UPDATE_FAIL;

	constructor( public error: WebApiError ) { }
}

export class UpdateSuccess implements Action {
	readonly type = UPDATE_SUCCESS;

	constructor( public alarm: Alarm ) { }
}

export class PhoneNumbersDelete implements Action {
	readonly type = PHONE_NUMBERS_DELETE;

	constructor( public alarmsPhoneNumbersId: number ) { }
}

export class PhoneNumbersDeleteFail implements Action {
	readonly type = PHONE_NUMBERS_DELETE_FAIL;

	constructor( public error: WebApiError ) { }
}

export class PhoneNumbersDeleteSuccess implements Action {
	readonly type = PHONE_NUMBERS_DELETE_SUCCESS;

	constructor( public alarmsPhoneNumbersId: number ) { }
}

export class PhoneNumbersList implements Action {
	readonly type = PHONE_NUMBERS_LIST;

	constructor( public facilityId: number ) { }
}

export class PhoneNumbersListFail implements Action {
	readonly type = PHONE_NUMBERS_LIST_FAIL;

	constructor( public error: WebApiError ) { }
}

export class PhoneNumbersListSuccess implements Action {
	readonly type = PHONE_NUMBERS_LIST_SUCCESS;

	constructor( public phoneNumbers: AlarmPhoneNumber[] ) { }
}

export class PhoneNumbersNew implements Action {
	readonly type = PHONE_NUMBERS_NEW;

	constructor( public phoneNumber: AlarmPhoneNumber ) { }
}

export class PhoneNumbersNewFail implements Action {
	readonly type = PHONE_NUMBERS_NEW_FAIL;

	constructor( public error: WebApiError ) { }
}

export class PhoneNumbersNewSuccess implements Action {
	readonly type = PHONE_NUMBERS_NEW_SUCCESS;

	constructor( public phoneNumber: AlarmPhoneNumber ) { }
}

export class PhoneNumbersUpdate implements Action {
	readonly type = PHONE_NUMBERS_UPDATE;

	constructor( public phoneNumber: AlarmPhoneNumber ) { }
}

export class PhoneNumbersUpdateFail implements Action {
	readonly type = PHONE_NUMBERS_UPDATE_FAIL;

	constructor( public error: WebApiError ) { }
}

export class PhoneNumbersUpdateSuccess implements Action {
	readonly type = PHONE_NUMBERS_UPDATE_SUCCESS;

	constructor( public phoneNumber: AlarmPhoneNumber ) { }
}

export type AlarmsAction
	= Get
	| GetFail
	| GetSuccess
	| Update
	| UpdateFail
	| UpdateSuccess
	| PhoneNumbersDelete
	| PhoneNumbersDeleteFail
	| PhoneNumbersDeleteSuccess
	| PhoneNumbersList
	| PhoneNumbersListFail
	| PhoneNumbersListSuccess
	| PhoneNumbersNew
	| PhoneNumbersNewFail
	| PhoneNumbersNewSuccess
	| PhoneNumbersUpdate
	| PhoneNumbersUpdateFail
	| PhoneNumbersUpdateSuccess;
