<mat-form-field class="course-name">
	<mat-select #course placeholder="Course" [value]="courseId" (selectionChange)="courseChange($event)">
		<mat-option *ngFor="let course of courses" [value]="course.Id">{{course.Name}}</mat-option>
	</mat-select>
</mat-form-field>

<mat-form-field class="hole-number">
	<mat-select placeholder="Hole #" [value]="selected" (selectionChange)="holeChange($event)">
		<mat-option *ngFor="let hole of holesByCourse(course.value)" [value]="hole.Id">{{hole.Number}}</mat-option>
	</mat-select>
</mat-form-field>