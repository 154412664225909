import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { WebLogin } from '../login.model';

@Component( {
	selector: 'ct-login-form',
	templateUrl: './login-form.component.html',
	styleUrls: ['./login-form.component.css']
} )
export class LoginFormComponent implements OnInit {
	loginForm: FormGroup;

	@Input() set webLogin( webLogin: WebLogin ) {
		if ( this.loginForm && webLogin ) {
			this.loginForm.setValue( { login: webLogin.Login, password: webLogin.Password } );
		}
	}

	@Output() login = new EventEmitter<WebLogin>();

	constructor( private fb: FormBuilder ) { }

	ngOnInit() {
		this.loginForm = this.fb.group( {
			login: ['', [Validators.required, Validators.maxLength( 50 )]],
			password: ['', [Validators.required, Validators.maxLength( 50 )]]
		} );
	}

	onSubmit() {
		this.login.emit( new WebLogin( this.loginForm.controls.login.value, this.loginForm.controls.password.value, '' ) );
	}
}
