import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';

import { Store } from '@ngrx/store';
import * as fromCartTrac from '../../cart-trac.reducers';
import * as StatusActions from './status.actions';

import { StatusTrackingServer } from './status-tracking-server/status-tracking-server.model';
import { StatusOther } from './status-other/status-other.model';

@Component( {
	selector: 'ct-status',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './status.component.html',
	styleUrls: ['./status.component.css']
} )
export class StatusComponent implements OnInit {
	statusTrackingServer$: Observable<StatusTrackingServer>;
	statusOther$: Observable<StatusOther>;

	constructor( private store$: Store<fromCartTrac.State> ) {
		this.statusTrackingServer$ = this.store$.select( fromCartTrac.getStatusTrackingServer );
		this.statusOther$ = this.store$.select( fromCartTrac.getStatusOther );
	}

	ngOnInit() {
		this.store$.dispatch( new StatusActions.StatusTrackingServerLoad() );
		this.store$.dispatch( new StatusActions.StatusOtherLoad() );
	}
}
