<mat-toolbar mat-dialog-title color="secondary">New Device</mat-toolbar>

<mat-dialog-content>

	<ct-model-picker [label]="true" (select)="onSelectModel($event)"></ct-model-picker>

	<ct-mode-picker [label]="true" (select)="onSelectMode($event)"></ct-mode-picker>

	<form novalidate [formGroup]="deviceForm">
		<mat-form-field>
			<input matInput placeholder="Device ID" type="text" formControlName="deviceId" />
		</mat-form-field>

		<mat-form-field>
			<input matInput placeholder="Number" type="text" formControlName="number" />
		</mat-form-field>

		<mat-form-field>
			<input matInput placeholder="Name" type="text" formControlName="name" />
		</mat-form-field>
	</form>

</mat-dialog-content>
<mat-dialog-actions>
	<button mat-raised-button (click)="dialogRef.close()">Close</button>
	<button mat-raised-button mat-primary (click)="newDevice()" [disabled]=!deviceForm.valid>Create</button>
</mat-dialog-actions>