import { Component, Input, OnChanges } from '@angular/core';

import { BingMapService } from '../bing-map.service';
import { TrackingDevice } from '../../../tracking/tracking-device.model';

@Component( {
	selector: 'ct-bing-map-devices',
	template: '<ng-content></ng-content>'
} )
export class BingMapDevicesComponent implements OnChanges {

	@Input() trackingDevices: TrackingDevice[];
	@Input() settingsModes: string[];

	constructor( private bingMapService: BingMapService ) { }

	ngOnChanges() {
		this.bingMapService.displayDevices( this.trackingDevices, this.settingsModes );
	}
}
