import { BingMapControlLoaderLazyConfig } from '../app/shared/bing-map/bing-map-control-loader/bing-map-control-loader-lazy';
import * as packageJSON from '../../package.json';

export const environment = {
	production: false,
	webApiServer: 'https://webapidev.carttrac.com',
	version: packageJSON.version,

	bingMapConfig(): BingMapControlLoaderLazyConfig {
		const config = new BingMapControlLoaderLazyConfig();		// If needded, we can override the default values here

		return config;
	}
};
