import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CartTracAngularModule } from '../../cart-trac-angular.module';
import { CartTracMaterialModule } from '../../cart-trac-material.module';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ListsEffects } from './lists.effects';
import { reducer } from './lists.reducer';

import { AdsModule } from '../ads/ads.module';

import { ListsComponent } from './lists.component';
import { ActionsComponent } from './actions/actions.component';
import { ListsService } from './lists.service';

@NgModule( {
	imports: [
		CommonModule,
		CartTracAngularModule,
		CartTracMaterialModule,
		DragDropModule,

		StoreModule.forFeature( 'adManagerLists', reducer ),
		EffectsModule.forFeature( [ListsEffects] ),

		AdsModule
	],
	declarations: [
		ListsComponent,
		ActionsComponent
	],
	exports: [ListsComponent],
	providers: [ListsService]
} )
export class ListsModule { }
