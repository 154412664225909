export class AdSet {
	constructor(
		public AdSetID: number,
		public Name: string,
		public AdVersionID: number,
		public OwnerType: number,
		public StartTime: string,
		public EndTime: string,
		public Exclusive: boolean,
		public CourseNumber: string,
	) { }
}
