import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { WebApiService } from '../shared/web-api/web-api.service';
import { WebApiError } from '../shared/web-api/web-api-error';
import { Mode, Model } from '../device-manager/devices/devices.model';
import { StatusOther } from './status/status-other/status-other.model';

@Injectable()
export class SystemService {
	private serviceBaseUrl: string;

	constructor( private webApiService: WebApiService ) {
		this.serviceBaseUrl = this.webApiService.serverUrl + '/api/system';
	}

	modes(): Observable<Mode[] | WebApiError> {
		return this.webApiService.get<Mode[]>( this.serviceBaseUrl + '/modes' );
	}

	models(): Observable<Model[] | WebApiError> {
		return this.webApiService.get<Model[]>( this.serviceBaseUrl + '/models' );
	}

	statusOther(): Observable<StatusOther | WebApiError> {
		return this.webApiService.get<StatusOther>( this.serviceBaseUrl + '/status/other' );
	}
}
