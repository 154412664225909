import { CdkDropList } from '@angular/cdk/drag-drop';
import { MatExpansionPanel } from '@angular/material/expansion';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import * as ListsActions from './lists.actions';

import { AdList } from './lists.model';

export interface State extends EntityState<AdList> {
	progress: boolean;
	selected: AdList;
	dropListRef: CdkDropList<MatExpansionPanel[]>;
}

export const adapter = createEntityAdapter<AdList>( {
	selectId: ( adList: AdList ) => adList.AdListID,
	sortComparer: false
} );

const initialState: State = adapter.getInitialState( {
	progress: false,
	selected: null,
	dropListRef: null
} );

export function reducer( state = initialState, action: ListsActions.AdsListsAction ): State {

	switch ( action.type ) {
		case ListsActions.DELETE:
		case ListsActions.NEW:
		case ListsActions.UPDATE:
			return { ...state, progress: true };

		case ListsActions.CLEAR:
		case ListsActions.LIST:
			return { ...adapter.removeAll( state ), selected: null };

		case ListsActions.DELETE_SUCCESS:
			return { ...adapter.removeOne( state.selected.AdListID, state ), progress: false, selected: null };

		case ListsActions.LIST_SUCCESS:
			return { ...adapter.addAll( action.adLists, state ), progress: false, selected: null };

		case ListsActions.NEW_SUCCESS:
			return { ...adapter.addOne( action.adList, state ), progress: false, selected: null };

		case ListsActions.UPDATE_SUCCESS:
			return { ...adapter.updateOne( { id: action.adList.AdListID, changes: action.adList }, { ...state, progress: false, selected: null } ) };

		case ListsActions.SELECT:
			return { ...state, selected: action.adList };

		case ListsActions.SET_DROP_LIST:
			return { ...state, dropListRef: action.dropListRef };

		case ListsActions.DELETE_FAIL:
		case ListsActions.LIST_FAIL:
		case ListsActions.NEW_FAIL:
		case ListsActions.UPDATE_FAIL:
			return initialState;

		default:
			return state;
	}
}

export const {
	selectIds: getIds,
	selectEntities: getEntities,
	selectAll: getAll,
	selectTotal: getCount,
} = adapter.getSelectors();

export const getProgress = ( state: State ) => state.progress;
export const getSelectedAdList = ( state: State ) => state.selected;
export const getDropListRef = ( state: State ) => state.dropListRef;
