import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { RecurringMessage, RecurringMessageSetActiveParameters } from '../recurring-messages.model';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
	selector: 'ct-recurring-messages-list',
	templateUrl: './recurring-messages-list.component.html',
	styleUrls: [ './recurring-messages-list.component.css' ]
})
export class RecurringMessagesListComponent implements OnInit {

	@Input() recurringMessages: RecurringMessage[];
	@Input() selected: RecurringMessage;

	// tslint:disable-next-line:no-output-native
	@Output() select = new EventEmitter<RecurringMessage>();
	@Output() setActive = new EventEmitter<RecurringMessageSetActiveParameters>();

	constructor() { }

	ngOnInit() {
	}

	onSelect(recurringMessage: RecurringMessage) {
		if (this.selected === null || this.selected.Id !== recurringMessage.Id)
			this.select.emit(recurringMessage);
	}

	onSetActive(checkBoxChange: MatCheckboxChange) {
		this.setActive.emit(new RecurringMessageSetActiveParameters(this.selected.Id, checkBoxChange.checked));
	}
}
