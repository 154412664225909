import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { WebApiService } from '../../shared/web-api/web-api.service';
import { WebApiError } from '../../shared/web-api/web-api-error';
import { TerminalCommand } from './trackers-terminal-command.model';
import { TerminalResponse } from './trackers-terminal-response.model';
import { Tracker } from './trackers.model';
import { StatusTrackingServer } from '../../system/status/status-tracking-server/status-tracking-server.model';

@Injectable()
export class TrackersService {
	private serviceBaseUrl: string;

	constructor( private webApiService: WebApiService ) {
		this.serviceBaseUrl = this.webApiService.serverUrl + '/api/trackers';
	}

	list( facilityId: number ): Observable<Tracker[] | WebApiError> {
		return this.webApiService.get<Tracker[]>( this.serviceBaseUrl + '/' + facilityId );
	}

	status(): Observable<StatusTrackingServer | WebApiError> {
		return this.webApiService.get<StatusTrackingServer>( this.serviceBaseUrl + '/status' );
	}

	command( terminalCommand: TerminalCommand ): Observable<TerminalResponse | WebApiError> {
		return this.webApiService.post( this.serviceBaseUrl + '/command', terminalCommand );
	}

	reset( connectionIndex: number ): Observable<TerminalResponse | WebApiError> {
		return this.webApiService.delete( this.serviceBaseUrl + '/' + connectionIndex );
	}
}
