import { Component, EventEmitter, Output } from '@angular/core';

@Component( {
	selector: 'ct-toolbar',
	templateUrl: './toolbar.component.html',
	styleUrls: ['./toolbar.component.css']
} )
export class ToolbarComponent
{
	@Output() menuClicked = new EventEmitter();
}
