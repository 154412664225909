import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromCartTrac from '../../cart-trac.reducers';
import * as RoutingActions from '../../routing/routing.actions';
import * as ZonesVersionsActions from './zones-versions.actions';

import { ZoneVersion } from './zones-versions.model';
import { Facility } from '../../facilities/facilities.model';

@Component( {
	selector: 'ct-zones-versions',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './zones-versions.component.html',
	styleUrls: ['./zones-versions.component.css']
} )
export class ZonesVersionsComponent implements OnInit {

	@Input() facility: Facility;
	@Input() selectedVersion: ZoneVersion;

	versions$: Observable<ZoneVersion[]>;

	constructor( private store$: Store<fromCartTrac.State> ) {

		const sortVersions = ( a: ZoneVersion, b: ZoneVersion ) => {
			if ( isNaN( parseFloat( a.Number ) ) || isNaN( parseFloat( b.Number ) ) ) { 	// Isn't a number so lowercase the string to properly compare
				if ( a.Number.toLowerCase() < b.Number.toLowerCase() ) { return -1; }
				if ( a.Number.toLowerCase() > b.Number.toLowerCase() ) { return 1; }
			} else {
				if ( parseFloat( a.Number ) < parseFloat( b.Number ) ) { return -1; }		// Parse strings as numbers to compare properly
				if ( parseFloat( a.Number ) > parseFloat( b.Number ) ) { return 1; }
			}

			return 0; // equal each other
		};

		this.versions$ = this.store$.select( fromCartTrac.getZonesVersions ).pipe(
			map( ( versions: ZoneVersion[] ) => versions ? versions.sort( sortVersions ) : [] )
		);
	}

	ngOnInit() {
		if ( !this.facility )
			this.store$.dispatch( new RoutingActions.Go( { path: ['/login/'] } ) );
	}

	selectVersion( version: ZoneVersion ) {
		this.store$.dispatch( new ZonesVersionsActions.Select( version ) );
	}

	definitions() {
		this.store$.dispatch( new ZonesVersionsActions.HolesLocationsNew( this.selectedVersion.ZoneVersionId ) );
	}
}
