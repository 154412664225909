import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import * as StatusActions from './status.actions';
import * as RoutingActions from '../../routing/routing.actions';
import * as fromCartTrac from '../../cart-trac.reducers';

import { WebApiError } from '../../shared/web-api/web-api-error';
import { SystemService } from '../system.service';
import { TrackersService } from '../../device-manager/trackers/trackers.service';
import { StatusTrackingServer } from './status-tracking-server/status-tracking-server.model';
import { StatusOther } from './status-other/status-other.model';
import { DialogService } from '../../shared/dialogs/dialog.service';

@Injectable()
export class StatusEffects {
	@Effect()
	trackingServerStatusLoad$ = this.actions$.pipe(
		ofType( StatusActions.STATUS_TRACKING_SERVER_LOAD ),
		switchMap( () => this.trackersService.status().pipe(
			map( ( trackingServerStatus: StatusTrackingServer ) => new StatusActions.StatusTrackingServerLoadSuccess( trackingServerStatus ) ),
			catchError( ( error: WebApiError ) => of( new StatusActions.StatusTrackingServerLoadFail( error ) ) )
		) )
	);

	@Effect()
	webApiVersion$ = this.actions$.pipe(
		ofType( StatusActions.STATUS_OTHER_LOAD ),
		switchMap( () => this.systemService.statusOther().pipe(
			map( ( statusOther: StatusOther ) => new StatusActions.StatusOtherLoadSuccess( statusOther ) ),
			catchError( ( error: WebApiError ) => of( new StatusActions.StatusOtherLoadFail( error ) ) )
		) )
	);

	@Effect( { dispatch: false } )
	webApiFail$ = this.actions$.pipe(
		ofType( StatusActions.STATUS_TRACKING_SERVER_LOAD_FAIL || StatusActions.STATUS_OTHER_LOAD_FAIL ),
		tap( ( action: StatusActions.StatusTrackingServerLoadFail | StatusActions.StatusOtherLoadFail ) => {
			this.dialogService.webApiError( `StatusEffects - ${action.type}`, action.payload ).subscribe(
				() => {
					this.store$.dispatch( new RoutingActions.Go( { path: ['/login'] } ) );
				}
			);
		} )
	);

	constructor(
		private actions$: Actions,
		private store$: Store<fromCartTrac.State>,
		private systemService: SystemService,
		private trackersService: TrackersService,
		private dialogService: DialogService
	) { }
}
