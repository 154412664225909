<mat-tab-group>
    <mat-tab *ngFor="let course of rangerData" label="{{course.Name}}">
            <table *ngFor="let hole of course.RangerDataHoles">
                <tr>
                    <td>
                        <div class="ranger-hole-number">{{hole.Number}}</div>
                    </td>
                    <td *ngFor="let device of hole.RangerDataDevices">
                        <div class="ranger-device" (dblclick)="traceDevice( device.Id )">
                            <div class="ranger-device-progress"
                                 [style.background-color]="device.POPColor"
                                 [style.width]="rangerDeviceProgressWidth( device.Progress )"></div>
                            <div class="ranger-device-fill" [style.width]="rangerDeviceFillWidth( device.Progress )"></div>
                            <div class="ranger-device-number">{{device.Number}}</div>
                        </div>
                    </td>
                </tr>
            </table>
    </mat-tab>
</mat-tab-group>
