import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators';

import * as fromCartTrac from '../cart-trac.reducers';
import * as LoginActions from './login.actions';
import * as SystemActions from '../system/system.actions';
import * as RoutingActions from '../routing/routing.actions';

import { LoginService } from './login.service';
import { WebUser, MobileSettingsDTO } from './login.model';
import { WebApiService } from '../shared/web-api/web-api.service';
import { WebApiError } from '../shared/web-api/web-api-error';
import { DialogService } from '../shared/dialogs/dialog.service';

@Injectable()
export class LoginEffects {
	@Effect()
	login$ = this.actions$.pipe(
		ofType( LoginActions.LOGIN ),
		map( ( action: LoginActions.Login ) => action.webLogin ),
		tap( webLogin => this.webApiService.init( webLogin ) ),
		switchMap( webLogin => this.loginService.login( webLogin ).pipe(
			concatMap( ( webUser: WebUser ) => [
				new LoginActions.Success( webUser ),
				new SystemActions.Build(),
				new RoutingActions.Go( { path: ['/facilities'] } )
			] ),
			catchError( ( error: WebApiError ) => of( new LoginActions.Fail( error ) ) )
		) )
	);

	@Effect()
	settingsSave$ = this.actions$.pipe(
		ofType( LoginActions.SETTINGS_SAVE ),
		map( ( action: LoginActions.SettingsSave ) => action.settings ),
		switchMap( ( mobileSettings ) => this.loginService.saveSettings( mobileSettings ).pipe(
			map( ( settingsSaved: MobileSettingsDTO ) => new LoginActions.SettingsSaveSuccess( settingsSaved ) ),
			catchError( ( error: WebApiError ) => of( new LoginActions.SettingsSaveFail( error ) ) )
		) )
	);

	@Effect( { dispatch: false } )
	fail$ = this.actions$.pipe(
		ofType( LoginActions.FAIL, LoginActions.SETTINGS_SAVE_FAIL ),
		tap( ( action: LoginActions.Fail | LoginActions.SettingsSaveFail ) =>
			this.dialogService.webApiError( `LoginEffects - ${action.type}`, action.error ).subscribe(
				() => this.store$.dispatch( new RoutingActions.Go( { path: ['/login'] } ) )
			)
		)
	);

	constructor(
		private actions$: Actions,
		private store$: Store<fromCartTrac.State>,
		private dialogService: DialogService,
		private loginService: LoginService,
		private webApiService: WebApiService
	) { }
}
