import { NgModule } from '@angular/core';

import { CartTracAngularModule } from '../../cart-trac-angular.module';
import { CartTracMaterialModule } from '../../cart-trac-material.module';
import { DialogModule } from '../../shared/dialogs/dialog.module';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ImagesEffects } from './images.effects';
import { reducer } from './images.reducer';

import { ActionsComponent } from './actions/actions.component';
import { BulkActionsComponent } from './bulk-actions/bulk-actions.component';
import { ImagesComponent } from './images.component';
import { UploadComponent } from './bulk-actions/upload/upload.component';
import { NewComponent } from './new/new.component';

import { AdImageTypePickerModule } from '../../shared/pickers/ad-image-type-picker/ad-image-type-picker.module';
import { ModelPickerModule } from '../../shared/pickers/model-picker/model-picker.module';
import { ImageCropperModule } from 'ngx-image-cropper';
import { AngularEditorModule } from './new/angular-editor/angular-editor.module';

import { ImagesService } from './images.service';
import { WebApiService } from '../../shared/web-api/web-api.service';

@NgModule( {
	declarations: [
		ActionsComponent,
		BulkActionsComponent,
		ImagesComponent,
		UploadComponent,
		NewComponent
	],
	imports: [
		CartTracAngularModule,
		CartTracMaterialModule,
		DialogModule,
		ImageCropperModule,
		AngularEditorModule,
		AdImageTypePickerModule,
		ModelPickerModule,

		StoreModule.forFeature( 'adManagerImages', reducer ),
		EffectsModule.forFeature( [ImagesEffects] )
	],
	exports: [ImagesComponent],
	providers: [
		ImagesService,
		WebApiService
	]
} )
export class ImagesModule { }
