import { AbstractControl } from '@angular/forms';

export function ValidateDaysOfWeek( control: AbstractControl ) {

	if ( !( control.get( 'daysOfWeekMon' ).value || control.get( 'daysOfWeekTue' ).value || control.get( 'daysOfWeekWed' ).value ||
		control.get( 'daysOfWeekThu' ).value || control.get( 'daysOfWeekFri' ).value || control.get( 'daysOfWeekSat' ).value ||
		control.get( 'daysOfWeekSun' ).value ) )
		return { mandatory: true };

	return null;
}
