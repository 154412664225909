import { NgModule } from '@angular/core';

import { StoreModule } from '@ngrx/store';
import { reducer } from './trace-parameters.reducer';

import { CartTracAngularModule } from '../cart-trac-angular.module';
import { CartTracMaterialModule } from '../cart-trac-material.module';

import { PipesModule } from '../shared/utils/pipes/pipes.module';

import { TraceParametersRoutingModule } from './trace-parameters-routing.module';
import { TraceParametersComponent } from './trace-parameters.component';
import { TraceDatesComponent } from './trace-dates/trace-dates.component';
import { TraceDevicesComponent } from './trace-devices/trace-devices.component';

@NgModule( {
	declarations: [
		TraceParametersComponent,
		TraceDatesComponent,
		TraceDevicesComponent,
	],
	imports: [
		CartTracAngularModule,
		CartTracMaterialModule,

		TraceParametersRoutingModule,

		PipesModule,

		StoreModule.forFeature( 'traceParameters', reducer ),
	]
} )
export class TraceParametersModule { }
