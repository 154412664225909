<div>
	<table>
		<thead>
			<tr>
				<th>Message</th>
				<th>Devices</th>
				<th>Active</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let recurringMessage of recurringMessages" [ngClass]="recurringMessage.CSSClass"
				(click)="onSelect(recurringMessage)">
				<td>{{ recurringMessage.Text | ellipsis:40:true:" " }}</td>
				<td>{{ recurringMessage.DeviceNumbers | ellipsis:25:true:"," }}</td>
				<td>
					<mat-checkbox [checked]="recurringMessage.Active" (change)="onSetActive($event)"></mat-checkbox>
				</td>
			</tr>
		</tbody>
	</table>
</div>