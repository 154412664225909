import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { forkJoin } from 'rxjs';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import * as AdminActions from './admin.actions';
import * as CoursesActions from '../courses/courses.actions';
import * as DevicesActions from '../device-manager/devices/devices.actions';
import * as RoutingActions from '../routing/routing.actions';
import * as fromCartTrac from '../cart-trac.reducers';

import { CoursesService } from '../courses/courses.service';
import { DevicesService } from '../device-manager/devices/devices.service';
import { MapService } from './map/map.service';
import { DialogService } from '../shared/dialogs/dialog.service';

import { WebApiError } from '../shared/web-api/web-api-error';

@Injectable()
export class AdminEffects {

	@Effect()
	buildFacility$ = this.actions$.pipe(
		ofType( AdminActions.BUILD ),
		map( ( action: AdminActions.Build ) => action.payload ),
		switchMap( facilityId =>
			forkJoin(
				this.coursesService.list( facilityId ),
				this.coursesService.holesList( facilityId ),
				this.devicesService.list( facilityId ),
				this.mapService.map( facilityId )
			).pipe( catchError( ( error: WebApiError ) => of( new AdminActions.BuildFail( error ) ) ) )
		)
	).pipe( mergeMap( results => {
		return [
			new CoursesActions.ListSuccess( results[0] ),
			new CoursesActions.HolesListSuccess( results[1] ),
			new DevicesActions.ListSuccess( results[2] ),
			new AdminActions.MapGetSuccess( results[3] ),
			new AdminActions.BuildSuccess(),
			new RoutingActions.Go( { path: ['/tracking'] } )
		];
	} ) );

	@Effect( { dispatch: false } )
	webApiFail$ = this.actions$.pipe(
		ofType( AdminActions.BUILD_FAIL ),
		tap( ( action: AdminActions.BuildFail ) =>
			this.dialogService.webApiError( `AdminEffects - ${action.type}`, action.payload ).subscribe(
				() => this.store$.dispatch( new RoutingActions.Go( { path: ['/login'] } ) )
			)
		)
	);

	constructor(
		private actions$: Actions,
		private store$: Store<fromCartTrac.State>,
		private dialogService: DialogService,
		private coursesService: CoursesService,
		private devicesService: DevicesService,
		private mapService: MapService
	) { }
}
