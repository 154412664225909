import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AdSet } from './sets.model';
import { WebApiService } from '../../shared/web-api/web-api.service';
import { WebApiError } from '../../shared/web-api/web-api-error';

@Injectable()
export class SetsService {

	private serviceBaseUrl: string;

	constructor( private webApiService: WebApiService ) {
		this.serviceBaseUrl = this.webApiService.serverUrl + '/api/ads/sets';
	}

	delete( id: number ): Observable<void | WebApiError> {
		return this.webApiService.delete( this.serviceBaseUrl + '/' + id );
	}

	list( adVersionId: number ): Observable<AdSet[] | WebApiError> {
		return this.webApiService.get<AdSet[]>( this.serviceBaseUrl + '/' + adVersionId );
	}

	new( adSet: AdSet ): Observable<AdSet | WebApiError> {
		return this.webApiService.post( this.serviceBaseUrl, adSet );
	}

	update( adSet: AdSet ): Observable<AdSet | WebApiError> {
		return this.webApiService.put( this.serviceBaseUrl, adSet );
	}
}

