import { Injectable } from '@angular/core';
import { saveAs as FileSaver } from 'file-saver';
import * as XLSX from 'xlsx';

@Injectable()
export class FileService {

	fileType: string;
	fileExtension: string;

	constructor() { }

	public saveToExcel<T>( jsonData: T[], fileName: string, sheetName: string ): void {
		this.fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
		this.fileExtension = '.xlsx';

		const workbook = XLSX.utils.book_new();
		const worksheet = XLSX.utils.json_to_sheet( jsonData );
		XLSX.utils.book_append_sheet( workbook, worksheet, sheetName );
		const xlsxBuffer: Blob = XLSX.write( workbook, { bookType: 'xlsx', type: 'array' } );
		this.save( xlsxBuffer, fileName );
	}

	save( blob: Blob, fileName: string ) {
		const data: Blob = new Blob( [blob], { type: this.fileType } );
		FileSaver( data, fileName + this.fileExtension );
	}
}
