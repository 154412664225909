import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import * as CoursesActions from './courses.actions';
import * as RoutingActions from '../routing/routing.actions';
import * as fromCartTrac from '../cart-trac.reducers';

import { CoursesService } from './courses.service';
import { Course, Hole } from './courses.model';
import { DialogService } from '../shared/dialogs/dialog.service';
import { WebApiError } from '../shared/web-api/web-api-error';

@Injectable()
export class CoursesEffects {

	@Effect()
	list$ = this.actions$.pipe(
		ofType( CoursesActions.LIST ),
		map( ( action: CoursesActions.List ) => action.facilityId ),
		switchMap( facilityId => this.coursesService.list( facilityId ).pipe(
			map( ( courses: Course[] ) => new CoursesActions.ListSuccess( courses ) ),
			catchError( ( error: WebApiError ) => of( new CoursesActions.ListFail( error ) ) )
		) )
	);

	@Effect()
	holesList$ = this.actions$.pipe(
		ofType( CoursesActions.HOLES_LIST ),
		map( ( action: CoursesActions.HolesList ) => action.courseId ),
		switchMap( courseId => this.coursesService.holesList( courseId ).pipe(
			map( ( holes: Hole[] ) => new CoursesActions.HolesListSuccess( holes ) ),
			catchError( ( error: WebApiError ) => of( new CoursesActions.HolesListFail( error ) ) )
		) )
	);

	@Effect( { dispatch: false } )
	fail$ = this.actions$.pipe(
		ofType( CoursesActions.LIST_FAIL, CoursesActions.HOLES_LIST_FAIL ),
		tap( ( action: CoursesActions.ListFail | CoursesActions.HolesListFail ) =>
			this.dialogService.webApiError( `CoursesEffects - ${action.type}`, action.error ).subscribe(
				() => this.store$.dispatch( new RoutingActions.Go( { path: ['/login'] } ) )
			)
		)
	);

	constructor(
		private actions$: Actions,
		private store$: Store<fromCartTrac.State>,
		private dialogService: DialogService,
		private coursesService: CoursesService
	) { }
}
