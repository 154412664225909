import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';

import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import * as fromCartTrac from '../cart-trac.reducers';
import * as LayoutActions from '../layout/layout.actions';
import * as RoutingActions from '../routing/routing.actions';

import { Facility } from '../facilities/facilities.model';

@Component( {
	selector: 'ct-admin',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './admin.component.html',
	styleUrls: ['./admin.component.css']
} )
export class AdminComponent implements OnDestroy, OnInit {
	facility$: Observable<Facility>;

	private facilitySubscription: Subscription;

	constructor(
		private store$: Store<fromCartTrac.State> ) {
		this.facility$ = this.store$.select( fromCartTrac.getFacility );
	}

	ngOnInit() {
		this.facilitySubscription = this.facility$.pipe(
			take( 1 )
		).subscribe( facility => {
			if ( facility ) {
				this.store$.dispatch( new LayoutActions.FeatureName( 'ADMIN' ) );
			}
			else
				this.store$.dispatch( new RoutingActions.Go( { path: ['/login/'] } ) );
		} );
	}

	ngOnDestroy() {
		this.facilitySubscription.unsubscribe();
	}
}
