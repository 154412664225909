import { Action } from '@ngrx/store';

import { WebApiError } from '../../shared/web-api/web-api-error';
import { ZoneVersion } from './zones-versions.model';

export const HOLES_LOCATIONS_NEW = '[ZonesVersions] Holes Location New';
export const HOLES_LOCATIONS_NEW_FAIL = '[ZonesVersions] Holes Location New Fail';
export const HOLES_LOCATIONS_NEW_SUCCESS = '[ZonesVersions] Holes Location New Success';
export const LOAD = '[ZonesVersions] Load';
export const LOAD_FAIL = '[ZonesVersions] Load Fail';
export const LOAD_SUCCESS = '[ZonesVersions] Load Success';
export const SELECT = '[ZonesVersions] Select';

export class HolesLocationsNew implements Action {
	readonly type = HOLES_LOCATIONS_NEW;

	constructor( public payload: number ) { }
}

export class HolesLocationsNewFail implements Action {
	readonly type = HOLES_LOCATIONS_NEW_FAIL;

	constructor( public payload: WebApiError ) { }
}

export class HolesLocationsNewSuccess implements Action {
	readonly type = HOLES_LOCATIONS_NEW_SUCCESS;

	constructor( public payload: number ) { }
}

export class Load implements Action {
	readonly type = LOAD;

	constructor( public payload: number ) { }
}

export class LoadFail implements Action {
	readonly type = LOAD_FAIL;

	constructor( public payload: WebApiError ) { }
}

export class LoadSuccess implements Action {
	readonly type = LOAD_SUCCESS;

	constructor( public payload: ZoneVersion[] ) { }
}

export class Select implements Action {
	readonly type = SELECT;

	constructor( public payload: ZoneVersion ) { }
}

export type ZonesAction
	= HolesLocationsNew
	| HolesLocationsNewFail
	| HolesLocationsNewSuccess
	| Load
	| LoadFail
	| LoadSuccess
	| Select;
