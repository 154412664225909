import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ZoneVersion } from '../zones-versions.model';

@Component( {
	selector: 'ct-zones-versions-list',
	templateUrl: './zones-versions-list.component.html',
	styleUrls: ['./zones-versions-list.component.css']
} )
export class ZonesVersionsListComponent {

	@Input() versions: ZoneVersion[];
	@Output() selectVersion = new EventEmitter<ZoneVersion>();

	constructor() { }

	select( version: ZoneVersion ) {
		this.selectVersion.emit( version );
	}
}
