<div id="versions-list">
	<table>
		<thead>
			<tr>
				<th>Number</th>
				<th>Name</th>
				<th>Published</th>
				<th>Active</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let version of versions; let i = index" [ngClass]="version.CSSClass"
			 (click)="select(version)">
				<td>{{version.Number}}</td>
				<td>{{version.Name}}</td>
				<td>
					<span *ngIf="version.Published">
                        <mat-icon class="material-icons">check</mat-icon>
                    </span>
				</td>
				<td>
					<span *ngIf="version.Active">
                        <mat-icon class="material-icons">check</mat-icon>
                    </span>
				</td>
			</tr>
		</tbody>
	</table>
</div>