import { Action } from '@ngrx/store';

import { WebApiError } from '../../shared/web-api/web-api-error';
import { Device, DeviceMoveParameters } from './devices.model';

export const DELETE = '[Devices] Delete';
export const DELETE_FAIL = '[Devices] Delete Fail';
export const DELETE_SUCCESS = '[Devices] Delete Success';
export const LIST = '[Devices] List';
export const LIST_FAIL = '[Devices] List Fail';
export const LIST_SUCCESS = '[Devices] List Success';
export const MOVE = '[Devices] Move';
export const MOVE_FAIL = '[Devices] Move Fail';
export const MOVE_SUCCESS = '[Devices] Move Success';
export const NEW = '[Devices] New';
export const NEW_FAIL = '[Devices] New Fail';
export const NEW_SUCCESS = '[Devices] New Success';
export const PURGE = '[Devices] Purge';
export const PURGE_FAIL = '[Devices] Purge Fail';
export const PURGE_SUCCESS = '[Devices] Purge Success';
export const SAVE = '[Devices] Save';
export const SELECT = '[Devices] Select';
export const SORT = '[Devices] Sort';
export const TOGGLE_EXPANDED = '[Devices] Toggle Expanded';
export const UPDATE = '[Devices] Update';
export const UPDATE_FAIL = '[Devices] Update Fail';
export const UPDATE_SUCCESS = '[Devices] Update Success';

export class Delete implements Action {
	readonly type = DELETE;

	constructor( public id: number ) { }
}

export class DeleteFail implements Action {
	readonly type = DELETE_FAIL;

	constructor( public error: WebApiError ) { }
}

export class DeleteSuccess implements Action {
	readonly type = DELETE_SUCCESS;

	constructor() { }
}

export class ToggleExpanded implements Action {
	readonly type = TOGGLE_EXPANDED;

	constructor() { }
}

export class List implements Action {
	readonly type = LIST;

	constructor( public facilityId: number ) { }
}

export class ListFail implements Action {
	readonly type = LIST_FAIL;

	constructor( public error: WebApiError ) { }
}

export class ListSuccess implements Action {
	readonly type = LIST_SUCCESS;

	constructor( public devices: Device[] ) { }
}

export class Move implements Action {
	readonly type = MOVE;

	constructor( public parameters: DeviceMoveParameters ) { }
}

export class MoveFail implements Action {
	readonly type = MOVE_FAIL;

	constructor( public error: WebApiError ) { }
}

export class MoveSuccess implements Action {
	readonly type = MOVE_SUCCESS;

	constructor() { }
}

export class New implements Action {
	readonly type = NEW;

	constructor( public device: Device ) { }
}

export class NewFail implements Action {
	readonly type = NEW_FAIL;

	constructor( public error: WebApiError ) { }
}

export class NewSuccess implements Action {
	readonly type = NEW_SUCCESS;

	constructor( public device: Device ) { }
}

export class Save implements Action {
	readonly type = SAVE;

	constructor( public parameters: { devices: Device[], folderName: string } ) { }
}

export class Select implements Action {
	readonly type = SELECT;

	constructor( public device: Device ) { }
}

export class Sort implements Action {
	readonly type = SORT;

	constructor( public sortOrder: number ) { }
}

export class Update implements Action {
	readonly type = UPDATE;

	constructor( public device: Device ) { }
}

export class UpdateFail implements Action {
	readonly type = UPDATE_FAIL;

	constructor( public error: WebApiError ) { }
}

export class UpdateSuccess implements Action {
	readonly type = UPDATE_SUCCESS;

	constructor( public device: Device ) { }
}

export type DevicesAction
	= Delete
	| DeleteFail
	| DeleteSuccess
	| List
	| ListFail
	| ListSuccess
	| Move
	| MoveFail
	| MoveSuccess
	| New
	| NewFail
	| NewSuccess
	| Select
	| Sort
	| ToggleExpanded
	| Update
	| UpdateFail
	| UpdateSuccess;
