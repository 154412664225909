import { Component, ChangeDetectionStrategy, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromCartTrac from '../../cart-trac.reducers';
import * as FacilitiesActions from '../facilities.actions';
import * as LayoutActions from '../../layout/layout.actions';
import * as RoutingActions from '../../routing/routing.actions';

import { Facility } from '../facilities.model';
import { WebUser } from '../../login/login.model';

@Component( {
	selector: 'ct-facility-selector',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './facility-selector.component.html',
	styleUrls: ['./facility-selector.component.css']
} )
export class FacilitySelectorComponent implements OnDestroy, OnInit {

	facilities$: Observable<Facility[]>;

	private webUser$: Observable<WebUser>;
	private webUserSubscription: Subscription;

	constructor( private store$: Store<fromCartTrac.State> ) {

		this.webUser$ = this.store$.select( fromCartTrac.getWebUser );
		this.facilities$ = this.store$.select( fromCartTrac.getFacilities );
	}

	ngOnInit() {
		this.webUserSubscription = this.webUser$.pipe( take( 1 ) )
			.subscribe( webUser => {
				if ( webUser ) {
					this.store$.dispatch( new LayoutActions.FeatureName( 'FACILITIES' ) );
					this.store$.dispatch( new FacilitiesActions.List( webUser.Id ) );
				}
				else
					this.store$.dispatch( new RoutingActions.Go( { path: ['/login'] } ) );
			} );
	}

	onSelected( facility: Facility ) {
		this.store$.dispatch( new FacilitiesActions.Selected( facility ) );
	}

	ngOnDestroy() {
		this.webUserSubscription.unsubscribe();
	}
}

