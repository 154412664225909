import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatCard } from '@angular/material/card';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { CdkDropList } from '@angular/cdk/drag-drop';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromCartTrac from '../cart-trac.reducers';
import * as LayoutActions from '../layout/layout.actions';
import * as RoutingActions from '../routing/routing.actions';
import * as AdminActions from '../admin/admin.actions';
import * as ImagesActions from './images/images.actions';
import * as VersionsActions from './versions/versions.actions';

import { Facility } from '../facilities/facilities.model';
import { AdImage, AdImageType, AdImageListParameters, AdImageTypes } from './images/images.model';
import { Model } from '../device-manager/devices/devices.model';
import { AdVersionListParameters, AdVersion } from './versions/versions.model';
import { AdManagerTabs } from './ad-manager-tabs';

@Component( {
	selector: 'ct-ad-manager',
	templateUrl: './ad-manager.component.html',
	styleUrls: ['./ad-manager.component.css']
} )
export class AdManagerComponent implements OnInit, OnDestroy {
	adsDropList$: Observable<CdkDropList<MatCard[]>>;
	facility$: Observable<Facility>;
	selectedAdImage$: Observable<AdImage>;
	selectedAdImageType$: Observable<AdImageType>;
	selectedAdVersion$: Observable<AdVersion>;
	selectedUnitModel$: Observable<Model>;

	adManagerTabs = AdManagerTabs;
	currentTabIndex: number = AdManagerTabs.Images;

	private facility: Facility;
	private selectedAdImageType: AdImageType;
	private selectedUnitModel: Model;

	private facilitySubscription: Subscription;
	private selectedAdImageTypeSubscription: Subscription;
	private selectedUnitModelSubscription: Subscription;

	constructor( private store$: Store<fromCartTrac.State> ) {

		this.adsDropList$ = this.store$.select( fromCartTrac.getAdManagerDropListRef );
		this.facility$ = this.store$.select( fromCartTrac.getFacility );
		this.selectedAdImage$ = this.store$.select( fromCartTrac.getSelectedAdImage );
		this.selectedAdImageType$ = this.store$.select( fromCartTrac.getSelectedAdImageType );
		this.selectedUnitModel$ = this.store$.select( fromCartTrac.getSelectedUnitModel );
		this.selectedAdVersion$ = this.store$.select( fromCartTrac.getSelectedAdVersion );
	}

	ngOnInit() {
		this.selectedAdImageTypeSubscription = this.selectedAdImageType$.subscribe( adImageType => this.selectedAdImageType = adImageType );
		this.selectedUnitModelSubscription = this.selectedUnitModel$.subscribe( unitModel => this.selectedUnitModel = unitModel );

		this.facilitySubscription = this.facility$
			.subscribe( facility => {
				if ( facility ) {
					this.facility = facility;
					this.store$.dispatch( new LayoutActions.FeatureName( 'AD IMAGES' ) );
					this.store$.dispatch( new ImagesActions.List(
						new AdImageListParameters( facility.Id, this.selectedUnitModel ? this.selectedUnitModel.Id : 1, this.selectedAdImageType.Type ) ) );
				} else {
					this.store$.dispatch( new RoutingActions.Go( { path: ['/login/'] } ) );
				}
			} );
	}

	ngOnDestroy() {
		this.facilitySubscription.unsubscribe();
		this.selectedAdImageTypeSubscription.unsubscribe();
		this.selectedUnitModelSubscription.unsubscribe();

		this.store$.dispatch( new ImagesActions.Clear() );
	}

	onTabChange( tabChange: MatTabChangeEvent ) {

		this.currentTabIndex = tabChange.index;

		this.refresh( tabChange.index );
	}

	selectUnitModel( unitModel: Model ) {
		this.store$.dispatch( new AdminActions.SelectUnitModel( unitModel ) );

		this.refresh( this.currentTabIndex );
	}

	refresh( tabIndex: number ) {
		switch ( this.currentTabIndex ) {
			case this.adManagerTabs.Images:
				this.store$.dispatch( new LayoutActions.FeatureName( 'AD IMAGES' ) );
				this.store$.dispatch( new ImagesActions.List(
					new AdImageListParameters( this.facility.Id, this.selectedUnitModel.Id, this.selectedAdImageType.Type ) ) );
				break;
			case AdManagerTabs.Workspace:
				this.store$.dispatch( new LayoutActions.FeatureName( 'AD VERSIONS' ) );
				this.store$.dispatch( new VersionsActions.List( new AdVersionListParameters( this.facility.Id, this.selectedUnitModel.Id, false ) ) );
				this.store$.dispatch( new ImagesActions.Clear() );
				break;
			case AdManagerTabs.Published:
				this.store$.dispatch( new LayoutActions.FeatureName( 'AD VERSIONS' ) );
				this.store$.dispatch( new VersionsActions.List( new AdVersionListParameters( this.facility.Id, this.selectedUnitModel.Id, true ) ) );
				this.store$.dispatch( new ImagesActions.Clear() );
				break;
		}
	}
}
