import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { AdImageType, AdImageTypes } from '../../../ad-manager/images/images.model';
import { MatSelectChange } from '@angular/material/select';

@Component( {
	selector: 'ct-ad-image-type-picker',
	templateUrl: './ad-image-type-picker.component.html',
	styleUrls: ['./ad-image-type-picker.component.css']
} )
export class AdImageTypesComponent implements OnInit {
	@Input() selected: AdImageType;
	@Output() select = new EventEmitter<AdImageType>();

	adImageTypes: AdImageType[] = AdImageTypes;

	constructor() { }

	ngOnInit() {
	}

	adImageTypeChange( event: MatSelectChange ) {
		this.select.emit( event.value );
	}
}
