import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { WebApiService } from '../../shared/web-api/web-api.service';

@Injectable()
export class TwilioService
{
	private username: string;
	private password: string;
	private accountsUrl: string;
	private simsUrl: string;

	constructor( private webApiService: WebApiService ) { }

	/**
	 * Initialization of Twilio WebApi.
	 * @param userName
	 * @param password
	 * @param accountsUrl
	 * @param simsUrl
	 */
	init( userName: string, password: string, accountsUrl: string, simsUrl: string )
	{
		this.username = userName;
		this.password = password;
		this.accountsUrl = accountsUrl;
		this.simsUrl = simsUrl;
	}

	/**
	 * Authorization header for Twilio WebApi
	 */
	authorization(): string
	{
		return this.webApiService.basicAuthorization( this.username, this.password );
	}

	// curl -G https://api.twilio.com/2010-04-01/Accounts.json -u 'ACabb4375d31fa876bb35f48224d4f2a7a:eb9bf372607c39148f8cff7b0731b110'
	/**
	 * GET Twilio Accounts
	 */
	accounts(): Observable<any>
	{
		return this.webApiService.get( this.accountsUrl, this.authorization() );
	}

	/**
	 * GET Twilio SIMs
	 */
	sims(): Observable<any>
	{
		return this.webApiService.get( this.simsUrl, this.authorization() );
	}
}
