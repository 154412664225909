import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { CartTracModule } from './app/cart-trac.module';
import { environment } from './environments/environment';

if ( environment.production ) {
	enableProdMode();

	window.console.log = () => { };
}

platformBrowserDynamic().bootstrapModule( CartTracModule )
	.catch( ( err: Error ) => console.error( err ) );
