import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { WebApiService } from '../shared/web-api/web-api.service';
import { WebApiError } from '../shared/web-api/web-api-error';
import { TrackingDevice } from './tracking-device.model';

@Injectable()
export class TrackingService {
	private serviceBaseUrl: string;

	constructor( private webApiService: WebApiService ) {
		this.serviceBaseUrl = this.webApiService.serverUrl + '/api/devices';
	}

	list( facilityId: number, loginId: number, progress: boolean = false ): Observable<TrackingDevice[] | WebApiError> {
		return this.webApiService.get<TrackingDevice[]>( this.serviceBaseUrl + '/tracking/' + facilityId + '/' + loginId );
	}
}
