export interface CustomClass {
	name: string;
	class: string;
	tag?: string;
}

export interface AngularEditorConfig {
	customClasses?: CustomClass[];
	defaultFontName?: string;
	defaultFontSize?: '1' | '2' | '3' | '4' | '5' | '6' | '7' | string;
	defaultParagraphSeparator?: string;
	editable?: boolean;
	enableToolbar?: boolean;
	height?: 'auto' | string;
	imagefillColor: string;
	imageHeight: number;
	imageWidth: number;
	minHeight?: '0' | string;
	minWidth?: '0' | string;
	placeholder?: string;
	showToolbar?: boolean;
	spellcheck?: boolean;
	translate?: 'yes' | 'now' | string;
	uploadUrl?: string;
	width?: 'auto' | string;
}

export const angularEditorConfig: AngularEditorConfig = {
	defaultFontName: 'Arial',
	defaultFontSize: '5',
	defaultParagraphSeparator: '',
	editable: true,
	enableToolbar: true,
	imagefillColor: '#ffffff',
	imageHeight: 0,
	imageWidth: 0,
	height: 'auto',
	minHeight: '0',
	minWidth: '0',
	placeholder: 'Enter text here...',
	showToolbar: true,
	spellcheck: true,
	translate: 'yes',
	uploadUrl: 'v1/image',
	width: 'auto'
};
