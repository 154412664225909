import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import * as fromCartTrac from '../cart-trac.reducers';
import * as TraceParametersActions from './trace-parameters.actions';
import * as RoutingActions from '../routing/routing.actions';
import * as LayoutActions from '../layout/layout.actions';

import { TraceDevice } from './trace-device.model';
import { TraceParameters } from './trace-parameters.model';

@Component( {
	selector: 'ct-trace-parameters',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './trace-parameters.component.html',
	styleUrls: ['./trace-parameters.component.css'],
} )
export class TraceParametersComponent implements OnInit {
	traceDevices$: Observable<TraceDevice[]>;

	private startDate: Date;
	private endDate: Date;

	constructor( private store$: Store<fromCartTrac.State> ) {
		const sortTraceDevices = ( a: TraceDevice, b: TraceDevice ) => {
			if ( isNaN( parseFloat( a.Number ) ) || isNaN( parseFloat( b.Number ) ) ) { 	// Isn't a number so lowercase the string to properly compare
				if ( a.Number.toLowerCase() < b.Number.toLowerCase() ) { return -1; }
				if ( a.Number.toLowerCase() > b.Number.toLowerCase() ) { return 1; }
			} else {
				if ( parseFloat( a.Number ) < parseFloat( b.Number ) ) { return -1; }		// Parse strings as numbers to compare properly
				if ( parseFloat( a.Number ) > parseFloat( b.Number ) ) { return 1; }
			}

			return 0; // equal each other
		};

		this.traceDevices$ = this.store$.select( fromCartTrac.getTraceDevices ).pipe(
			map( ( traceDevices: TraceDevice[] ) => traceDevices ? traceDevices.sort( sortTraceDevices ) : [] )
		);
	}

	ngOnInit() {
		this.store$.dispatch( new LayoutActions.FeatureName( 'TRACE' ) );
	}

	newStartDate( date: Date ) {
		this.startDate = date;
	}

	newEndDate( date: Date ) {
		this.endDate = date;
	}

	select( id: number ) {
		this.store$.dispatch( new TraceParametersActions.Set( new TraceParameters( id, this.startDate, this.endDate ) ) );
		this.store$.dispatch( new RoutingActions.Go( { path: ['/trace/'] } ) );
	}
}

