import { Component, Input, OnInit } from '@angular/core';

import { BingMapService } from '../bing-map.service';
import { Course, Hole } from '../../../courses/courses.model';
import { MobileSettings, WebUser } from '../../../login/login.model';

@Component( {
	selector: 'ct-bing-map-flags-tees',
	template: '<ng-content></ng-content>'
} )
export class BingMapFlagsTeesComponent implements OnInit {
	@Input() courses: Course[];
	@Input() holes: Hole[];
	@Input() webUser: WebUser;

	constructor( private bingMapService: BingMapService ) { }

	ngOnInit() {
		this.bingMapService.displayFlagsAndTees( this.courses, this.holes, this.webUser.Settings );
	}
}
