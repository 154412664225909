<button mat-icon-button matTooltip="Copy Version" (click)="copy($event)">
	<mat-icon class="material-icons">content_copy</mat-icon>
</button>
<button mat-icon-button matTooltip="Delete Version" *ngIf="!adVersion.Active" (click)="delete($event)">
	<mat-icon class="material-icons">delete_forever</mat-icon>
</button>
<button mat-icon-button matTooltip="Publish Version" *ngIf="!adVersion.Published" (click)="publish($event)">
	<mat-icon class="material-icons">create_new_folder</mat-icon>
</button>
<button mat-icon-button matTooltip="Activate Version" *ngIf="adVersion.Published && !adVersion.Active" (click)="activate($event)">
	<mat-icon class="material-icons">offline_bolt</mat-icon>
</button>
<button mat-icon-button matTooltip="Active Version" *ngIf="adVersion.Published && adVersion.Active">
	<mat-icon class="material-icons">directions_run</mat-icon>
</button>