import { Action } from '@ngrx/store';

import { WebApiError } from '../../shared/web-api/web-api-error';
import { Ad } from './ads.model';

export const DELETE = '[Ads] Delete';
export const DELETE_FAIL = '[Ads] Delete Fail';
export const DELETE_SUCCESS = '[Ads] Delete Success';
export const LIST = '[Ads] List';
export const LIST_FAIL = '[Ads] List Fail';
export const LIST_SUCCESS = '[Ads] List Success';
export const NEW = '[Ads] New';
export const NEW_FAIL = '[Ads] New Fail';
export const NEW_SUCCESS = '[Ads] New Success';
export const SELECT = '[Ads] Select';
export const UPDATE = '[Ads] Update';
export const UPDATE_FAIL = '[Ads] Update Fail';
export const UPDATE_SUCCESS = '[Ads] Reanme Success';

export class Delete implements Action {
	readonly type = DELETE;

	constructor( public id: number ) { }
}

export class DeleteFail implements Action {
	readonly type = DELETE_FAIL;

	constructor( public error: WebApiError ) { }
}

export class DeleteSuccess implements Action {
	readonly type = DELETE_SUCCESS;
}

export class List implements Action {
	readonly type = LIST;

	constructor( public adListId: number ) { }
}

export class ListFail implements Action {
	readonly type = LIST_FAIL;

	constructor( public error: WebApiError ) { }
}

export class ListSuccess implements Action {
	readonly type = LIST_SUCCESS;

	constructor( public ads: Ad[] ) { }
}

export class New implements Action {
	readonly type = NEW;

	constructor( public ad: Ad ) { }
}

export class NewFail implements Action {
	readonly type = NEW_FAIL;

	constructor( public error: WebApiError ) { }
}

export class NewSuccess implements Action {
	readonly type = NEW_SUCCESS;

	constructor( public ad: Ad ) { }
}

export class Select implements Action {
	readonly type = SELECT;

	constructor( public ad: Ad ) { }
}

export class Update implements Action {
	readonly type = UPDATE;

	constructor( public ad: Ad ) { }
}

export class UpdateFail implements Action {
	readonly type = UPDATE_FAIL;

	constructor( public error: WebApiError ) { }
}

export class UpdateSuccess implements Action {
	readonly type = UPDATE_SUCCESS;

	constructor( public ad: Ad ) { }
}

export type AdsAction
	= Delete
	| DeleteFail
	| DeleteSuccess
	| List
	| ListFail
	| ListSuccess
	| New
	| NewFail
	| NewSuccess
	| Select
	| Update
	| UpdateFail
	| UpdateSuccess;
