import * as LayoutActions from './layout.actions';

export interface State {
	featureName: string;
	openSidenav: boolean;
}

const initialState: State = {
	featureName: null,
	openSidenav: false,
};

export function reducer( state = initialState, action: LayoutActions.LayoutAction ): State {
	switch ( action.type ) {
		case LayoutActions.FEATURE_NAME:
			return { ...state, featureName: action.payload };

		case LayoutActions.CLOSE_SIDENAV:
			return { ...state, openSidenav: false };

		case LayoutActions.OPEN_SIDENAV:
			return { ...state, openSidenav: true };

		default:
			return state;
	}
}

export const getComponentName = ( state: State ) => state.featureName;
export const getOpenSidenav = ( state: State ) => state.openSidenav;
