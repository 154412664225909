import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AdList } from './lists.model';
import { WebApiService } from '../../shared/web-api/web-api.service';
import { WebApiError } from '../../shared/web-api/web-api-error';

@Injectable()
export class ListsService {

	private serviceBaseUrl: string;

	constructor( private webApiService: WebApiService ) {
		this.serviceBaseUrl = this.webApiService.serverUrl + '/api/ads/lists';
	}

	delete( id: number ): Observable<void | WebApiError> {
		return this.webApiService.delete( this.serviceBaseUrl + '/' + id );
	}

	list( adSetId: number ): Observable<AdList[] | WebApiError> {
		return this.webApiService.get<AdList[]>( this.serviceBaseUrl + '/' + adSetId );
	}

	new( adList: AdList ): Observable<AdList | WebApiError> {
		return this.webApiService.post( this.serviceBaseUrl, adList );
	}

	update( adList: AdList ): Observable<AdList | WebApiError> {
		return this.webApiService.put( this.serviceBaseUrl, adList );
	}
}

