import { Component, EventEmitter, Input, Output } from '@angular/core';

import { RangerData } from '../ranger-data.model';

@Component( {
	selector: 'ct-ranger-grid',
	templateUrl: './ranger-grid.component.html',
	styleUrls: ['./ranger-grid.component.css']
} )
export class RangerGridComponent
{
	@Input() rangerData: RangerData[];
	@Output() traceDeviceId = new EventEmitter<number>();

	private rangerDeviceWidth = 50;

	constructor( ) { }

	rangerDeviceProgressWidth( progress: number ): string
	{
		return ( this.rangerDeviceWidth * progress / 100 ) + 'px';
	}

	rangerDeviceFillWidth( progress: number ): string
	{
		return ( this.rangerDeviceWidth - this.rangerDeviceWidth * progress / 100 ) + 'px';
	}

	traceDevice( id: number )
	{
		this.traceDeviceId.emit( id );
	}
}
