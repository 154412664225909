import * as SystemActions from './system.actions';

import { Mode } from '../device-manager/devices/devices.model';
import { Model } from '../device-manager/devices/devices.model';
import { Facility } from '../facilities/facilities.model';

export interface State {
	modes: Mode[];
	models: Model[];
	progress: boolean;
	pickedFacility: Facility;
	pickedMode: Mode;
	pickedModel: Model;
}

const initialState: State = {
	modes: [],
	models: [],
	progress: false,
	pickedFacility: null,
	pickedMode: null,
	pickedModel: null
};

export function reducer( state = initialState, action: SystemActions.SystemAction ): State {
	switch ( action.type ) {
		case SystemActions.MODELS_LOAD:
		case SystemActions.MODES_LOAD:
			return { ...state, progress: true };

		case SystemActions.MODELS_LOAD_SUCCESS:
			return { ...state, progress: false, models: action.models };

		case SystemActions.MODES_LOAD_SUCCESS:
			return { ...state, progress: false, modes: action.modes };

		case SystemActions.MODELS_LOAD_FAIL:
		case SystemActions.MODES_LOAD_FAIL:
			return initialState;

		case SystemActions.PICK_FACILITY:
			return { ...state, pickedFacility: action.facility };

		case SystemActions.PICK_MODE:
			return { ...state, pickedMode: action.mode };

		case SystemActions.PICK_MODEL:
			return { ...state, pickedModel: action.model };

		default:
			return state;
	}
}

export const getModels = ( state: State ) => state.models;
export const getModes = ( state: State ) => state.modes;
export const getProgress = ( state: State ) => state.progress;
export const getPickedFacility = ( state: State ) => state.pickedFacility;
export const getPickedMode = ( state: State ) => state.pickedMode;
export const getPickedModel = ( state: State ) => state.pickedModel;
