<button *ngIf="systemUser" mat-icon-button matTooltip="New Device" (click)="new()">
	<mat-icon class="material-icons">add</mat-icon>
</button>
<button *ngIf="systemUser" mat-icon-button matTooltip="Delete Device" [disabled]="!selected" (click)="delete()">
	<mat-icon class="material-icons">remove</mat-icon>
</button>
<button *ngIf="systemUser" mat-icon-button matTooltip="Move Device" [disabled]="!selected" (click)="move()">
	<mat-icon class="material-icons">redo</mat-icon>
</button>
<button mat-icon-button matTooltip="Edit Device" [disabled]="!selected" (click)="edit()">
	<mat-icon class="material-icons">edit</mat-icon>
</button>
<button mat-icon-button matTooltip="Sort by" [matMenuTriggerFor]="sortMenu" [disabled]="devices.length < 2">
	<mat-icon class="material-icons">sort</mat-icon>
</button>
<mat-menu #sortMenu="matMenu">
	<button mat-menu-item (click)="sort(1)">Number</button>
	<button mat-menu-item (click)="sort(2)">Device ID</button>
	<button mat-menu-item (click)="sort(3)">Last Update</button>
	<button mat-menu-item (click)="sort(4)">Model</button>
	<button mat-menu-item (click)="sort(5)">Mode</button>
</mat-menu>
<button *ngIf="!expanded" mat-icon-button matTooltip="Expand More" (click)="toggleExpand()">
	<mat-icon class="material-icons">expand_more</mat-icon>
</button>
<button *ngIf="expanded" mat-icon-button matTooltip="Expand Less" (click)="toggleExpand()">
	<mat-icon class="material-icons">expand_less</mat-icon>
</button>