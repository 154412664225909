import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CartTracAngularModule } from '../../cart-trac-angular.module';
import { CartTracMaterialModule } from '../../cart-trac-material.module';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AdsEffects } from './ads.effects';
import { reducer } from './ads.reducer';

import { HoleSelectorModule } from '../../courses/hole-selector/hole-selector.module';

import { AdsComponent } from './ads.component';
import { AdsService } from './ads.service';

@NgModule( {
	imports: [
		CommonModule,
		CartTracAngularModule,
		CartTracMaterialModule,
		HoleSelectorModule,

		StoreModule.forFeature( 'adManagerAds', reducer ),
		EffectsModule.forFeature( [AdsEffects] )
	],
	declarations: [
		AdsComponent
	],
	exports: [AdsComponent],
	providers: [AdsService]
} )
export class AdsModule { }
