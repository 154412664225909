<mat-toolbar mat-dialog-title color="secondary">New {{data?.unitModel?.Name}} Ad Version</mat-toolbar>

<form [formGroup]="newForm" (ngSubmit)="new()">
	<mat-dialog-content>
		<mat-form-field>
			<input matInput type="text" placeholder="New Version Name" formControlName="name" required [errorStateMatcher]="errorStateMatcher">
			<mat-error>You must enter a value</mat-error>
		</mat-form-field>
	</mat-dialog-content>

	<mat-dialog-actions>
		<button mat-raised-button (click)="dialogRef.close()">Close</button>
		<button mat-raised-button mat-primary submit [disabled]="newForm.invalid">Create</button>
	</mat-dialog-actions>
</form>