import { NgModule } from '@angular/core';
import { CartTracAngularModule } from '../../../cart-trac-angular.module';
import { CartTracMaterialModule } from '../../../cart-trac-material.module';

import { ModelPickerComponent } from './model-picker.component';

@NgModule( {
	declarations: [
		ModelPickerComponent
	],
	exports: [
		ModelPickerComponent,
	],
	imports: [
		CartTracAngularModule,
		CartTracMaterialModule
	]
} )
export class ModelPickerModule { }
