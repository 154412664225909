import { BingMapControlLoader } from './bing-map-control-loader';
import { Injectable } from '@angular/core';

/**
 * When using the BingMapControlLoaderScript, the Maps API must be added to the page via a `<script>` tag.
 * It's important that the Bing Maps Cointrol script gets loaded first on the page.
 */
@Injectable()
export class BingMapControlLoaderScript implements BingMapControlLoader {
	load(): Promise<void> {
		if ( !( window as any ).Microsoft || !( window as any ).Microsoft.Maps ) {
			throw new Error( 'Bing Maps Control not loaded on page!' );
		}

		return Promise.resolve();
	}
}
