import { NgModule } from '@angular/core';
import { AngularEditorComponent } from './angular-editor.component';
import { AngularEditorToolbarComponent } from './angular-editor-toolbar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

@NgModule( {
	imports: [
		CommonModule, FormsModule, ReactiveFormsModule, MatIconModule
	],
	declarations: [AngularEditorComponent, AngularEditorToolbarComponent],
	exports: [AngularEditorComponent, AngularEditorToolbarComponent]
} )
export class AngularEditorModule {
}
