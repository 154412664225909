import { EntityState, createEntityAdapter } from '@ngrx/entity';

import * as AdsActions from './ads.actions';
import { Ad } from './ads.model';

export interface State extends EntityState<Ad> {
	progress: boolean;
	selected: Ad;

}

export const adapter = createEntityAdapter<Ad>( {
	selectId: ( ad: Ad ) => ad.AdID,
	sortComparer: false
} );

const initialState: State = adapter.getInitialState( {
	progress: false,
	selected: null
} );

export function reducer( state = initialState, action: AdsActions.AdsAction ): State {

	switch ( action.type ) {
		case AdsActions.DELETE:
		case AdsActions.NEW:
		case AdsActions.UPDATE:
			return { ...state, progress: true };

		case AdsActions.LIST:
			return { ...adapter.removeAll( state ), progress: true };

		case AdsActions.DELETE_SUCCESS:
			return { ...adapter.removeOne( state.selected.AdID, state ), progress: false, selected: null };

		case AdsActions.LIST_SUCCESS:
			return { ...adapter.addAll( action.ads, state ), progress: false, selected: null };

		case AdsActions.NEW_SUCCESS:
			return { ...adapter.addOne( action.ad, state ), progress: false, selected: null };

		case AdsActions.UPDATE_SUCCESS:
			return {
				...adapter.updateOne( { id: action.ad.AdID, changes: action.ad }, { ...state, progress: false, selected: null } )
			};

		case AdsActions.SELECT:
			return { ...state, selected: action.ad };

		case AdsActions.DELETE_FAIL:
		case AdsActions.LIST_FAIL:
		case AdsActions.NEW_FAIL:
		case AdsActions.UPDATE_FAIL:
			return initialState;

		default:
			return state;
	}
}

export const {
	selectIds: getIds,
	selectEntities: getEntities,
	selectAll: getAll,
	selectTotal: getCount,
} = adapter.getSelectors();

export const getProgress = ( state: State ) => state.progress;
export const getSelectedAd = ( state: State ) => state.selected;
