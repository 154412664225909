import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import * as RecurringMessagesActions from './recurring-messages.actions';
import * as RoutingActions from '../../routing/routing.actions';
import * as fromCartTrac from '../../cart-trac.reducers';

import { RecurringMessagesService } from './recurring-messages.service';
import { DialogService } from '../../shared/dialogs/dialog.service';
import { WebApiError } from '../../shared/web-api/web-api-error';
import { RecurringMessage, RecurringMessageSetActiveParameters } from './recurring-messages.model';

@Injectable()
export class RecurringMessagesEffects {

	@Effect()
	delete$ = this.actions$.pipe(
		ofType( RecurringMessagesActions.DELETE ),
		map( ( action: RecurringMessagesActions.Delete ) => action.id ),
		switchMap( id => this.recurringMessagesService.delete( id ).pipe(
			map( () => new RecurringMessagesActions.DeleteSuccess() ),
			catchError( ( error: WebApiError ) => of( new RecurringMessagesActions.DeleteFail( error ) ) )
		) )
	);

	@Effect()
	list$ = this.actions$.pipe(
		ofType( RecurringMessagesActions.LIST ),
		map( ( action: RecurringMessagesActions.List ) => action.facilityId ),
		switchMap( facilityId => this.recurringMessagesService.list( facilityId ).pipe(
			map( ( recurringMessages: RecurringMessage[] ) => new RecurringMessagesActions.ListSuccess( recurringMessages ) ),
			catchError( ( error: WebApiError ) => of( new RecurringMessagesActions.ListFail( error ) ) )
		) )
	);

	@Effect()
	new$ = this.actions$.pipe(
		ofType( RecurringMessagesActions.NEW ),
		map( ( action: RecurringMessagesActions.New ) => action.recurringMessage ),
		mergeMap( recurringMessage => this.recurringMessagesService.new( recurringMessage ).pipe(			// switchMap unsubscribes when second Observable arrives, that cancels the http request
			map( ( newRecurringMessage: RecurringMessage ) => new RecurringMessagesActions.NewSuccess( newRecurringMessage ) ),
			catchError( ( error: WebApiError ) => of( new RecurringMessagesActions.NewFail( error ) ) )
		) )
	);

	@Effect()
	send$ = this.actions$.pipe(
		ofType( RecurringMessagesActions.SEND ),
		map( ( action: RecurringMessagesActions.Send ) => action.id ),
		switchMap( id => this.recurringMessagesService.send( id ).pipe(
			tap( ( count: number ) => this.dialogService.alert( 'Send Message', `Sent to ${count} Devices(s).` ) ),
			map( count => new RecurringMessagesActions.SendSuccess( count ) ),
			catchError( ( error: WebApiError ) => of( new RecurringMessagesActions.SendFail( error ) ) )
		) )
	);

	@Effect()
	setActive$ = this.actions$.pipe(
		ofType( RecurringMessagesActions.SET_ACTIVE ),
		map( ( action: RecurringMessagesActions.SetActive ) => action.parameters ),
		switchMap( ( parameters: RecurringMessageSetActiveParameters ) => this.recurringMessagesService.setActive( parameters ).pipe(
			map( () => new RecurringMessagesActions.SetActiveSuccess( parameters ) ),
			catchError( ( error: WebApiError ) => of( new RecurringMessagesActions.SetActiveFail( error ) ) )
		) )
	);

	@Effect()
	update$ = this.actions$.pipe(
		ofType( RecurringMessagesActions.UPDATE ),
		map( ( action: RecurringMessagesActions.Update ) => action.update ),
		switchMap( update => this.recurringMessagesService.update( update.update ).pipe(
			map( ( updatedRecurringMessage: RecurringMessage ) => new RecurringMessagesActions.UpdateSuccess( updatedRecurringMessage ) ),
			catchError( ( error: WebApiError ) => of( new RecurringMessagesActions.UpdateFail( error ) ) )
		) )
	);

	@Effect( { dispatch: false } )
	fail$ = this.actions$.pipe(
		ofType( RecurringMessagesActions.DELETE_FAIL, RecurringMessagesActions.LIST_FAIL, RecurringMessagesActions.NEW_FAIL,
			RecurringMessagesActions.SEND_FAIL, RecurringMessagesActions.SET_ACTIVE_FAIL, RecurringMessagesActions.UPDATE_FAIL ),
		tap( ( action: RecurringMessagesActions.DeleteFail | RecurringMessagesActions.ListFail | RecurringMessagesActions.NewFail |
			RecurringMessagesActions.SendFail | RecurringMessagesActions.SetActiveFail | RecurringMessagesActions.UpdateFail ) =>
			this.dialogService.webApiError( `RecurringMessagesEffects - ${action.type}`, action.error ).subscribe(
				() => this.store$.dispatch( new RoutingActions.Go( { path: ['/login'] } ) )
			)
		)
	);

	constructor(
		private actions$: Actions,
		private store$: Store<fromCartTrac.State>,
		private recurringMessagesService: RecurringMessagesService,
		private dialogService: DialogService
	) { }
}
