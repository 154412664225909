import { Component, EventEmitter, Input, Output } from '@angular/core';

import { DialogService } from '../../../shared/dialogs/dialog.service';
import { ZoneVersion } from '../zones-versions.model';

@Component( {
	selector: 'ct-zones-versions-actions',
	templateUrl: './zones-versions-actions.component.html',
	styleUrls: ['./zones-versions-actions.component.css']
} )
export class ZonesVersionsActionsComponent {

	@Input() selectedVersion: ZoneVersion;
	@Output() definitions = new EventEmitter();

	constructor( private dialogService: DialogService ) { }

	onDefinitions() {
		this.dialogService.confirm( 'Create Trackers Hole Definitions', `From Version: "${this.selectedVersion.Name}"` )
			.subscribe( positive => positive ? this.definitions.emit() : null );
	}
}
