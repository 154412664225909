import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component( {
	selector: 'ct-error-dialog',
	templateUrl: './dialog-error.component.html',
	styleUrls: ['./dialog-error.component.css']
} )
export class ErrorDialogComponent
{
	public title: string;
	public location: string;
	public message: string;

	constructor( public dialogRef: MatDialogRef<ErrorDialogComponent> )
	{
	}
}
