export class AdImage {
	constructor(
		public AdImageID: number,
		public Name: string,
		public Type: number,
		public TimeStamp: Date,
		public FileName: string,
		public Image: string,
		public Thumbnail: string,
		public FacilityID: number,
		public UnitModelID: number,

		public CSSClass: string = 'ad-image'				// DisplayAdImage
	) { }
}

export const AdImageTypes: AdImageType[] = [
	{ Type: 2, Code: 'BA', Name: 'Banner', VersionsThumbnailHeight: 33, ImagesThumbnailHeight: 50 },
	{ Type: 3, Code: 'GS', Name: 'Green Side', VersionsThumbnailHeight: 127, ImagesThumbnailHeight: 190 },
	{ Type: 4, Code: 'ST', Name: 'Staging', VersionsThumbnailHeight: 161, ImagesThumbnailHeight: 240 },
	{ Type: 5, Code: 'FS', Name: 'Full Screen', VersionsThumbnailHeight: 161, ImagesThumbnailHeight: 240 }
];

export const AdImageSizes: AdImageSize[] = [
	{ UnitModelID: 1, Type: 2, Width: 234, Heigth: 100 },
	{ UnitModelID: 1, Type: 3, Width: 234, Heigth: 380 },
	{ UnitModelID: 1, Type: 4, Width: 234, Heigth: 480 },
	{ UnitModelID: 1, Type: 5, Width: 234, Heigth: 480 },
	{ UnitModelID: 2, Type: 2, Width: 480, Heigth: 150 },
	{ UnitModelID: 2, Type: 3, Width: 480, Heigth: 650 },
	{ UnitModelID: 2, Type: 4, Width: 480, Heigth: 800 },
	{ UnitModelID: 2, Type: 5, Width: 480, Heigth: 800 }
];

export enum AdImageSources { Select, Crop, Html }

export class AdImageType {
	constructor(
		public Type: number,
		public Code: string,
		public Name: string,
		public VersionsThumbnailHeight: number,
		public ImagesThumbnailHeight: number
	) { }
}

export class AdImageSize {
	constructor(
		public UnitModelID: number,
		public Type: number,
		public Width: number,
		public Heigth: number
	) { }
}

export class AdImageListParameters {
	constructor(
		public FacilityID: number,
		public UnitModelID: number,
		public ImageTypeType: number
	) { }
}

export class AdImageUploadParameters {
	constructor(
		public FacilityID: number,
		public SourceFolder: string
	) { }
}

export class AdImageRenameParameters {
	constructor(
		public AdImageID: number,
		public Name: string
	) { }
}
