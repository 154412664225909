import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component( {
	selector: 'ct-nav-item',
	templateUrl: './nav-item.component.html',
	styleUrls: ['./nav-item.component.css']
} )
export class NavItemComponent
{
	@Input() icon = '';
	@Input() hint = '';
	@Input() routerLink: string | any[] = '/';
	@Output() activate = new EventEmitter();
}
