<h1 mat-dialog-title>Map Action Menu</h1>

<div mat-dialog-content>
	<mat-list>
		<mat-list-item>
			<mat-label>Fullscreen</mat-label>
			<button mat-mini-fab (click)="requestFullscreen()">
				<mat-icon>fullscreen</mat-icon>
			</button>
		</mat-list-item>
		<mat-list-item>
			<mat-label>Reset View</mat-label>
			<button mat-mini-fab (click)="resetView()">
				<mat-icon>center_focus_weak</mat-icon>
			</button>
		</mat-list-item>
		<mat-list-item>
			<mat-label>Set View</mat-label>
			<button mat-mini-fab (click)="setView()">
				<mat-icon>center_focus_strong</mat-icon>
			</button>
			<mat-form-field>
				<mat-label>Latitude</mat-label>
				<input matInput [(ngModel)]="latitude">
			</mat-form-field>
			<mat-form-field>
				<mat-label>Longitude</mat-label>
				<input matInput [(ngModel)]="longitude">
			</mat-form-field>

		</mat-list-item>
	</mat-list>
</div>

<div mat-dialog-actions>
	<button mat-raised-button mat-dialog-close>CLOSE</button>
</div>