import { NgModule } from '@angular/core';
import { CartTracMaterialModule } from '../../cart-trac-material.module';
import { CartTracAngularModule } from '../../cart-trac-angular.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducer } from './alarms.reducer';

import { AlarmsComponent } from './alarms.component';
import { AlarmsEffects } from './alarms.effects';
import { AlarmsService } from './alarms.service';

import { AlarmComponent } from './alarm/alarm.component';
import { PhoneNumbersComponent } from './phone-numbers/phone-numbers.component';

@NgModule( {
	declarations: [
		AlarmsComponent,
		AlarmComponent,
		PhoneNumbersComponent
	],
	imports: [
		CartTracAngularModule,
		CartTracMaterialModule,
		StoreModule.forFeature( 'adminAlarms', reducer ),
		EffectsModule.forFeature( [AlarmsEffects] )
	],
	exports: [
		AlarmsComponent,
		AlarmComponent,
		PhoneNumbersComponent
	],
	providers: [AlarmsService]
} )
export class AlarmsModule { }
