import { Component, Input, OnInit } from '@angular/core';

import { Hole } from '../courses.model';

@Component( {
	selector: 'ct-courses-holes-tabs',
	templateUrl: './courses-holes-tabs.component.html',
	styleUrls: ['./courses-holes-tabs.component.css']
} )
export class CoursesHolesTabsComponent implements OnInit {

	@Input() holes: Hole[];

	constructor() { }

	ngOnInit() {
	}

}
