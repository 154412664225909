import * as ZonesVersionsActions from './zones-versions.actions';

import { ZoneVersion } from './zones-versions.model';

export interface State {
	holesLocationsCount: number;
	progress: boolean;
	selectedVersion: ZoneVersion;
	versions: ZoneVersion[];
}

const initialState: State = {
	holesLocationsCount: null,
	progress: false,
	selectedVersion: null,
	versions: []
};

export function reducer( state = initialState, action: ZonesVersionsActions.ZonesAction ): State {
	switch ( action.type ) {
		case ZonesVersionsActions.HOLES_LOCATIONS_NEW:
		case ZonesVersionsActions.LOAD:
			return { ...state, progress: true };

		case ZonesVersionsActions.HOLES_LOCATIONS_NEW_FAIL:
		case ZonesVersionsActions.LOAD_FAIL:
			return initialState;

		case ZonesVersionsActions.HOLES_LOCATIONS_NEW_SUCCESS:
			return { ...state, progress: false, selectedVersion: null, holesLocationsCount: action.payload };

		case ZonesVersionsActions.LOAD_SUCCESS:
			return { ...state, progress: false, selectedVersion: null, versions: displayZoneVersion( action.payload ) };

		case ZonesVersionsActions.SELECT:
			action.payload.CSSClass = 'zone-version-selected';

			if ( state.selectedVersion ) {
				const index = state.versions.findIndex( v => v.ZoneVersionId === state.selectedVersion.ZoneVersionId );

				const version = { ...state.selectedVersion, CSSClass: 'zone-version' };

				const versions = Object.assign( [...state.versions], { [index]: version } );

				return { ...state, versions: versions, selectedVersion: action.payload };
			}
			else
				return { ...state, selectedVersion: action.payload };

		default:
			return state;
	}

	function displayZoneVersion( zoneVersions: ZoneVersion[] ): ZoneVersion[] {
		zoneVersions.forEach( zoneVersion => zoneVersion.CSSClass = 'zone-version' );
		return zoneVersions;
	}
}

export const getProgress = ( state: State ) => state.progress;
export const getVersions = ( state: State ) => state.versions;
export const getSelectedVersion = ( state: State ) => state.selectedVersion;
export const getHolesLocationsCount = ( state: State ) => state.holesLocationsCount;
