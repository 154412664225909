import { Component, Input, OnInit } from '@angular/core';

import { Facility } from '../../../facilities/facilities.model';

@Component( {
	selector: 'ct-branding',
	templateUrl: './branding.component.html',
	styleUrls: ['./branding.component.css']
} )
export class BrandingComponent implements OnInit {
	@Input() featureName: string;
	@Input() facility: Facility;

	constructor() { }

	ngOnInit() {
	}

	get facilityLogo(): string {
		return this.facility ? this.facility.Logo : null;
	}
}
