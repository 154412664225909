export class RecurringMessage {
	constructor(
		public Id: number,
		public FacilityId: number,
		public Active: boolean,
		public Text: string,
		public Time: string,
		public DaysOfWeek: number,
		public DayOfMonth: number,
		public DeviceNumbers: string,

		public CSSClass: string = 'recurring-message'			// Display
	) { }
}

export class RecurringMessageSetActiveParameters {
	constructor(
		public Id: number,
		public Active: boolean
	) { }
}
