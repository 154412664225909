import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { WebApiService } from '../../shared/web-api/web-api.service';
import { WebApiError } from '../../shared/web-api/web-api-error';

import { Map } from '../../facilities/facilities.model';

@Injectable()
export class MapService {
	private serviceBaseUrl: string;

	constructor( private webApiService: WebApiService ) {
		this.serviceBaseUrl = this.webApiService.serverUrl + '/api/facilities';
	}

	map( facilityId: number ): Observable<Map | WebApiError> {
		return this.webApiService.get<Map>( this.serviceBaseUrl + '/map/' + facilityId );
	}
}
