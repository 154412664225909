import { NgModule } from '@angular/core';
import { CartTracAngularModule } from '../../../cart-trac-angular.module';
import { CartTracMaterialModule } from '../../../cart-trac-material.module';

import { FacilityPickerComponent } from './facility-picker.component';

@NgModule( {
	declarations: [
		FacilityPickerComponent
	],
	exports: [
		FacilityPickerComponent,
	],
	imports: [
		CartTracAngularModule,
		CartTracMaterialModule
	]
} )
export class FacilityPickerModule { }
