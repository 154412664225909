import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { Observable, Subscription, Subject } from 'rxjs';
import { GPSUpdatesService } from './gps-updates.service';
import { GPSUpdate } from './gps-updates.model';
import { Facility } from '../../facilities/facilities.model';

@Component( {
	selector: 'ct-gps-updates',
	templateUrl: './gps-updates.component.html',
	styleUrls: ['./gps-updates.component.css']
} )
export class GpsUpdatesComponent implements OnInit, OnDestroy {

	@Input() facility: Facility;

	gpsUpdates: Subject<GPSUpdate[]> = new Subject();

	private gpsUpdate: Observable<GPSUpdate>;
	private gpsUpdatesArray: GPSUpdate[] = [];
	private gpsUpdateSubscription: Subscription;

	constructor( private gpsUpdatesService: GPSUpdatesService ) { }

	ngOnInit() {

		this.gpsUpdate = this.gpsUpdatesService.init( this.facility.Id );

		this.gpsUpdateSubscription = this.gpsUpdate
			.subscribe( gpsUpdate => {
				this.gpsUpdatesArray.push( gpsUpdate );
				this.gpsUpdates.next( this.gpsUpdatesArray );
			} );
	}

	ngOnDestroy() {
		this.gpsUpdatesService.close();
		this.gpsUpdateSubscription.unsubscribe();
	}
}
