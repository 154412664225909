import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Facility } from '../../../../facilities/facilities.model';
import { Device } from '../../devices.model';

@Component( {
	selector: 'ct-device-manager-devices-actions-move',
	templateUrl: './move.component.html',
	styleUrls: ['./move.component.css']
} )
export class MoveComponent implements OnInit {
	destinationFacility: Facility;

	constructor(
		public dialogRef: MatDialogRef<MoveComponent>,
		@Inject( MAT_DIALOG_DATA ) public data: { device: Device, facility: Facility }
	) { }

	ngOnInit() {
		this.destinationFacility = this.data.facility;
	}

	move() {
		this.dialogRef.close( this.destinationFacility );
	}

	onSelect( facility: Facility ) {
		this.destinationFacility = facility;
	}
}
