import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';

import { Observable, Subscription, combineLatest } from 'rxjs';

import { Store } from '@ngrx/store';

import * as fromCartTrac from '../cart-trac.reducers';
import * as TraceActions from './trace.actions';
import * as BingMapActions from '../shared/bing-map/bing-map.actions';
import * as RoutingActions from '../routing/routing.actions';
import * as LayoutActions from '../layout/layout.actions';

import { Course, Hole } from '../courses/courses.model';
import { Device } from '../device-manager/devices/devices.model';
import { Facility, Map } from '../facilities/facilities.model';
import { WebUser } from '../login/login.model';
import { TracePoint } from './trace-point.model';
import { TraceParameters } from '../trace-parameters/trace-parameters.model';

@Component( {
	selector: 'ct-trace-map',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './trace.component.html',
	styleUrls: ['./trace.component.css']
} )
export class TraceComponent implements OnDestroy, OnInit {
	courses$: Observable<Course[]>;
	holes$: Observable<Hole[]>;
	facility$: Observable<Facility>;
	fullscreen$: Observable<boolean>;
	map$: Observable<Map>;
	systemUser$: Observable<boolean>;
	webUser$: Observable<WebUser>;
	traceParameters$: Observable<TraceParameters>;
	tracePoints$: Observable<TracePoint[]>;

	bingMapLoaded: boolean;

	private devices$: Observable<Device[]>;
	private dataSubscription: Subscription;

	constructor( private store$: Store<fromCartTrac.State> ) { }

	ngOnInit() {
		this.courses$ = this.store$.select( fromCartTrac.getCourses );
		this.holes$ = this.store$.select( fromCartTrac.getHoles );
		this.devices$ = this.store$.select( fromCartTrac.getDevices );
		this.facility$ = this.store$.select( fromCartTrac.getFacility );
		this.fullscreen$ = this.store$.select( fromCartTrac.getBingMapFullscreen );
		this.map$ = this.store$.select( fromCartTrac.getMap );
		this.systemUser$ = this.store$.select( fromCartTrac.getSystemUser );
		this.webUser$ = this.store$.select( fromCartTrac.getWebUser );
		this.traceParameters$ = this.store$.select( fromCartTrac.getTraceParameters );
		this.tracePoints$ = this.store$.select( fromCartTrac.getTracePoints );
	}

	onBingMapLoaded() {
		this.bingMapLoaded = true;

		this.dataSubscription = combineLatest( this.traceParameters$, this.devices$,
			( traceParameters, devices ) => {
				if ( traceParameters ) {
					return { traceParameters, featureName: 'TRACE DEVICE #' + devices.find( device => device.Id === traceParameters.Id ).Number };
				}
			} )
			.subscribe( ( combined ) => {
				if ( combined ) {
					this.store$.dispatch( new LayoutActions.FeatureName( combined.featureName ) );
					this.store$.dispatch( new TraceActions.Load( combined.traceParameters ) );
				}
				else {
					this.store$.dispatch( new RoutingActions.Go( { path: ['/login/'] } ) );
				}
			} );
	}

	setFullscreen( fullscreen: boolean ) {
		this.store$.dispatch( new BingMapActions.Fullscreen( fullscreen ) );
	}

	ngOnDestroy() {
		this.store$.dispatch( new TraceActions.Reset() );

		if ( this.dataSubscription ) {
			this.dataSubscription.unsubscribe();
		}
	}
}
