<div class="branding">
  <table>
    <tr>
      <td class="branding-left">
        <span *ngIf="facilityLogo">
          <img src="data:image/PNG;base64,{{facilityLogo}}" class="branding-image-left" />
        </span>
      </td>
      <td class="branding-center">
        <span>{{featureName | uppercase}}</span>
      </td>
      <td class="branding-right">
        <img src="assets/cart-trac-logo.png" class="branding-image-right" />
      </td>
    </tr>
  </table>
</div>