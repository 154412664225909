import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromCartTrac from '../cart-trac.reducers';
import * as RoutingActions from '../routing/routing.actions';
import * as LayoutActions from '../layout/layout.actions';

import { Facility } from '../facilities/facilities.model';

import { Course, Hole } from './courses.model';

@Component( {
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'ct-courses',
	templateUrl: './courses.component.html',
	styleUrls: ['./courses.component.css']
} )
export class CoursesComponent implements OnInit, OnDestroy {

	courses$: Observable<Course[]>;
	holes$: Observable<Hole[]>;

	private facility$: Observable<Facility>;
	private facilitySubscription: Subscription;

	constructor( private store$: Store<fromCartTrac.State> ) {
		this.facility$ = this.store$.select( fromCartTrac.getFacility );
		this.courses$ = this.store$.select( fromCartTrac.getCourses );
		this.holes$ = this.store$.select( fromCartTrac.getHoles );
	}

	ngOnInit() {
		this.facilitySubscription = this.facility$.pipe( take( 1 ) )
			.subscribe( facility => {
				if ( facility )
					this.store$.dispatch( new LayoutActions.FeatureName( 'COURSES' ) );
				else
					this.store$.dispatch( new RoutingActions.Go( { path: ['/login/'] } ) );
			} );
	}

	ngOnDestroy() {
		this.facilitySubscription.unsubscribe();
	}
}
