import * as TraceParametersActions from './trace-parameters.actions';

import { TraceParameters } from './trace-parameters.model';

export interface State {
	traceParameters: TraceParameters;
}

const initialState: State = {
	traceParameters: null
};

export function reducer( state = initialState, action: TraceParametersActions.TraceParametersAction ): State {
	switch ( action.type ) {
		case TraceParametersActions.SET:
			return { ...state, traceParameters: action.payload };

		default:
			return state;
	}
}

export const getTraceParameters = ( state: State ) => state.traceParameters;
