import { NgModule } from '@angular/core';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ZonesVersionsEffects } from './zones-versions.effects';
import { reducer } from './zones-versions.reducer';

import { CartTracAngularModule } from '../../cart-trac-angular.module';
import { CartTracMaterialModule } from '../../cart-trac-material.module';
import { DialogModule } from '../../shared/dialogs/dialog.module';
import { PipesModule } from '../../shared/utils/pipes/pipes.module';

import { ZonesVersionsComponent } from './zones-versions.component';
import { ZonesVersionsListComponent } from './zones-versions-list/zones-versions-list.component';
import { ZonesVersionsActionsComponent } from './zones-versions-actions/zones-versions-actions.component';

import { ZonesVersionsService } from './zones-versions.service';

@NgModule( {
	declarations: [
		ZonesVersionsComponent,
		ZonesVersionsListComponent,
		ZonesVersionsActionsComponent
	],
	imports: [
		CartTracAngularModule,
		CartTracMaterialModule,
		DialogModule,
		PipesModule,

		StoreModule.forFeature( 'zonesVersions', reducer ),
		EffectsModule.forFeature( [ZonesVersionsEffects] )
	],
	exports: [ZonesVersionsComponent],
	providers: [ZonesVersionsService]
} )
export class ZonesVersionsModule { }
