import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component( {
	selector: 'ct-alert-dialog',
	templateUrl: './dialog-alert.component.html',
	styleUrls: ['./dialog-alert.component.css']
} )
export class AlertDialogComponent
{
	public title: string;
	public message: string;

	constructor( public dialogRef: MatDialogRef<AlertDialogComponent> )
	{
	}
}
