import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Device, Mode } from '../../devices.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BingMapEntities } from '../../../../shared/bing-map/bing-map-entities';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
	selector: 'ct-devices-edit',
	templateUrl: './edit.component.html',
	styleUrls: [ './edit.component.css' ]
})
export class EditComponent implements OnInit {
	deviceForm: FormGroup;

	modeId: string;
	fillColor: string;
	textColor: string;
	borderColor: string;
	deviceIMG: string;
	firmwareEnabled: boolean;
	configEnabled: boolean;
	adsEnabled: boolean;
	zonesEnabled: boolean;
	// tslint:disable-next-line:no-inferrable-types
	changed: boolean = false;

	get disabled() {
		return this.deviceForm.invalid || this.deviceForm.valid && !this.changed;
	}

	constructor(
		private fb: FormBuilder,
		public dialogRef: MatDialogRef<EditComponent>,
		@Inject(MAT_DIALOG_DATA) public data: { device: Device }
	) { }

	ngOnInit() {
		this.deviceForm = this.fb.group({
			number: [ this.data.device.Number, [ Validators.required, Validators.pattern('[0-9]{3}') ] ],
			name: [ this.data.device.Name, [ Validators.maxLength(25) ] ]
		});

		this.modeId = this.data.device.ModeId;
		this.fillColor = '#' + this.data.device.FillColor.slice(2);
		this.textColor = '#' + this.data.device.TextColor.slice(2);
		this.borderColor = '#' + this.data.device.BorderColor.slice(2);
		this.deviceIMG = BingMapEntities.deviceCanvas(this.deviceForm.get('number').value, 'X' + this.textColor, 'X' + this.borderColor, 'X' + this.fillColor);
		this.firmwareEnabled = this.data.device.FirmwareDownloadEnabled;
		this.configEnabled = this.data.device.ConfigDownloadEnabled;
		this.adsEnabled = this.data.device.AdsDownloadEnabled;
		this.zonesEnabled = this.data.device.ZonesDownloadEnabled;
	}

	onModeChange(mode: Mode) {
		this.modeId = mode.Id;

		if (this.data.device.ModeId !== this.modeId)
			this.changed = true;
	}

	onFillColorChange(event: Event) {
		this.fillColor = (event.currentTarget as HTMLInputElement).value;
		this.deviceIMG = BingMapEntities.deviceCanvas(this.deviceForm.get('number').value, 'X' + this.textColor, 'X' + this.borderColor, 'X' + this.fillColor);
		this.changed = true;
	}

	onTextColorChange(event: Event) {
		this.textColor = (event.currentTarget as HTMLInputElement).value;
		this.deviceIMG = BingMapEntities.deviceCanvas(this.deviceForm.get('number').value, 'X' + this.textColor, 'X' + this.borderColor, 'X' + this.fillColor);
		this.changed = true;
	}

	onBorderColorChange(event: Event) {
		this.borderColor = (event.currentTarget as HTMLInputElement).value;
		this.deviceIMG = BingMapEntities.deviceCanvas(this.deviceForm.get('number').value, 'X' + this.textColor, 'X' + this.borderColor, 'X' + this.fillColor);
		this.changed = true;
	}

	onDownloadChange(event: MatSlideToggleChange) {
		this.changed = true;
	}

	updateDevice() {
		this.dialogRef.close({
			Id: this.data.device.Id,
			ModeId: this.modeId,
			Number: this.deviceForm.get('number').value,
			Name: this.deviceForm.get('name').value,
			FillColor: 'FF' + this.fillColor.slice(1),
			TextColor: 'FF' + this.textColor.slice(1),
			BorderColor: 'FF' + this.borderColor.slice(1),
			FirmwareDownloadEnabled: this.firmwareEnabled,
			ConfigDownloadEnabled: this.configEnabled,
			AdsDownloadEnabled: this.adsEnabled,
			ZonesDownloadEnabled: this.zonesEnabled
		});
	}
}
