import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { MobileSettings, WebUser } from '../../login/login.model';

@Component( {
	selector: 'ct-settings-other',
	templateUrl: './settings-other.component.html',
	styleUrls: ['./settings-other.component.css']
} )
export class SettingsOtherComponent implements OnInit {
	@Input() webUser: WebUser;
	@Output() saveSettings = new EventEmitter<MobileSettings>();

	constructor() { }

	ngOnInit() {
	}

	save() {
		this.saveSettings.emit( this.webUser.Settings );
	}
}
