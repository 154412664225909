import * as CoursesActions from './courses.actions';

import { Course, Hole } from './courses.model';

export interface State {
	courses: Course[];
	holes: Hole[];
	progress: boolean;
}

const initialState: State = {
	courses: [],
	holes: [],
	progress: false,
};

export function reducer( state = initialState, action: CoursesActions.CoursesAction ): State {
	switch ( action.type ) {
		case CoursesActions.LIST:
			return { ...state, progress: true };

		case CoursesActions.LIST_SUCCESS:
			return { ...state, progress: false, courses: action.courses };

		case CoursesActions.HOLES_LIST:
			return { ...state, progress: true };

		case CoursesActions.HOLES_LIST_SUCCESS:
			return { ...state, progress: false, holes: action.holes };

		case CoursesActions.LIST_FAIL:
		case CoursesActions.HOLES_LIST_FAIL:
			return initialState;

		default:
			return state;
	}
}

export const getProgress = ( state: State ) => state.progress;
export const getCourses = ( state: State ) => state.courses;
export const getHoles = ( state: State ) => state.holes;
