import * as FacilitiesActions from './facilities.actions';
import { Facility, Map, FacilitySettings } from './facilities.model';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';

export interface State extends EntityState<Facility> {
	map: Map;
	progress: boolean;
	selected: Facility;
	sortOrder: number;
}

export const adapter = createEntityAdapter<Facility>( {
	selectId: ( facility: Facility ) => facility.Id,
	sortComparer: false
} );

const initialState: State = adapter.getInitialState( {
	map: null,
	progress: false,
	selected: null,
	sortOrder: 1
} );

export function reducer( state = initialState, action: FacilitiesActions.FacilitiesAction ): State {
	switch ( action.type ) {
		case FacilitiesActions.LIST:
		case FacilitiesActions.SETTINGS_SAVE:
			return { ...state, progress: true };

		case FacilitiesActions.LIST_FAIL:
		case FacilitiesActions.SETTINGS_SAVE_FAIL:
			return initialState;

		case FacilitiesActions.LIST_SUCCESS:
			action.facilities.forEach( facility => facility.Settings = parseFacilitySettings( facility.Settings as any ) );

			return { ...adapter.addAll( action.facilities, state ), progress: false, selected: null };

		case FacilitiesActions.SELECTED:
			return { ...state, selected: action.facility };

		case FacilitiesActions.SETTINGS_SAVE_SUCCESS:
			state.selected.Settings = parseFacilitySettings( action.settingsDTO.JSON );
			return { ...adapter.updateOne( { id: action.settingsDTO.FacilityId, changes: state.selected }, state ), progress: false };

		default:
			return state;
	}
}

function parseFacilitySettings( json: string ): FacilitySettings {

	let settings: FacilitySettings = JSON.parse( json );

	if ( !settings ) {
		settings = {
			ResetDeviceColorEOD: false,
			ResetDeviceNameEOD: false,
			ResetDeviceModeEOD: false,
			ResetDeviceModeEOR: true
		};
	}

	return settings;
}

export const {
	selectIds: getIds,
	selectEntities: getEntities,
	selectAll: getAll,
	selectTotal: getCount,
} = adapter.getSelectors();

export const getFacility = ( state: State ) => state.selected;
export const getFacilitySettings = ( state: State ) => state.selected.Settings;
export const getProgress = ( state: State ) => state.progress;
export const getSortOrder = ( state: State ) => state.sortOrder;

const getSortedIds = createSelector(
	getAll,
	getSortOrder,
	( facilities, sortOrder ) => {

		let sortFunction: ( a: Facility, b: Facility ) => number;

		switch ( sortOrder ) {
			case 1:
				sortFunction = ( a, b ) => ( a.Name > b.Name ? 1 : -1 );
				break;
		}

		return facilities
			.slice()									// don't change devices
			.sort( sortFunction )						// sort
			.map( device => device.Id );				// return ids
	} );

export const getAllSorted = createSelector(
	getEntities,
	getSortedIds,
	( entities, sortedIds ) => {
		return sortedIds.map( id => entities[id] );
	} );
