export class TrackingDevice
{
	constructor(
		public Id: number,
		public Latitude: number,
		public Longitude: number,
		public Location: string,
		public PaceOfPlay: number,
		public GPSTime: Date,
		public AlertOn: boolean,
		public FillColor: string,
		public TextColor: string,
		public BorderColor: string,
		public RoundStartTime: Date,
		public ZoneType: number,

		public Mode: string,			// DisplayTrackingDevice
		public ModeName: string,
		public Name: string,
		// tslint:disable-next-line:variable-name
		public Number: string,
	) { }
}

export class TrackingParameters
{
	constructor(
		public Id: number,
		public LoginId: number
	) { }
}
