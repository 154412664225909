import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { Mode } from '../../../device-manager/devices/devices.model';
import { MatSelectChange } from '@angular/material/select';
import { Store } from '@ngrx/store';
import * as fromCartTrac from '../../../cart-trac.reducers';
import { Observable, Subscription } from 'rxjs';
import * as SystemAction from '../../../system/system.actions';

@Component( {
	selector: 'ct-mode-picker',
	templateUrl: './mode-picker.component.html',
	styleUrls: ['./mode-picker.component.css']
} )
export class ModePickerComponent implements OnDestroy, OnInit {
	@Input() label: boolean;
	@Input() selected: Mode;
	@Input() modeId: string;
	@Output() select = new EventEmitter<Mode>();

	modes$: Observable<Mode[]>;
	pickedMode$: Observable<Mode>;

	modes: Mode[];

	private modesSubscription: Subscription;
	private pickerSubscription: Subscription;

	constructor( private store$: Store<fromCartTrac.State> ) {
		this.modes$ = this.store$.select( fromCartTrac.getModes );
		this.pickedMode$ = this.store$.select( fromCartTrac.getPickedMode );
	}

	ngOnInit() {
		this.modesSubscription = this.modes$
			.subscribe( modes => {
				this.modes = modes;

				if ( !this.selected && this.modes.length > 0 ) {

					let mode: Mode;

					if ( this.modeId )
						mode = this.modes.find( m => m.Id === this.modeId );
					else
						mode = this.modes[0];

					this.select.emit( mode );
					this.store$.dispatch( new SystemAction.PickMode( mode ) );
				}
			} );

		this.pickerSubscription = this.pickedMode$
			.subscribe( mode => {
				this.selected = mode;
			} );
	}

	selectionChange( event: MatSelectChange ) {
		this.select.emit( event.value );
		this.store$.dispatch( new SystemAction.PickMode( event.value ) );
	}

	ngOnDestroy() {
		this.modesSubscription.unsubscribe();
		this.pickerSubscription.unsubscribe();
	}
}
