import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromCartTrac from '../cart-trac.reducers';
import * as RangerActions from './ranger.actions';
import * as RoutingActions from '../routing/routing.actions';
import * as TraceParametersActions from '../trace-parameters/trace-parameters.actions';
import * as LayoutActions from '../layout/layout.actions';

import { RangerData } from './ranger-data.model';
import { Facility } from '../facilities/facilities.model';

@Component({
	selector: 'ct-ranger',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './ranger.component.html',
	styleUrls: ['./ranger.component.css']
})
export class RangerComponent implements OnInit, OnDestroy {

	rangerData$: Observable<RangerData[]>;

	private facility$: Observable<Facility>;
	private facilitySubscription: Subscription;
	private timerId: number;

	constructor(private store$: Store<fromCartTrac.State>) {

		const sortRangerData = (a: RangerData, b: RangerData) => {
			if (isNaN(parseFloat(a.Number)) || isNaN(parseFloat(b.Number))) { 	// Isn't a number so lowercase the string to properly compare
				if (a.Number.toLowerCase() < b.Number.toLowerCase()) { return -1; }
				if (a.Number.toLowerCase() > b.Number.toLowerCase()) { return 1; }
			} else {
				if (parseFloat(a.Number) < parseFloat(b.Number)) { return -1; }		// Parse strings as numbers to compare properly
				if (parseFloat(a.Number) > parseFloat(b.Number)) { return 1; }
			}

			return 0; // equal each other
		};

		this.rangerData$ = this.store$.select(fromCartTrac.getRangerData);
		this.facility$ = this.store$.select(fromCartTrac.getFacility);

		this.rangerData$ = this.store$.select(fromCartTrac.getRangerData).pipe(
			map((RangerDataCourses: RangerData[]) => RangerDataCourses ? RangerDataCourses.sort(sortRangerData) : [])
		);
	}

	ngOnInit() {
		this.facilitySubscription = this.facility$.pipe(take(1))
			.subscribe(facility => {
				if (facility) {
					this.store$.dispatch(new LayoutActions.FeatureName('RANGER'));
					this.store$.dispatch(new RangerActions.Load(facility.Id));
					this.timerId = window.setInterval(() => this.store$.dispatch(new RangerActions.Load(facility.Id)), 15000);
				}
				else {
					this.store$.dispatch(new RoutingActions.Go({ path: ['/login/'] }));
				}
			});
	}

	traceDevice(id: number) {
		this.store$.dispatch(new TraceParametersActions.Set({ Id: id }));
		this.store$.dispatch(new RoutingActions.Go({ path: ['/trace/'] }));
	}

	ngOnDestroy() {
		this.facilitySubscription.unsubscribe();
		clearInterval(this.timerId);
	}
}
