<button mat-icon-button matTooltip="Command" (click)="onCommand()" [disabled]="!selected">
    <mat-icon class="material-icons">cloud_upload</mat-icon>
</button>
<button mat-icon-button matTooltip="Reset" (click)="onReset()" [disabled]="!selected">
    <mat-icon class="material-icons">cancel</mat-icon>
</button>
<button mat-icon-button matTooltip="Power On / Off" (click)="onPowerOnOff()" [disabled]="powerOnOffDisabled()">
    <mat-icon class="material-icons">power</mat-icon>
</button>
<button mat-icon-button matTooltip="Sort by" [matMenuTriggerFor]="sortMenu" [disabled]="trackers.length < 2">
    <mat-icon class="material-icons">sort</mat-icon>
</button>
<mat-menu #sortMenu="matMenu">
    <button mat-menu-item (click)="sort(1)">Number</button>
    <button mat-menu-item (click)="sort(2)">MEID</button>
    <button mat-menu-item (click)="sort(3)">Last Update</button>
    <button mat-menu-item (click)="sort(4)">Model</button>
</mat-menu>