import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { AlarmPhoneNumber } from '../alarms.model';

@Component( {
	selector: 'ct-phone-numbers',
	templateUrl: './phone-numbers.component.html',
	styleUrls: ['./phone-numbers.component.css']
} )
export class PhoneNumbersComponent implements OnInit {

	@Input() phoneNumbers: AlarmPhoneNumber[];
	@Output() delete = new EventEmitter<number>();
	@Output() new = new EventEmitter<string>();
	@Output() update = new EventEmitter<AlarmPhoneNumber>();

	get addDisabled(): boolean { return this.addMode || ( this.phoneNumbers && this.phoneNumbers.length >= this.maxPhoneNumbers ); }

	invalidFlags: boolean[] = [];
	readonly phoneNumberRegEx = '^\\+\\d{11,14}$';

	private addMode: boolean = false;
	private readonly maxPhoneNumbers = 3;

	constructor() { }

	ngOnInit() {
	}

	ngOnChanges() {
		this.invalidFlags = [];
		this.phoneNumbers.forEach( phoneNumber => this.invalidFlags.push( phoneNumber.ToPhoneNumber.match( this.phoneNumberRegEx ) === null ) );
	}

	onAdd() {
		this.addMode = true;
		this.phoneNumbers.push( new AlarmPhoneNumber( null, null, null ) );
	}

	onDelete( id: number ) {
		if ( this.addMode ) {
			this.addMode = false;
			this.phoneNumbers.pop();
		}
		else
			this.delete.emit( id );
	}

	onChange( index: number, toPhonePhoneNumber: string ) {
		if ( this.phoneNumbers[index].ToPhoneNumber.match( this.phoneNumberRegEx ) === null ) {
			this.invalidFlags[index] = true;
		}
		else {
			this.invalidFlags[index] = false;

			if ( this.addMode ) {
				this.addMode = false;
				this.new.emit( toPhonePhoneNumber );
			}
			else
				this.update.emit( new AlarmPhoneNumber( this.phoneNumbers[index].Id, this.phoneNumbers[index].FacilityId, toPhonePhoneNumber ) );
		}
	}


}
