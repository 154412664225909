import { NgModule } from '@angular/core';
import { CartTracMaterialModule } from '../../cart-trac-material.module';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogService } from './dialog.service';
import { AlertDialogComponent } from './dialog-alert.component';
import { ConfirmDialogComponent } from './dialog-confirm.component';
import { ErrorDialogComponent } from './dialog-error.component';

export const DIALOGS = [
	AlertDialogComponent,
	ConfirmDialogComponent,
	ErrorDialogComponent
];

@NgModule( {
	imports: [CartTracMaterialModule],
	exports: DIALOGS,
	declarations: DIALOGS,
	providers: [
		DialogService,
		{ provide: MAT_DIALOG_DATA, useValue: {} },
		{ provide: MatDialogRef, useValue: {} }
	]
} )
export class DialogModule { }
