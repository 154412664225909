import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { VisibleDeviceMode } from '../settings.model';
import { MobileSettings, WebUser } from '../../login/login.model';

@Component( {
	selector: 'ct-settings-tracking',
	templateUrl: './settings-tracking.component.html',
	styleUrls: ['./settings-tracking.component.css']
} )
export class SettingsTrackingComponent implements OnInit {
	@Input() visibleDeviceModes: VisibleDeviceMode[];
	@Input() webUser: WebUser;
	@Output() saveSettings = new EventEmitter<MobileSettings>();

	constructor() { }

	ngOnInit() {
	}

	save() {
		this.webUser.Settings.Tracking.Modes = [];

		this.visibleDeviceModes.forEach( ( visibleDeviceMode: VisibleDeviceMode ) => {
			if ( visibleDeviceMode.checked ) {
				this.webUser.Settings.Tracking.Modes.push( visibleDeviceMode.value );
			}
		} );

		this.saveSettings.emit( this.webUser.Settings );
	}
}
