import { Component, ChangeDetectionStrategy, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromCartTrac from '../cart-trac.reducers';
import * as ZonesVersionsActions from './zones-versions/zones-versions.actions';
import * as LayoutActions from '../layout/layout.actions';
import * as RoutingActions from '../routing/routing.actions';

import { Facility } from '../facilities/facilities.model';
import { ZoneVersion } from './zones-versions/zones-versions.model';
import { ZonesTabs } from './zones-versions/zones-versions.model';

@Component( {
	selector: 'ct-zones',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './zones.component.html',
	styleUrls: ['./zones.component.css']
} )
export class ZonesComponent implements OnDestroy, OnInit {

	facility$: Observable<Facility>;
	selectedVersion$: Observable<ZoneVersion>;
	zoneVersions$: Observable<ZoneVersion[]>;

	private facilityId: number;
	private facilitySubscription: Subscription;

	constructor( private store$: Store<fromCartTrac.State> ) {
		this.facility$ = this.store$.select( fromCartTrac.getFacility );
		this.selectedVersion$ = this.store$.select( fromCartTrac.getSelectedZonesVersion );
	}

	ngOnInit() {
		this.facilitySubscription = this.facility$.pipe( take( 1 ) )
			.subscribe( facility => {
				if ( facility ) {
					this.facilityId = facility.Id;
					this.store$.dispatch( new LayoutActions.FeatureName( 'ZONES' ) );
					this.tabChanged( ZonesTabs.Versions );
				} else {
					this.store$.dispatch( new RoutingActions.Go( { path: ['/login/'] } ) );
				}
			} );
	}

	tabChanged( tabIndex: number ) {
		switch ( tabIndex ) {
			case ZonesTabs.Versions:
				this.store$.dispatch( new ZonesVersionsActions.Load( this.facilityId ) );
				break;
			case ZonesTabs.Other:
				break;
		}
	}

	ngOnDestroy() {
		this.facilitySubscription.unsubscribe();
	}
}
