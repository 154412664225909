import { Action } from '@ngrx/store';

import { TraceParameters } from './trace-parameters.model';

export const SET = '[TraceParameters] Set';

export class Set implements Action {
	readonly type = SET;

	constructor( public payload: TraceParameters ) { }
}

export type TraceParametersAction
	= Set;
