<form [formGroup]="traceDatesForm">
	<table>
		<tr>
			<td>
				<mat-form-field>
					<input matInput placeholder="Start Date" [matDatepicker]="startDatepicker" formControlName="startDateControl" (dateChange)="startDateChanged($event)"
					/>
					<mat-datepicker-toggle matSuffix [for]="startDatepicker"></mat-datepicker-toggle>
					<mat-datepicker #startDatepicker></mat-datepicker>
				</mat-form-field>
			</td>
			<td>
				<mat-form-field>
					<input matInput placeholder="End Date" [matDatepicker]="endDatepicker" formControlName="endDateControl" (dateChange)="endDateChanged($event)"
					/>
					<mat-datepicker-toggle matSuffix [for]="endDatepicker"></mat-datepicker-toggle>
					<mat-datepicker #endDatepicker></mat-datepicker>
				</mat-form-field>
			</td>
		</tr>
	</table>
</form>