<mat-toolbar color="secondary" *ngIf="edit">Edit Recurring Message</mat-toolbar>
<mat-toolbar color="secondary" *ngIf="!edit">New Recurring Message</mat-toolbar>

<form [formGroup]="editForm" (ngSubmit)="onSubmit()">
	<mat-slide-toggle id="active" matInput formControlName="active">Active</mat-slide-toggle>

	<div id="text">
		<mat-form-field>
			<textarea matInput type="text" placeholder="Message" formControlName="text"></textarea>
			<mat-error *ngIf="text.invalid">{{ errorText() }}</mat-error>
		</mat-form-field>
	</div>

	<div id="deviceNumbers">
		<mat-form-field>
			<input matInput type="text" placeholder="Device Numbers" formControlName="deviceNumbers">
			<mat-error *ngIf="deviceNumbers.invalid">{{ errorDeviceNumbers() }}</mat-error>
		</mat-form-field>
	</div>

	<div>
		<mat-form-field id=time>
			<input matInput type="time" placeholder="Time" formControlName="time">
			<mat-error *ngIf="time.invalid">{{ errorTime() }}</mat-error>
		</mat-form-field>

		<mat-form-field id=dayOfMonth>
			<mat-select matInput placeholder="Day of Month" formControlName="dayOfMonth">
				<mat-option value="0">Everyday</mat-option>
				<mat-option value="1">1</mat-option>
				<mat-option value="2">2</mat-option>
				<mat-option value="3">3</mat-option>
				<mat-option value="4">4</mat-option>
				<mat-option value="5">5</mat-option>
				<mat-option value="6">6</mat-option>
				<mat-option value="7">7</mat-option>
				<mat-option value="8">8</mat-option>
				<mat-option value="9">9</mat-option>
				<mat-option value="10">10</mat-option>
				<mat-option value="11">11</mat-option>
				<mat-option value="12">12</mat-option>
				<mat-option value="13">13</mat-option>
				<mat-option value="14">14</mat-option>
				<mat-option value="15">15</mat-option>
				<mat-option value="16">16</mat-option>
				<mat-option value="17">17</mat-option>
				<mat-option value="18">18</mat-option>
				<mat-option value="19">19</mat-option>
				<mat-option value="20">20</mat-option>
				<mat-option value="21">21</mat-option>
				<mat-option value="22">22</mat-option>
				<mat-option value="23">23</mat-option>
				<mat-option value="24">24</mat-option>
				<mat-option value="25">25</mat-option>
				<mat-option value="26">26</mat-option>
				<mat-option value="27">27</mat-option>
				<mat-option value="28">28</mat-option>
				<mat-option value="29">29</mat-option>
				<mat-option value="30">30</mat-option>
				<mat-option value="31">31</mat-option>
			</mat-select>
		</mat-form-field>
	</div>
	<div id="daysOfWeekAll" formGroupName="daysOfWeek">
		<label>Days of Week</label>
		<mat-slide-toggle labelPosition="before" formControlName="daysOfWeekAll">All</mat-slide-toggle>
		<div id="daysOfWeek">
			<mat-checkbox labelPosition="before" formControlName="daysOfWeekSun">Sun</mat-checkbox>
			<mat-checkbox labelPosition="before" formControlName="daysOfWeekMon">Mon</mat-checkbox>
			<mat-checkbox labelPosition="before" formControlName="daysOfWeekTue">Tue</mat-checkbox>
			<mat-checkbox labelPosition="before" formControlName="daysOfWeekWed">Wed</mat-checkbox>
			<mat-checkbox labelPosition="before" formControlName="daysOfWeekThu">Thu</mat-checkbox>
			<mat-checkbox labelPosition="before" formControlName="daysOfWeekFri">Fri</mat-checkbox>
			<mat-checkbox labelPosition="before" formControlName="daysOfWeekSat">Sat</mat-checkbox>
			<div id=daysOfWeekError>{{ errorDaysOfWeek() }}</div>
		</div>
	</div>

	<div id="buttons">
		<button mat-raised-button type="reset" (click)="onCancel()">Cancel</button>
		<button mat-raised-button type="submit"
			[disabled]="editForm.invalid || (editForm.valid && editForm.pristine)">Submit</button>
	</div>
</form>