import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { WebApiService } from '../../shared/web-api/web-api.service';
import { WebApiError } from '../../shared/web-api/web-api-error';
import { ZoneVersion } from './zones-versions.model';

@Injectable()
export class ZonesVersionsService {
	private serviceBaseUrl: string;

	constructor( private webApiService: WebApiService ) {
		this.serviceBaseUrl = this.webApiService.serverUrl + '/api/zones';
	}

	versions( facilityId: number ): Observable<ZoneVersion[] | WebApiError> {
		return this.webApiService.get<ZoneVersion[]>( this.serviceBaseUrl + '/versions/' + facilityId );
	}
}
