import { ChangeDetectionStrategy, Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';

import * as fromCartTrac from '../cart-trac.reducers';
import * as TrackingActions from './tracking.actions';
import * as BingMapActions from '../shared/bing-map/bing-map.actions';
import * as RoutingActions from '../routing/routing.actions';
import * as LayoutActions from '../layout/layout.actions';

import { Course, Hole } from '../courses/courses.model';
import { Facility, Map } from '../facilities/facilities.model';
import { WebUser } from '../login/login.model';
import { TrackingDevice, TrackingParameters } from './tracking-device.model';

@Component({
	selector: 'ct-tracking-map',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './tracking.component.html',
	styleUrls: ['./tracking.component.css']
})
export class TrackingComponent implements OnDestroy, OnInit {
	courses$: Observable<Course[]>;
	holes$: Observable<Hole[]>;
	trackingDevices$: Observable<TrackingDevice[]>;
	facility$: Observable<Facility>;
	fullscreen$: Observable<boolean>;
	map$: Observable<Map>;
	webUser$: Observable<WebUser>;
	settingsModes$: Observable<string[]>;
	settingsUsePOPColors$: Observable<boolean>;
	systemUser$: Observable<boolean>;

	bingMapLoaded: boolean;

	private facilitySubscription: Subscription;
	private facilityId: number;
	private webUserSubscription: Subscription;
	private loginId: number;
	private timerId: number;

	constructor(private store$: Store<fromCartTrac.State>) { }

	ngOnInit() {
		this.courses$ = this.store$.select(fromCartTrac.getCourses);
		this.holes$ = this.store$.select(fromCartTrac.getHoles);
		this.trackingDevices$ = this.store$.select(fromCartTrac.getTrackingDevices);
		this.facility$ = this.store$.select(fromCartTrac.getFacility);
		this.fullscreen$ = this.store$.select(fromCartTrac.getBingMapFullscreen);
		this.map$ = this.store$.select(fromCartTrac.getMap);
		this.systemUser$ = this.store$.select(fromCartTrac.getSystemUser);
		this.webUser$ = this.store$.select(fromCartTrac.getWebUser);
		this.settingsModes$ = this.store$.select(fromCartTrac.getSettingsModes);

		this.facilitySubscription = this.facility$.pipe(take(1))
			.subscribe(facility => {
				if (facility) {
					this.facilityId = facility.Id;
					this.store$.dispatch(new LayoutActions.FeatureName('TRACKING'));
				}
				else {
					this.store$.dispatch(new RoutingActions.Go({ path: ['/login/'] }));
				}
			});

		this.webUserSubscription = this.webUser$.pipe(take(1))
			.subscribe( webUser => {
				if (webUser) {
					this.loginId = webUser.Id;
				}
				else {
					this.store$.dispatch(new RoutingActions.Go({ path: ['/login/'] }));
				}
			});

	}

	onBingMapLoaded() {
		this.bingMapLoaded = true;
		this.store$.dispatch(new TrackingActions.Load(new TrackingParameters(this.facilityId, this.loginId)));
		this.timerId = window.setInterval(() => this.store$.dispatch(new TrackingActions.Load(new TrackingParameters(this.facilityId, this.loginId))), 15000);
	}

	setFullscreen(fullscreen: boolean) {
		this.store$.dispatch(new BingMapActions.Fullscreen(fullscreen));
	}

	ngOnDestroy() {
		this.facilitySubscription.unsubscribe();
		this.webUserSubscription.unsubscribe();
		clearInterval(this.timerId);
	}
}
