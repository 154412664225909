import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CdkDropList } from '@angular/cdk/drag-drop';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromCartTrac from '../../cart-trac.reducers';
import * as VersionsActions from './versions.actions';
import * as SetsActions from '../sets/sets.actions';
import * as ImagesActions from '../images/images.actions';
import * as AdminActions from '../../admin/admin.actions';
import { AdVersion, AdVersionRenameParameters, AdVersionCopyParameters, AdVersionPublishParameters } from './versions.model';
import { Facility } from '../../facilities/facilities.model';
import { Model } from '../../device-manager/devices/devices.model';
import { AdSet } from '../sets/sets.model';
import { AdImage, AdImageListParameters, AdImageTypes, AdImageType } from '../images/images.model';
import { Course } from '../../courses/courses.model';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { MatCard } from '@angular/material/card';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { DialogService } from '../../shared/dialogs/dialog.service';
import { NewComponent } from './new/new.component';
import { FormErrorStateMatcher } from '../../shared/utils/form-error-state-matcher';
import { distinctUntilChanged } from 'rxjs/operators';
import { SetsComponent } from '../sets/sets.component';

@Component( {
	selector: 'ct-ad-manager-versions',
	templateUrl: './versions.component.html',
	styleUrls: ['./versions.component.css']
} )
export class VersionsComponent implements OnInit, OnDestroy {

	@Input() facility: Facility;
	@Input() published: boolean;
	@Input() selected: AdVersion;
	@Input() selectedAdImageType: AdImageType;
	@Input() selectedUnitModel: Model;

	adDropList$: Observable<CdkDropList<MatCard[]>>;
	adImages$: Observable<AdImage[]>;
	adSets$: Observable<AdSet[]>;
	adVersions$: Observable<AdVersion[]>;
	courses$: Observable<Course[]>;
	nameExists$: Observable<boolean>;
	selectedAdSet$: Observable<AdSet>;

	editMode = false;
	editForm: FormGroup;
	nameErrorMatcher = new FormErrorStateMatcher();
	renameExists: boolean;
	get name(): string { return this.editForm.get( 'name' ).value; }

	adDropList: CdkDropList<MatCard[]>;

	@ViewChild( 'setsComponent' ) setsComponent: SetsComponent;

	private nameExistsSubscription: Subscription;
	private dropListSubscription: Subscription;
	private dialogRef: MatDialogRef<NewComponent>;

	constructor(
		private store$: Store<fromCartTrac.State>,
		private formBuilder: FormBuilder,
		private dialog: MatDialog,
		private dialogService: DialogService
	) {

		this.adDropList$ = this.store$.select( fromCartTrac.getAdManagerDropListRef );
		this.adImages$ = this.store$.select( fromCartTrac.getAdManagerImages );
		this.adSets$ = this.store$.select( fromCartTrac.getAdManagerSets );
		this.adVersions$ = this.store$.select( fromCartTrac.getAdManagerVersions );
		this.courses$ = this.store$.select( fromCartTrac.getCourses );
		this.nameExists$ = this.store$.select( fromCartTrac.getAdManagerVersionNameExists );
		this.selectedAdSet$ = this.store$.select( fromCartTrac.getSelectedAdSet );
	}

	ngOnInit() {
		this.dropListSubscription = this.adDropList$.subscribe( dropList => this.adDropList = dropList );

		this.editForm = this.formBuilder.group( {
			name: [null, Validators.required]
		} );
	}

	ngOnDestroy() {
		if ( this.nameExistsSubscription ) {
			this.nameExistsSubscription.unsubscribe();
		}

		this.dropListSubscription.unsubscribe();
	}

	activate( adVersion: AdVersion ) {
		this.store$.dispatch( new VersionsActions.Activate( adVersion.AdVersionID ) );
	}

	closed( adVersion: AdVersion ) {
		if ( this.selected && this.selected.AdVersionID === adVersion.AdVersionID ) {
			this.editMode = false;
			this.reset();
		}

		this.onClearImages();
	}

	copy( adVersion: AdVersion ) {
		const now = new Date();
		const daySeconds = now.getHours() * 3600 + now.getSeconds();
		this.store$.dispatch( new VersionsActions.Copy( new AdVersionCopyParameters( adVersion.AdVersionID, adVersion.Name + ' - Copy - ' + daySeconds ) ) );
	}

	delete( adVersion: AdVersion ) {
		this.store$.dispatch( new VersionsActions.Delete( adVersion.AdVersionID ) );
	}

	edit( adVersion: AdVersion ) {
		this.editMode = true;
		this.store$.dispatch( new VersionsActions.Select( adVersion ) );
	}

	imageHeight(): number {
		return AdImageTypes.find( type => type.Type === this.selectedAdImageType.Type ).ImagesThumbnailHeight;
	}

	new() {
		if ( this.nameExistsSubscription ) {
			this.nameExistsSubscription.unsubscribe();
		}

		this.dialogRef = this.dialog.open( NewComponent, { data: { facilityId: this.facility.Id, unitModel: this.selectedUnitModel } } );

		this.dialogRef.afterClosed()
			.subscribe( ( adVersion: AdVersion ) => {
				if ( adVersion.Name !== '' ) {
					this.nameExistsSubscription = this.nameExists$
						.pipe( distinctUntilChanged() )
						.subscribe( ( nameExists ) => {
							if ( nameExists ) {
								this.dialogService.alert( 'Create Ad Version', `Version name: "${adVersion.Name}" already exists.` )
									.subscribe( () => {
										this.nameExistsSubscription.unsubscribe();
										this.store$.dispatch( new VersionsActions.NameExistsReset() );
									} );
							}
						} );

					this.store$.dispatch( new VersionsActions.New( adVersion ) );
				}
			} );
	}

	newSet( event: Event ) {
		event.stopPropagation();
		this.setsComponent.new();
	}

	onListImages( adImageType: AdImageType ) {
		this.editMode = false;
		this.store$.dispatch( new AdminActions.SelectAdImageType( adImageType ) );
		this.store$.dispatch( new ImagesActions.List( new AdImageListParameters( this.facility.Id, this.selectedUnitModel.Id, adImageType.Type ) ) );
	}

	onClearImages( adImageType: AdImageType = this.selectedAdImageType ) {
		this.store$.dispatch( new ImagesActions.Clear() );
	}

	opened( adVersion: AdVersion ) {
		this.store$.dispatch( new VersionsActions.Select( adVersion ) );

		if ( this.editMode && this.selected && this.selected.AdVersionID !== adVersion.AdVersionID ) {
			this.editMode = false;
		}

		if ( this.editMode ) {
			this.store$.dispatch( new SetsActions.Clear() );
			this.editForm.get( 'name' ).setValue( adVersion.Name );
		} else {
			this.store$.dispatch( new SetsActions.List( adVersion.AdVersionID ) );
		}
	}

	publish( adVersion: AdVersion ) {
		this.store$.dispatch( new VersionsActions.Publish( new AdVersionPublishParameters( adVersion.AdVersionID, false, true, this.selectedUnitModel.Id ) ) );
	}

	rename( event: Event = null ) {
		if ( event ) {
			event.stopPropagation();
		}

		if ( this.nameExistsSubscription ) {
			this.nameExistsSubscription.unsubscribe();
		}

		this.nameExistsSubscription = this.nameExists$
			.pipe( distinctUntilChanged() )
			.subscribe( ( nameExists ) => {
				if ( nameExists ) {
					this.dialogService.alert( 'Ad Version Rename', `Version name: "${this.name}" already exists.` )
						.subscribe( () => {
							this.reset();
							this.nameExistsSubscription.unsubscribe();
							this.store$.dispatch( new VersionsActions.NameExistsReset() );
						} );
				}
			} );

		this.store$.dispatch( new VersionsActions.Rename( new AdVersionRenameParameters( this.selected.AdVersionID, this.name ) ) );
	}

	reset( event: Event = null ) {
		if ( event ) {
			event.stopPropagation();
		}

		this.editForm.reset( { name: this.selected.Name } );
	}
}
