import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { DialogService } from '../../../shared/dialogs/dialog.service';

import { UploadComponent as UploadComponent } from './upload/upload.component';
import { Facility } from '../../../facilities/facilities.model';
import { AdImageUploadParameters } from '../images.model';

@Component( {
	selector: 'ct-ads-images-bulk-actions',
	templateUrl: './bulk-actions.component.html',
	styleUrls: ['./bulk-actions.component.css']
} )
export class BulkActionsComponent {
	@Input() facility: Facility;

	@Output( 'download' ) downloadImages = new EventEmitter<void>();
	@Output( 'upload' ) uploadImages = new EventEmitter<AdImageUploadParameters>();
	@Output( 'purge' ) purgeImages = new EventEmitter<void>();

	private dialogRef: MatDialogRef<UploadComponent>;

	constructor(
		private dialog: MatDialog,
		private dialogService: DialogService
	) { }

	download() {
		this.dialogService.confirm( 'Download Images', 'Are You Sure?' )
			.subscribe( positive => positive ? this.downloadImages.emit() : null );
	}

	upload() {
		this.dialogRef = this.dialog.open( UploadComponent, { data: this.facility } );
		this.dialogRef.afterClosed()
			.subscribe( ( uploadParameters: AdImageUploadParameters ) => uploadParameters ? this.uploadImages.emit( uploadParameters ) : null );
	}

	purge() {
		this.dialogService.confirm( 'Purge Unused Images', 'Are You Sure?' )
			.subscribe( ( positive: boolean ) => positive ? this.purgeImages.emit() : null );
	}
}
