import { NgModule } from '@angular/core';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { LoginEffects } from './login.effects';
import { reducer } from './login.reducer';

import { CartTracAngularModule } from '../cart-trac-angular.module';
import { CartTracMaterialModule } from '../cart-trac-material.module';

import { LoginComponent } from './login.component';
import { LoginService } from './login.service';
import { LoginFormComponent } from './login-form/login-form.component';

@NgModule( {
	declarations: [
		LoginComponent,
		LoginFormComponent
	],
	imports: [
		CartTracAngularModule,
		CartTracMaterialModule,

		StoreModule.forFeature( 'login', reducer ),
		EffectsModule.forFeature( [LoginEffects] )
	],
	providers: [LoginService]
} )
export class LoginModule { }
