import { ActionReducerMap, createSelector, createFeatureSelector } from '@ngrx/store';

import * as fromAdManagerImages from './ad-manager/images/images.reducer';
import * as fromAdManagerVersions from './ad-manager/versions/versions.reducer';
import * as fromAdManagerSets from './ad-manager/sets/sets.reducer';
import * as fromAdManagerLists from './ad-manager/lists/lists.reducer';
import * as fromAdManagerAds from './ad-manager/ads/ads.reducer';
import * as fromAdmin from './admin/admin.reducer';
import * as fromAdminAlarms from './admin/alarms/alarms.reducer';
import * as fromAdminRecurringMessages from './admin/recurring-messages/recurring-messages.reducer';
import * as fromBingMap from './shared/bing-map/bing-map.reducer';
import * as fromCourses from './courses/courses.reducer';
import * as fromDevices from './device-manager/devices/devices.reducer';
import * as fromFacilities from './facilities/facilities.reducer';
import * as fromLayout from './layout/layout.reducer';
import * as fromLogin from './login/login.reducer';
import * as fromRanger from './ranger/ranger.reducer';
import * as fromRouter from '@ngrx/router-store';
import * as fromSystem from './system/system.reducer';
import * as fromSystemStatus from './system/status/status.reducer';
import * as fromTrace from './trace/trace.reducer';
import * as fromTraceParameters from './trace-parameters/trace-parameters.reducer';
import * as fromTrackers from './device-manager/trackers/trackers.reducer';
import * as fromTracking from './tracking/tracking.reducer';
import * as fromZonesVersions from './zones/zones-versions/zones-versions.reducer';

import { TraceDevice } from './trace-parameters/trace-device.model';
import { VisibleDeviceMode } from './settings/settings.model';

export interface State {
	adManagerAds: fromAdManagerAds.State;
	adManagerImages: fromAdManagerImages.State;
	adManagerLists: fromAdManagerLists.State;
	adManagerSets: fromAdManagerSets.State;
	adManagerVersions: fromAdManagerVersions.State;
	admin: fromAdmin.State;
	adminAlarms: fromAdminAlarms.State;
	adminRecurringMessages: fromAdminRecurringMessages.State;
	bingMap: fromBingMap.State;
	courses: fromCourses.State;
	devices: fromDevices.State;
	facilities: fromFacilities.State;
	layout: fromLayout.State;
	login: fromLogin.State;
	ranger: fromRanger.State;
	router: fromRouter.RouterReducerState;
	system: fromSystem.State;
	systemStatus: fromSystemStatus.State;
	trace: fromTrace.State;
	traceParameters: fromTraceParameters.State;
	trackers: fromTrackers.State;
	tracking: fromTracking.State;
	zonesVersions: fromZonesVersions.State;
}

export const reducers: ActionReducerMap<State> = {
	adManagerAds: fromAdManagerAds.reducer,
	adManagerImages: fromAdManagerImages.reducer,
	adManagerLists: fromAdManagerLists.reducer,
	adManagerSets: fromAdManagerSets.reducer,
	adManagerVersions: fromAdManagerVersions.reducer,
	admin: fromAdmin.reducer,
	adminAlarms: fromAdminAlarms.reducer,
	adminRecurringMessages: fromAdminRecurringMessages.reducer,
	bingMap: fromBingMap.reducer,
	courses: fromCourses.reducer,
	devices: fromDevices.reducer,
	facilities: fromFacilities.reducer,
	layout: fromLayout.reducer,
	login: fromLogin.reducer,
	ranger: fromRanger.reducer,
	router: fromRouter.routerReducer,
	system: fromSystem.reducer,
	systemStatus: fromSystemStatus.reducer,
	trace: fromTrace.reducer,
	traceParameters: fromTraceParameters.reducer,
	trackers: fromTrackers.reducer,
	tracking: fromTracking.reducer,
	zonesVersions: fromZonesVersions.reducer
};

/**
 * AdManagerAds Selectors
 */
export const getAdManagerAdsState = createFeatureSelector<fromAdManagerAds.State>( 'adManagerAds' );
export const getAdManagerAdsProgress = createSelector( getAdManagerAdsState, fromAdManagerAds.getProgress );
export const getAdManagerAds = createSelector( getAdManagerAdsState, fromAdManagerAds.getAll );
export const getSelectedAd = createSelector( getAdManagerAdsState, fromAdManagerAds.getSelectedAd );

/**
 * AdManagerImages Selectors
 */
export const getAdManagerImagesState = createFeatureSelector<fromAdManagerImages.State>( 'adManagerImages' );
export const getAdManagerImagesProgress = createSelector( getAdManagerImagesState, fromAdManagerImages.getProgress );
export const getAdManagerImages = createSelector( getAdManagerImagesState, fromAdManagerImages.getAllSorted );
export const getAdManagerBulkActionCount = createSelector( getAdManagerImagesState, fromAdManagerImages.getBulkActionCount );
export const getSelectedAdImage = createSelector( getAdManagerImagesState, fromAdManagerImages.getSelected );

/**
 * AdManagerLists Selectors
 */
export const getAdManagerListsState = createFeatureSelector<fromAdManagerLists.State>( 'adManagerLists' );
export const getAdManagerListsProgress = createSelector( getAdManagerListsState, fromAdManagerLists.getProgress );
export const getAdManagerLists = createSelector( getAdManagerListsState, fromAdManagerLists.getAll );
export const getAdManagerDropListRef = createSelector( getAdManagerListsState, fromAdManagerLists.getDropListRef );
export const getSelectedAdList = createSelector( getAdManagerListsState, fromAdManagerLists.getSelectedAdList );

/**
 * AdManagerSets Selectors
 */
export const getAdManagerSetsState = createFeatureSelector<fromAdManagerSets.State>( 'adManagerSets' );
export const getAdManagerSetsProgress = createSelector( getAdManagerSetsState, fromAdManagerSets.getProgress );
export const getAdManagerSets = createSelector( getAdManagerSetsState, fromAdManagerSets.getAll );
export const getSelectedAdSet = createSelector( getAdManagerSetsState, fromAdManagerSets.getSelectedAdSet );
export const getAdManagerSetNameExists = createSelector( getAdManagerSetsState, fromAdManagerSets.getNameExists );

/**
 * AdManagerVersions Selectors
 */
export const getAdManagerVersionsState = createFeatureSelector<fromAdManagerVersions.State>( 'adManagerVersions' );
export const getAdManagerVersionsProgress = createSelector( getAdManagerVersionsState, fromAdManagerVersions.getProgress );
export const getAdManagerVersions = createSelector( getAdManagerVersionsState, fromAdManagerVersions.getAllSorted );
export const getSelectedAdVersion = createSelector( getAdManagerVersionsState, fromAdManagerVersions.getSelectedAdVersion );
export const getAdManagerVersionNameExists = createSelector( getAdManagerVersionsState, fromAdManagerVersions.getNameExists );

/**
 * Admin Selectors
 */
export const getAdminState = createFeatureSelector<fromAdmin.State>( 'admin' );
export const getAdminProgress = createSelector( getAdminState, fromAdmin.getProgress );
export const getMap = createSelector( getAdminState, fromAdmin.getMap );
export const getSelectedUnitModel = createSelector( getAdminState, fromAdmin.getSelectedUnitModel );
export const getSelectedAdImageType = createSelector( getAdminState, fromAdmin.getSelectedAdImageType );

/**
 * AdminAlarms Selectors
 */
export const getAlarmsState = createFeatureSelector<fromAdminAlarms.State>( 'adminAlarms' );
export const getAlarm = createSelector( getAlarmsState, fromAdminAlarms.getAlarm );
export const getAlarmsPhoneNumbers = createSelector( getAlarmsState, fromAdminAlarms.getPhoneNumbers );
export const getAlarmsProgress = createSelector( getAlarmsState, fromAdminAlarms.getProgress );

/**
 * AdminRecurringMessages Selectors
 */
export const getRecurringMessagesState = createFeatureSelector<fromAdminRecurringMessages.State>( 'adminRecurringMessages' );
export const getRecurringMessages = createSelector( getRecurringMessagesState, fromAdminRecurringMessages.getAll );
export const getRecurringMessagesSelected = createSelector( getRecurringMessagesState, fromAdminRecurringMessages.getSelected );
export const getRecurringMessagesProgress = createSelector( getRecurringMessagesState, fromAdminRecurringMessages.getProgress );

/**
 * BingMap Selectors
 */
export const getBingMapState = createFeatureSelector<fromBingMap.State>( 'bingMap' );
export const getBingMapFullscreen = createSelector( getBingMapState, fromBingMap.getFullscreen );

/**
 * Courses Selectors
 */
export const getCoursesState = createFeatureSelector<fromCourses.State>( 'courses' );
export const getCoursesProgress = createSelector( getCoursesState, fromCourses.getProgress );
export const getCourses = createSelector( getCoursesState, fromCourses.getCourses );
export const getHoles = createSelector( getCoursesState, fromCourses.getHoles );

/**
 * Devices Selectors
 */
export const getDevicesState = createFeatureSelector<fromDevices.State>( 'devices' );
export const getDevicesExpanded = createSelector( getDevicesState, fromDevices.getExpanded );
export const getDevicesProgress = createSelector( getDevicesState, fromDevices.getProgress );
export const getDevicesDTO = createSelector( getDevicesState, fromDevices.getAllSorted );
export const getDevicesSelected = createSelector( getDevicesState, fromDevices.getSelected );

/**
 * Facilities Selectors
 */
export const getFacilitiesState = createFeatureSelector<fromFacilities.State>( 'facilities' );
export const getFacilitiesProgress = createSelector( getFacilitiesState, fromFacilities.getProgress );
export const getFacilities = createSelector( getFacilitiesState, fromFacilities.getAllSorted );
export const getFacility = createSelector( getFacilitiesState, fromFacilities.getFacility );

/**
 * Login Selectors
 */
export const getLoginState = createFeatureSelector<fromLogin.State>( 'login' );
export const getLoginProgress = createSelector( getLoginState, fromLogin.getProgress );
export const getWebLogin = createSelector( getLoginState, fromLogin.getWebLogin );
export const getWebUser = createSelector( getLoginState, fromLogin.getWebUser );
export const getSystemUser = createSelector( getWebUser, ( webUser => webUser && webUser.Role === 2 || false ) );
export const getLoggedIn = createSelector( getWebUser, ( webUser => webUser !== null ) );
export const getSettingsModes = createSelector( getWebUser, ( webUser => webUser && webUser.Settings && webUser.Settings.Tracking && webUser.Settings.Tracking.Modes || null ) );

/**
 * Layout Selectors
 */
export const getLayoutState = createFeatureSelector<fromLayout.State>( 'layout' );
export const getFeatureName = createSelector( getLayoutState, fromLayout.getComponentName );
export const getOpenSidenav = createSelector( getLayoutState, fromLayout.getOpenSidenav );

/**
 * Ranger Selectors
 */
export const getRangerState = createFeatureSelector<fromRanger.State>( 'ranger' );
export const getRangerProgress = createSelector( getRangerState, fromRanger.getProgress );
export const getRangerData = createSelector( createSelector( getRangerState, fromRanger.getRangerData ), getWebUser,
	( rangerData, webUser ) => {
		rangerData.map( course =>
			course.RangerDataHoles.map( hole =>
				hole.RangerDataDevices = hole.RangerDataDevices.filter( device =>
					webUser.Settings.Tracking.Modes.indexOf( device.Mode ) >= 0 )
			)
		);

		return rangerData;
	}
);

/**
 * System Selectors
 */
export const getSystemState = createFeatureSelector<fromSystem.State>( 'system' );
export const getSystemProgress = createSelector( getSystemState, fromSystem.getProgress );
export const getModels = createSelector( getSystemState, fromSystem.getModels );
export const getModes = createSelector( getSystemState, fromSystem.getModes );
export const getPickedFacility = createSelector( getSystemState, fromSystem.getPickedFacility );
export const getPickedMode = createSelector( getSystemState, fromSystem.getPickedMode );
export const getPickedModel = createSelector( getSystemState, fromSystem.getPickedModel );
export const getVisibleDeviceModes = createSelector( getSettingsModes, getModes,
	( settingsModes, modes ) =>
		modes.map( ( mode ) => {
			const checked = settingsModes.find( settingMode => mode.Id === settingMode ) ? true : false;

			return new VisibleDeviceMode( mode.Id, mode.Name, checked );
		} )
);

/**
 * SystemStatus Selectors
 */
export const getSystemStatusState = createFeatureSelector<fromSystemStatus.State>( 'systemStatus' );
export const getStatusProgress = createSelector( getSystemStatusState, fromSystemStatus.getProgress );
export const getStatusTrackingServer = createSelector( getSystemStatusState, fromSystemStatus.getStatusTrackingServer );
export const getStatusOther = createSelector( getSystemStatusState, fromSystemStatus.getStatusOther );

/**
 * Trace Selectors
 */
export const getTraceState = createFeatureSelector<fromTrace.State>( 'trace' );
export const getTraceProgress = createSelector( getTraceState, fromTrace.getProgress );
export const getTracePoints = createSelector( getTraceState, fromTrace.getTracePoints );

/**
 * TraceParameters Selectors
 */
export const getTraceParametersState = createFeatureSelector<fromTraceParameters.State>( 'traceParameters' );
export const getTraceParameters = createSelector( getTraceParametersState, fromTraceParameters.getTraceParameters );
export const getTraceDevices = createSelector( getDevicesDTO, devices => devices.map( device => new TraceDevice( device.Id, device.Number ) ) );

/**
 * Trackers Selectors
 */
export const getTrackersState = createFeatureSelector<fromTrackers.State>( 'trackers' );
export const getTrackersProgress = createSelector( getTrackersState, fromTrackers.getProgress );
export const getTrackers = createSelector( getTrackersState, fromTrackers.getAllSorted );
export const getTrackersRefresh = createSelector( getTrackersState, fromTrackers.getRefresh );
export const getSelectedTracker = createSelector( getTrackersState, fromTrackers.getSelected );

/**
 * Tracking Selectors
 */
export const getTrackingState = createFeatureSelector<fromTracking.State>( 'tracking' );
export const getTrackingProgress = createSelector( getTrackingState, fromTracking.getProgress );
export const getTrackingDevices = createSelector( createSelector( getTrackingState, fromTracking.getTrackingDevices ), getDevicesDTO, getModes,
	( trackingDevices, devices, modes ) =>
		trackingDevices.map( trackingDevice => {
			const device = devices.find( d => d.Id === trackingDevice.Id );

			if ( device ) {
				trackingDevice.Mode = device.ModeId;
				trackingDevice.ModeName = modes.find( mode => mode.Id === device.ModeId ).Name;
				trackingDevice.Name = device.Name;
				trackingDevice.Number = device.Number;
			}

			return trackingDevice;
		} )
);

/**
 * ZonesVersions Selectors
 */
export const getZonesVersionsState = createFeatureSelector<fromZonesVersions.State>( 'zonesVersions' );
export const getZonesVersionsProgress = createSelector( getZonesVersionsState, fromZonesVersions.getProgress );
export const getZonesVersions = createSelector( getZonesVersionsState, fromZonesVersions.getVersions );
export const getSelectedZonesVersion = createSelector( getZonesVersionsState, fromZonesVersions.getSelectedVersion );
export const getHolesLocationsCount = createSelector( getZonesVersionsState, fromZonesVersions.getHolesLocationsCount );

/**
 * Progress Indicators
 */
export const getShowProgressA = createSelector(
	getAdminProgress, getAdManagerImagesProgress, getCoursesProgress, getDevicesProgress, getFacilitiesProgress, getLoginProgress,
	( adminProgress, adsImagesProgress, coursesProgress, devicesProgress, facilitiesProgress, loginProgress ) =>
		( adminProgress || adsImagesProgress || coursesProgress || devicesProgress || facilitiesProgress || loginProgress ) );

export const getShowProgressB = createSelector(
	getSystemProgress, getTraceProgress, getZonesVersionsProgress, getAlarmsProgress, getRecurringMessagesProgress, getAdManagerVersionsProgress, getAdManagerSetsProgress,
	( systemProgress, traceProgress, zonesVersionsProgress, alarmsProgress, recurringmessagesProgress, adManagerVersionsProgress, adManagerSetsProgress ) =>
		systemProgress || traceProgress || zonesVersionsProgress || alarmsProgress || recurringmessagesProgress || adManagerVersionsProgress || adManagerSetsProgress );

export const getShowProgressC = createSelector(
	getAdManagerListsProgress, getAdManagerAdsProgress, getTrackersProgress,
	( adManagerListsProgress, adManagerAdsProgress, trackersProgress ) =>
		adManagerListsProgress || adManagerAdsProgress || trackersProgress );

export const getShowProgress = createSelector( getShowProgressA, getShowProgressB, getShowProgressC,
	( progressA, progressB, progressC ) => progressA || progressB || progressC );


////////////////////////////
// Combined Selectors - These belong to the selector group, e.g. Devices, but all of the used selectors have to be defined before use.
////////////////////////////
export const getDevices = createSelector( getDevicesDTO, getModels, getModes, ( devices, models, modes ) =>
	devices.map( ( device => {
		device.ModelName = models.find( model => model.Id === device.ModelId ).Name;
		device.ModeName = modes.find( mode => mode.Id === device.ModeId ).Name;

		return device;
	} )
	)
);


