import { NgModule } from '@angular/core';
import { FacilitySelectorComponent } from './facility-selector.component';
import { FacilitySelectorListComponent } from './list/list.component';
import { FacilitySelectorListItemComponent } from './list/item/item.component';
import { CartTracAngularModule } from '../../cart-trac-angular.module';
import { CartTracMaterialModule } from '../../cart-trac-material.module';
import { FacilitiesModule } from '../../facilities/facilities.module';

@NgModule( {
	declarations: [
		FacilitySelectorComponent,
		FacilitySelectorListComponent,
		FacilitySelectorListItemComponent
	],
	exports: [
		FacilitySelectorComponent
	],
	imports: [
		CartTracAngularModule,
		CartTracMaterialModule,
		FacilitiesModule
	]
} )
export class FacilitySelectorModule { }
