
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { WebApiService } from '../shared/web-api/web-api.service';
import { WebApiError } from '../shared/web-api/web-api-error';
import { WebLogin, MobileSettingsDTO, WebUser } from './login.model';

@Injectable()
export class LoginService {
	private serviceBaseUrl: string;

	constructor( private webApiService: WebApiService ) {
		this.serviceBaseUrl = this.webApiService.serverUrl + '/api/logins';
	}

	login( webLogin: WebLogin ): Observable<WebUser | WebApiError> {
		return this.webApiService.post( this.serviceBaseUrl + '/login', webLogin );
	}

	saveSettings( mobileSettings: MobileSettingsDTO ): Observable<MobileSettingsDTO | WebApiError> {
		return this.webApiService.put( this.serviceBaseUrl + '/settings', mobileSettings );
	}
}

