<mat-accordion class="headers-align">
	<div>
		<mat-expansion-panel id="{{adList.AdListID}}" cdkDropList #adsDropList="cdkDropList" (cdkDropListDropped)="dropAdImage($event)"
		 #listPanel *ngFor="let adList of adLists" (opened)="opened(adList)" (closed)="closed(adList)">
			<mat-expansion-panel-header>
				<mat-panel-title>
					<mat-icon class="material-icons">list</mat-icon>
					<span class="panel-title-text">{{adList.Name}}</span>
				</mat-panel-title>
				<mat-panel-description>
					<span *ngIf="!listPanel.expanded" class="list-description">{{adList.AdCount}}</span>
				</mat-panel-description>
			</mat-expansion-panel-header>

			<ct-ad-manager-ads *matExpansionPanelContent [courseNumber]="courseNumber" [ads]="ads$ | async" [selected]="selectedAd$ | async"
			 [courses]="courses$ | async" [holes]="holes$ | async" [published]="published"></ct-ad-manager-ads>
		</mat-expansion-panel>
	</div>
</mat-accordion>