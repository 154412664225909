import { NgModule } from '@angular/core';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AdminEffects } from './admin.effects';
import { reducer } from './admin.reducer';

import { CartTracAngularModule } from '../cart-trac-angular.module';
import { CartTracMaterialModule } from '../cart-trac-material.module';

import { AdminRoutingModule } from './admin-routing.module';
import { RecurringMessagesModule } from './recurring-messages/recurring-messages.module';
import { AlarmsModule } from './alarms/alarms.module';

import { AdminComponent } from './admin.component';
import { MapService } from './map/map.service';
import { CoursesService } from '../courses/courses.service';
import { DevicesService } from '../device-manager/devices/devices.service';
import { GpsUpdatesComponent } from './gps-updates/gps-updates.component';
import { GPSUpdatesService } from './gps-updates/gps-updates.service';

@NgModule( {
	declarations: [
		AdminComponent,
		GpsUpdatesComponent
	],
	imports: [
		CartTracAngularModule,
		CartTracMaterialModule,

		AdminRoutingModule,

		RecurringMessagesModule,
		AlarmsModule,

		StoreModule.forFeature( 'admin', reducer ),
		EffectsModule.forFeature( [AdminEffects] )
	],
	providers: [
		MapService,
		CoursesService,
		DevicesService,
		GPSUpdatesService
	]
} )
export class AdminModule { }
