import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import * as ListsActions from './lists.actions';
import * as RoutingActions from '../../routing/routing.actions';
import * as fromCartTrac from '../../cart-trac.reducers';

import { ListsService } from './lists.service';
import { DialogService } from '../../shared/dialogs/dialog.service';
import { AdList } from './lists.model';
import { WebApiError } from '../../shared/web-api/web-api-error';

@Injectable()
export class ListsEffects {

	@Effect()
	delete$ = this.actions$.pipe(
		ofType( ListsActions.DELETE ),
		map( ( action: ListsActions.Delete ) => action.id ),
		switchMap( id => this.listsService.delete( id ).pipe(
			map( () => new ListsActions.DeleteSuccess() ),
			catchError( ( error: WebApiError ) => of( new ListsActions.DeleteFail( error ) ) )
		) ) );

	@Effect()
	list$ = this.actions$.pipe(
		ofType( ListsActions.LIST ),
		map( ( action: ListsActions.List ) => action.adSetId ),
		switchMap( adSetId => this.listsService.list( adSetId ).pipe(
			map( ( lists: AdList[] ) => new ListsActions.ListSuccess( lists ) ),
			catchError( ( error: WebApiError ) => of( new ListsActions.ListFail( error ) ) )
		) )
	);

	@Effect()
	new$ = this.actions$.pipe(
		ofType( ListsActions.NEW ),
		map( ( action: ListsActions.New ) => action.adList ),
		mergeMap( adList => this.listsService.new( adList ).pipe(			// switchMap unsubscribes when second Observable arrives, that cancels the http request
			map( ( newAdList: AdList ) => new ListsActions.NewSuccess( newAdList ) ),
			catchError( ( error: WebApiError ) => of( new ListsActions.NewFail( error ) ) )
		) )
	);

	@Effect()
	update$ = this.actions$.pipe(
		ofType( ListsActions.UPDATE ),
		map( ( action: ListsActions.Update ) => action.adList ),
		switchMap( adList => this.listsService.update( adList ).pipe(
			map( ( updatedAdList: AdList ) => new ListsActions.UpdateSuccess( updatedAdList ) ),
			catchError( ( error: WebApiError ) => of( new ListsActions.UpdateFail( error ) ) )
		) )
	);

	@Effect( { dispatch: false } )
	fail$ = this.actions$.pipe(
		ofType( ListsActions.DELETE_FAIL, ListsActions.LIST_FAIL, ListsActions.NEW_FAIL, ListsActions.UPDATE_FAIL ),
		tap( ( action: ListsActions.DeleteFail | ListsActions.ListFail | ListsActions.NewFail | ListsActions.UpdateFail ) =>
			this.dialogService.webApiError( `ListsEffects - ${action.type}`, action.error ).subscribe(
				() => this.store$.dispatch( new RoutingActions.Go( { path: ['/login'] } ) )
			)
		)
	);

	constructor(
		private actions$: Actions,
		private store$: Store<fromCartTrac.State>,
		private listsService: ListsService,
		private dialogService: DialogService
	) { }
}
