import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { StoreModule } from '@ngrx/store';
import { reducer } from './layout.reducer';

import { CartTracMaterialModule } from '../cart-trac-material.module';

import { BrandingComponent } from './toolbar/branding/branding.component';
import { LayoutComponent } from './layout.component';
import { NavItemComponent } from './sidenav/nav-item/nav-item.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { ToolbarComponent } from './toolbar/toolbar.component';

export const COMPONENTS = [
	BrandingComponent,
	LayoutComponent,
	NavItemComponent,
	SidenavComponent,
	ToolbarComponent
];

@NgModule( {
	declarations: COMPONENTS,
	exports: COMPONENTS,
	imports: [
		CommonModule,
		RouterModule,
		CartTracMaterialModule,

		StoreModule.forFeature( 'layout', reducer ),
	]
} )
export class LayoutModule { }
