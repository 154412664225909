import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { WebApiService } from '../../shared/web-api/web-api.service';
import { WebApiError } from '../../shared/web-api/web-api-error';
import { Device, DeviceMoveParameters } from './devices.model';

@Injectable()
export class DevicesService {

	private serviceBaseUrl: string;

	constructor( private webApiService: WebApiService ) {
		this.serviceBaseUrl = this.webApiService.serverUrl + '/api/devices';
	}

	delete( id: number ): Observable<void | WebApiError> {
		return this.webApiService.delete( this.serviceBaseUrl + '/' + id );
	}

	list( facilityId: number ): Observable<Device[] | WebApiError> {
		return this.webApiService.get<Device[]>( this.serviceBaseUrl + '/' + facilityId );
	}

	move( parameters: DeviceMoveParameters ): Observable<DeviceMoveParameters | WebApiError> {
		return this.webApiService.put( this.serviceBaseUrl + '/move', parameters );
	}

	new( device: Device ): Observable<Device | WebApiError> {
		return this.webApiService.post( this.serviceBaseUrl, device );
	}

	update( device: Device ): Observable<Device | WebApiError> {
		return this.webApiService.put( this.serviceBaseUrl, device );
	}

	upload( devices: Device[] ) {
		//////////////////////////////////////////
	}
}
