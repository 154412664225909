import { Injectable } from '@angular/core';

@Injectable()
export class BrowserDetectionService
{
	isMobile(): boolean
	{
		return /Android|webOS|iPhone|iPad|iPod|BlackBerry|Opera Mini|IEMobile/i.test( navigator.userAgent );
	}
}
