import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromCartTrac from '../cart-trac.reducers';
import * as LoginActions from './login.actions';
import * as LayoutActions from '../layout/layout.actions';

import { WebLogin } from './login.model';
import { environment } from '../../environments/environment';

@Component( {
	selector: 'ct-login',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css']
} )
export class LoginComponent implements OnInit {

	version: string = environment.version;
	webLogin$: Observable<WebLogin>;

	constructor( private store$: Store<fromCartTrac.State> ) {
		this.webLogin$ = this.store$.select( fromCartTrac.getWebLogin );
	}

	ngOnInit() {
		this.store$.dispatch( new LayoutActions.FeatureName( 'LOGIN' ) );
	}

	login( webLogin: WebLogin ) {
		this.store$.dispatch( new LoginActions.Login( webLogin ) );
	}
}
