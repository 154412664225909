import { Component, OnInit } from '@angular/core';

@Component( {
	selector: 'ct-users',
	templateUrl: './user-manager.component.html',
	styleUrls: ['./user-manager.component.css']
} )
export class UserManagerComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}

	tabChanged( tabIndex: number ) {
	}
}
