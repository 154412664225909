<div id="courses-holes-list">
	<mat-toolbar>
		<ct-courses-holes-actions></ct-courses-holes-actions>
	</mat-toolbar>
	<table>
		<thead>
			<tr>
				<th>Number</th>
				<th>Tee Lat / Long</th>
				<th>Green Lat / Long</th>
				<th>Yardage</th>
				<th>&nbsp;&nbsp;Par&nbsp;&nbsp;</th>
				<th>CPO</th>
				<th>Name</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let hole of holes" (click)="select(hole)">
				<td>{{hole.Number}}</td>
				<td>{{hole.TeeBoxLatitude | number:'1.6-6'}}&nbsp;{{hole.TeeBoxLongitude | number:'1.6-6'}}&nbsp;</td>
				<td>&nbsp;{{hole.CenterLatitude | number:'1.6-6'}}&nbsp;{{hole.CenterLongitude | number:'1.6-6'}}</td>
				<td>{{hole.Yardage}}</td>
				<td>{{hole.Par}}</td>
				<td>
					<mat-checkbox [ngModel]="hole.CPO" disabled="true"></mat-checkbox>
				</td>
				<td>{{hole.Name}}</td>
			</tr>
		</tbody>
	</table>
</div>