<mat-toolbar mat-dialog-title color="secondary">Download Devices</mat-toolbar>
<div>
	<mat-dialog-content align="center">
		<p><em>Facility:</em>&nbsp;<strong>{{data.facility.Name}}</strong></p>
		<p><em>Device Count:</em>&nbsp;<strong>{{data.devices.length}}</strong></p>
		<div>
			<mat-form-field>
				<mat-label>File Name</mat-label>
				<input matInput type="text" class="file-name" required mdMaxLength="50" [(ngModel)]="fileName"
					name="folderName" autofocus />
			</mat-form-field>
		</div>

		<p><em>&nbsp;{{line1}}&nbsp;</em></p>
		<p><strong>&nbsp;{{line2}}&nbsp;</strong></p>
	</mat-dialog-content>
	<mat-dialog-actions align="center">
		<button mat-raised-button (click)="dialogRef.close()">Close</button>
		<button mat-raised-button mat-primary [disabled]="done" (click)="download()">Download</button>
	</mat-dialog-actions>
</div>