import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Device } from '../devices.model';

@Component( {
	selector: 'ct-devices-list',
	templateUrl: './list.component.html',
	styleUrls: ['./list.component.css']
} )
export class ListComponent {

	@Input() devices: Device[];
	@Input() expanded: boolean;

	// tslint:disable-next-line:no-output-native
	@Output() select = new EventEmitter<Device>();

	constructor() { }

	onSelect( device: Device ) {
		this.select.emit( device );
	}
}
